import React from "react";

import SearchView from "../../components/SearchView/SearchView";
import { gql } from "@apollo/client";
import { ExtendedGridColDef, getTableMeta } from "../common/data-grid-cols";
import { GridColDef } from "@mui/x-data-grid-pro";

const LettersPage = () => {
  const { columns, filters } = getTableMeta("letters");

  const lettersQuery = (columnsToFilter: GridColDef[]) => {
    const queryFields = columnsToFilter
      .filter(
        (column) =>
          !column.hide &&
          column.field !== "property_pages" &&
          column.field !== "edit_letter_modal" &&
          column.field !== "lob_pdf_url"
      )
      .map((column) => {
        if (column.field === "add_view_comments_modal") {
          return `add_view_comments_modal {
            count
            foreign_id
            foreign_type
            most_recent_comment_body
            most_recent_comment_id
            total
            unread
          }`;
        }
        return column.field;
      })
      .join("\n");

    return gql`
      query($q: KibanaQL="", $phrase: String, $size: Int, $from: Int, $sort: String) {
        results: letters(
          search: { query: $q, phrase: $phrase, size: $size, from: $from, sort: $sort }
        ) {
          size
          total
          hits {
            id
            ${queryFields}
            lob_pdf_url
            property_ids
          }
        }
      }
    `;
  };

  const renderColumn = (column: ExtendedGridColDef): GridColDef => {
    return { ...column };
  };

  return (
    <SearchView
      searchQuery={lettersQuery}
      columns={columns
        .filter(
          (item) =>
            item.field !== "property_pages" && item.field !== "lob_pdf_url"
        )
        .map((column: ExtendedGridColDef) => renderColumn(column))}
      filters={filters}
      title="Letters"
    />
  );
};

export default LettersPage;
