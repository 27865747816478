import { faChevronRight, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import "./ReviewedValue.scss";

interface ReviewedValueInterface {
  formValue: string | number | string[] | number[];
  initialValue: string | number | string[] | number[];
  valueType: string;
  onClick: () => void;
}

export const ReviewedValue = ({
  formValue,
  initialValue,
  valueType,
  onClick,
}: ReviewedValueInterface) => {
  const [showEditIcon, setShowEditIcon] = useState<boolean>(false);

  return (
    <div className="flex-row">
      <div className="flex-element">
        <div className="informationTileWrapper">
          <Typography className="font-light">
            {initialValue || "Unknown"}
          </Typography>
          <Typography variant="body2" className="description">
            Current {valueType}{" "}
          </Typography>
        </div>
      </div>
      <div className="flex-chevron">
        <FontAwesomeIcon icon={faChevronRight} style={{ height: "0.8em" }} />
      </div>
      <div
        className={classNames(
          "flex-element",
          "flex-element-values",
          { infoGreyValue: showEditIcon },
          "hoverClassValue"
        )}
        onMouseEnter={() => setShowEditIcon(true)}
        onMouseLeave={() => setShowEditIcon(false)}
        onClick={() => onClick()}
      >
        <div className="informationTileValue">
          <Typography className="text-emphasis">{formValue}</Typography>
          <Typography variant="body2" className="description">
            New {valueType}
          </Typography>
        </div>
        <div className="iconContainer">
          {showEditIcon && (
            <FontAwesomeIcon icon={faPencil} className="iconWrapperValue" />
          )}
        </div>
      </div>
    </div>
  );
};
