import React from "react";
import { ApolloProvider } from "@apollo/client/react";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "apollo-link-context";

import { useAuth0 } from "@auth0/auth0-react";

import env from "./env";

//
function AuthorizedApolloProvider({ children }) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const httpLink = createHttpLink({ uri: env.API_URL });

  const authLink = setContext(async () => {
    let headers = {};

    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      headers.Authorization = `Bearer ${token}`;
    }
    headers.ReactDashboard = "true";

    return {
      headers: headers,
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default AuthorizedApolloProvider;
