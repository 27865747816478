import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { PropsWithChildren } from "react";
import { faFile } from "@fortawesome/free-solid-svg-icons";

export type IconType =
  | "airbnb"
  | "booking"
  | "vrbo"
  | "flipkey"
  | "homeaway"
  | "file"
  | "bars"
  | "step1"
  | "step2"
  | "step3"
  | "step4";

interface IconProps {
  type: IconType;
  classNameWrapper?: string;
  className?: string;
  extension?: string;
}

export const Icon: React.FC<PropsWithChildren<IconProps>> = ({
  type,
  className,
  classNameWrapper,
  extension = "svg",
  children,
}) => {
  return (
    <span className={classNameWrapper}>
      {type === "file" ? (
        <FontAwesomeIcon icon={faFile} className={className} />
      ) : (
        <img src={`/icons/${type}.${extension}`} className={className} alt="" />
      )}
      {children}
    </span>
  );
};
