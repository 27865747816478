import React, { useContext } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./SearchViewToolbar.scss";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { JurisdictionContext } from "../../dashboard/common/JurisdictionContext";

interface Data {
  results: Array<any>;
  size: number;
  total: number;
}

interface DataResponse {
  results: Data;
}

export interface SearchViewToolbarInterface {
  searchValue: string;
  handleSearch: any;
  title: string;
  data: DataResponse;
  fromRows: number;
  toRows: number;
  readOnly: boolean;
  handleShowCheckBoxes: any;
  showCheckboxes: boolean;
  handleClearSelection: any;
  handleViewTemplateButtonClick: any;
  openTemplatesMenu: boolean;
  anchorEl: any;
  handleClose: any;
  templates: any;
  handleTemplateClick: any;
  loading: boolean;
  selectedTemplate: any;
}

const SearchViewToolbar = ({
  searchValue,
  handleSearch,
  title,
  data,
  fromRows,
  toRows,
  readOnly,
  handleShowCheckBoxes,
  handleClearSelection,
  showCheckboxes,
  handleViewTemplateButtonClick,
  openTemplatesMenu,
  anchorEl,
  handleClose,
  templates,
  handleTemplateClick,
  loading,
  selectedTemplate,
}: SearchViewToolbarInterface) => {
  const { showSendLetter } = useContext(JurisdictionContext);

  const handleItemClick = (selectedMenuItem: string) => {
    handleTemplateClick(selectedMenuItem);
  };

  return (
    <Toolbar className={`${title.toLowerCase()}-search search-bar`}>
      <div className="search-container">
        <IconButton>
          <FontAwesomeIcon icon={faSearch} />
        </IconButton>

        <InputBase
          placeholder={`Search ${title}`}
          value={searchValue}
          inputProps={{ "aria-label": "search" }}
          readOnly={readOnly}
          onChange={handleSearch}
          style={{
            minWidth: "400px",
          }}
        />
      </div>
      {data && data.results && (
        <div>
          {fromRows}-{toRows} of {data.results.total}
        </div>
      )}
      {title.toLocaleLowerCase() === "rental units" && showSendLetter && (
        <div className="sendLetter">
          {!showCheckboxes && (
            <Button
              sx={{ float: "right" }}
              variant="contained"
              className="submitButton"
              onClick={handleShowCheckBoxes}
            >
              Send Letters
            </Button>
          )}
        </div>
      )}
      {showCheckboxes && (
        <>
          <div className="hideSendLetter">
            <Button
              variant="text"
              className="clearSelectionButton"
              onClick={handleClearSelection}
            >
              Clear Selection
            </Button>
          </div>
          <div className="sendLetter">
            <Button
              className="sendLetterTemplates"
              variant="contained"
              onClick={handleViewTemplateButtonClick}
            >
              Select Template
              {openTemplatesMenu ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Button>
          </div>
          <Menu
            anchorEl={anchorEl}
            open={openTemplatesMenu}
            onClose={() => handleClose()}
          >
            {templates?.map((item: any, i: number) => [
              <MenuItem
                key={i}
                id={item.letter_template_id}
                onClick={() => handleItemClick(item)}
              >
                <div className="progressSpin">
                  {loading &&
                    selectedTemplate.letter_template_id ===
                      item.letter_template_id && (
                      <CircularProgress thickness={2} size={20} />
                    )}
                </div>
                {item.customer_display_name}
              </MenuItem>,
            ])}
          </Menu>
        </>
      )}
    </Toolbar>
  );
};

export default SearchViewToolbar;
