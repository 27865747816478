import { Box, Checkbox, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./MatchDetailCheckbox.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEquals } from "@fortawesome/free-solid-svg-icons";

export interface MatchDetailCheckboxProps {
  checked: boolean;
  onCheckboxChange: (value: boolean) => void;
  evidence: {
    comments: string;
    photo_url: string;
    screenshot_url: string;
    evidence_url: string;
  };
}

export const MatchDetailCheckbox = (props: MatchDetailCheckboxProps) => {
  const [checked, setChecked] = useState<boolean>(props.checked);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    props.onCheckboxChange(event.target.checked);
  };

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  return (
    <Box>
      <Grid container className="gridContainer" alignItems={"center"}>
        <Grid item>
          <Checkbox
            checked={checked}
            onChange={handleChange}
            id={props.evidence.comments}
          />
        </Grid>
        <Grid className="grid-item-container" item container xs={5}>
          <Grid item xs={3} className="gridItem">
            <img src={props.evidence.photo_url} alt="" className="image" />
          </Grid>
          <Grid item xs={1} className="equalsToSign">
            <FontAwesomeIcon icon={faEquals}></FontAwesomeIcon>
          </Grid>
          <Grid item xs={3} className="gridItem">
            <img src={props.evidence.screenshot_url} alt="" className="image" />
          </Grid>
          <Grid item xs={5} className="gridItem2">
            <Typography className="comment" variant="body2">
              {props.evidence.comments}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
