import jsonLogic from "json-logic-js";

//
jsonLogic.add_operation("json", JSON.parse);
jsonLogic.add_operation("stringify", JSON.stringify);
jsonLogic.add_operation("keys", Object.keys);
jsonLogic.add_operation("first", (a) => a[0]);
jsonLogic.add_operation("last", (a) => a.slice(-1)[0]);
jsonLogic.add_operation("array", (a, b) => new Array(a).fill(b));
jsonLogic.add_operation("num", (a) => Number(a));
jsonLogic.add_operation("join", (a, b) => a.join(b));

//
class QuestionnairesParser {
  logic(subject, context) {
    if (typeof subject === "object" && subject !== null) {
      try {
        // JSONLogic
        subject = jsonLogic.apply(subject, context.get());
      } catch (e) {
        // noop: this wasn't JSONLogic
      }
    }
    return subject;
  }

  interpolate(subject, context) {
    if (typeof subject === "string") {
      // Replace dynamic keys with their values from the context
      subject = subject.replace(/\[([^\]]+)\]/g, (match) => {
        let key = match.slice(1, -1).trim();
        let res = key.split(".").reduce((obj, key) => obj[key], context);
        return `[${res}]`;
      });

      let value = subject.replace(/\{\{[^}]+\}\}/g, (match) => {
        let result = match
          .slice(2, -2)
          .trim()
          .split(".")
          .reduce((searchObject, key) => {
            if (key.includes("[") && key.includes("]")) {
              return this.handleArrayNotation(searchObject, key);
            } else {
              return key.includes("+")
                ? this.handleAdditionNotation(searchObject, key)
                : searchObject && searchObject[key];
            }
          }, context);
        return typeof result === "object"
          ? JSON.stringify(result)
          : result === undefined
          ? match
          : result;
      });

      return this.isValidJson(value) ? JSON.parse(value) : value;
    } else {
      return subject;
    }
  }

  handleArrayNotation(searchObject, key) {
    let [mainKey, subKey] = key.split("[");
    subKey = subKey.replace("]", "");
    return searchObject && searchObject[mainKey][subKey];
  }

  handleAdditionNotation(searchObject, key) {
    return key.split("+").reduce((field, value) => {
      let fieldName = field.trim();
      let number = value.trim();
      return (
        searchObject[fieldName] + (isNaN(number) ? number : parseInt(number))
      );
    });
  }

  isValidJson(str) {
    try {
      return typeof JSON.parse(str) !== "number";
    } catch (e) {
      return false;
    }
  }

  stripTags(subject) {
    let tags = [],
      tag = "";
    for (var i = 1; i < arguments.length; i++) {
      tag = arguments[i].replace(/<|>/g, "").trim();
      if (arguments[i].length > 0) {
        tags.push(tag, "/" + tag);
      }
    }

    if (!(typeof subject === "string") && !(subject instanceof String)) {
      return "";
    } else if (tags.length === 0) {
      return subject.replace(new RegExp("<(s*/?)[^>]+>", "g"), "");
    } else {
      return subject.replace(
        new RegExp("<(?!(" + tags.join("|") + ")s*/?)[^>]+>", "g"),
        ""
      );
    }
  }

  parse(subject, context) {
    if (subject !== null) {
      if (typeof subject === "object") {
        try {
          // JSONLogic
          subject = this.logic(subject, context);
        } catch (e) {
          // noop: this wasn't JSONLogic
        }
      }

      if (subject && typeof subject === "object") {
        const isArray = Array.isArray(subject);
        isArray
          ? subject
          : Object.keys(subject).forEach((val, i) => {
              const key = isArray ? i : val;
              subject[key] = this.parse(subject[key], context);
            });
      } else if (typeof subject === "string") {
        subject = this.interpolate(subject, context.get());
        subject = this.stripTags(
          subject,
          "a",
          "strong",
          "em",
          "br",
          "ul",
          "li"
        );
      }
    }
    return subject;
  }

  getContextValue(value, context) {
    let temp = parser.interpolate(value, context.get());
    return typeof temp !== "object"
      ? temp.includes("{{") && temp.includes("}}")
        ? ""
        : temp
      : temp;
  }
}

const parser = new QuestionnairesParser();
export default parser;
