import React, { useContext, useState } from "react";
import ListingGallery from "../ListingGallery/ListingGallery";
import AddressInformation from "../AddressInformation/AddressInformation";
import "./RentalUnitDetailsPrintFormat.scss";
import { ListingInterface, RentalUnitContext } from "../RentalUnitContext";
import { GoogleMap, Marker } from "@react-google-maps/api";
import {
  Box,
  IconButton,
  Paper,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { Icon } from "../../../../components/Icon/Icon";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty, parseSubsite } from "../../../common/utils";
import { faCircle, faExpand } from "@fortawesome/free-solid-svg-icons";

const RentalUnitDetailsPrintFormat = (props: any) => {
  document.title = `Rental Unit Record`;
  const { rentalUnit, activities, listings } = useContext(RentalUnitContext);

  const parseToHumanReadable = (data: string): string => {
    const str: Array<string> = data.split(/_/g).map((item) => {
      return item[0] + item.slice(1).toLowerCase();
    });
    return str.reduce((a, b) => a + " " + b);
  };

  const mapToDataGrid = () => {
    if (activities) {
      return activities?.hits.map((item, index) => {
        return {
          id: index,
          date: dayjs(item.published_at).format("MM/DD/YYYY"),
          activity: parseToHumanReadable(item.type),
          details: item.summary,
        };
      });
    }
  };

  const StatusIndicatorTooltip = styled(
    ({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    )
  )(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      marginBottom: "5px !important",
    },
  }));

  const getListingStatusIndicator = (status: boolean) => {
    return (
      <FontAwesomeIcon
        className="icon"
        icon={faCircle}
        color={status ? "rgb(0, 173, 130)" : "grey"}
      />
    );
  };

  const isMatchDetailsExist = () => {
    const newListingMatchData = listings?.hits.filter(
      (item: ListingInterface) =>
        item.id === props.selectedListing.internal_listing_id
    )[0];
    let hideMatches = props.isIdentified !== true;
    if (
      newListingMatchData &&
      newListingMatchData.analysis && // Check 2 : Hiding Matches if there is no match data in selected listing.
      isEmpty(newListingMatchData.analysis.comments) &&
      isEmpty(newListingMatchData.analysis.created_at) &&
      isEmpty(newListingMatchData.analysis.evidence) &&
      (isEmpty(newListingMatchData.analysis.matches) ||
        (newListingMatchData.analysis.matches &&
          (isEmpty(newListingMatchData.analysis.matches.owner_name) ||
            !newListingMatchData.analysis.matches.owner_name) &&
          isEmpty(newListingMatchData.analysis.matches.city) &&
          isEmpty(newListingMatchData.analysis.matches.postal_code))) &&
      isEmpty(newListingMatchData.analysis.worker_id)
    ) {
      hideMatches = true;
    }

    return hideMatches;
  };

  const [data] = useState(mapToDataGrid());

  return (
    <Box
      style={{
        maxWidth: "1366px",
        margin: "auto",
        paddingLeft: "16px",
      }}
    >
      <div className="print gallery">
        <div className="complianceStatusWrapper">
          <p style={{ fontSize: "24px", marginRight: "10px" }}>
            {rentalUnit ? rentalUnit?.situs_address.formatted : ""}
          </p>
          {props.complianceStatus}
        </div>
      </div>
      <div className="gallery">
        <ListingGallery />
      </div>

      <div className="di-flex">
        <div className="gallery" style={{ maxWidth: "33%", width: "33%" }}>
          <div className="addressInfo">
            <AddressInformation />
          </div>

          <div
            style={{
              paddingTop: "60px",
              pageBreakInside: "avoid",
              backgroundColor: "#fff",
            }}
          >
            <GoogleMap
              center={{
                lat: rentalUnit?.situs_address.position.latitude,
                lng: rentalUnit?.situs_address.position.longitude,
              }}
              zoom={17}
              mapContainerStyle={{ width: "79%", height: "300px" }}
            >
              <Marker
                position={{
                  lat: rentalUnit?.situs_address.position.latitude,
                  lng: rentalUnit?.situs_address.position.longitude,
                }}
                title={rentalUnit?.situs_address.formatted}
              />
            </GoogleMap>
            <div>
              {rentalUnit?.situs_address.position.latitude}
              {", "}
              {rentalUnit?.situs_address.position.longitude}
              {" (Lat, Long)"}
            </div>
          </div>
        </div>
        <div className="listingInfo">
          {rentalUnit ? (
            <Box className="listingInformationContainer">
              <TabContext value={String(0)}>
                <Box className="tabList">
                  <TabList>
                    <Tab
                      className="tabLabel"
                      key={props.selectedListing.id}
                      value={String(0)}
                      label={
                        <Typography variant="body1" className="bold" key={0}>
                          <Icon
                            className="subsiteIcon"
                            type={parseSubsite(
                              props.selectedListing.internal_subsite_name
                            )}
                          />
                          <span className="labelText">
                            {props.selectedListing.internal_listing_id.toUpperCase()}
                          </span>
                          <StatusIndicatorTooltip
                            title={
                              (props.selectedListing.is_advertised
                                ? "Active"
                                : "Inactive") + " Listing"
                            }
                            placement="top"
                            arrow
                          >
                            <span>
                              {getListingStatusIndicator(
                                props.selectedListing.is_advertised
                              )}
                            </span>
                          </StatusIndicatorTooltip>
                        </Typography>
                      }
                    />
                  </TabList>
                </Box>
                <TabPanel key={0} value={String(0)} sx={{ padding: 0 }}>
                  <TableContainer
                    component={Paper}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Table className="table">
                      <colgroup>
                        <col style={{ width: "40%" }} />
                        <col style={{ width: "60%" }} />
                      </colgroup>
                      <TableBody>
                        <TableRow className="pageBreak">
                          <TableCell variant={"body"}>
                            <Typography variant="body1" className="bold">
                              URL
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              <a href={props.selectedListing.listing_url}>
                                {props.selectedListing.listing_url}
                              </a>
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow className="pageBreak">
                          <TableCell>
                            <Typography variant="body1" className="bold">
                              Listing status
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {props.selectedListing.is_advertised
                                ? "Active"
                                : "Inactive"}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow className="pageBreak">
                          <TableCell>
                            <Typography variant="body1" className="bold">
                              Listing ID
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {props.selectedListing.internal_listing_id}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow className="pageBreak">
                          <TableCell>
                            <Typography variant="body1" className="bold">
                              Listing title
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {props.selectedListing.listing_title}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow className="pageBreak">
                          <TableCell>
                            <Typography variant="body1" className="bold">
                              Platform name
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {props.selectedListing.internal_subsite_name}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow className="pageBreak">
                          <TableCell>
                            <Typography variant="body1" className="bold">
                              Bedrooms
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {props.selectedListing.listing_bedrooms}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow className="pageBreak">
                          <TableCell>
                            <Typography variant="body1" className="bold">
                              Cleaning fee in USD
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {props.selectedListing
                                .listing_cleaning_fee_in_usd && (
                                <span>
                                  {"$" +
                                    props.selectedListing
                                      .listing_cleaning_fee_in_usd}
                                </span>
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow className="pageBreak">
                          <TableCell>
                            <Typography variant="body1" className="bold">
                              Daily rate in USD
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {props.selectedListing
                                .listing_daily_rate_in_usd && (
                                <span>
                                  {"$" +
                                    props.selectedListing
                                      .listing_daily_rate_in_usd}
                                </span>
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow className="pageBreak">
                          <TableCell>
                            <Typography variant="body1" className="bold">
                              Host provided name
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {props.selectedListing.listing_host_provided_name}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow className="pageBreak">
                          <TableCell>
                            <Typography variant="body1" className="bold">
                              Max people per bedroom
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {
                                props.selectedListing
                                  .listing_max_number_of_people_per_bedroom
                              }
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow className="pageBreak">
                          <TableCell>
                            <Typography variant="body1" className="bold">
                              Max sleeping capacity
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {
                                props.selectedListing
                                  .listing_max_sleeping_capacity
                              }
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow className="pageBreak">
                          <TableCell>
                            <Typography variant="body1" className="bold">
                              Min nights
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {props.selectedListing.listing_min_nights}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow className="pageBreak">
                          <TableCell>
                            <Typography variant="body1" className="bold">
                              Property type
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {props.selectedListing.listing_property_type}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow className="pageBreak">
                          <TableCell>
                            <Typography variant="body1" className="bold">
                              Review count
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {props.selectedListing.listing_review_count}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow className="pageBreak">
                          <TableCell>
                            <Typography variant="body1" className="bold">
                              Listing room type
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {props.selectedListing.listing_room_type}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow className="pageBreak">
                          <TableCell>
                            <Typography variant="body1" className="bold">
                              Bathrooms
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {props.selectedListing.listing_total_bathrooms}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow className="pageBreak">
                          <TableCell>
                            <Typography variant="body1" className="bold">
                              View screenshot history
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            {props.hasScreenshots ? (
                              <IconButton>
                                <FontAwesomeIcon icon={faExpand} />
                              </IconButton>
                            ) : (
                              <Typography variant="body1">
                                We do not have screenshots for this listing.
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow className="pageBreak">
                          <TableCell>
                            <Typography variant="body1" className="bold">
                              View match details
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {!isMatchDetailsExist() ? (
                                <IconButton>
                                  <FontAwesomeIcon icon={faExpand} />
                                </IconButton>
                              ) : (
                                "Property has not yet been identified."
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              </TabContext>
            </Box>
          ) : null}
        </div>
      </div>
      <div className="activityTimelineDiv">
        <Typography
          variant="h6"
          sx={{ display: "table" }}
          className="bold title"
        >
          Activity Timeline
        </Typography>
        <table className="table activityTimeline">
          <tbody>
            <tr>
              <th>Date</th>
              <th>Activity</th>
              <th>Details</th>
            </tr>
            {data?.map((row: any) => {
              return (
                <tr key={row.id} className="borderBottom">
                  <td>{row.date}</td>
                  <td>{row.activity}</td>
                  <td>{row.details}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="footer">Total Rows: {activities?.hits.length}</div>
      </div>
    </Box>
  );
};

export default RentalUnitDetailsPrintFormat;
