import React from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./MatchDetails.scss";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { ListingInterface } from "../../RentalUnitContext";

export interface MatchDetailsProps {
  matchData: ListingInterface;
}

const MatchDetails = (props: MatchDetailsProps) => {
  const matchIcon = (matchValue: any) => {
    return <FontAwesomeIcon icon={matchValue ? faCircleCheck : faCircle} />;
  };

  const matchColor = (matchValue: any) => {
    return {
      color: matchValue ? "#000" : "rgba(0,0,0,0.25)",
    };
  };

  return (
    <Box className="matchDetails">
      {props.matchData ? (
        <div>
          {props.matchData.analysis?.worker_id && (
            <Typography variant="h6" className="bold">
              Analyst
            </Typography>
          )}
          <Typography variant="body1" sx={{ fontWeight: 300 }}>
            {props.matchData.analysis?.worker_id}
          </Typography>
          <Typography variant="h6" className="bold">
            Match Type
          </Typography>
          <Typography
            variant="body1"
            sx={{
              ...matchColor(
                props.matchData.analysis?.matches &&
                  props.matchData.analysis?.matches.owner_name
              ),
              fontWeight: 300,
            }}
          >
            {matchIcon(
              props.matchData.analysis?.matches &&
                props.matchData.analysis?.matches.owner_name
            )}{" "}
            Owner Name
          </Typography>
          <Typography
            sx={{
              ...matchColor(
                props.matchData.analysis?.matches &&
                  props.matchData.analysis?.matches.postal_code
              ),
              fontWeight: 300,
            }}
          >
            {matchIcon(
              props.matchData.analysis?.matches &&
                props.matchData.analysis?.matches.postal_code
            )}{" "}
            Zip Code
          </Typography>
          <Typography
            sx={{
              ...matchColor(
                props.matchData.analysis?.matches &&
                  props.matchData.analysis?.matches.city
              ),
              fontWeight: 300,
            }}
          >
            {matchIcon(
              props.matchData.analysis?.matches &&
                props.matchData.analysis?.matches.city
            )}{" "}
            City Match
          </Typography>
          {props.matchData.analysis?.comments && (
            <Typography variant="h6" className="bold">
              Explanation
            </Typography>
          )}
          <Typography variant="body1" sx={{ fontWeight: 300 }}>
            {props.matchData.analysis?.comments}
          </Typography>

          <Paper
            sx={{
              minHeight: "250px",
              boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
              padding: "0 10px",
              marginBottom: "50px",
            }}
          >
            <Grid
              columns={9}
              container
              justifyContent="center"
              alignItems="center"
              className="matchPhotosList"
            >
              <Grid container direction={"row"} sx={{ marginBottom: "10px" }}>
                <Grid item xs={4}>
                  <Typography variant="h6" className="bold">
                    Listing Photos
                  </Typography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4}>
                  <Typography variant="h6" className="bold">
                    Matching 3rd Party Sources
                  </Typography>
                </Grid>
              </Grid>
              {props.matchData.analysis?.evidence.length === 0 ? (
                <Grid
                  container
                  direction={"row"}
                  justifyContent="center"
                  alignItems="flex-end"
                >
                  <Grid item xs={5}>
                    <Typography variant="body1" sx={{ fontWeight: 300 }}>
                      No listing photos available.
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1" sx={{ fontWeight: 300 }}>
                      No matched photos available.
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                props.matchData.analysis?.evidence
                  .filter((item: any) => item)
                  .map((evidence: any, index: number) => (
                    <Grid
                      container
                      direction={"row"}
                      key={index}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={4}>
                        <img
                          src={evidence.photo_url}
                          style={{ maxWidth: "100%" }}
                          alt="Photo"
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Typography
                          variant="h1"
                          className="bold"
                          style={{
                            textAlign: "center",
                            color: "#4CAF50",
                            fontWeight: 300,
                          }}
                        >
                          =
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <img
                          src={evidence.screenshot_url}
                          style={{ maxWidth: "100%" }}
                          alt="Screenshot"
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        xs={9}
                        direction={"row"}
                        key={index}
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        <Grid item xs={4} style={{ marginBottom: "25px" }}>
                          <Typography variant="body1" sx={{ fontWeight: 400 }}>
                            Comment
                          </Typography>
                          <Typography variant="body1" sx={{ fontWeight: 300 }}>
                            {evidence.comments}
                          </Typography>
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={4} style={{ marginBottom: "25px" }}>
                          <Typography variant="body1" sx={{ fontWeight: 400 }}>
                            Source
                          </Typography>
                          <Link
                            variant="body1"
                            sx={{ fontWeight: 300, wordWrap: "break-word" }}
                            href={evidence.evidence_url}
                            target="_blank"
                          >
                            {evidence.evidence_url}
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
              )}
            </Grid>
          </Paper>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      )}
    </Box>
  );
};
export default MatchDetails;
