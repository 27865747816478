import React, { useState, ChangeEvent, useEffect } from "react";
import { FormControl, Box } from "@mui/material";
import GenericModalBody from "../../../components/ModalComponent/GenericModalBody";
import { loader } from "graphql.macro";
import { useMutation, useQuery } from "@apollo/client";
import EditOwnerInformationForm from "./ReportOwnerInformation/EditOwnerInformationForm";
import "./ReportIssue.scss";

interface ReportIssueProps {
  row: any;
  setSnackbarData: any;
  closeModal?: any;
  setModalWidth?: any;
  reloadGrid: () => any;
  openEditAIModal?: () => any;
  isDataUpdatedHandler?: (updated: boolean) => any;
}
export interface RentalUnitWithOwnerInfoInterface {
  parcel_number: string;
  situs_address: {
    position: {
      latitude: number;
      longitude: number;
    };
  };
  owner: {
    name: string;
    name_secondary: string;
    mailing_address: {
      city: string;
      line_1: string;
      state: string;
      postal_code: string;
      country_code: string;
    };
  } | null;
}

const LETTER_RETURNED_TEXT =
  "We will mark this address as undeliverable and not send further letters.";
const LETTER_RETURNED_TEXT_ON_SUBMIT =
  "We will mark this address as undeliverable and not send further letters. We’re applying your changes now. Because we have to recalculate some things, this may take a few seconds.";
const DONT_EDIT_OWNER_INFO_TEXT =
  "We will mark this address as undeliverable until we receive updated tax assessor records.";
const DONT_EDIT_OWNER_INFO_TEXT_ON_SUBMIT =
  "We will mark this address as undeliverable until we receive updated tax assessor records. We’re applying your changes now. Because we have to recalculate some things, this may take a few seconds.";
const LETTER_INCORRECT_IDENTIFICATION_TEXT =
  "OK, we will send this property back to our analysts for re-identification.";
const LETTER_INCORRECT_IDENTIFICATION_TEXT_ON_SUBMIT =
  "OK, we will send this property back to our analysts for re-identification. We’re applying your changes now. Because we have to recalculate some things, this may take a few seconds. ";
const SELECT_ISSUE_LABEL = "What was the issue?";
const SELECT_ISSUE_OPTIONS = [
  {
    value: "letterReturned",
    label: "Letter was returned",
  },
  {
    value: "ownerInformation",
    label: "Owner Information",
  },
  {
    value: "identificationAddress",
    label: "Identification Address",
  },
];
const HAS_OWNER_ADDRESS_LABEL =
  "Do you know the name and mailing address for the new owner?";
const HAS_CORRECT_PROPERTY_ADDRESS_LABEL =
  "Do you know the correct address for this property?";
const HAS_OWNER_ADDRESS_OPTIONS = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

const HAS_CORRECT_PROPERTY_ADDRESS_OPTIONS = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

const STEPS = {
  SELECT_ISSUE: 0,
  LETTER_RETURNED: 1.1,
  CHOOSE_UPDATE_OWNER_OR_NOT: 2.1,
  EDIT_OWNER_INFO: 2.2,
  DONT_EDIT_OWNER_INFO: 2.3,
  CHOOSE_UPDATE_IDENTIFIED_ADDRESS_OR_NOT: 3.1,
  UPDATE_IDENTIFIED_ADDRESS: 3.2,
  DONT_UPDATE_IDENTIFIED_ADDRESS: 3.3,
};

let MAX_MODAL_SIZE = "45%";
let MIN_MODAL_SIZE = "36%";

const updateModalSizes = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth <= 768) {
    MAX_MODAL_SIZE = "80%";
    MIN_MODAL_SIZE = "50%";
  } else if (screenWidth <= 1024) {
    MAX_MODAL_SIZE = "70%";
    MIN_MODAL_SIZE = "40%";
  } else if (screenWidth <= 1366) {
    MAX_MODAL_SIZE = "70%";
    MIN_MODAL_SIZE = "45%";
  } else {
    MAX_MODAL_SIZE = "55%";
    MIN_MODAL_SIZE = "36%";
  }
};
updateModalSizes();
window.addEventListener("resize", updateModalSizes);

const letterCorrection = loader("./LetterCorrection.graphql");
const getRUROwnerDetailsQuery = loader(
  "./ReportOwnerInformation/getRUROwnerDetails.graphql"
);

export const ReportIssue = (props: ReportIssueProps) => {
  const [selectedIssue, setSelectedIssue] = useState<string>("letterReturned");
  const [hasOwnerAddress, setHasOwnerAddress] = useState<boolean>(true);
  const [hasCorrectPropertyAddress, setHasCorrectPropertyAddress] =
    useState<boolean>(true);
  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [letterCorrectionMutation] = useMutation(letterCorrection);
  const [loadingRentalUnitData, setLoadingRentalUnitData] =
    useState<boolean>(false);
  const [rentalUnit, setRentalUnit] =
    useState<RentalUnitWithOwnerInfoInterface | null>(null);

  useEffect(() => {
    props.setModalWidth(MIN_MODAL_SIZE);
  }, []);

  const handleCorrection = async (status: string) => {
    setLoading(true);
    try {
      switch (status) {
        case "returned":
          setModalText(LETTER_RETURNED_TEXT_ON_SUBMIT);
          break;
        case "incorrect_identification":
          setModalText(LETTER_INCORRECT_IDENTIFICATION_TEXT_ON_SUBMIT);
          break;
      }

      await letterCorrectionMutation({
        variables: {
          letter_id: props.row.id,
          status: status,
        },
      });
      // takes some time on Elastic search to update the status, so reloading after a 3 secs delay
      setTimeout(() => {
        props.setSnackbarData({
          open: true,
          message: "Submitted successfully",
          severity: "success",
          vertical: "top",
          horizontal: "center",
        });
        setLoading(false);
        props.reloadGrid();
        props.closeModal();
      }, 3000);
    } catch (error) {
      setLoading(false);
      props.setSnackbarData({
        message: "Failed to submit the request, Please try again later",
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const handleDontKnowOwnerInformation = async () => {
    setLoading(true);
    try {
      setModalText(DONT_EDIT_OWNER_INFO_TEXT_ON_SUBMIT);
      await letterCorrectionMutation({
        variables: {
          letter_id: props.row.id,
          status: "undeliverable",
        },
      });
      setLoading(false);
      props.setSnackbarData({
        open: true,
        message: "Submitted successfully",
        severity: "success",
        vertical: "top",
        horizontal: "center",
      });
      props.closeModal();
    } catch (error) {
      setLoading(false);
      props.setSnackbarData({
        message: "Failed to submit the request, Please try again later",
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const handleIssueSelection = () => {
    props.setModalWidth(MIN_MODAL_SIZE);
    switch (selectedIssue) {
      case "letterReturned":
        setStep(STEPS.LETTER_RETURNED);
        break;
      case "ownerInformation":
        setStep(STEPS.CHOOSE_UPDATE_OWNER_OR_NOT);
        break;
      case "identificationAddress":
        setStep(STEPS.CHOOSE_UPDATE_IDENTIFIED_ADDRESS_OR_NOT);
        break;
    }
  };

  const handleIssueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedIssue(event.target.value);
  };

  const handleOwnerAddressCheck = (event: ChangeEvent<HTMLInputElement>) => {
    let value: boolean = false;
    if (event.target.value === "true") {
      value = true;
    } else if (event.target.value === "false") {
      value = false;
    }
    setHasOwnerAddress(value);
  };

  const handlePropertyAddressCheck = (event: ChangeEvent<HTMLInputElement>) => {
    let value: boolean = false;
    if (event.target.value === "true") {
      value = true;
    } else if (event.target.value === "false") {
      value = false;
    }
    setHasCorrectPropertyAddress(value);
  };

  const { refetch: refetchRentalUnit } = useQuery(getRUROwnerDetailsQuery, {
    skip: true,
  });

  const loadEditOwnerForm = async () => {
    if (!rentalUnit) {
      try {
        setLoadingRentalUnitData(true);
        const { data: rentalUnitData } = await refetchRentalUnit({
          id: props.row?.property_ids[0],
          q: `external_property_id:"${props.row?.property_ids[0]}"`,
          activities_query: `target_id:"${props.row?.property_ids[0]}"`,
        });
        if (rentalUnitData?.rentalUnit) {
          setRentalUnit(rentalUnitData?.rentalUnit);
        }
      } catch (error) {
        props.setSnackbarData({
          message: "Failed to load Owner Information",
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
      } finally {
        setLoadingRentalUnitData(false);
      }
    }
    props.setModalWidth(MAX_MODAL_SIZE);
    setStep(STEPS.EDIT_OWNER_INFO);
  };

  const handleOwnerInformationNext = () => {
    if (hasOwnerAddress) {
      loadEditOwnerForm();
    } else {
      setModalText(DONT_EDIT_OWNER_INFO_TEXT);
      setStep(STEPS.DONT_EDIT_OWNER_INFO);
    }
  };

  const handleIdentifiedAddressNext = async () => {
    if (hasCorrectPropertyAddress) {
      if (props.openEditAIModal) {
        props.openEditAIModal();
        props.closeModal();
      }
    } else {
      setModalText(LETTER_INCORRECT_IDENTIFICATION_TEXT);
      setStep(STEPS.DONT_UPDATE_IDENTIFIED_ADDRESS);
    }
  };

  useEffect(() => {
    switch (step) {
      case STEPS.LETTER_RETURNED:
        setModalText(LETTER_RETURNED_TEXT);
        break;
      case STEPS.SELECT_ISSUE:
        setLoading(false);
        break;
      case STEPS.CHOOSE_UPDATE_OWNER_OR_NOT:
        props.setModalWidth(MIN_MODAL_SIZE);
        break;
    }
  }, [step]);

  const updateFormData = (isUpdated: boolean) => {
    if (props.isDataUpdatedHandler) {
      props.isDataUpdatedHandler(isUpdated);
    }
  };

  return (
    <Box className="reportIssueBox">
      <FormControl component="fieldset" fullWidth>
        {step === STEPS.SELECT_ISSUE && (
          <GenericModalBody
            viewType="radio"
            radio={{
              name: "report-issue",
              value: selectedIssue,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                handleIssueChange(event),
              buttons: SELECT_ISSUE_OPTIONS,
              label: SELECT_ISSUE_LABEL,
            }}
            buttons={[
              {
                text: "Next",
                onClick: () => handleIssueSelection(),
                variant: "contained",
                color: "primary",
              },
            ]}
          />
        )}
        {step === STEPS.LETTER_RETURNED && (
          <GenericModalBody
            text={modalText}
            viewType="text"
            buttons={[
              {
                text: "Back",
                onClick: () => setStep(STEPS.SELECT_ISSUE),
                variant: "outlined",
                color: "primary",
              },
              {
                text: "Submit",
                onClick: () => handleCorrection("returned"),
                variant: "contained",
                color: "primary",
                loading: loading,
              },
            ]}
          />
        )}
        {step === STEPS.CHOOSE_UPDATE_OWNER_OR_NOT && (
          <GenericModalBody
            viewType="radio"
            radio={{
              name: "knows-owner-address",
              value: hasOwnerAddress,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                handleOwnerAddressCheck(event),
              buttons: HAS_OWNER_ADDRESS_OPTIONS,
              label: HAS_OWNER_ADDRESS_LABEL,
            }}
            buttons={[
              {
                text: "Back",
                onClick: () => setStep(STEPS.SELECT_ISSUE),
                variant: "outlined",
                color: "primary",
              },
              {
                text: "Next",
                onClick: () => handleOwnerInformationNext(),
                variant: "contained",
                color: "primary",
                loading: loadingRentalUnitData,
              },
            ]}
          />
        )}
        {step === STEPS.EDIT_OWNER_INFO && (
          <EditOwnerInformationForm
            goBack={() => setStep(STEPS.CHOOSE_UPDATE_OWNER_OR_NOT)}
            setSnackbarData={props.setSnackbarData}
            closeModal={props.closeModal}
            rentalUnitID={props.row?.property_ids[0]}
            rentalUnit={rentalUnit}
            loadingRentalUnitData
            reloadGrid={props.reloadGrid}
            updateFormData={updateFormData}
          />
        )}
        {step === STEPS.DONT_EDIT_OWNER_INFO && (
          <GenericModalBody
            text={modalText}
            viewType="text"
            buttons={[
              {
                text: "Back",
                onClick: () => setStep(STEPS.SELECT_ISSUE),
                variant: "outlined",
                color: "primary",
              },
              {
                text: "Submit",
                onClick: () => handleDontKnowOwnerInformation(),
                variant: "contained",
                color: "primary",
                loading: loading,
              },
            ]}
          />
        )}
        {step === STEPS.CHOOSE_UPDATE_IDENTIFIED_ADDRESS_OR_NOT && (
          <GenericModalBody
            viewType="radio"
            radio={{
              name: "knows-correct-property-address",
              value: hasCorrectPropertyAddress,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                handlePropertyAddressCheck(event),
              buttons: HAS_CORRECT_PROPERTY_ADDRESS_OPTIONS,
              label: HAS_CORRECT_PROPERTY_ADDRESS_LABEL,
            }}
            buttons={[
              {
                text: "Back",
                onClick: () => setStep(STEPS.SELECT_ISSUE),
                variant: "outlined",
                color: "primary",
              },
              {
                text: "Next",
                onClick: () => handleIdentifiedAddressNext(),
                variant: "contained",
                color: "primary",
              },
            ]}
          />
        )}
        {step === STEPS.DONT_UPDATE_IDENTIFIED_ADDRESS && (
          <GenericModalBody
            text={modalText}
            viewType="text"
            buttons={[
              {
                text: "Back",
                onClick: () =>
                  setStep(STEPS.CHOOSE_UPDATE_IDENTIFIED_ADDRESS_OR_NOT),
                variant: "outlined",
                color: "primary",
              },
              {
                text: "Submit",
                onClick: () => handleCorrection("incorrect_identification"),
                variant: "contained",
                color: "primary",
                loading: loading,
              },
            ]}
          />
        )}
      </FormControl>
    </Box>
  );
};

export default ReportIssue;
