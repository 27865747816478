
import React from 'react';

function View() {

  //
  return (
    <div>
      <h1>TODO3</h1>
    </div>
  );
}

export default View; // TODO3: auth for tasks
