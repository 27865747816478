//
import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography, FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material';

import { featureFlags } from '../../../common/feature-flags';

//
function View() {

  useEffect(() => {
    document.title = 'Beta Features | Host Compliance';
  });

  const [features] = useState([
    {
      id: 'local_api',
      summary: 'API, GraphQL API and WebSocket requests to local.hostcompliance.com'
    },
  ]);

  const [userFeatures, setUserFeatures] = useState(featureFlags.get());

  const toggleFeature = (id) => () => {
    userFeatures[id] = !userFeatures[id];
    setUserFeatures(userFeatures);
  };

  const save = () => {
    featureFlags.set(userFeatures);
    window.location.reload();
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}>
            <Typography variant="h5">Beta Features</Typography>
            <Typography variant="subtitle1">Preview upcoming features</Typography>
          </Grid>

          <Grid item xs={12} md={10}>
            <FormGroup>
              {
                features.map(item=>{
                  return(
                    <FormControlLabel
                      key={item.id}
                      label={item.summary}
                      control={
                        <Checkbox defaultChecked={userFeatures[item.id]} onChange={toggleFeature(item.id)} value={true} />
                      }
                    />
                  )
                })
              }
            </FormGroup>

            <Button onClick={save} variant="contained" color="primary">Save and Refresh</Button>

          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

//
export default View;
