import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import ModalComponent from "../../../../components/ModalComponent/ModalComponent";
import { EditAddressInformationModal } from "../../../rental-units/RentalUnitDetails/Modals/EditAddressInformation/EditAddressInformationModal";
import {
  ListingInterface,
  RentalUnitContext,
} from "../../../rental-units/RentalUnitDetails/RentalUnitContext";
import DialogComponent from "../../../../components/DialogComponent/DialogComponent";

interface ReportIssueModalCellProps {
  rowParams: any;
  title: string;
  cellText: string;
  children: any;
  showLoading?: () => any;
  stopLoading?: () => any;
  setSnackbarData?: any;
  reloadGrid: () => any;
}
const getRUROwnerDetailsQuery = loader(
  "./../ReportOwnerInformation/getRUROwnerDetails.graphql"
);

export const ReportIssueModalCell = (cellProps: ReportIssueModalCellProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [width, setWidth] = useState<string>("55%");
  const [openEditAddressInformationModal, setOpenEditAddressInformationModal] =
    useState<boolean>(false);
  const [rentalUnitData, setRentalUnitData] = useState<any>();
  const [selectedListingTable] = useState<number>(0);
  const [selectedListing, setSelectedListing] = useState<number>(0);
  const [isDataUpdated, setIsDataUpdated] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const confirmDialogContent = `Click “continue editing” to retrieve and submit your changes to the property's owner information. If you no longer wish to continue editing, click “discard changes."`;
  const infoText =
    "You have unsaved changes. If you exit, any changes you’ve inputted will be lost.";

  const { refetch: refetchRentalUnit } = useQuery(getRUROwnerDetailsQuery, {
    skip: true,
  });

  useEffect(() => {
    if (rentalUnitData) {
      setSelectedListing(
        rentalUnitData.listings?.hits.findIndex(
          (item: ListingInterface) =>
            item.id ===
            rentalUnitData.listing_tables?.hits[selectedListingTable]
              .internal_listing_id
        )
      );
    }
  }, [rentalUnitData, selectedListingTable]);

  const closeModal = () => {
    if (isDataUpdated) {
      setShowConfirmDialog(true);
      return;
    }
    setOpen(false);
  };

  const onDiscard = () => {
    setIsDataUpdated(false);
    setShowConfirmDialog(false);
    setOpen(false);
  };

  const fetchRentalUnitDetails = async () => {
    try {
      cellProps.showLoading?.();
      const { data: rentalUnitData } = await refetchRentalUnit({
        id: cellProps.rowParams.row?.property_ids[0],
        q: `external_property_id:"${cellProps.rowParams.row?.property_ids[0]}"`,
        activities_query: `target_id:"${cellProps.rowParams.row?.property_ids[0]}"`,
      });
      if (rentalUnitData) {
        setRentalUnitData(rentalUnitData);
        cellProps.stopLoading?.();
        setOpenEditAddressInformationModal(true);
      }
    } catch (error) {
      cellProps.setSnackbarData({
        message: "Failed to fetch Rental Unit details",
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      cellProps.stopLoading?.();
    }
  };

  const isDataUpdatedHandler = (updated: boolean) => {
    setIsDataUpdated(updated);
  };

  return (
    <>
      <Button
        variant="text"
        onClick={() => setOpen(true)}
        sx={{
          textTransform: "none",
          textDecoration: "underline",
        }}
      >
        {cellProps.cellText}
      </Button>
      <ModalComponent
        header={cellProps.title}
        open={open}
        onClose={() => {
          closeModal();
        }}
        width={width}
        sx={{
          margin: "auto",
        }}
      >
        {React.cloneElement(cellProps.children, {
          closeModal: closeModal,
          setModalWidth: setWidth,
          openEditAIModal: fetchRentalUnitDetails,
          isDataUpdatedHandler: isDataUpdatedHandler,
        })}
      </ModalComponent>
      <DialogComponent
        open={showConfirmDialog}
        handleClose={() => setShowConfirmDialog(false)}
        title={"Unsaved changes to address information"}
        contentInfoText={infoText}
        content={confirmDialogContent}
        onDiscard={onDiscard}
      ></DialogComponent>
      {rentalUnitData && (
        <RentalUnitContext.Provider
          value={{
            rentalUnitId: cellProps.rowParams.row?.property_ids[0],
            selectedListing: selectedListing,
            selectedListingTable: selectedListingTable,
            listingTables: rentalUnitData.listing_tables,
            rentalUnit: rentalUnitData.rentalUnit,
            listings: rentalUnitData.listings,
            activities: rentalUnitData.activities,
          }}
        >
          <EditAddressInformationModal
            open={openEditAddressInformationModal}
            closeModal={() => setOpenEditAddressInformationModal(false)}
            reloadGrid={cellProps.reloadGrid}
          />
        </RentalUnitContext.Provider>
      )}
    </>
  );
};
