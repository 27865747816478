
class FeatureFlags {
  storage = localStorage; // eslint-disable-line no-undef
  storageName = 'com.hostcompliance.features';

  set(subject) {
    return this.storage.setItem(this.storageName, JSON.stringify(subject));
  }

  get() {
    try {
      return this.storage.getItem(this.storageName) ? JSON.parse(this.storage.getItem(this.storageName)) : {};
    } catch (e) {
      console.error('HC: ', this.storageName, ' JSON is malformed.')
      return undefined;
    }
  }
}

const featureFlags = new FeatureFlags();

export { featureFlags }
