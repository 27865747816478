import React, { useState, useEffect } from "react";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import {
  Container,
  Typography,
  SwipeableDrawer,
  AppBar,
  Toolbar,
  IconButton,
  Breadcrumbs,
} from "@mui/material";
import Sidemenu from "../dashboard/common/sidemenu";
import { Icon } from "./../components/Icon/Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { LoadingScreen } from "../common/loading";
import EsriIntegrationCard from "./esri/components/EsriIntegrationCard";
import { useLazyQuery, gql } from "@apollo/client";
import { loader } from "graphql.macro";
import styles from "./IntegrationsPanel.module.scss";
import env from "../common/env";

const GET_PORTION_OF_ESRI_CONFIG = loader(
  "./queries/fetchPortionOfEsriConfig.graphql"
);

const GET_JURISDICTION = gql`
  query ($id: ID!) {
    jurisdiction(id: $id) {
      id
      name
      slug
    }
  }
`;

const INTEGRATIONS = {
  ESRI: {
    name: "Esri",
    description: "Manage Esri integration",
    link: "/integrations/esri",
    btnText: {
      create: "CONNECT TO ESRI",
      edit: "EDIT ESRI",
    },
  },
};

const IntegrationsPanel: React.FC = () => {
  const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [sidebarOpened, setSidebarOpened] = useState<boolean>(false);

  const [loadPortionOfEsriConfig, { data: esriData }] = useLazyQuery(
    GET_PORTION_OF_ESRI_CONFIG
  );
  const [
    loadJurisdictionData,
    { data: jurisdictionData, client: jurisdictionClient },
  ] = useLazyQuery(GET_JURISDICTION);

  useEffect(() => {
    document.title = `Integrations`;
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem("jurisdiction", user[env.AUTH0_METADATA_KEY].geoid);

      loadJurisdictionData({
        variables: { id: user[env.AUTH0_METADATA_KEY].geoid },
      });
      loadPortionOfEsriConfig({
        variables: { geoid: user[env.AUTH0_METADATA_KEY].geoid },
      });
    }
  }, [user]);

  useEffect(() => {
    async function setSession() {
      let session = {
        token_type: "Bearer",
        id_token: "",
        access_token: "",
      };
      session.access_token = await getAccessTokenSilently();
      session.id_token = (await getIdTokenClaims()).__raw;
      localStorage.setItem(
        "com.hostcompliance.session",
        JSON.stringify(session)
      ); // eslint-disable-line no-undef
    }

    setSession();
  }, [getAccessTokenSilently, getIdTokenClaims]);

  const toggleSidebar = (open: boolean) => (event: any) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    )
      return;
    setSidebarOpened(open);
  };

  return (
    <div>
      <div>
        <AppBar position="static" className={styles.AppBar}>
          <Toolbar>
            <IconButton
              className={styles.HamburgerMenu}
              aria-label="menu"
              onClick={toggleSidebar(true)}
            >
              <Icon type={"bars"} aria-label="menu" />
            </IconButton>
            <img
              src="https://cdn.hostcompliance.com/hostcompliance/Host-Compliance-icon-word.png"
              alt="Host Compliance"
              className={styles.AppBarIcon}
            />
            <Breadcrumbs>
              <Typography>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className={styles.ArrowIcon}
                />
                <span style={{ fontSize: "16px", fontWeight: 300 }}>
                  Integrations
                </span>
              </Typography>
            </Breadcrumbs>
          </Toolbar>
        </AppBar>
      </div>
      <Container maxWidth="xl">
        <EsriIntegrationCard integration={INTEGRATIONS.ESRI} data={esriData} />
      </Container>
      <SwipeableDrawer
        anchor="left"
        open={sidebarOpened}
        onClose={toggleSidebar(false)}
        onOpen={toggleSidebar(true)}
      >
        <Sidemenu
          closeSidebar={toggleSidebar(false)}
          jurisdictionClient={jurisdictionClient}
          jurisdictionData={jurisdictionData}
        />
      </SwipeableDrawer>
    </div>
  );
};

export default withAuthenticationRequired(IntegrationsPanel, {
  onRedirecting: LoadingScreen,
});
