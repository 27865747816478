import { EditableFieldOption } from "./data-grid-cols";

const VIOLATION_TRANSITIONS = [
  "33-28(C): Unlicensed short-term rental operation.",
  "33-28(C): Expired short-term rental license.",
  "33-28(D)(3): Exceeding rental capacity limit (12).",
  "N/A",
];

export const VIOLATION_OPTIONS: EditableFieldOption[] = [
  {
    id: "33-28(C): Unlicensed short-term rental operation.",
    label: "33-28(C): Unlicensed short-term rental operation.",
    transitions: VIOLATION_TRANSITIONS,
  },
  {
    id: "33-28(C): Expired short-term rental license.",
    label: "33-28(C): Expired short-term rental license.",
    transitions: VIOLATION_TRANSITIONS,
  },
  {
    id: "33-28(D)(3): Exceeding rental capacity limit (12).",
    label: "33-28(D)(3): Exceeding rental capacity limit (12).",
    transitions: VIOLATION_TRANSITIONS,
  },
  {
    id: "N/A",
    label: "N/A",
    transitions: VIOLATION_TRANSITIONS,
  },
];

const BOOLEAN_TRANSITIONS = ["Yes", "No", "N/A"];

export const BOOLEAN_OPTIONS: EditableFieldOption[] = [
  {
    id: "Yes",
    label: "Yes",
    transitions: BOOLEAN_TRANSITIONS,
  },
  {
    id: "No",
    label: "No",
    transitions: BOOLEAN_TRANSITIONS,
  },
  {
    id: "N/A",
    label: "N/A",
    transitions: BOOLEAN_TRANSITIONS,
  },
];
