import React, { useContext } from "react";
import {
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { EsriContext } from "../context/EsriContext";
import { useFormContext } from "react-hook-form";
import {
  EndpointDetail,
  EsriEndpointField,
  EndpointFields,
} from "../interfaces";

interface MappingProps {
  hcFieldName: string;
  userFriendlyName: string;
  helpText?: string;
}

const Mapping: React.FC<MappingProps> = ({
  hcFieldName,
  userFriendlyName,
  helpText,
}) => {
  const { state }: any = useContext(EsriContext);
  const { control, setValue, watch } = useFormContext();
  const mappingField = watch(`mappings.${hcFieldName}`);

  const onChangeWrapper = (event: any, onChange: any) => {
    event.target.value === ""
      ? setValue(`mappings.${hcFieldName}.field`, "")
      : setValue(`mappings.${hcFieldName}.field`, []);
    onChange(event);
  };

  return (
    <Box
      sx={{ marginY: "30px", paddingX: "70px" }}
      key={hcFieldName}
      display="flex"
    >
      <Box display="flex" flexDirection="column">
        <Typography sx={{ marginTop: "7px" }}>{userFriendlyName}</Typography>
        {helpText && (
          <Typography sx={{ fontSize: "0.8em", color: "#656665" }}>
            {helpText}
          </Typography>
        )}
      </Box>
      <Controller
        control={control}
        name={`mappings.${hcFieldName}.id`}
        render={({ field: { value, onChange } }) => (
          <FormControl
            variant="standard"
            sx={{ width: "200px", marginLeft: "auto", marginRight: 5 }}
          >
            <Select
              value={value}
              displayEmpty
              onChange={(event) => onChangeWrapper(event, onChange)}
            >
              <MenuItem value={""}>Default value</MenuItem>
              {state.endpoints_fields
                .sort(
                  (endpoint_1: EndpointFields, endpoint_2: EndpointFields) =>
                    endpoint_1.id > endpoint_2.id
                )
                .map((endpoint: EndpointDetail) => (
                  <MenuItem
                    value={String(endpoint.id)}
                    key={endpoint.id}
                  >{`Endpoint ${endpoint.id}`}</MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      />
      <Box display="flex" flexDirection="column">
        <Controller
          control={control}
          defaultValue={[]}
          name={`mappings.${hcFieldName}.field`}
          render={({ field: _field }) => {
            return mappingField["id"] === "" ? (
              <TextField
                sx={{ width: "200px", marginLeft: "auto", marginRight: 3 }}
                {..._field}
                onChange={(event) => _field.onChange([event.target.value])}
                variant="standard"
              ></TextField>
            ) : (
              <FormControl
                variant="standard"
                sx={{ width: "200px", marginLeft: "auto", marginRight: 3 }}
              >
                <Select
                  {..._field}
                  multiple
                  defaultValue={[]}
                  renderValue={(selected) => (
                    <Box display="flex" flexDirection="column">
                      {selected.map((field: string) => (
                        <span key={field}>{field}</span>
                      ))}
                    </Box>
                  )}
                >
                  {state.endpoints_fields
                    .find(
                      (used_field: EndpointDetail) =>
                        used_field.id == mappingField["id"]
                    )
                    ?.fields?.sort(
                      (
                        field_1: EsriEndpointField,
                        field_2: EsriEndpointField
                      ) => field_1.name.localeCompare(field_2.name, "en")
                    )
                    ?.map((item: EsriEndpointField) => (
                      <MenuItem value={item.name} key={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default Mapping;
