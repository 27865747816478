import {
  Box,
  ButtonUnstyled,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import { PrintTemplate } from "./PrintTemplate";
import "./Screenshots.scss";
import { LocalizationProvider, StaticDatePicker } from "@mui/lab";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";
import { formatDateTime, timeZone } from "../../../../common/utils";
import { RentalUnitContext, Screenshot } from "../../RentalUnitContext";

interface ScreenshotsProps {
  changeListing: (value: string) => void;
}

interface HasScreenshotsObject {
  [name: string]: boolean;
}

export const Screenshots = ({ changeListing }: ScreenshotsProps) => {
  const { listings, listingTables, selectedListing, selectedListingTable } =
    useContext(RentalUnitContext);
  const [screenshots, setScreenshots] = useState<Screenshot[]>();
  useEffect(() => {
    let screenshotsList = listings?.hits[selectedListing]?.screenshots;
    if (screenshotsList) setScreenshots(screenshotsList);
  }, [listings]);
  const [activeScreenshot, setActiveScreenshot] = useState<number>(0);
  const [imageLoaded, setImageLoaded] = useState<boolean>(true);
  const componentRef = useRef(null);
  const [hasScreenshotsObject, setHasScreenshotsObject] =
    useState<HasScreenshotsObject>({});

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    let newHasScreenshotsObject: HasScreenshotsObject = {};
    listings?.hits.forEach((value) => {
      let listingScreenshots = value.screenshots;
      if (listingScreenshots)
        newHasScreenshotsObject[value.id] = listingScreenshots.length > 0;
    });
    setHasScreenshotsObject(newHasScreenshotsObject);
  }, [listings]);

  const handleDownload = () => {
    if (listings && listings.hits[selectedListing].screenshots) {
      let selectedListingScreenshots =
        listings?.hits[selectedListing]?.screenshots;
      if (selectedListingScreenshots)
        saveAs(selectedListingScreenshots[activeScreenshot].file.url);
    }
  };

  const handleListingChange = (e: SelectChangeEvent<string>) => {
    if (e.target.value && hasScreenshotsObject[e.target.value]) {
      changeListing(e.target.value);
      setImageLoaded(false);
    }
  };

  const handleScreenshotChange = (value: dayjs.Dayjs | null) => {
    if (value && screenshots) {
      setActiveScreenshot(
        screenshots
          .map((item) => dayjs(item.created_at).format("YYYY-MM-DD"))
          .indexOf(value.format("YYYY-MM-DD"))
      );
    }
    setImageLoaded(false);
  };

  const disableDates = (date: dayjs.Dayjs) => {
    return !screenshots
      ?.map((item) => {
        return dayjs(item.created_at).format("YYYY-MM-DD");
      })
      .includes(date.format("YYYY-MM-DD"));
  };

  return screenshots ? (
    <Box className="screenshotModalBox">
      <PrintTemplate
        ref={componentRef}
        imgUrl={screenshots[activeScreenshot].file.url}
        date={screenshots[activeScreenshot].created_at}
      />
      <div className="innerDiv">
        <aside className="aside">
          <div className="asideContainer">
            <FormControl
              sx={{ m: 1, minWidth: 120, maxWidth: 300 }}
              className="select"
            >
              <InputLabel shrink htmlFor="select">
                Listing
              </InputLabel>
              <Select
                labelId="listingId"
                value={
                  listingTables?.hits[selectedListingTable].internal_listing_id
                }
                label="Listing"
                onChange={(e) => handleListingChange(e)}
              >
                {listingTables?.hits.map((item, index) =>
                  !hasScreenshotsObject[item.internal_listing_id] ? (
                    <Tooltip
                      key={index}
                      title={"We do not have screenshots for this listing."}
                      placement="left"
                      style={{ pointerEvents: "visible" }}
                      arrow
                    >
                      <MenuItem
                        value={item.internal_listing_id}
                        key={index}
                        disabled={
                          !hasScreenshotsObject[item.internal_listing_id]
                        }
                      >
                        {item.internal_listing_id.toUpperCase()}
                      </MenuItem>
                    </Tooltip>
                  ) : (
                    <MenuItem
                      value={item.internal_listing_id}
                      key={index}
                      disabled={!hasScreenshotsObject[item.internal_listing_id]}
                    >
                      {item.internal_listing_id.toUpperCase()}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <div className="calendar">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  label="date"
                  openTo="day"
                  minDate={dayjs(
                    screenshots[screenshots.length - 1].created_at
                  )}
                  maxDate={dayjs(new Date())}
                  shouldDisableDate={disableDates}
                  value={dayjs(screenshots[0].created_at)}
                  onChange={(newValue) => handleScreenshotChange(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </div>
        </aside>
        <section className="main">
          <div className="screenshotInformation">
            <div className="screenshotDate">
              <Typography variant="button" className="date">
                {formatDateTime(screenshots[activeScreenshot].created_at) +
                  " " +
                  timeZone}
              </Typography>
            </div>
            <div className="buttonGroup">
              <ButtonUnstyled className="topButton" onClick={handleDownload}>
                <FontAwesomeIcon className="printIcon" icon={faDownload} />
                Download
              </ButtonUnstyled>
              <ButtonUnstyled className="topButton" onClick={handlePrint}>
                <FontAwesomeIcon className="printIcon" icon={faPrint} />
                Print
              </ButtonUnstyled>
            </div>
          </div>
          <div className="screenshotWrapper">
            {!imageLoaded && (
              <div className="spinnerContainer">
                <CircularProgress />
              </div>
            )}
            <img
              src={screenshots[activeScreenshot].file.url}
              onLoad={() => setImageLoaded(true)}
              hidden={!imageLoaded}
            />
          </div>
        </section>
      </div>
    </Box>
  ) : null;
};
