import React from "react";
import { Box, Card, Typography, Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { ExecutionStatus } from "../interfaces";

interface EsriIntegrationCardProps {
  integration: {
    name: string;
    description: string;
    link: string;
    btnText: {
      create: string;
      edit: string;
    };
  };
  data: any;
}

const LAST_STATUS_TEXT = "Last data fetch status:";

const EsriIntegrationCard: React.FC<EsriIntegrationCardProps> = ({
  integration: { name, description, link, btnText },
  data,
}) => {
  const renderLastExecutionStatus = (
    last_execution_status: ExecutionStatus,
    last_execution_datetime: string
  ) => {
    switch (last_execution_status) {
      case ExecutionStatus.SUCCESS: {
        const lastExecutionTime: string | null = last_execution_datetime;
        const formatedDate =
          lastExecutionTime && new Date(lastExecutionTime).toLocaleString();

        return (
          <Box>
            <Typography>
              {LAST_STATUS_TEXT}{" "}
              <FontAwesomeIcon style={{ color: "green" }} icon={faCircle} />{" "}
              {ExecutionStatus.SUCCESS}
            </Typography>
            <Typography>Last sync date/time: {formatedDate} UTC</Typography>
          </Box>
        );
      }
      case ExecutionStatus.FAILED: {
        const _lastExecutionTime: string | null = last_execution_datetime;
        const _formatedDate =
          _lastExecutionTime && new Date(_lastExecutionTime).toLocaleString();

        return (
          <Box>
            <Typography>
              {LAST_STATUS_TEXT}{" "}
              <FontAwesomeIcon style={{ color: "red" }} icon={faCircle} />{" "}
              {ExecutionStatus.FAILED}
            </Typography>
            <Typography>Last sync date/time: {_formatedDate} UTC</Typography>
          </Box>
        );
      }
      default:
        return (
          <Typography>
            {LAST_STATUS_TEXT} {ExecutionStatus.STATUS_UNKNOWN}
          </Typography>
        );
    }
  };

  return (
    <Card
      sx={{
        height: "150px",
        marginTop: "50px",
        borderRadius: "6px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Box sx={{ width: "1300px", height: "100%" }} display="flex">
        <Box
          sx={{ width: "350px", height: "100%", marginLeft: "40px" }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Typography variant="h4">{name}</Typography>
          <Typography>{description}</Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", marginLeft: "50px" }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: "#003258", width: "150px" }}
            component={NavLink}
            to={link}
          >
            {data?.fetchPortionOfEsriConfig ? btnText.edit : btnText.create}
          </Button>
        </Box>
        <Box display="flex" alignItems="center" sx={{ marginLeft: "200px" }}>
          {data?.fetchPortionOfEsriConfig &&
            renderLastExecutionStatus(
              data.fetchPortionOfEsriConfig.last_execution_status,
              data.fetchPortionOfEsriConfig.last_execution_datetime
            )}
        </Box>
      </Box>
    </Card>
  );
};

export default EsriIntegrationCard;
