import React, { useContext, useEffect, useState } from "react";
import { loader } from "graphql.macro";
import { useLazyQuery } from "@apollo/client";
import ListingGallery from "./ListingGallery/ListingGallery";
import ListingInformation from "./ListingInformation/ListingInformation";
import {
  Badge,
  Box,
  ButtonUnstyled,
  CircularProgress,
  Grid,
  Skeleton,
  useTheme,
} from "@mui/material";
import AddressInformation from "./AddressInformation/AddressInformation";
import ActivityTimeline from "./ActivityTimeline/ActivityTimeline";
import { RouteComponentProps } from "react-router";
import "./RentalUnitDetails.scss";
import { ListingInterface, RentalUnitContext } from "./RentalUnitContext";
import RentalUnitDetailsPrintFormat from "./print/RentalUnitDetailsPrintFormat";
import EmailIcon from "@mui/icons-material/Email";
import DropdownComponent from "../../../components/DropdownComponent/DropdownComponent";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import PrintIcon from "@mui/icons-material/Print";
import { JurisdictionContext } from "../../common/JurisdictionContext";
import LetterSummary from "../Letter/LetterSummary";

const query = loader("./RentalUnitDetails.graphql");
const templatesQuery = loader("../Letter/LetterTemplates.graphql");
const templateStoredPreviewQuery = loader(
  "../Letter/LetterTemplateStoredPreview.graphql"
);

interface RentalUnitDetailsPageProps
  extends RouteComponentProps<{
    rentalUnitId: string;
    listingId: string | undefined;
  }> {}

export interface TemplateCriteria {
  criteria_id: string;
  criteria_description: string;
  criteria_met: boolean;
}
export interface TemplateInfo {
  letter_template_id: string;
  customer_display_name: string;
  is_eligible: boolean;
}

export interface TemplateDetails {
  rental_unit_id: string;
  templates: TemplateInfo[];
  letter_template_count: number;
}

const RentalUnitDetailsPage = (props: RentalUnitDetailsPageProps) => {
  const rentalUnitId = props.match.params.rentalUnitId;
  const listingId = props.match.params.listingId;
  const theme = useTheme();
  document.title = `Rental Unit Record`;

  const [showPrintFormat, setShowPrintFormat] = useState<boolean>(false);
  const [showLetterSummaryModal, setShowLetterSummaryModal] =
    useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<{
    id: string;
    name: string;
  }>({ id: "", name: "" });
  const [templateCriteria, setTemplateCriteria] = useState<TemplateCriteria[]>(
    []
  );
  const [templateThumbnail, setTemplateThumbnail] = useState<string[]>([]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openTemplatesMenu = Boolean(anchorEl);

  const { showSendLetter } = useContext(JurisdictionContext);
  const [templateHasScreenshot, setTemplateHasScreenshot] = useState(false);

  useEffect(() => {
    const onPrint = (event: any) => {
      // render print format on ctrl+p
      if (
        event.key?.toLowerCase() === "p" &&
        (event.ctrlKey || event.metaKey)
      ) {
        handleClick();
      }
    };
    window.addEventListener("keydown", onPrint);

    return () => {
      window.removeEventListener("keydown", onPrint);
    };
  }, []);

  useEffect(() => {
    setTemplateHasScreenshot(false);
    const templateList = getSelectedRentalUnitTemplates();
    if (templateList) {
      setTemplateThumbnail([]);
      const selectedTemplateDetails = templateList.templates.filter(
        (template: TemplateInfo) =>
          template.letter_template_id === selectedTemplate?.id
      );
      let thumbnails: any[] = [];
      selectedTemplateDetails[0]?.thumbnails?.map((thumbnail: any) => {
        thumbnails.push({ image: thumbnail.medium });
      });
      if (selectedTemplateDetails[0]?.has_screenshot) {
        setTemplateHasScreenshot(true);
      }
      setTemplateThumbnail(thumbnails);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    const templateList = getSelectedRentalUnitTemplates();
    if (templateList) {
      setTemplateCriteria([]);
      const selectedTemplateDetails = templateList.templates.filter(
        (template: TemplateInfo) =>
          template.letter_template_id === selectedTemplate?.id
      );
      setTemplateCriteria(selectedTemplateDetails[0]?.criteria);
    }
  }, [selectedTemplate]);

  const handleClick = () => {
    setShowPrintFormat(true);
    setTimeout(() => window.print(), 500);
    window.onafterprint = () => setShowPrintFormat(false);
  };

  const handleViewTemplateButtonClick = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showLetterSummary = (event: any) => {
    setSelectedTemplate({ id: event.target.id, name: event.target.innerText });
    setShowLetterSummaryModal(true);
  };

  useEffect(() => {
    getData({
      variables: {
        q: `external_property_id:"${rentalUnitId}"`,
        id: rentalUnitId,
        activities_query: `target_id:"${rentalUnitId}"`,
      },
    });
  }, []);

  useEffect(() => {
    if (showSendLetter || !showLetterSummaryModal) {
      getTemplates({
        variables: {
          rental_unit_ids: [rentalUnitId],
        },
      });
    }
  }, [showSendLetter, showLetterSummaryModal]);

  useEffect(() => {
    if (showLetterSummaryModal) {
      let screenshotParam =
        data.listings?.hits[selectedListing]?.screenshots[0];
      let listingParam = data.listing_tables?.hits[selectedListingTable];
      getStoredPreview({
        variables: {
          template_id: selectedTemplate.id,
          rental_unit_id: rentalUnitId,
          ...(screenshotParam && templateHasScreenshot
            ? {
                screenshot: {
                  created_at: screenshotParam.created_at,
                  url: screenshotParam.file.url,
                },
                listing: {
                  id: listingParam.internal_listing_id,
                  title: listingParam.listing_title,
                  url: listingParam.listing_url,
                },
              }
            : {}),
        },
      });
    }
  }, [showLetterSummaryModal]);

  const [getData, { data }] = useLazyQuery(query, {
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [getTemplates, { data: templates, loading: loadingTemplates }] =
    useLazyQuery(templatesQuery, {
      errorPolicy: "all",
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    });

  const [
    getStoredPreview,
    { data: storedPreview, loading: storedPreviewLoading },
  ] = useLazyQuery(templateStoredPreviewQuery, {
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [selectedListingTable, setSelectedListingTable] = useState<number>(0);
  const [selectedListing, setSelectedListing] = useState<number>(0);

  useEffect(() => {
    if (data) {
      setSelectedListing(
        data.listings?.hits.findIndex(
          (item: ListingInterface) =>
            item.id ===
            data.listing_tables?.hits[selectedListingTable].internal_listing_id
        )
      );
    }
  }, [data, selectedListingTable]);

  const handleSelectedListingTableChange = (value: number) => {
    setSelectedListingTable(value);
  };

  const complianceStatus = (is_compliant: boolean) => {
    let complianceStatus: string;
    let style: React.CSSProperties;
    if (is_compliant === null) {
      complianceStatus = "Not identified";
      style = {
        backgroundColor: theme.palette.warning.light,
      };
    } else if (is_compliant) {
      complianceStatus = "Compliant";
      style = {
        backgroundColor: theme.palette.success.light,
      };
    } else {
      complianceStatus = "Non-compliant";
      style = {
        backgroundColor: theme.palette.error.light,
      };
    }
    return (
      <p
        className={"complianceStatus"}
        style={{ fontSize: "16px", textTransform: "uppercase", ...style }}
      >
        {complianceStatus}
      </p>
    );
  };

  const getTemplateCount = () => {
    const templateInfo = templates?.letter_templates?.find(
      (template: TemplateDetails) => template.rental_unit_id === rentalUnitId
    );
    return templateInfo ? templateInfo.letter_template_count : 0;
  };

  const getSelectedRentalUnitTemplates = () => {
    return templates?.letter_templates?.find(
      (template: TemplateDetails) => template.rental_unit_id === rentalUnitId
    );
  };

  const getEligibleTemplates = () => {
    const templateList = getSelectedRentalUnitTemplates();
    if (templateList) {
      return templateList.templates.filter(
        (template: TemplateInfo) => template.is_eligible === true
      );
    }
    return [];
  };

  const getDefaultListingScreenshotDate = () => {
    const templateInfo = templates?.letter_templates?.find(
      (template: TemplateDetails) => template.rental_unit_id === rentalUnitId
    );
    return templateInfo ? templateInfo.default_listing_screenshot_date : "";
  };

  const getDefaultInternalListingID = () => {
    const templateInfo = templates?.letter_templates?.find(
      (template: TemplateDetails) => template.rental_unit_id === rentalUnitId
    );
    return templateInfo ? templateInfo.default_internal_listing_id : "";
  };

  const getNonEligibleTemplates = () => {
    const templateList = getSelectedRentalUnitTemplates();
    if (templateList) {
      return templateList.templates.filter(
        (template: TemplateInfo) => template.is_eligible === false
      );
    }
    return [];
  };

  const getNoDataMsg = () => {
    const templateList = getSelectedRentalUnitTemplates();
    if (!templateList) {
      return "Templates not configured";
    }
    return null;
  };

  const handleModalClose = () => {
    setShowLetterSummaryModal(false);
    handleClose();
  };

  return data ? (
    <RentalUnitContext.Provider
      value={{
        rentalUnitId: rentalUnitId,
        selectedListing: selectedListing,
        selectedListingTable: selectedListingTable,
        listingTables: data.listing_tables,
        rentalUnit: data.rentalUnit,
        listings: data.listings,
        activities: data.activities,
      }}
    >
      <Box
        style={{
          maxWidth: "1366px",
          margin: "auto",
        }}
        className="hiddenPrint"
      >
        <Grid
          container
          spacing={2}
          columns={12}
          margin={"auto"}
          alignItems="flex-end"
        >
          <Grid item xs={9}>
            {data ? (
              <div className="complianceStatusWrapper">
                <p style={{ fontSize: "24px", marginRight: "10px" }}>
                  {data ? data.rentalUnit?.situs_address.formatted : ""}
                </p>
                {complianceStatus(data.rentalUnit?.is_compliant)}
              </div>
            ) : (
              <Skeleton />
            )}
          </Grid>
          <Grid item xs={3}>
            <div style={{ textAlign: "right" }}>
              {data ? (
                <div>
                  {showSendLetter && (
                    <>
                      <div className="progressTemplates">
                        {loadingTemplates && (
                          <CircularProgress thickness={4} size={20} />
                        )}
                      </div>
                      <ButtonUnstyled
                        className="printListing"
                        onClick={handleViewTemplateButtonClick}
                      >
                        <Badge
                          badgeContent={getTemplateCount()}
                          className="printIcon"
                        >
                          <EmailIcon fontSize="small" />
                        </Badge>
                        Letters Due
                        {openTemplatesMenu ? <ExpandLess /> : <ExpandMore />}
                      </ButtonUnstyled>
                    </>
                  )}
                  {openTemplatesMenu ? (
                    <DropdownComponent
                      open={openTemplatesMenu}
                      handleClose={handleClose}
                      handlePrimaryItemClick={showLetterSummary}
                      anchorEl={anchorEl}
                      primaryContent={getEligibleTemplates()}
                      secondaryContent={getNonEligibleTemplates()}
                      secondaryContentTitle={
                        "Other Templates (criteria not met)"
                      }
                      tooltipData={getSelectedRentalUnitTemplates()}
                      noDataMsg={getNoDataMsg()}
                    />
                  ) : undefined}
                  <ButtonUnstyled
                    className="printListing"
                    onClick={handleClick}
                  >
                    <PrintIcon className="printIcon" fontSize="small" />
                    Print
                  </ButtonUnstyled>
                </div>
              ) : (
                <Skeleton />
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            {data ? (
              <ListingGallery />
            ) : (
              <Skeleton variant="rectangular" height={200} />
            )}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4} className="addressGrid">
                {data ? (
                  <AddressInformation />
                ) : (
                  <Skeleton variant="rectangular" height={400} width={"100%"} />
                )}
              </Grid>
              <Grid item xs={8} className="listingGrid">
                {data ? (
                  <ListingInformation
                    complianceStatus={data.rentalUnit?.is_compliant}
                    isIdentified={data.rentalUnit?.is_identified}
                    onSelectedListingChange={handleSelectedListingTableChange}
                    defaultListing={listingId}
                    hasScreenshots={
                      data.listings?.hits[selectedListing].screenshots?.length >
                      0
                    }
                  />
                ) : (
                  <Skeleton variant="rectangular" height={400} />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {data && data.activities ? (
              <div id="activityTimeline">
                <ActivityTimeline />
              </div>
            ) : (
              <Skeleton variant="rectangular" height={200} />
            )}
          </Grid>
        </Grid>
      </Box>
      {showPrintFormat ? (
        <RentalUnitDetailsPrintFormat
          complianceStatus={complianceStatus(data.rentalUnit?.is_compliant)}
          hasScreenshots={
            data?.listings?.hits[selectedListing].screenshots.length > 0
          }
          selectedListing={data?.listing_tables?.hits[selectedListingTable]}
          isIdentified={data.rentalUnit?.is_identified}
        />
      ) : null}
      {showLetterSummaryModal && (
        <LetterSummary
          showLetterSummaryModal={showLetterSummaryModal}
          templateCriteria={templateCriteria}
          templateThumbnail={templateThumbnail}
          templateHasScreenshot={templateHasScreenshot}
          handleModalClose={handleModalClose}
          selectedListingTable={selectedListingTable}
          selectedTemplate={selectedTemplate}
          storedPreview={storedPreview}
          rentalUnitData={data}
          rentalUnitId={rentalUnitId}
          storedPreviewLoading={storedPreviewLoading}
          selectedListing={selectedListing}
          defaultSelectedScreenshot={getDefaultListingScreenshotDate()}
          defaultSelectedListing={getDefaultInternalListingID()}
        ></LetterSummary>
      )}
    </RentalUnitContext.Provider>
  ) : (
    <div
      style={{
        height: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default RentalUnitDetailsPage;
