import React from "react";
import { useGridRootProps } from "@mui/x-data-grid-pro";
import { unstable_useId as useId, useForkRef } from "@mui/material/utils";
import { ButtonProps } from "@mui/material";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//@ts-ignore
interface GridToolbarClearAllButtonProps extends ButtonProps {
  onClick: () => void;
}
export const GridToolbarClearAllButton = React.forwardRef<
  HTMLButtonElement,
  GridToolbarClearAllButtonProps
>(function GridToolbarClearAllButton(props, ref) {
  const { onClick } = props;
  const rootProps = useGridRootProps();
  const buttonID = useId();
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const handleRef = useForkRef(ref, buttonRef);

  return (
    <rootProps.components.BaseButton
      id={buttonID}
      ref={handleRef}
      startIcon={<FontAwesomeIcon icon={faClose} />}
      onClick={onClick}
      sx={{ marginLeft: "auto", order: 2, color: "rgba(0,0,0,0.5)" }}
    >
      <span style={{ fontSize: "0.8125rem" }}>Clear All Filters</span>
    </rootProps.components.BaseButton>
  );
});
