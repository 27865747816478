import React, { ChangeEvent, useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { loader } from "graphql.macro";
import { useMutation, useQuery } from "@apollo/client";
import InvalidateLetters from "./InvalidateLetters";
import { LoadingButton } from "@mui/lab";
import { RentalUnitWithOwnerInfoInterface } from "../ReportIssue";
import { LoadingScreen } from "../../../../common/loading";
import CountryInput from "../../../common/CountryInput";
import { isCountryCodeValid } from "../../../common/utils";

const updateOIMutation = loader("./ReportAndUpdateOwnerInformation.graphql");
const getLettersByRURQuery = loader("./getLettersByRUR.graphql");

interface EditAddressInformationFormProps {
  goBack?: () => any;
  setSnackbarData: (data: any) => any;
  closeModal: () => any;
  loadingRentalUnitData: boolean;
  rentalUnitID: string | null;
  rentalUnit: RentalUnitWithOwnerInfoInterface | null;
  reloadGrid: () => any | null;
  updateFormData?: (
    isUpdated: boolean,
    initialFormData: OwnerFormData
  ) => any | null;
}

export interface OwnerFormData {
  id: string;
  owner_name_1: string | null;
  owner_name_2: string | null;
  latitude: number | undefined;
  longitude: number | undefined;
  city: string | null;
  parcel_number: string | undefined;
  address: string | null;
  state: string | null;
  zipcode: string | null;
  country: string | null;
  remove_letters: string | null;
  external_property_id: string | null;
  letter_templates: any;
}

const EDIT_ADDRESS_STEP = "Edit Address";
const VERIFY_LETTERS_STEP = "Verify Letters";
const SUBMIT_STEP = "Submit";
const SUBMIT_TEXT =
  "We have everything we need. Are you ready to submit these changes?";
const SUBMITTING_TEXT =
  "We’re applying your changes now. Because we have to recalculate some things, this may take a few seconds. ";

const EditAddressInformationForm = (props: EditAddressInformationFormProps) => {
  const [step, setStep] = useState<string>(EDIT_ADDRESS_STEP);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [removeLetters, setRemoveLetters] = useState<string[]>([]);
  const [submitText, setSubmitText] = useState<string>(SUBMIT_TEXT);
  const [loading, setLoading] = useState<boolean>(false);
  const [isCountryValid, setIsCountryValid] = useState<boolean>(false);

  const initialFormData: OwnerFormData = {
    id: "",
    owner_name_1: props.rentalUnit?.owner?.name ?? null,
    owner_name_2: props.rentalUnit?.owner?.name_secondary ?? null,
    latitude: props.rentalUnit?.situs_address.position.latitude,
    longitude: props.rentalUnit?.situs_address.position.longitude,
    city: props.rentalUnit?.owner?.mailing_address.city ?? null,
    parcel_number: props.rentalUnit?.parcel_number,
    address: props.rentalUnit?.owner?.mailing_address.line_1 ?? null,
    state: props.rentalUnit?.owner?.mailing_address.state ?? null,
    zipcode: props.rentalUnit?.owner?.mailing_address.postal_code ?? null,
    country: props.rentalUnit?.owner?.mailing_address.country_code ?? null,
    remove_letters: "",
    external_property_id: props.rentalUnitID,
    letter_templates: null,
  };

  const [formData, setFormData] = useState<OwnerFormData>(initialFormData);

  const [updateRentalUnit] = useMutation(updateOIMutation, {
    refetchQueries: ["getRentalUnitDetails"],
    awaitRefetchQueries: true,
  });

  const resetRemoveLetters = (loadFromLetterData: boolean) => {
    if (loadFromLetterData) {
      if (lettersData && lettersData.results?.hits) {
        const hits = lettersData.results?.hits;
        let defaultLetters = hits.map((letter: any) => letter.id);
        setRemoveLetters(defaultLetters);
      }
    } else {
      setRemoveLetters([]);
    }
  };

  const { data: lettersData, loading: loadingLettersData } = useQuery(
    getLettersByRURQuery,
    {
      variables: {
        q: `(property_ids:"${props.rentalUnitID}")`,
        phrase: "",
        from: 0,
        sort: "",
      },
      onError: () => {
        props.setSnackbarData({
          message: "Failed to load letters information",
          type: "error",
          vertical: "top",
          horizontal: "center",
        });
      },
    }
  );

  // might have to make this dynamic while implementing inside RUR details page
  const [steps] = useState<string[]>([
    EDIT_ADDRESS_STEP,
    VERIFY_LETTERS_STEP,
    SUBMIT_STEP,
  ]);

  const handleNext = async () => {
    const letter_templates = lettersData?.results?.hits
      .filter((letter: any) => removeLetters.includes(letter.id))
      .map((item: any) => item.letter_template_id);

    setFormData((prevFormData) => ({
      ...prevFormData,
      remove_letters: removeLetters.join(","),
      letter_templates: letter_templates,
    }));

    switch (step) {
      case EDIT_ADDRESS_STEP:
        setStep(VERIFY_LETTERS_STEP);
        break;
      case VERIFY_LETTERS_STEP:
        setSubmitText(SUBMIT_TEXT);
        setStep(SUBMIT_STEP);
        break;
      case SUBMIT_STEP:
        setSubmitText(SUBMITTING_TEXT);
        setLoading(true);
        try {
          await updateRentalUnit({ variables: formData });
          setTimeout(() => {
            props.setSnackbarData({
              message: "Successfully updated the address",
              type: "success",
              vertical: "top",
              horizontal: "center",
            });
            setLoading(false);
            props.reloadGrid();
            props.closeModal();
          }, 3000);
        } catch (err) {
          props.setSnackbarData({
            message: "Failed to update the address",
            type: "error",
            vertical: "top",
            horizontal: "center",
          });
          setLoading(false);
        }
    }
  };

  const handleLettersInvalidation = (event: ChangeEvent<HTMLInputElement>) => {
    removeLetters.includes(event.target.id)
      ? setRemoveLetters(
          removeLetters.filter((item) => item !== event.target.id)
        )
      : setRemoveLetters([...removeLetters, event.target.id]);
  };

  const handleBack = () => {
    switch (step) {
      case EDIT_ADDRESS_STEP:
        if (props.goBack) {
          props.goBack();
        }
        break;
      case VERIFY_LETTERS_STEP:
        setStep(EDIT_ADDRESS_STEP);
        break;
      case SUBMIT_STEP:
        setStep(VERIFY_LETTERS_STEP);
        break;
    }
  };

  useEffect(() => {
    resetRemoveLetters(true);
  }, [lettersData]);

  useEffect(() => {
    if (isCountryCodeValid(formData.country ? formData.country : "")) {
      setIsCountryValid(true);
    }

    const isFormValid =
      formData.owner_name_1 &&
      formData.address &&
      formData.city &&
      formData.state &&
      formData.zipcode &&
      formData.country &&
      isCountryCodeValid(formData.country ? formData.country : "");
    setFormValid(isFormValid ? true : false);
  }, [formData]);

  const handleCountryCodeChange = (newCountryCode: string) => {
    let dataUpdated = false;
    if (isCountryCodeValid(newCountryCode)) {
      setIsCountryValid(true);
    } else {
      setIsCountryValid(false);
      setFormValid(false);
    }
    const updatedFormData = {
      ...formData,
      country: newCountryCode,
    };
    setFormData(updatedFormData);
    if (JSON.stringify(initialFormData) !== JSON.stringify(updatedFormData)) {
      dataUpdated = true;
    }
    if (props.updateFormData) {
      props.updateFormData(dataUpdated, initialFormData);
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: keyof OwnerFormData
  ) => {
    const value = event.target.value === " " ? "" : event.target.value;
    const updatedFormData = {
      ...formData,
      [fieldName]: value,
    };
    setFormData(updatedFormData);
    let dataUpdated = false;
    if (JSON.stringify(initialFormData) !== JSON.stringify(updatedFormData)) {
      dataUpdated = true;
    }

    props.updateFormData
      ? props.updateFormData(dataUpdated, initialFormData)
      : null;
  };

  return (
    <Box className="ownerFlow" component="form" autoComplete="off">
      <Stepper
        className="stepCounterEditOwnerAddress"
        activeStep={steps.indexOf(step)}
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {step === EDIT_ADDRESS_STEP && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className="editOwnerText">
              Please correct/verify the tax record.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Owner Name 1"
              value={formData.owner_name_1}
              placeholder="Please enter the Owner Name 1"
              onChange={(event) => handleInputChange(event, "owner_name_1")}
              fullWidth
              required
              error={formData.owner_name_1 === ""}
              helperText={
                formData.owner_name_1 === "" ? "Owner Name 1 is required." : ""
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Owner Name 2"
              value={formData.owner_name_2}
              placeholder="Please enter the Owner Name 2"
              onChange={(event) => handleInputChange(event, "owner_name_2")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Mailing Address and Unit Number"
              placeholder="Please enter the Mailing Address and Unit Number"
              value={formData.address}
              onChange={(event) => handleInputChange(event, "address")}
              fullWidth
              required
              error={formData.address === ""}
              helperText={
                formData.address === ""
                  ? "Mailing Address and Unit Number is required."
                  : ""
              }
            />
          </Grid>

          <Grid item xs={3} sx={{ maxWidth: "25%" }}>
            <TextField
              label="Mailing City"
              placeholder="Please enter the Mailing City"
              value={formData.city}
              onChange={(event) => handleInputChange(event, "city")}
              fullWidth
              required
              error={formData.city === ""}
              helperText={
                formData.city === "" ? "Mailing City is required." : ""
              }
            />
          </Grid>
          <Grid item xs={3} sx={{ maxWidth: "25%" }}>
            <TextField
              label="Mailing State"
              placeholder="Please enter two letters Mailing State code e.g. CO"
              value={formData.state}
              onChange={(event) => handleInputChange(event, "state")}
              fullWidth
              required
              error={formData.state === ""}
              helperText={
                formData.state === "" ? "Mailing State is required." : ""
              }
            />
          </Grid>
          <Grid item xs={3} sx={{ maxWidth: "25%" }}>
            <TextField
              label="Mailing Zip Code"
              placeholder="Please enter the Mailing Zip Code"
              value={formData.zipcode}
              onChange={(event) => handleInputChange(event, "zipcode")}
              fullWidth
              required
              error={formData.zipcode === ""}
              helperText={
                formData.zipcode === "" ? "Mailing Zip Code is required." : ""
              }
            />
          </Grid>
          <Grid item xs={3} sx={{ maxWidth: "25%" }}>
            <CountryInput
              onCountryCodeChange={handleCountryCodeChange}
              value={formData.country}
              required={true}
              label="Mailing Country"
              placeholder="Please enter the Mailing Country ISO-2 code e.g. US"
              error={!isCountryValid}
              helperText={!isCountryValid ? "Mailing Country is required." : ""}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography className="editOwnerFooterText">
              These changes are temporary and will be overwritten when the
              newest tax record data is loaded.
            </Typography>
          </Grid>
        </Grid>
      )}
      {step === VERIFY_LETTERS_STEP &&
        (loadingLettersData ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingScreen />
            <Typography variant="body1">Loading Letters Information</Typography>
          </div>
        ) : (
          <>
            {lettersData && lettersData.results?.hits.length > 0 ? (
              <InvalidateLetters
                removeLetters={removeLetters}
                lettersData={lettersData}
                handleChange={handleLettersInvalidation}
                resetRemoveLetters={resetRemoveLetters}
              />
            ) : (
              <Typography variant="body1">
                No letters sent to that property
              </Typography>
            )}
          </>
        ))}
      {step === SUBMIT_STEP && <Typography>{submitText}</Typography>}
      <div className="editOwnerButtons">
        {props.goBack && (
          <Button onClick={handleBack} variant="outlined" color="primary">
            Back
          </Button>
        )}
        <LoadingButton
          onClick={handleNext}
          variant="contained"
          color="primary"
          disabled={!formValid}
          loading={loading}
          style={{ marginLeft: "10px" }}
        >
          {step === SUBMIT_STEP ? "Submit" : "Next"}
        </LoadingButton>
      </div>
    </Box>
  );
};

export default EditAddressInformationForm;
