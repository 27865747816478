import React from "react";
import { gql } from "@apollo/client";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useLocation } from "react-router-dom";

import SearchView from "../../components/SearchView/SearchView";
import { getTableMeta } from "../common/data-grid-cols";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const RegistrationHistoryPage = () => {
  const query = useQuery();
  const permitRegistrationID = query.get("permit_registration_id"); //get permit_registration_id query parameters from the URL
  const { columns, filters } = getTableMeta("registration_history");

  const registrationHistoryQuery = (columnsToFilter: GridColDef[]) => {
    const queryFields = columnsToFilter
      .filter(
        (column) => !column.hide && column.field !== "add_view_comments_modal"
      )
      .map((column) => {
        if (column.field === "listings") {
          return `listings {
            website
            webpage
          }`;
        } else {
          return column.field;
        }
      })
      .join("\n");

    return gql`
      query($q: KibanaQL="", $phrase: String, $size: Int, $from: Int, $sort: String, $permitRegistrationID: Int) {
        results: registration_history(
          search: { query: $q, phrase: $phrase, size: $size, from: $from, sort: $sort },
          only: { permit_registration_id: $permitRegistrationID }
        ) {
          size
          total
          hits {
            id
            ${queryFields}
          }
        }
      }
    `;
  };

  return (
    <SearchView
      searchQuery={registrationHistoryQuery}
      columns={columns}
      filters={filters}
      title={`Registration History${
        permitRegistrationID ? ` for ID ${permitRegistrationID}` : ""
      }`}
      tableType={"registration_history"}
      variables={{
        permitRegistrationID: permitRegistrationID
          ? parseInt(permitRegistrationID, 10)
          : null,
      }}
    />
  );
};

export default RegistrationHistoryPage;
