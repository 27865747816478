import React from "react";
import { gql } from "@apollo/client";
import { GridColDef } from "@mui/x-data-grid-pro";
import SearchView from "../../components/SearchView/SearchView";
import { ExtendedGridColDef, getTableMeta } from "../common/data-grid-cols";

const PaymentsPage = () => {
  const { columns, filters } = getTableMeta("payment_itemization");

  const PaymentsQuery = (columnsToFilter: ExtendedGridColDef[]) => {
    const queryFields = columnsToFilter
      .filter((column: ExtendedGridColDef) => !column.hide && !column.dynamic)
      .map((column) => {
        if (column.field === "mark_payment_as_returned_modal") {
          // Always include tax payment_datetime payment_type status in the query when mark_payment_as_returned_modal is requested
          return `${column.field} tax payment_datetime payment_type status`;
        }
        return column.field;
      })
      .join("\n");

    return gql`
      query($q: KibanaQL="", $phrase: String, $size: Int, $from: Int, $sort: String) {
        results: payment_itemization(
          search: { query: $q, phrase: $phrase, size: $size, from: $from, sort: $sort }
        ) {
          size
          total
          hits {
            id
            ${queryFields}
          }
        }
      }
    `;
  };

  const renderColumn = (column: ExtendedGridColDef): GridColDef => {
    return { ...column };
  };

  return (
    <SearchView
      searchQuery={PaymentsQuery}
      columns={columns.map((column: ExtendedGridColDef) =>
        renderColumn(column)
      )}
      filters={filters}
      title="Payments"
    />
  );
};

export default PaymentsPage;
