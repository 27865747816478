import React from "react";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

interface UploadcareCDNRendererProps {
  urls: string[];
  urlLabel: string;
}

const CustomTooltip = styled(
  ({ className, ...props }: TooltipProps & { className?: string }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f5",
    color: "#000",
    maxWidth: "400px",
    fontSize: "0.875rem",
    fontWeight: "normal",
    border: "1px solid black",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#f5f5f5",
    fontSize: "2em",
    "&::before": {
      border: "1px solid black",
    },
  },
});

const UploadcareCDNRenderer = ({
  urls,
  urlLabel,
}: UploadcareCDNRendererProps) => {
  return (
    <CustomTooltip
      title={
        <div style={{ maxWidth: "400px", lineHeight: "250%" }}>
          <ol>
            {urls.map((url, index) => (
              <li key={index}>
                <a rel="noreferrer" href={url} target="_blank">{`${urlLabel} ${
                  index + 1
                }`}</a>
              </li>
            ))}
          </ol>
        </div>
      }
      arrow
    >
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "400px",
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <a
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            textDecoration: "underline",
          }}
        >
          Multiple evidences available
        </a>
      </div>
    </CustomTooltip>
  );
};

export default UploadcareCDNRenderer;
