import React, { useContext, useEffect, useState } from "react";
import { Box, ImageList, ImageListItem, Typography } from "@mui/material";
import { ButtonUnstyled } from "@mui/base";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTableCells } from "@fortawesome/free-solid-svg-icons";
import "./ListingGallery.scss";
import Gallery from "../Modals/Gallery/Gallery";
import { RentalUnitContext } from "../RentalUnitContext";

export interface GalleryItem {
  img: string | null;
  title: string;
  rows?: number;
  cols?: number;
}

const ListingGallery = () => {
  const { listings, selectedListing } = useContext(RentalUnitContext);
  const [itemData, setItemData] = useState<GalleryItem[]>();
  useEffect(() => {
    if (listings && listings.hits[selectedListing].photos !== null) {
      let photos = listings?.hits[selectedListing].photos;
      if (photos) {
        setItemData([
          {
            img: photos[0]?.url ? photos[0]?.url : null,
            title: "Home 0",
            rows: 2,
            cols: 2,
          },
          ...photos.slice(1, 5).map((image, index) => {
            return {
              img: image.url,
              title: "Home " + (index + 1),
            };
          }),
        ]);
      }
    }
  }, [listings, selectedListing]);

  const srcset = (image: string, size: number, rows = 1, cols = 1) => {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  };

  const [openModal, setOpenModal] = useState(false);
  return itemData ? (
    <Box className="galleryBox">
      <ImageList variant="quilted" cols={4} rowHeight={150} gap={10}>
        {itemData.map((item) => (
          <ImageListItem
            key={item.img}
            cols={item.cols || 1}
            rows={item.rows || 1}
          >
            {item.img && (
              <img
                {...srcset(item.img, 120, item.rows, item.cols)}
                alt={item.title || ""}
                loading="lazy"
                onError={(error) =>
                  error.currentTarget.setAttribute(
                    "srcset",
                    "/images/photo-missing.png"
                  )
                }
              />
            )}
          </ImageListItem>
        ))}
      </ImageList>
      <ButtonUnstyled
        variant="text"
        className="showAllPhotos hiddenPrint"
        onClick={() => setOpenModal(true)}
      >
        Show all
      </ButtonUnstyled>
      {listings?.hits[selectedListing].photos && (
        <Gallery
          imageList={
            listings?.hits[selectedListing]?.photos?.map((image) => {
              return image.url;
            }) ?? []
          }
          open={openModal}
          closeModal={() => setOpenModal(false)}
        />
      )}
    </Box>
  ) : (
    <Box
      sx={{
        height: "200px",
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography variant="h1" align="center">
        <FontAwesomeIcon icon={faTableCells} color="grey" />
      </Typography>
      <Typography variant="h6" color="grey" align="center">
        No photos available
      </Typography>
    </Box>
  );
};

export default ListingGallery;
