import React, { useContext, useState } from "react";
import {
  DataGridPro,
  GridCellParams,
  GridCsvExportOptions,
  GridToolbarContainer,
  GridValueGetterParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import "./ActivityTimeline.scss";
import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import { formatDate } from "../../../common/utils";
import ActivityCommentsRenderer from "./ActivityCommentsRenderer";
import { CommentableType } from "../../../common/renderer/CommentsDrawer";
import { RentalUnitContext } from "../RentalUnitContext";
import { GridToolbarExport } from "../../../../components/DataGridCustomToolbar/GridToolbarExport";
import ActivityLettersRenderer from "./ActivityLettersRenderer";

/**
 * Custom Toolbar with just Export button
 * @param param : Grid Api reference
 * @returns Downloads the csv file
 */
const CustomExportToolbar = ({ gridRef }: any) => {
  const csvOptions: GridCsvExportOptions = {
    fileName: "Activity Timeline",
    fields: ["date", "activity", "details"],
  };

  // Added flag to hide toolbar export as part of HC-2253, please remove whenever toolbar is required.
  const showToolbar = false;
  return (
    <GridToolbarContainer
      style={{ justifyContent: "flex-end", display: "flex" }}
    >
      {showToolbar ? (
        <GridToolbarExport
          onClick={() => gridRef.current.exportDataAsCsv(csvOptions)}
        />
      ) : undefined}
    </GridToolbarContainer>
  );
};

const activityTimelineCols = [
  {
    headerName: "Date",
    field: "date",
    flex: 2,
    align: "left",
    headerAlign: "left",
    valueGetter: (params: GridValueGetterParams) => {
      if (params.row.date) {
        return `${formatDate(params.row.date)}`;
      }
    },
  },
  {
    headerName: "Activity",
    field: "activity",
    flex: 3,
    align: "left",
    headerAlign: "left",
  },
  {
    headerName: "Details",
    field: "details",
    flex: 8,
    align: "left",
    headerAlign: "left",
    renderCell: (params: GridCellParams) => {
      const details = params.value
        .split("<br>")
        .map((line: string, index: number) => (
          <React.Fragment key={index}>
            {line}
            {index !== params.value.split("<br>").length - 1 && <br />}
          </React.Fragment>
        ));
      return (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {details}
        </div>
      );
    },
  },
  {
    headerName: "View",
    field: "",
    flex: 3,
    align: "left",
    headerAlign: "left",
    renderCell: (params: GridCellParams) => (
      <>
        <ActivityCommentsRenderer params={params} />
        <ActivityLettersRenderer params={params} />
      </>
    ),
  },
];

const ActivityTimeline = () => {
  const { activities } = useContext(RentalUnitContext);
  const [pageSize, setPageSize] = useState<number>(10);
  const gridRef = useGridApiRef();

  const parseToHumanReadable = (data: string): string => {
    const str: Array<string> = data.split(/_/g).map((item) => {
      return item[0] + item.slice(1).toLowerCase();
    });
    return str.reduce((a, b) => a + " " + b);
  };

  const pathName = window.location.pathname;

  const getForeignId = () => {
    return pathName.split("/")[3];
  };

  const getForeignType = () => {
    const source = pathName.split("/")[2];
    switch (source) {
      case "rental-units":
        return CommentableType.RENTAL_UNIT;

      case "registrations":
        return CommentableType.PERMIT;

      case "listings":
        return CommentableType.LISTING;
    }
  };

  const mapToDataGrid = () => {
    if (activities) {
      return activities?.hits.map((item, index) => {
        let details = item.summary;
        if (item.type === "COMMENT" && item.timeline_recent_comment) {
          details += `<br>${item.timeline_recent_comment}`;
        }
        return {
          id: index,
          date: dayjs(item.published_at).format("MM/DD/YYYY"),
          publishedAt: formatDate(item.published_at),
          activity: parseToHumanReadable(item.type),
          details: details,
          view: { foreignId: getForeignId(), foreignType: getForeignType() },
          letterData: {
            letterId: item.letter_id,
            letterCode: item.letter_code,
          },
        };
      });
    }
  };

  const [data] = useState(mapToDataGrid());

  return data ? (
    <div className="activityTimelineContainer">
      <Typography variant="h6" sx={{ display: "table" }} className="bold title">
        Activity Timeline
      </Typography>
      <DataGridPro
        className="activityTimeline"
        pagination
        paginationMode={"client"}
        apiRef={gridRef}
        disableColumnResize
        // @ts-ignore
        columns={activityTimelineCols}
        disableSelectionOnClick
        components={{
          Toolbar: CustomExportToolbar,
        }}
        componentsProps={{
          toolbar: {
            gridRef: gridRef,
          },
        }}
        rows={data}
        rowsPerPageOptions={[10, 25, 50, 100]}
        pageSize={pageSize}
        onPageSizeChange={(value) => setPageSize(value)}
        autoHeight
      />
    </div>
  ) : (
    <Box
      sx={{
        height: "100%",
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography variant="h1" align="center">
        <FontAwesomeIcon icon={faTableList} color="grey" />
      </Typography>
      <Typography variant="h6" color="grey" align="center">
        No activities data available
      </Typography>
    </Box>
  );
};

export default ActivityTimeline;
