import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  Menu,
  MenuItem,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LetterCriteriaTooltip from "../../dashboard/rental-units/Letter/LetterCriteriaTooltip";
import {
  TemplateCriteria,
  TemplateInfo,
} from "../../dashboard/rental-units/RentalUnitDetails/RentalUnitDetails";
import "./DropdownComponent.scss";

interface DropdownProps {
  open: boolean;
  primaryContent: TemplateInfo[] | [];
  secondaryContent: TemplateInfo[] | [];
  noDataMsg: string | null;
  secondaryContentTitle: string;
  handleClose: () => void;
  handlePrimaryItemClick: (event: any) => void;
  anchorEl: HTMLElement | null;
  tooltipData: any;
}

const DropdownComponent = (props: DropdownProps) => {
  const [hoveredItemId, setHoveredItemId] = useState<string>("");
  const [expand, setExpand] = useState<boolean>(false);
  const [templateCriteria, setTemplateCriteria] = useState<TemplateCriteria[]>(
    []
  );

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 500,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  }));

  useEffect(() => {
    const templateList = props.tooltipData;
    if (templateList) {
      setTemplateCriteria([]);
      const selectedTemplateDetails = templateList.templates.filter(
        (template: TemplateInfo) =>
          template.letter_template_id === hoveredItemId
      );
      setTemplateCriteria(selectedTemplateDetails[0]?.criteria);
    }
  }, [hoveredItemId]);

  const handleMenuItemHover = (event: any) => {
    const templateId = event.target.id;
    setHoveredItemId(templateId);
  };

  const handleSecondaryMenuClick = () => {
    setExpand(!expand);
  };

  return (
    <Menu
      anchorEl={props.anchorEl}
      open={props.open}
      onClose={() => props.handleClose()}
    >
      {props.primaryContent.map((item, i: number) => [
        <LightTooltip
          style={{ backgroundColor: "#ffffff" }}
          placement="left"
          title={<LetterCriteriaTooltip templateCriteria={templateCriteria} />}
          key={i}
        >
          <MenuItem
            key={i}
            onClick={(event) => props.handlePrimaryItemClick(event)}
            id={item.letter_template_id}
            onMouseEnter={handleMenuItemHover}
          >
            {item.customer_display_name}
          </MenuItem>
        </LightTooltip>,
      ])}
      {props.secondaryContent.length > 0 && (
        <div>
          <MenuItem
            sx={{ color: "#747474" }}
            onClick={() => handleSecondaryMenuClick()}
          >
            {props.secondaryContentTitle}
            {expand ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>

          <Collapse in={expand} unmountOnExit>
            {props.secondaryContent.map((item, i: number) => [
              <LightTooltip
                key={i}
                placement="left"
                title={
                  <LetterCriteriaTooltip templateCriteria={templateCriteria} />
                }
              >
                <MenuItem
                  sx={{ color: "#747474", pl: 4, cursor: "none" }}
                  key={i}
                  id={item.letter_template_id}
                  onMouseEnter={handleMenuItemHover}
                >
                  {item.customer_display_name}
                </MenuItem>
              </LightTooltip>,
            ])}
          </Collapse>
        </div>
      )}
      {props.noDataMsg && <MenuItem>{props.noDataMsg}</MenuItem>}
    </Menu>
  );
};

export default DropdownComponent;
