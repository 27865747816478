
import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useQuery, gql } from '@apollo/client';
import { Paper, Switch, FormGroup, FormControlLabel } from '@mui/material';
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import { LoadingScreen } from '../../common/loading';
import env from '../../common/env';

const GET_INITIAL = gql`
  query($jurisdiction_id:ID!) {
    geometry(jurisdiction_id:$jurisdiction_id)
    rentalUnits(search: { size:1 }) { total }
  }
`;

const GET_RENTAL_UNITS = gql`
  query($q:KibanaQL="" $size:Int) {
    rentalUnits(search: { query:$q size:$size }) {
      hits {
        id
        situs_address {
          concatenated
          position {
            longitude
            latitude
          }
        }
        is_compliant
        is_identified
      }
    }
  }
`;

function View() {
  //
  const { user } = useAuth0();
  const [jurisdictionId] = useState(user['https://api.hostcompliance.com/metadata'].geoid);
  const { data:initialData, loading:initialLoading } = useQuery(GET_INITIAL, { variables: { jurisdiction_id: jurisdictionId } });

  // Categories
  const [categories, setCategories] = useState({
    COMPLIANT: {
      active: true,
      color: 'green',
      description: 'COMPLIANT w. land use regulations'
    },
    NONCOMPLIANT:  {
      active: true,
      color: '#ff0000',
      description: 'NON-COMPLIANT w. land use regulations'
    },
    UNKNOWN: {
      active: true,
      color: 'blue',
      description: 'UNKNOWN COMPLIANCE STATUS'
    },
    UNIDENTIFIED: {
      active: true,
      color: 'yellow',
      description: 'NOT YET IDENTIFIED'
    }
  });

  const toggleCategory = (id) => () => {
    categories[id].active = !categories[id].active;
    setCategories(categories);
    processRentalUnits(rentalUnitData.rentalUnits.hits);
  };

  // Rental Units
  const { data:rentalUnitData, loading:rentalUnitLoading } = useQuery(GET_RENTAL_UNITS, {
    skip: initialLoading,
    variables: { size: initialData && initialData.rentalUnits.total }
  });
  const [rentalUnits, setRentalUnits] = useState([]);

  const processRentalUnits = useCallback((hits) => {
    const rentalUnitsCopy = JSON.parse(JSON.stringify(hits));

    setRentalUnits(rentalUnitsCopy
      .map(item => {
        if (item.is_identified === false) {
          item.category_id = 'UNIDENTIFIED';
        } else if (item.is_compliant === true) {
          item.category_id = 'COMPLIANT';
        } else if (item.is_compliant === false) {
          item.category_id = 'NONCOMPLIANT';
        } else {
          item.category_id = 'UNKNOWN';
        }
        return item;
      })
      .filter(o => Object.keys(categories).filter(k => categories[k].active).indexOf(o.category_id) > -1)
    );
  }, [setRentalUnits, categories]);

  useEffect(() => {
    if (!rentalUnitData) return;
    processRentalUnits(rentalUnitData.rentalUnits.hits);
  }, [rentalUnitData, rentalUnitLoading, processRentalUnits]);

  // Map
  const { isLoaded:isGoogleMapLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: env.GOOGLE_MAPS_API_KEY
  });

  const onLoad = useCallback(function callback(map) {
    function processPoints(geometry, callback, thisArg) {
      if (geometry instanceof window.google.maps.LatLng) {
        callback.call(thisArg, geometry);
      } else if (geometry instanceof window.google.maps.Data.Point) {
        callback.call(thisArg, geometry.get());
      } else {
        geometry.getArray().forEach(function(g) {
          processPoints(g, callback, thisArg);
        });
      }
    }

    const bounds = new window.google.maps.LatLngBounds();

    let geometry = JSON.parse(JSON.stringify(initialData.geometry));
    geometry.features[0].id = 'borders';

    map.data.addGeoJson(geometry);
    processPoints(map.data.getFeatureById('borders').getGeometry(), bounds.extend, bounds);

    map.fitBounds(bounds);
  }, [initialData]);

  const [infoWindow, setInfoWindow] = useState(null);

  //
  return (
    <div>
      {!initialLoading && isGoogleMapLoaded && (
        <GoogleMap
          mapContainerStyle={{
            width: '100%',
            height: '100%'
          }}
          zoom={10}
          onLoad={onLoad}
        >
          {
            rentalUnits.map(item => {
              return (
                <Marker
                  key={item.id}
                  position={{
                    lat: item.situs_address.position.latitude,
                    lng: item.situs_address.position.longitude,
                  }}
                  icon={{
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 4,
                    fillColor: categories[item.category_id].color,
                    fillOpacity: 1,
                    strokeWeight: 0,
                  }}
                  onClick={() => {
                    setInfoWindow({
                      description: item.situs_address.concatenated + (item.is_identified && item.explanation ? ': ' + item.explanation : ''),
                      position: {
                        lat: item.situs_address.position.latitude,
                        lng: item.situs_address.position.longitude,
                      },
                    });
                  }}
                />
              )
            })
          }

          {infoWindow && (
            <InfoWindow
              position={{
                lat: infoWindow.position.lat,
                lng: infoWindow.position.lng
              }}
              onCloseClick={() => {setInfoWindow(null)}}
            >
              <span>{infoWindow.description}</span>
            </InfoWindow>
          )}
        </GoogleMap>
      )}

      <Paper
        square
        elevation={1}
        style={{
          top: '60px',
          position: 'absolute',
          right: '10px',
          padding: '11px',
        }}
      >
        {categories && (
          <FormGroup>
            {
              Object.keys(categories).map(id=>{
                const item = categories[id];

                // checked={item.active}

                return(
                  <FormControlLabel
                    key={id}
                    style={{
                      borderLeft: '6px solid ' + item.color,
                      padding: '4px 0',
                    }}
                    control={
                      <Switch size="small" checked={item.active} onChange={toggleCategory(id)} />
                    }
                    label={item.description}
                  />
                )
              })
            }
          </FormGroup>
        )}
      </Paper>
    </div>
  );
}

export default withAuthenticationRequired(View, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: LoadingScreen,
});
