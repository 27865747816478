import React from "react";
import {
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import env from "../../common/env";
import qs, { ParsedQs } from "qs";

export default function DashboardSelector() {
  const handleBoardOptionChange = (event: any) => {
    window.parent.parent.location.href = event.target.value;
  };
  const queryParams: ParsedQs = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const last12MonthsBoard =
    queryParams.active_last_12_months_only != undefined
      ? queryParams.active_last_12_months_only.toString().toLowerCase() ===
        "true"
      : false;
  const url = `${env.KIBANA_URL}/app/kibana#/dashboard/${env.KIBANA_ADDRESS_IDENTIFICATION}?embed=true&_g=()`;
  const active_url = `${env.KIBANA_URL}/app/kibana#/dashboard/${env.KIBANA_ADDRESS_IDENTIFICATION_12_MONTHS}?embed=true&amp;_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-15y,to:now))&amp;_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:'Address%20Identification%20Dashboard%20(Active%20Last%2012%20Months)',viewMode:view)`;
  return (
    <Paper>
      <FormControl sx={{ background: "#fff" }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={handleBoardOptionChange}
        >
          <FormControlLabel
            value={"/loadKibana?url=" + encodeURIComponent(url)}
            control={<Radio />}
            label="Currently Advertised"
            checked={!last12MonthsBoard}
          />
          <FormControlLabel
            value={"/loadKibana?url=" + encodeURIComponent(active_url)}
            control={<Radio />}
            label="Currently Advertised and Active Last 12 Months"
            checked={last12MonthsBoard}
          />
        </RadioGroup>
      </FormControl>
    </Paper>
  );
}
