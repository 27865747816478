import React, { useState, useContext } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import ModalComponent from "../../../../../components/ModalComponent/ModalComponent";
import { Steps, FormDataInterface, Evidence } from "./Steps/Steps";
import DialogComponent from "../../../../../components/DialogComponent/DialogComponent";
import { ListingInterface, RentalUnitContext } from "../../RentalUnitContext";
import "./EditAddressInformationModal.scss";

interface EditAddressInformationProps {
  open: boolean;
  closeModal: () => void;
  reloadGrid?: () => any;
}

const confirmDialogContent = `Click “continue editing” to retrieve and submit your changes to the property's address information. If you no longer wish to continue editing, click “discard changes."`;
const infoText =
  "You have unsaved changes. If you exit, any changes you’ve inputted will be lost.";

export const EditAddressInformationModal = (
  props: EditAddressInformationProps
) => {
  const { rentalUnitId, listings, rentalUnit } = useContext(RentalUnitContext);

  const prepareEvidence = (listing: ListingInterface): Evidence => {
    const keepIndexes = listing.analysis?.evidence.map((evidence: any) => {
      return evidence?.evidence_index;
    });
    return {
      listing_id: listing.id,
      keep: keepIndexes as number[],
      discard: [],
    };
  };

  const initialForm: FormDataInterface = {
    id: rentalUnitId,
    internal_listing_ids: listings?.hits.map((item) => item.id) || [""],
    formatted: rentalUnit?.situs_address.formatted || "",
    unit_number: rentalUnit?.situs_address.unit_number || "",
    parcel_number: rentalUnit?.parcel_number || "",
    latitude: rentalUnit?.situs_address.position.latitude || 0,
    longitude: rentalUnit?.situs_address.position.longitude || 0,
    comments:
      listings?.hits.map((item: ListingInterface) => {
        return {
          comment: item.analysis ? item.analysis.comments : null,
          internal_listing_id: item.id,
        };
      }) || [],
    remove_letters: [],
    evidences: listings?.hits.map((item: ListingInterface) =>
      prepareEvidence(item)
    ),
  };

  const [formData, setFormData] = useState<FormDataInterface>(initialForm);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [showSubmitDialog, setShowSubmitDialog] = useState<boolean>(false);

  const handleChange = (data: FormDataInterface) => {
    setFormData(data);
  };

  const handleClose = () => {
    if (JSON.stringify(initialForm) !== JSON.stringify(formData)) {
      setShowConfirmDialog(true);
      return;
    }
    props.closeModal();
  };

  const handleSubmit = () => {
    if (props.reloadGrid) {
      setTimeout(() => {
        setShowSubmitDialog(true);
      }, 3000);
    } else {
      setShowSubmitDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setShowSubmitDialog(false);
    if (props.reloadGrid) {
      props.reloadGrid();
    }
    props.closeModal();
  };

  return (
    <ModalComponent
      open={props.open}
      onClose={handleClose}
      className={"editAImodal modal"}
      header="Edit Address Information"
    >
      <Steps
        onFormDataChanged={handleChange}
        initialForm={initialForm}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
      <DialogComponent
        open={showConfirmDialog}
        handleClose={() => setShowConfirmDialog(false)}
        title={"Unsaved changes to address information"}
        contentInfoText={infoText}
        content={confirmDialogContent}
        onDiscard={() => {
          setFormData(initialForm);
          setShowConfirmDialog(false);
          props.closeModal();
        }}
      ></DialogComponent>
      <Dialog open={showSubmitDialog} sx={{ padding: -50 }}>
        <DialogTitle>
          You have successfully changed address information.
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            The information you have submitted has been received successfully.
            It will be used the next time we crawl STR listings in your area. We
            do this every 3-4 days. For more immediate assistance please contact
            hcsystems@granicus.com.
          </Typography>
        </DialogContent>
        <Button
          variant="contained"
          onClick={handleCloseDialog}
          sx={{ width: 200, margin: "20px auto" }}
        >
          Continue
        </Button>
      </Dialog>
    </ModalComponent>
  );
};
