import * as React from "react";
import Box from "@mui/material/Box";
import "./Gallery.scss";
import { ImageList, ImageListItem } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalComponent from "../../../../../components/ModalComponent/ModalComponent";
import { RentalUnitContext } from "../../RentalUnitContext";

export default function Gallery({
  open,
  closeModal,
  imageList,
}: {
  open: boolean;
  closeModal: any;
  imageList: string[];
}) {
  const { selectedListing } = useContext(RentalUnitContext);
  const [bigImage, setBigImage] = useState(imageList[0]);

  useEffect(() => {
    setBigImage(imageList[0]);
  }, [selectedListing]);

  const countPrevImage = (image: string): number => {
    const currentIndex: number = imageList.indexOf(image);
    if (currentIndex === 0) {
      return imageList.length - 1;
    }
    return currentIndex - 1;
  };

  const countNextImage = (image: string): number => {
    const nextIndex: number = imageList.indexOf(image) + 1;
    if (nextIndex === imageList.length) {
      return 0;
    }
    return nextIndex;
  };

  const handleKeyDown = (event: any) => {
    switch (event.keyCode) {
      case 37: // arrow left
        setBigImage(imageList[countPrevImage(bigImage)]);
        break;
      case 39: // arrow right
        setBigImage(imageList[countNextImage(bigImage)]);
        break;
    }
  };

  return (
    <ModalComponent
      header={"Listing Photos"}
      open={open}
      onClose={() => closeModal(false)}
    >
      <Box className="galleryModalBox" onKeyDown={handleKeyDown}>
        <ImageList
          variant="quilted"
          className="imageList"
          cols={6}
          rowHeight={130}
        >
          <ImageListItem className="bigImage" key={bigImage} cols={4} rows={4}>
            <div
              className="prevImage"
              onClick={() => setBigImage(imageList[countPrevImage(bigImage)])}
            >
              <div className="innerPrevImage">
                <FontAwesomeIcon icon={faAngleLeft} />
              </div>
            </div>
            <img
              src={bigImage}
              loading="lazy"
              alt="Big image"
              onError={(error) =>
                error.currentTarget.setAttribute(
                  "srcset",
                  "/images/photo-missing.png"
                )
              }
            />
            <div
              className="nextImage"
              onClick={() => setBigImage(imageList[countNextImage(bigImage)])}
            >
              <div className="innerNextImage">
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </div>
          </ImageListItem>
          {imageList.map((image) => (
            <ImageListItem key={image} cols={1} rows={1}>
              <img
                src={image}
                alt={"Image " + imageList.indexOf(image)}
                loading="lazy"
                onClick={() => setBigImage(image)}
                onError={(error) =>
                  error.currentTarget.setAttribute(
                    "srcset",
                    "/images/photo-missing.png"
                  )
                }
                className={image === bigImage ? "selected" : ""}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </ModalComponent>
  );
}
