const dot = require("dot-object");
import React, { useState } from "react";
import { TextField, FormControl, FormHelperText } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDayjs";

function View({ question, context }) {
  const [formError, setFormError] = useState();
  const [formValues, setFormValues] = useState({
    output: context.pick("value") || "",
  });

  const validate = (fieldValue) => {
    if (isNaN(fieldValue.getTime())) {
      setFormError("Invalid Date");
      return false;
    } else {
      setFormError();
      return true;
    }
  };

  const handleDatePickerChange = (e) => {
    let values = { ...formValues };
    dot.str("output", e.$d, values);
    setFormValues(values);
    if (validate(e.$d)) {
      context.assign({
        value: e.$d.toISOString(),
      });
    }
  };

  return (
    <FormControl error fullWidth>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DesktopDatePicker
          label={question.properties.locale.name}
          placeholder={question.properties.locale.placeholder}
          variant="filled"
          value={formValues.output}
          onChange={handleDatePickerChange}
          required={question.input.required}
          readOnly={question.input.readonly}
          autoFocus
          inputProps={{
            inputref: "ref-datepicker",
          }}
          autoComplete="off"
          openTo={question.input.view === "year" ? "year" : "day"}
          aria-describedby="datepicker-helper-text"
          minDate={
            question.input.min ? new Date(question.input.min) : undefined
          }
          maxDate={
            question.input.max ? new Date(question.input.max) : undefined
          }
          inputFormat="MM/DD/YYYY"
          renderInput={(params) => (
            <TextField id="datepicker" name="output" {...params} />
          )}
        />
        <FormHelperText
          id="datepicker-helper-text"
          style={{ fontSize: "0.9rem" }}
        >
          {formError}
        </FormHelperText>
      </LocalizationProvider>
    </FormControl>
  );
}

export default View;
