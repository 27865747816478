
const dot = require('dot-object');
import React, { useState } from 'react';
import { TextField, FormControl, FormHelperText } from '@mui/material';

function View({ question, context }) {

  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    output: context.pick('value') || ''
  });

  const validate = (formValues) => {
    let tmp = { ...formErrors };

    // any additional validation...
    if (formValues.output < question.input.min) {
      tmp.text = `${question.properties.locale.validation_minimum} ${question.input.min}`;
    } else if (formValues.output > question.input.max) {
      tmp.text = `${question.properties.locale.validation_maximum} ${question.input.max}`;
    } else {
      delete tmp.text;
    }

    setFormErrors(tmp);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const castValue = isNaN(value) ? 0 : parseInt(value);

    let values = {...formValues};
    dot.str(name, castValue, values);
    setFormValues(values);
    validate({ [name]: castValue });
    context.assign({
      value: castValue
    });
  };

  return (
    <FormControl error fullWidth>
      <TextField
        id="text"
        name="output"
        label={question.properties.locale.name}
        placeholder={question.properties.locale.placeholder}
        variant="filled"
        value={String(isNaN(formValues.output) ? 0 : formValues.output)}
        onChange={handleInputChange}
        required={question.input.required}
        readOnly={question.input.readonly}
        autoFocus
        inputProps={{
          maxLength: question.input.maxlength ? question.input.maxlength : 80,
          inputMode: 'numeric',
          inputref: 'ref-text',
          pattern: '[0-9]*'
        }}
        autoComplete="off"
        aria-describedby="text-helper-text"
      />
      <FormHelperText id="text-helper-text" style={{fontSize:'0.9rem'}}>{formErrors.text}</FormHelperText>
    </FormControl>
  );
}

export default View;
