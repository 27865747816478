import React, { useState } from "react";
import { FormDataInterface } from "../Steps";
import { Box, Typography } from "@mui/material";
import "./ReviewChanges.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { ReviewedValue } from "./ReviewedValue";
import classNames from "classnames";
import { Letter } from "../../../../RentalUnitContext";
import { ReviewedLetters } from "./ReviewedLetters";
import { Icon } from "../../../../../../../components/Icon/Icon";

interface ReviewChangesProps {
  formData: FormDataInterface;
  initialForm: FormDataInterface;
  jumpToStep: (step: number) => void;
  steps: string[];
  lettersData: Letter[];
}

export const ReviewChanges = ({
  formData,
  initialForm,
  jumpToStep,
  steps,
  lettersData,
}: ReviewChangesProps) => {
  const [initialCoordinates] = useState<string>(
    `${initialForm.latitude}: ${initialForm.longitude}`
  );
  const [formCoordinates] = useState<string>(
    `${formData.latitude}: ${formData.longitude}`
  );

  const valueFormatter = (
    initValue: string,
    formValue: string,
    step: number,
    valueType: string
  ) => {
    if (initValue === formValue) {
      return null;
    }
    return (
      <ReviewedValue
        formValue={formValue}
        initialValue={initValue}
        onClick={() => jumpToStep(step)}
        valueType={valueType}
      />
    );
  };

  const listsFormatter = () => {
    const [showEditIcon, setShowEditIcon] = useState<boolean>(false);
    if (
      JSON.stringify(formData.comments) ===
        JSON.stringify(initialForm.comments) &&
      JSON.stringify(formData.evidences) ===
        JSON.stringify(initialForm.evidences)
    ) {
      return (
        <Typography variant="body1" className="font-light">
          No changes made
        </Typography>
      );
    }
    return (
      <div className="flex-row">
        <div
          className={classNames(
            "flex-element",
            { infoGreyValue: showEditIcon },
            "hoverClassValue"
          )}
          onMouseEnter={() => setShowEditIcon(true)}
          onMouseLeave={() => setShowEditIcon(false)}
          onClick={() => jumpToStep(2)}
        >
          <div className="informationTileValue">
            <Typography className="text-emphasis">
              Changes made to match details evidence and/or comments
            </Typography>
          </div>
          <div className="iconContainer">
            {showEditIcon && (
              <FontAwesomeIcon icon={faPencil} className="iconWrapperValue" />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Box className="reviewBox">
      <Typography variant={"h6"} className="heading">
        Review your changes:
      </Typography>
      <div className={"flex-container"}>
        {/* STEP 1 */}

        {/* VARIANT - ALL THREE CHANGED */}
        {initialForm.formatted !== formData.formatted &&
        initialForm.parcel_number !== formData.parcel_number &&
        initialForm.unit_number !== formData.unit_number ? (
          <div className="flex-row-parent">
            <div className="flex-column-stretch">
              <div className="flex-row-stepper">
                <div className="flex-item-icon">
                  <Icon type="step1" />
                </div>
                <Typography variant="body1" className="text-stepper">
                  {steps[0]}
                </Typography>
              </div>
              <div
                className="flex-row stepInfo-allThree"
                style={{ height: "100%" }}
              >
                <div className="halfStep" />
                <div />
              </div>
            </div>
            <div className="flex-column-values">
              {valueFormatter(
                initialForm["formatted"],
                formData["formatted"],
                0,
                "Property Address"
              )}
              {valueFormatter(
                initialForm["unit_number"],
                formData["unit_number"],
                0,
                "Unit Number"
              )}
              {valueFormatter(
                initialForm["parcel_number"],
                formData["parcel_number"],
                0,
                "Parcel Number"
              )}
            </div>
          </div>
        ) : (
          // VARIANT - LESS THAN 3 VISIBLE

          <div className="flex-row-stepper">
            <div className="flex-item-icon">
              <Icon type="step1" />
            </div>
            <div className="flex-item stepInfo">
              <Typography variant="body1" className="text-stepper">
                {steps[0]}
              </Typography>
            </div>
          </div>
        )}
        {initialForm.formatted !== formData.formatted &&
        initialForm.parcel_number !== formData.parcel_number &&
        initialForm.unit_number !== formData.unit_number ? null : (
          <div className="flex-row flex-row-parent">
            <div className="flex-row stepInfo">
              <div className="halfStep" />
              <div />
            </div>
            <div className="flex-column">
              {initialForm.formatted === formData.formatted &&
              initialForm.parcel_number === formData.parcel_number &&
              initialForm.unit_number === formData.unit_number ? (
                <Typography variant="body1" className="font-light">
                  No changes made
                </Typography>
              ) : null}
              {valueFormatter(
                initialForm["formatted"],
                formData["formatted"],
                0,
                "Property Address"
              )}
              {valueFormatter(
                initialForm["unit_number"],
                formData["unit_number"],
                0,
                "Unit Number"
              )}
              {valueFormatter(
                initialForm["parcel_number"],
                formData["parcel_number"],
                0,
                "Parcel Number"
              )}
            </div>
          </div>
        )}

        {/* STEP 2 */}

        <div className="flex-row-stepper">
          <div className="flex-item-icon">
            <Icon type="step2" />
          </div>
          <div className="flex-item stepInfo">
            <Typography variant="body1" className="text-stepper">
              {steps[1]}
            </Typography>
          </div>
        </div>

        <div className="flex-row flex-row-parent">
          <div className="flex-row stepInfo">
            <div className="halfStep" />
            <div className="distance" />
          </div>
          <div className="flex-column">
            {initialCoordinates === formCoordinates ? (
              <Typography variant="body1" className="font-light">
                No changes made
              </Typography>
            ) : (
              valueFormatter(
                initialCoordinates,
                formCoordinates,
                1,
                "Coordinates"
              )
            )}
          </div>
        </div>

        {/* STEP 3 */}

        <div className="flex-row-stepper">
          <div className="flex-item-icon">
            <Icon type="step3" />
          </div>
          <div className="flex-item stepInfo">
            <Typography variant="body1" className="text-stepper">
              {steps[2]}
            </Typography>
          </div>
        </div>

        <div className="flex-row flex-row-parent">
          {steps.length === 5 ? (
            <div className="flex-row stepInfo">
              <div className="halfStep" />
              <div />
            </div>
          ) : (
            <div className="flex-row stepInfo" />
          )}
          <div className="flex-column">{listsFormatter()}</div>
        </div>

        {/* STEP 4 */}
        {steps.length === 5 ? (
          <>
            <div className="flex-row-stepper">
              <div className="flex-item-icon">
                <Icon type="step4" />
              </div>
              <div className="flex-item stepInfo">
                <Typography variant="body1" className="text-stepper">
                  {steps[3]}
                </Typography>
              </div>
            </div>
            <div className="flex-row flex-row-parent">
              <div className="flex-row stepInfo" />
              <div className="flex-column">
                {formData.remove_letters.length > 0 ? (
                  <ReviewedLetters
                    formValue={lettersData}
                    onClick={() => jumpToStep(3)}
                  />
                ) : (
                  <Typography variant="body1" className="font-light">
                    No changes made
                  </Typography>
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Box>
  );
};
