import { GridCellParams } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import ReturnType from "typescript";
import countries from "./countryCodes.json";
export interface CountryInterface {
  code: string;
  name: string;
}

const locale = navigator.language.toLocaleLowerCase();
const eu_locales = [
  "en-gb",
  "en",
  "de-ch",
  "de-at",
  "de",
  "pl",
  "de-de",
  "de-li",
  "af",
  "sq",
  "am",
  "ar",
  "an",
  "hy",
  "ast",
  "az",
  "bn",
  "eu",
  "be",
  "bs",
  "br",
  "bg",
  "my",
  "ca",
  "ceb",
  "ckb",
  "chr",
  "zh",
  "co",
  "hr",
  "cs",
  "da",
  "nl",
  "en-nz",
  "en-gb-oxendict",
  "eo",
  "et",
  "fo",
  "fi",
  "fr",
  "fr-fr",
  "fr-ch",
  "gl",
  "ka",
  "el",
  "ht",
  "he",
  "hu",
  "is",
  "hmn",
  "ga",
  "it-it",
  "it",
  "it-ch",
  "ku",
  "la",
  "lv",
  "lt",
  "lb",
  "mk",
  "no",
  "nb",
  "pt",
  "fa",
  "pt-pt",
  "ro",
  "mo",
  "rm",
  "sr",
  "gd",
  "es",
  "es-ar",
  "es-es",
  "sv",
  "tr",
  "uk",
  "cy",
];

const getDateFormat = () => {
  switch (locale) {
    case "en-us":
      return "MMMM D, YYYY";
    default:
      return "D MMMM YYYY";
  }
};

export const getDateFormatForDatePicker = () => {
  switch (locale) {
    case "en-us":
      return "MM/DD/YYYY";
    default:
      return "DD/MM/YYYY";
  }
};

const getDateTimeFormat = () => {
  if (locale === "en-us") return "MMMM D, YYYY - h:mm A";
  else if (eu_locales.includes(locale)) return "D MMMM YYYY - H:mm ";
  return "D MMMM YYYY - h:mm A";
};

const getDateOptionalTimeFormat = (dateTimeStr: string) => {
  if (dateTimeStr.split("T")[1].startsWith("00:00:00")) {
    return getDateFormat();
  } else {
    if (locale === "en-us") return "MMMM D at YYYY - h:mm A";
    else if (eu_locales.includes(locale)) return "D MMMM YYYY at H:mm ";
    return "D MMMM YYYY at h:mm A";
  }
};

export const timeZone = new Date()
  .toLocaleDateString("en-US", { day: "2-digit", timeZoneName: "long" })
  .slice(4)
  .split(" ")
  .map(function (item) {
    return item[0];
  })
  .join("");

export const formatDate = (dateStr: string) => {
  return dayjs(dateStr).format(getDateFormat());
};

export const formatDateTime = (dateTimeStr: string) => {
  return dayjs(dateTimeStr).format(getDateTimeFormat());
};

export const formatDateWithOptionalTime = (dateTimeStr: string) => {
  return dayjs(dateTimeStr).format(getDateOptionalTimeFormat(dateTimeStr));
};

export const getFormattedDateCell = () => {
  return (param: GridCellParams) => {
    if (param.value) {
      return formatDate(param.value);
    }
  };
};

export const fetchService = async (
  urlPath: any,
  method: string,
  headers: any,
  body: any
) => {
  return fetch(urlPath, {
    method: method,
    headers: headers,
    body: body,
  });
};

export let extractContent = (htmlElements: string) => {
  if (!htmlElements) {
    return "";
  }
  const childNodes = new DOMParser().parseFromString(htmlElements, "text/html")
    .documentElement.childNodes;
  // here 0th is head and 1st is body in childnodes of documentElement
  if (childNodes && childNodes[1]) {
    return childNodes[1].firstChild?.textContent;
  }
};

export let extractInnerHTML = (htmlElements: string) => {
  if (!htmlElements) {
    return "";
  }
  const childNodes = new DOMParser().parseFromString(htmlElements, "text/html")
    .documentElement.childNodes;
  // here 0th is head and 1st is body in childnodes of documentElement
  if (childNodes && childNodes[1] && childNodes[1] instanceof HTMLElement) {
    return (childNodes[1] as HTMLElement).innerHTML;
  }
  return "";
};

export const parseSubsite = (subsiteName: string): ReturnType<any> => {
  const icons = ["airbnb", "vrbo", "flipkey", "booking", "homeaway", "vacasa"];
  const parsedSubsite = subsiteName.toLowerCase();
  if (icons.includes(parsedSubsite)) {
    return parsedSubsite;
  } else {
    return "file";
  }
};

export const isEmpty = (value: any) => {
  return value == null || value.length === 0;
};

export const titleCase = (str: string) => {
  str = str.toLowerCase();
  let strArray = str.split(" ");
  for (var i = 0; i < str.length; i++) {
    if (strArray[i]) {
      strArray[i] =
        strArray[i].trim().charAt(0).toUpperCase() +
        strArray[i].trim().slice(1);
    }
  }
  return strArray.join("");
};

export const getUserTimestamp = (createdAt: string) => {
  // Convert the timestamp to a JavaScript Date object
  const date = new Date(createdAt);

  // Format the date into a user-friendly timestamp
  return date.toLocaleDateString() + " " + date.toLocaleTimeString();
};

export const currency_formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const capitalizeFirstLetters = (inputString: string) => {
  return inputString
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const isCountryCodeValid = (code: string): boolean => {
  return countries.some(
    (country: CountryInterface) => country.code === code.toUpperCase()
  );
};

export type QueryParams = {
  [key: string]: string;
};

export const queryStringToObject = (queryString: string): QueryParams => {
  const params = new URLSearchParams(queryString);
  const obj: QueryParams = {};
  for (const [key, value] of params.entries()) {
    obj[key] = value;
  }
  return obj;
};
