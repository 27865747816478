import React, { useEffect, useContext } from "react";
import { Typography, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import EsriStepper from "../Stepper";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { LoadingScreen } from "../../../common/loading";
import styles from "./EsriPage.module.scss";
import Notification from "../components/Notification";
import { EsriContext, loadConfig } from "../context/EsriContext";
import { loader } from "graphql.macro";
import { useLazyQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import env from "../../../common/env";

const GET_ESRI_CONFIG = loader("../queries/fetchEsriConfig.graphql");

const SIDE_MENU_DESCRIPTION =
  "Host Compliance connects with Esri for matching assessor data.";
const SIDE_MENU_LINK_TEXT = "Return to Host Compliance";

const EsriPage: React.FC = () => {
  const { user } = useAuth0();
  const { dispatch } = useContext(EsriContext);

  const [loadEsriConfig] = useLazyQuery(GET_ESRI_CONFIG, {
    onCompleted(data) {
      data.esriConfig && dispatch(loadConfig(data.esriConfig));
    },
  });

  useEffect(() => {
    if (user) {
      loadEsriConfig({
        variables: {
          geoid: user[env.AUTH0_METADATA_KEY].geoid,
        },
      });
    }
  }, [user]);

  return (
    <Box className={styles.EsriPage}>
      <Notification />
      <Box className={styles.LeftSection}>
        <img
          src="https://cdn.hostcompliance.com/hostcompliance/Host-Compliance-icon-word.png"
          alt="Host Compliance"
          style={{ maxHeight: "26px", marginLeft: "10px" }}
        />
        <Typography variant="h4" className={styles.LeftSideDescription}>
          {SIDE_MENU_DESCRIPTION}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ marginX: "70px" }}
          component={NavLink}
          to="/integrations"
        >
          <span style={{ marginRight: "5px" }}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </span>
          {SIDE_MENU_LINK_TEXT}
        </Typography>
      </Box>
      <Box className={styles.RightSection}>
        <Box sx={{ marginTop: 15 }}>
          <EsriStepper />
        </Box>
      </Box>
    </Box>
  );
};

export default withAuthenticationRequired(EsriPage, {
  onRedirecting: LoadingScreen,
});
