import React from "react";

export interface RentalUnitInterface {
  rentalUnitId: string;
  listingId: string;
  is_identified: boolean;
  compliance_explanation: string;
  parcel_number: string;
  permit_number: string;
  situs_address: {
    formatted: string;
    unit_number: string;
    position: {
      latitude: number;
      longitude: number;
    };
  };
  owner: {
    name: string;
    name_secondary: string;
    mailing_address: {
      line_1: string;
      city: string;
      country_code: string;
      state: string;
      postal_code: string;
    };
    business_mailing_address: {
      line_1: string;
      line_2: string;
    };
  };
}

export interface ListingTableInterface {
  internal_listing_id: string;
  listing_url: string;
  external_property_id: string;
  parcel_number: string;
  is_compliant: boolean;
  compliance_explanation: string;
  is_advertised: boolean;
  is_qualified: boolean;
  identification_status: string;
  created_before_timing: string;
  first_identified_timing: string;
  last_reposted_timing: string;
  last_review_date: string;
  last_documented_stay: string;
  last_documented_stay_timing: string;
  removed_timing: string;
  unit_number_identified: string;
  parcel_number_identified: string;
  last_mailing_timing: string;
  internal_subsite_name: string;
  listing_title: string;
  address: string;
  unit_number: string;
  listing_min_nights: string;
  listing_room_type: string;
  owner_name: string;
  owner_address: string;
  permit_number: string;
  permit_type: string;
  case_number: string;
  listing_registration_number: string;
  str_policy: string;
  homestead_exemption: string;
  community: string;
  vacation_rental_district: string;
  code_enforcement_area_name: string;
  code_officer: string;
  listing_bedrooms: string;
  listing_max_sleeping_capacity: string;
  listing_max_number_of_people_per_bedroom: string;
  jurisdiction: string;
  under_active_identification: string;
  latitude: number;
  longitude: number;
  property_page: string;
  listing_property_type: string;
  internal_date_last_found_actual: string;
  screenshot_last_captured_actual: string;
  created_before_date_actual: string;
  internal_date_first_found_actual: string;
  first_identified_date_actual: string;
  removed_date_actual: string;
  last_reposted_actual: string;
  listing_daily_rate_in_usd: string;
  next_available_date: string;
  listing_cleaning_fee_in_usd: string;
  listing_host_provided_name: string;
  airbnb_host_id: string;
  airbnb_host_url: string;
  actively_managed_past_year: string;
  last_actively_managed: string;
  zipcode: string;
  listing_flagged: string;
  listing_flagged_reason: string;
  workerid: string;
  listing_review_count: string;
  listing_total_bathrooms: string;
  add_view_comments_modal: string;
  most_recent_comment: string;
  rental_unit_id: string;
}

export interface ListingInterface {
  id: string;
  screenshots: Screenshot[] | null;
  analysis: {
    created_at: string;
    comments: string;
    evidence: {
      comments: string;
      evidence_url: string;
      photo_url: string;
      screenshot_url: string;
      evidence_index: number;
    }[];
    matches: any;
    worker_id: string;
  } | null;
  photos: Photo[] | null;
  letters: Letter[] | null;
}

export interface Photo {
  url: string;
}

export interface Screenshot {
  listing_id: string;
  created_at: string;
  checked_at: string;
  status: "ACTIVE" | "INACTIVE";
  type: "SCREENSHOT" | "EVIDENCE";
  file: {
    url: string;
  };
}

export interface ActivitiesInterface {
  type: string;
  summary: string;
  published_at: string;
  letter_id: number;
  letter_code: string;
  timeline_recent_comment: string;
}

export interface RentalUnitContextInterface {
  rentalUnitId: string;
  selectedListingTable: number;
  selectedListing: number;
  rentalUnit?: RentalUnitInterface;
  listingTables?: {
    hits: ListingTableInterface[];
  };
  listings?: {
    hits: ListingInterface[];
  };
  activities?: {
    hits: ActivitiesInterface[];
  } | null;
}

export interface Letter {
  letter_recipient_status: string;
  id: string;
  created_at: string;
  pdf_url: string;
  status: string;
  status_at: string;
  recipient: {
    name: string;
    address: string;
  };
  property_ids: string[];
  customer_display_name: string;
}

export const RentalUnitContext =
  React.createContext<RentalUnitContextInterface>({
    rentalUnitId: "",
    selectedListingTable: 0,
    selectedListing: 0,
  });
