// Based on original grid filter input component
// https://github.com/mui/mui-x/blob/master/packages/grid/x-data-grid/src/components/panel/filterPanel/GridFilterInputMultipleSingleSelect.tsx

import React, { useEffect, useCallback, useMemo, useState } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { unstable_useId as useId } from "@mui/material/utils";
import {
  GridFilterInputMultipleSingleSelectProps,
  GridFilterInputMultipleSingleSelect as OriginalInputComponent,
} from "@mui/x-data-grid-pro";

const getValueFromOption = (option: any | undefined) => {
  if (typeof option === "object" && option !== null) {
    return option.value;
  }
  return option;
};

const isOptionEqualToValue: GridFilterInputMultipleSingleSelectProps["isOptionEqualToValue"] =
  (option, value) => getValueFromOption(option) === getValueFromOption(value);

const filter = createFilterOptions<any>();
function GridFilterInputMultipleSingleSelect(
  props: GridFilterInputMultipleSingleSelectProps
) {
  const { item, applyValue, apiRef, focusElementRef, ...other } = props;
  const id = useId();
  const resolvedColumn = apiRef.current.getColumn(item.columnField);
  const { valueFormatter, field } = resolvedColumn;
  const [availableOptions, setAvailableOptions] = useState<string[]>([]);
  const [selectedColumn] = useState<string>(resolvedColumn.field);
  const valueOptions = resolvedColumn.valueOptions({
    field: resolvedColumn.field,
  });

  function arraysEqual<T>(arr1: T[], arr2: T[]): boolean {
    return (
      arr1.length === arr2.length &&
      arr1.every((value, index) => value === arr2[index])
    );
  }

  useEffect(() => {
    if (valueOptions.length && !arraysEqual(valueOptions, availableOptions)) {
      setAvailableOptions(valueOptions);
    }
  }, [valueOptions, availableOptions]);
  let resolvedValueOptions: any[] = [...availableOptions];

  const resolvedFormattedValueOptions =
    resolvedValueOptions.map(getValueFromOption);

  const filterValueOptionFormatter = (option: any) => {
    if (typeof option === "object") {
      return option.label;
    }
    return valueFormatter && option !== ""
      ? valueFormatter({ value: option, field, api: apiRef.current })
      : option;
  };

  // The value is computed from the item.value and used directly
  // If it was done by a useEffect/useState, the Autocomplete could receive incoherent value and options
  const filterValues = useMemo(() => {
    if (!Array.isArray(item.value)) {
      return [];
    }

    if (resolvedValueOptions !== undefined) {
      const itemValueIndexes = item.value.map((element) => {
        // get the index matching between values and valueoptions
        const formattedElement = getValueFromOption(element);
        const index =
          resolvedFormattedValueOptions?.findIndex(
            (formatedOption: any) => formatedOption === formattedElement
          ) || 0;

        return index;
      });

      return itemValueIndexes
        .filter((index) => index >= 0)
        .map((index: number) => resolvedValueOptions[index]);
    }
    return item.value;
  }, [item.value, resolvedValueOptions, resolvedFormattedValueOptions]);

  useEffect(() => {
    if (
      !Array.isArray(item.value) ||
      filterValues.length !== item.value.length
    ) {
      // update the state if the filter value has been cleaned by the component
      if (availableOptions.length) {
        applyValue({ ...item, value: filterValues.map(getValueFromOption) });
      }
    }
  }, [item, filterValues, applyValue]);

  //effect to clear any selected filter values when selected column is changed
  useEffect(() => {
    if (item.columnField !== selectedColumn) {
      applyValue({ ...item, value: [] });
    }
  }, [item.columnField, selectedColumn, applyValue]);

  const handleChange = useCallback(
    (event, value) => {
      applyValue({ ...item, value: [...value.map(getValueFromOption)] });
    },
    [applyValue, item]
  );

  return (
    <Autocomplete
      disabled={!valueOptions.length}
      multiple
      freeSolo={false}
      fullWidth={true}
      options={resolvedValueOptions}
      isOptionEqualToValue={isOptionEqualToValue}
      filterOptions={filter}
      id={id}
      value={filterValues}
      onChange={handleChange}
      renderTags={(value: any[], getTagProps) =>
        value.map(
          (option: { label: string; value: any } | string, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              variant="outlined"
              size="small"
              label={filterValueOptionFormatter(option)}
              {...getTagProps({ index })}
            />
          )
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          disabled={!valueOptions.length}
          label={
            valueOptions.length
              ? apiRef.current.getLocaleText("filterPanelInputLabel")
              : "Loading..."
          }
          placeholder={apiRef.current.getLocaleText(
            "filterPanelInputPlaceholder"
          )}
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: true,
          }}
          inputRef={focusElementRef}
          type={"singleSelect"}
          variant="standard"
        />
      )}
      {...other}
    />
  );
}

GridFilterInputMultipleSingleSelect.propTypes =
  OriginalInputComponent.propTypes;

export { GridFilterInputMultipleSingleSelect };
