import "./sidemenu.scss";

import React, { useReducer, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCrosshairs,
  faCog,
  faCircleQuestion,
  faCity,
  faEnvelope,
  faScaleBalanced,
  faSignal,
  faMoneyBillAlt,
  faDollarSign,
  faAt,
} from "@fortawesome/free-solid-svg-icons";

import jwt_decode from "jwt-decode";

import { useAuth0 } from "@auth0/auth0-react";

import { featureFlags } from "../../common/feature-flags";
import { ChangePasswordModal } from "./Modals/ChangePasswordModal";

const SideMenuComponent = ({
  closeSidebar,
  jurisdictionData,
  jurisdictionClient,
}: {
  closeSidebar: any;
  jurisdictionData: any;
  jurisdictionClient: any;
}) => {
  const {
    user,
    logout,
    getAccessTokenSilently,
  }: { user?: any; logout: any; getAccessTokenSilently: any } = useAuth0();
  const [scope, setScope] = useState<string>("");
  const [openChangePasswordModal, setOpenChangePasswordModal] =
    useState<boolean>(false);
  useEffect(() => {
    async function getScope() {
      const token = await getAccessTokenSilently();
      setScope(jwt_decode<any>(token).scope);
    }
    getScope();
  }, [getAccessTokenSilently]);

  const [expanded, setExpanded] = useReducer(
    (state: any, action: any) => {
      return { ...state, ...action };
    },
    {
      addressId: true,
      complianceMonitoring: true,
      rentalActivityMonitoring: true,
      ShortTermRentalHotline: true,
      revenue: true,
      user: true,
      support: true,
      taxCollection: true,
    }
  );

  function getEnvVar(v: string): string {
    const ret = process.env[v];
    if (ret === undefined) {
      throw new Error("process.env." + v + " is undefined!");
    }
    return ret;
  }

  const revert = function () {
    let userFeatures = featureFlags.get();
    userFeatures.toolbar = false;
    featureFlags.set(userFeatures);
    window.location.href = getEnvVar("REACT_APP_LEGACY_URL");
  };

  const changePassword = function () {
    setOpenChangePasswordModal(true);
  };

  const signout = function () {
    jurisdictionClient.resetStore();
    logout({ returnTo: window.location.origin });
  };

  const closeChangePasswordModal = () => {
    setOpenChangePasswordModal(false);
  };

  return (
    <div className="sidemenu">
      <img
        src="https://cdn.hostcompliance.com/hostcompliance/Host-Compliance-icon-word.png"
        alt="Host Compliance"
        style={{ maxHeight: "23px" }}
      />

      <List component="nav">
        {scope.indexOf("read:reports:address_identification") > -1 && (
          <section>
            <ListItem
              button
              onClick={() => setExpanded({ addressId: !expanded.addressId })}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faCrosshairs} />
              </ListItemIcon>
              <ListItemText primary="Address Identification" />
            </ListItem>
            <Collapse in={expanded.addressId} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  component={NavLink}
                  onClick={closeSidebar}
                  activeClassName="active"
                  to="/dashboard/reports/address-identification"
                >
                  <ListItemText inset>Dashboard</ListItemText>
                </ListItem>

                {scope.indexOf("read:rental_units") > -1 && (
                  <ListItem
                    button
                    component={NavLink}
                    onClick={closeSidebar}
                    activeClassName="active"
                    to="/dashboard/rental-units"
                  >
                    <ListItemText inset>Rental Units</ListItemText>
                  </ListItem>
                )}

                {scope.indexOf("read:listings") > -1 && (
                  <ListItem
                    button
                    component={NavLink}
                    onClick={closeSidebar}
                    activeClassName="active"
                    to="/dashboard/listings"
                  >
                    <ListItemText inset>Listings</ListItemText>
                  </ListItem>
                )}

                {scope.indexOf("read:permits") > -1 && (
                  <ListItem
                    button
                    component={NavLink}
                    onClick={closeSidebar}
                    activeClassName="active"
                    to="/dashboard/registrations"
                  >
                    <ListItemText inset>Registrations</ListItemText>
                  </ListItem>
                )}
              </List>
            </Collapse>
          </section>
        )}
        {scope.indexOf("read:letters") > -1 && (
          <section>
            <ListItem
              button
              onClick={() =>
                setExpanded({
                  complianceMonitoring: !expanded.complianceMonitoring,
                })
              }
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faEnvelope} />
              </ListItemIcon>
              <ListItemText primary="Compliance Monitoring" />
            </ListItem>
            <Collapse
              in={expanded.complianceMonitoring}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {scope.indexOf("read:letters") > -1 && (
                  <ListItem
                    button
                    component={NavLink}
                    onClick={closeSidebar}
                    activeClassName="active"
                    to="/dashboard/letters"
                  >
                    <ListItemText inset>Letters</ListItemText>
                  </ListItem>
                )}
              </List>
            </Collapse>
          </section>
        )}
        {(scope.indexOf("read:revenue_estimates") > -1 ||
          scope.indexOf("read:invoices") > -1) && (
          <section>
            <ListItem
              button
              onClick={() =>
                setExpanded({
                  rentalActivityMonitoring: !expanded.rentalActivityMonitoring,
                })
              }
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faScaleBalanced} />
              </ListItemIcon>
              <ListItemText primary="Rental Activity Monitoring" />
            </ListItem>
            <Collapse
              in={expanded.rentalActivityMonitoring}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {scope.indexOf("read:revenue_estimates") > -1 && (
                  <ListItem
                    button
                    component={NavLink}
                    onClick={closeSidebar}
                    activeClassName="active"
                    to="/dashboard/audit-discovery"
                  >
                    <ListItemText inset>Audit Discovery</ListItemText>
                  </ListItem>
                )}
                <ListItem
                  button
                  component={NavLink}
                  onClick={closeSidebar}
                  activeClassName="active"
                  to="/dashboard/audit-management"
                >
                  <ListItemText inset>Audit Management</ListItemText>
                </ListItem>
                {scope.indexOf("read:invoices") > -1 && (
                  <ListItem
                    button
                    component={NavLink}
                    onClick={closeSidebar}
                    activeClassName="active"
                    to="/dashboard/invoices"
                  >
                    <ListItemText inset>Invoices</ListItemText>
                  </ListItem>
                )}
              </List>
            </Collapse>
          </section>
        )}
        {scope.indexOf("read:complaints") > -1 && (
          <section>
            <ListItem
              button
              onClick={() =>
                setExpanded({
                  ShortTermRentalHotline: !expanded.ShortTermRentalHotline,
                })
              }
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faSignal} />
              </ListItemIcon>
              <ListItemText primary="24/7 STR Hotline" />
            </ListItem>
            <Collapse
              in={expanded.ShortTermRentalHotline}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {scope.indexOf("read:reports:complaints") > -1 && (
                  <ListItem
                    button
                    component={NavLink}
                    onClick={closeSidebar}
                    activeClassName="active"
                    to="/dashboard/reports/complaints"
                  >
                    <ListItemText inset>Complaints Dashboard</ListItemText>
                  </ListItem>
                )}
                {scope.indexOf("read:complaints") > -1 && (
                  <ListItem
                    button
                    component={NavLink}
                    onClick={closeSidebar}
                    activeClassName="active"
                    to="/dashboard/str-hotline"
                  >
                    <ListItemText inset>Short-term Rental Hotline</ListItemText>
                  </ListItem>
                )}
              </List>
            </Collapse>
          </section>
        )}
        {scope.indexOf("read:reports:payments") > -1 && (
          <section>
            <ListItem
              button
              onClick={() =>
                setExpanded({
                  taxCollection: !expanded.taxCollection,
                })
              }
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faMoneyBillAlt} />
              </ListItemIcon>
              <ListItemText primary="Tax Collection" />
            </ListItem>
            <Collapse in={expanded.taxCollection} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {scope.indexOf("read:reports:payments") > -1 && (
                  <ListItem
                    button
                    component={NavLink}
                    onClick={closeSidebar}
                    activeClassName="active"
                    to="/dashboard/reported-revenue"
                  >
                    <ListItemText inset>Reported Revenue</ListItemText>
                  </ListItem>
                )}
              </List>
            </Collapse>
          </section>
        )}
        {scope.indexOf("read:payments") > -1 && (
          <section>
            <ListItem
              button
              onClick={() =>
                setExpanded({
                  revenue: !expanded.revenue,
                })
              }
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faDollarSign} />
              </ListItemIcon>
              <ListItemText primary="Revenue" />
            </ListItem>
            <Collapse in={expanded.revenue} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {scope.indexOf("read:payments") > -1 && (
                  <ListItem
                    button
                    component={NavLink}
                    onClick={closeSidebar}
                    activeClassName="active"
                    to="/dashboard/payments"
                  >
                    <ListItemText inset>Payments</ListItemText>
                  </ListItem>
                )}
              </List>
            </Collapse>
          </section>
        )}
        {scope.indexOf("read:emails") > -1 && (
          <section>
            <ListItem
              button
              component={NavLink}
              onClick={closeSidebar}
              activeClassName="active"
              to="/dashboard/emails"
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faAt} />
              </ListItemIcon>
              <ListItemText primary="Emails" />
            </ListItem>
          </section>
        )}
        <section>
          <ListItem
            button
            onClick={() => setExpanded({ support: !expanded.support })}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faCircleQuestion} />
            </ListItemIcon>
            <ListItemText primary="Support" />
          </ListItem>
          <Collapse in={expanded.support} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                component={NavLink}
                onClick={closeSidebar}
                activeClassName="active"
                target="_blank"
                to={{
                  pathname: "https://support.granicus.com/s/contactsupport",
                }}
              >
                <ListItemText inset>Contact Support</ListItemText>
              </ListItem>
              <ListItem
                button
                component={NavLink}
                onClick={closeSidebar}
                activeClassName="active"
                target="_blank"
                to={{
                  pathname:
                    "https://support.granicus.com/s/article-group/a8C4A000000XbjJUAS/host-compliance",
                }}
              >
                <ListItemText inset>Knowledge Base</ListItemText>
              </ListItem>
              <ListItem
                button
                component={NavLink}
                onClick={closeSidebar}
                activeClassName="active"
                target="_blank"
                to={{
                  pathname:
                    "https://support.granicus.com/s/article/Granicus-Universityhelpgranicuscom-1680-DP",
                }}
              >
                <ListItemText inset>Granicus University</ListItemText>
              </ListItem>
            </List>
          </Collapse>
        </section>
        {jurisdictionData && jurisdictionData.jurisdiction && (
          <section className="jurisdiction-section">
            <ListItem
              button
              disabled // to be removed after we get submenus under this
              onClick={() =>
                setExpanded({ jurisdiction: !expanded.jurisdiction })
              }
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faCity} />
              </ListItemIcon>
              <ListItemText primary={jurisdictionData.jurisdiction.name} />
            </ListItem>
          </section>
        )}
        <section>
          <ListItem
            button
            onClick={() => setExpanded({ user: !expanded.user })}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faCog} />
            </ListItemIcon>
            <ListItemText primary={user.name} />
          </ListItem>
          <Collapse in={expanded.user} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {scope.indexOf("switch:jurisdictions") > -1 && (
                <ListItem
                  button
                  component={NavLink}
                  onClick={closeSidebar}
                  activeClassName="active"
                  to="/dashboard/jurisdictions"
                >
                  <ListItemText inset>
                    Change Jurisdictions <small>Admin</small>
                  </ListItemText>
                </ListItem>
              )}
              {scope.indexOf("read:features") > -1 &&
                process.env.REACT_APP_ENVIRONMENT !== "production" && (
                  <ListItem
                    button
                    component={NavLink}
                    onClick={closeSidebar}
                    activeClassName="active"
                    to="/dashboard/features"
                  >
                    <ListItemText inset>
                      Beta Features <small>Admin</small>
                    </ListItemText>
                  </ListItem>
                )}
              {scope.indexOf("update:extensions") > -1 && (
                <ListItem
                  button
                  component={NavLink}
                  onClick={closeSidebar}
                  activeClassName="active"
                  to="/integrations"
                >
                  <ListItemText inset>
                    Integrations <small>Admin</small>
                  </ListItemText>
                </ListItem>
              )}

              {jurisdictionData?.jurisdiction?.revert_to_legacy && (
                <ListItem button onClick={revert}>
                  <ListItemText inset>Revert to Old Dashboard</ListItemText>
                </ListItem>
              )}

              <ListItem button onClick={changePassword}>
                <ListItemText inset>Change Password</ListItemText>
              </ListItem>
              <ListItem button color="primary" onClick={signout}>
                <ListItemText inset>Sign Out</ListItemText>
              </ListItem>
            </List>
          </Collapse>
        </section>
      </List>
      <ChangePasswordModal
        open={openChangePasswordModal}
        closeModal={closeChangePasswordModal}
        signout={signout}
      />
    </div>
  );
};

export default SideMenuComponent;
