import React from "react";
import { GridFilterPanel } from "@mui/x-data-grid-pro";
import { Button } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

export interface DataGridCustomFilterPanelProps {
  applyFilter: () => void;
}

const GridToolBarFilter = (props: DataGridCustomFilterPanelProps) => {
  return (
    <>
      <GridFilterPanel />
      <div className="filterPanel">
        <Button
          onClick={props.applyFilter}
          startIcon={<DoneIcon className="applyFilter" fontSize="small" />}
        >
          Apply
        </Button>
      </div>
    </>
  );
};

export default GridToolBarFilter;
