import {
  Autocomplete,
  Box,
  DialogContent,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { loader } from "graphql.macro";
import { useLazyQuery } from "@apollo/client";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { RentalUnitContext } from "../../../../RentalUnitContext";
import SearchAddress, { Coordinates } from "./SearchAddress";
import { FormDataInterface } from "../Steps";

interface HasKnownUnitNumber {
  hasUnitNumber: boolean;
  isUnitNumberKnown: boolean;
}

interface EditAddressProps {
  setEditAddressValues: (values: FormDataInterface) => void;
  formValues: FormDataInterface;
  initialForm: FormDataInterface;
}

interface AddressToParcel {
  provided_address: string;
  parcel_number: string;
}

const query = loader("./address_to_parcel.graphql");

export const EditAddress = ({
  setEditAddressValues,
  formValues,
  initialForm,
}: EditAddressProps) => {
  const { listingTables } = useContext(RentalUnitContext);
  const [formData, setFormData] = useState<FormDataInterface>(formValues);
  const [parcelFromAddress, setParcelFromAddress] = useState<AddressToParcel[]>(
    []
  );
  const [otherParcelNumber, setOtherPracelNumber] = useState<string>(
    formData.parcel_number
  );
  const [refetched, setRefetched] = useState<boolean>(false);

  const [isUnitNumberKnown, setIsUnitNumberKnown] =
    useState<HasKnownUnitNumber>({
      hasUnitNumber: !!formData.unit_number,
      isUnitNumberKnown: !!formData.unit_number,
    });

  const [parcelNumber, setParcelNumber] = useState<string>(
    formData.parcel_number ? formData.parcel_number : "Other"
  );

  const [getParcel, { data, loading }] = useLazyQuery(query, {
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.id === "parcel_number") {
      setOtherPracelNumber(event.target.value);
    }
    setFormData({ ...formData, [event.target.id]: event.target.value });
  };

  const handleUnitNumberRadioChange = (value: string) => {
    switch (value) {
      case "hasKnown":
        setIsUnitNumberKnown({
          hasUnitNumber: true,
          isUnitNumberKnown: true,
        });
        break;
      case "hasUnknown":
        setIsUnitNumberKnown({
          hasUnitNumber: true,
          isUnitNumberKnown: false,
        });
        break;
      case "no":
        setIsUnitNumberKnown({
          hasUnitNumber: false,
          isUnitNumberKnown: false,
        });
        break;
    }
  };

  // Functions and Effects for handling Parcel Number options

  // Handler for parcel number options in radio buttons group
  const handleParcelNumberOptionsChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    //setting this specifically to cover the case : if user changes option to "Other" after selecting "Unknown"
    if (event.target.value === "Other") {
      setFormData({ ...formData, parcel_number: otherParcelNumber });
    }
    setParcelNumber(event.target.value);
  };

  // Effect for changing parcel_number value in formData if Other is not picked

  useEffect(() => {
    if (parcelNumber !== "Other")
      setFormData({ ...formData, parcel_number: parcelNumber });
  }, [parcelNumber]);

  // Parser for picking proper value if coming back to this step

  const parseInitialParcelNumber = () => {
    if (
      formData.parcel_number !== initialForm.parcel_number &&
      formData.parcel_number !== "Unknown"
    ) {
      setParcelNumber("Other");
      return "Other";
    }
    return formData.parcel_number;
  };

  const handlePlaceChange = (value: string, coordinates?: Coordinates) => {
    setParcelFromAddress([]);
    setRefetched(false);
    if (coordinates) {
      setFormData({
        ...formData,
        formatted: value,
        latitude: coordinates.lat,
        longitude: coordinates.lng,
      });
    } else {
      setFormData({ ...formData, formatted: value });
    }
  };

  useEffect(() => {
    if (parcelFromAddress.length === 0 && !refetched) {
      getParcel({
        variables: {
          address: formData.formatted,
        },
      });
      setRefetched(true);
    }
    if (data) setParcelFromAddress(data.address_to_parcel);
  }, [loading, parcelFromAddress]);

  useEffect(() => {
    setEditAddressValues(formData);
  }, [formData]);

  return initialForm ? (
    <Grid>
      <DialogContent>
        <Box component="form">
          <FormGroup>
            <FormLabel
              className={"MainLabel"}
              hidden={listingTables && listingTables?.hits.length < 2}
            >
              There are {listingTables?.hits.length} listings for that Rental
              Unit. Select listings to update
            </FormLabel>
            <Autocomplete
              multiple
              id="selected_listings"
              options={
                listingTables
                  ? listingTables.hits.map((item) => {
                      return {
                        id: item.internal_listing_id,
                        title: item.listing_title,
                      };
                    })
                  : []
              }
              getOptionLabel={(item) => item.title}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              defaultValue={formData.internal_listing_ids.map((item) => {
                return {
                  id: item,
                  title:
                    listingTables?.hits.find(
                      (elem) => elem.internal_listing_id === item
                    )?.listing_title || "",
                };
              })}
              onChange={(event, value) =>
                setFormData({
                  ...formData,
                  internal_listing_ids: value.map((item) => item.id),
                })
              }
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Listings" />
              )}
              hidden={listingTables ? listingTables?.hits.length < 2 : false}
            />
            <FormLabel className={"MainLabel"}>Property Address</FormLabel>
            <SearchAddress
              initialValue={formData.formatted}
              setPlace={handlePlaceChange}
            />
            <FormLabel className="MainLabel">
              Does this property have a unit number?
            </FormLabel>
            <RadioGroup
              defaultValue={formValues.unit_number ? "hasKnown" : "no"}
              name="radio-buttons-group"
              row
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleUnitNumberRadioChange(event.target.value)
              }
            >
              <FormControlLabel
                value="hasKnown"
                control={<Radio />}
                label="Yes, and I know it"
              />
              <FormControlLabel
                value="hasUnknown"
                control={<Radio />}
                label="Yes, but I don't know it"
              />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
            {isUnitNumberKnown.hasUnitNumber &&
              isUnitNumberKnown.isUnitNumberKnown && (
                <>
                  <FormLabel className="MainLabel">Unit number</FormLabel>
                  <TextField
                    id="unit_number"
                    value={formData.unit_number}
                    onChange={handleChange}
                  />
                </>
              )}

            <FormLabel className="MainLabel">Parcel number</FormLabel>
            <RadioGroup
              defaultValue={() => parseInitialParcelNumber()}
              name="parcelNumberOption"
              row
              id="parcel_number_picker"
              onChange={handleParcelNumberOptionsChange}
            >
              <FormControlLabel
                value={initialForm.parcel_number}
                control={<Radio />}
                label={"Based on our data: " + initialForm.parcel_number}
              />
              {parcelFromAddress.length > 0 &&
              parcelFromAddress[0] &&
              parcelFromAddress[0].parcel_number &&
              parcelFromAddress[0].parcel_number !=
                initialForm.parcel_number ? (
                <FormControlLabel
                  value={
                    parcelFromAddress && parcelFromAddress[0]
                      ? parcelFromAddress[0].parcel_number
                      : ""
                  }
                  control={<Radio />}
                  label={
                    "Based on provided address: " +
                    (parcelFromAddress && parcelFromAddress[0]
                      ? parcelFromAddress[0].parcel_number
                      : "Not determined")
                  }
                />
              ) : null}

              <FormControlLabel
                control={<Radio />}
                label="Other"
                value={"Other"}
              />
              <FormControlLabel
                value={"Unknown"}
                control={<Radio />}
                label="Unknown"
              />
            </RadioGroup>
            {parcelNumber === "Other" && formData.parcel_number !== "Unknown" && (
              <>
                <FormLabel className="MainLabel">
                  Please provide us with proper parcel number
                </FormLabel>
                <TextField
                  autoFocus
                  id="parcel_number"
                  label="Please provide a parcel number"
                  value={formData.parcel_number}
                  onChange={handleChange}
                  required
                />
              </>
            )}
          </FormGroup>
        </Box>
      </DialogContent>
    </Grid>
  ) : null;
};
