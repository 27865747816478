import React from "react";

import { gql } from "@apollo/client";
import { GridColDef } from "@mui/x-data-grid-pro";

import SearchView from "../../components/SearchView/SearchView";
import { getTableMeta } from "../common/data-grid-cols";

const ListingsPage = () => {
  const { columns, filters, comment_id_column } = getTableMeta("listings");

  const listingsQuery = (
    columnsToFilter: GridColDef[],
    commentsIdColumn?: string
  ) => {
    const queryFields = columnsToFilter
      .filter((column) => !column.hide)
      .map((column) => {
        if (column.field === "airbnb_host_id") {
          // Always include airbnb_host_url in the query when airbnb_host_id is requested
          return `${column.field} airbnb_host_url`;
        } else if (column.field === "add_view_comments_modal") {
          return `add_view_comments_modal {
            unread
            count
            total
            foreign_id
            foreign_type
          }`;
        } else {
          return column.field;
        }
      })
      .join("\n");

    return gql`
      query($q: KibanaQL="", $phrase: String, $size: Int, $from: Int, $sort: String) {
        results: listing_tables(
          search: { query: $q, phrase: $phrase, size: $size, from: $from, sort: $sort }
        ) {
          size
          total
          hits {
            id: internal_listing_id
            ${commentsIdColumn}
            ${queryFields}
          }
        }
      }
    `;
  };

  return (
    <SearchView
      searchQuery={listingsQuery}
      columns={columns}
      filters={filters}
      commentIdColumn={comment_id_column}
      title="Listings"
    />
  );
};

export default ListingsPage;
