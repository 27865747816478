import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";

interface CountryInputProps {
  onCountryCodeChange: (countryCode: string) => void;
  value: string | null;
  required?: boolean;
  label: string;
  placeholder?: string;
  error: boolean;
  helperText: string;
}

const useStyles = makeStyles({
  input: {
    textTransform: "uppercase",
  },
  label: {
    textTransform: "none",
  },
  helperText: {
    textTransform: "none",
  },
});

const CountryInput: React.FC<CountryInputProps> = ({
  onCountryCodeChange,
  value,
  required,
  label,
  placeholder,
  error,
  helperText,
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState<string | null>(value);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const countryCode = event.target.value
      .toUpperCase()
      .slice(0, 2)
      .replace(/[^A-Z]/g, "");
    setInputValue(countryCode);
    onCountryCodeChange(countryCode);
  };

  return (
    <TextField
      className={classes.input}
      label={label}
      variant="outlined"
      value={inputValue}
      onChange={handleInputChange}
      InputProps={{
        className: classes.input,
        inputProps: { maxLength: 2 },
      }}
      InputLabelProps={{
        className: classes.label,
      }}
      placeholder={placeholder ? placeholder : label}
      required={required}
      error={error}
      helperText={helperText}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      fullWidth
    />
  );
};

export default CountryInput;
