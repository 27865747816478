import React from "react";
import { useGridRootProps } from "@mui/x-data-grid-pro";
import { unstable_useId as useId, useForkRef } from "@mui/material/utils";
import { ButtonProps } from "@mui/material";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//@ts-ignore
interface GridToolbarExportProps extends ButtonProps {
  onClick: () => void;
}
export const GridToolbarExport = React.forwardRef<
  HTMLButtonElement,
  GridToolbarExportProps
>(function GridToolbarExport(props, ref) {
  const { onClick } = props;
  const rootProps = useGridRootProps();
  const exportButtonId = useId();
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const handleRef = useForkRef(ref, buttonRef);

  return (
    <rootProps.components.BaseButton
      id={exportButtonId}
      ref={handleRef}
      startIcon={
        <FontAwesomeIcon icon={faDownload} />
      }
      onClick={onClick}
    >
      <span style={{fontSize: "0.8125rem"}}>Export</span>
    </rootProps.components.BaseButton>
  );
});
