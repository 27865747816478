
import React from 'react';

function View() {
  return (
    <span />
  );
}

export default View;
