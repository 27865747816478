import React from "react";

import { faHome, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { GridCellParams, GridRowParams } from "@mui/x-data-grid-pro";
import parse from "html-react-parser";
import {
  formatDate,
  formatDateTime,
  extractContent,
  capitalizeFirstLetters,
  extractInnerHTML,
} from "./utils";
import { Box, IconButton, Typography } from "@mui/material";
import CallRecordingModal from "./Modals/CallRecordingModal";
import { EditableFieldOption, TableEditableField } from "./data-grid-cols";
import EditableDropDownRenderer from "./renderer/EditableDropDownRenderer";
import UploadcareCDNRenderer from "../str-hotline/render-components/UploadcareCDNRenderer";
import LetterCriteriaGridCellRenderer from "../letters/render-components/LetterCriteriaGridCellRenderer";
import { PendingRegistrationModalCell } from "../registrations/PendingRegistrationModalCell/PendingRegistrationModalCell";
import { PendingRegistrationModal } from "../registrations/PendingRegistrationModalCell/PendingRegistrationModal";
import { BOOLEAN_OPTIONS, VIOLATION_OPTIONS } from "./constants";

export const renderUrlIcon = (
  iconLabel: string,
  iconType: IconDefinition,
  urlBase?: string,
  openNewPage?: Boolean
) => {
  // eslint-disable-next-line react/display-name
  return (param: GridCellParams) => {
    return (
      <IconButton
        aria-label={iconLabel}
        size="small"
        href={urlBase ? urlBase + param.id : ""}
        target={openNewPage ? "_blank" : ""}
      >
        <FontAwesomeIcon icon={iconType} />
      </IconButton>
    );
  };
};

export const renderRentalUnitCell = () => {
  // eslint-disable-next-line react/display-name
  return (param: GridCellParams) => {
    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        <IconButton
          aria-label={"rental_unit"}
          size="small"
          href={"/dashboard/rental-units/" + param.value}
          target={"_blank"}
        >
          <FontAwesomeIcon icon={faHome} />
        </IconButton>
      </div>
    );
  };
};

export const renderRentalUnitIdsCell = () => {
  // eslint-disable-next-line react/display-name
  return (param: GridCellParams) => {
    if (param.value) {
      return param.value.map((item: string, index: number) =>
        item ? (
          <div key={index} style={{ width: "100%", textAlign: "center" }}>
            <IconButton
              aria-label={"rental_unit"}
              size="small"
              href={"/dashboard/rental-units/" + item}
              target={"_blank"}
            >
              <FontAwesomeIcon icon={faHome} />
            </IconButton>
          </div>
        ) : null
      );
    } else {
      return null;
    }
  };
};

export const renderLetterId = (dataToShow: any) => {
  if (dataToShow.length !== 0) {
    // eslint-disable-next-line react/display-name
    return (param: GridCellParams) => {
      if (
        dataToShow.some(
          (item: { id: string; lob_pdf_url: string }) => item.id === param.value
        )
      ) {
        const url = dataToShow.find(
          (item: { id: string; lob_pdf_url: string }) => item.id === param.value
        ).lob_pdf_url;
        return (
          <Typography>
            <IconButton
              aria-label={"letter_id"}
              size={"small"}
              href={url}
              style={url ? { cursor: "pointer" } : { cursor: "default" }}
              target={"_blank"}
              rel={"noreferrer"}
              disabled={!url}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </IconButton>
            <a
              href={url}
              target={"_blank"}
              rel={"noreferrer"}
              style={{
                display: "inline",
                fontSize: "0.875rem",
                color: url ? "#003258" : "rgba(0, 0, 0, 0.87)",
                fontWeight: url ? 500 : 400,
              }}
            >
              {param.value}
            </a>
          </Typography>
        );
      } else return null;
    };
  } else return null;
};

export const renderHtmlfields = (fieldType: string) => {
  // eslint-disable-next-line react/display-name
  return (param: GridCellParams) => {
    if (param.value) {
      return (
        <div className="HTMLCell">
          {fieldType === "html" && parse(param.value.toString())}
          {fieldType === "url" && (
            <a href={param.value.toString()} target="_blank" rel="noreferrer">
              {param.value.toString()}
            </a>
          )}
        </div>
      );
    } else {
      return <span></span>;
    }
  };
};

export const renderAirbnbHostIDForRentalUnitsGrid = () => {
  // eslint-disable-next-line react/display-name
  return (param: GridCellParams) => {
    if (param.value) {
      return <div>{parse(param.value.toString())}</div>;
    } else {
      return <span></span>;
    }
  };
};

export const renderBooleanValues = () => {
  return (param: GridCellParams) => {
    if (param.value === true) {
      return "Yes";
    } else if (param.value === false) {
      return "No";
    } else {
      return "";
    }
  };
};

export const renderDateOrTimeValues = (fieldType: string) => {
  return (param: GridCellParams) => {
    if (param.value) {
      if (fieldType === "datetime") {
        return formatDateTime(param.value);
      } else {
        return formatDate(param.value);
      }
    }
  };
};

export const renderLastReportedPeriod = () => {
  return (param: GridCellParams) => {
    if (param.value) {
      let value = param.value;
      let dates = param.value.split(" to ");
      if (dates.length === 2) {
        return `${formatDate(dates[0])} to ${formatDate(dates[1])}`;
      }
      return value;
    }
  };
};

export const renderPropertyPages = () => {
  // eslint-disable-next-line react/display-name
  return (param: GridCellParams) => {
    if (param.value) {
      let rentalUnitIDs = param.value.split("<br/>").map((val: string) => {
        return extractContent(val);
      });
      return (
        <div className="property_pages_cell">
          {rentalUnitIDs.map((rurID: string, index: number) => {
            return (
              <div key={index}>
                <a
                  href={"/dashboard/rental-units/" + rurID}
                  target="_blank"
                  rel="noreferrer"
                >
                  {rurID}
                </a>
                <br />
              </div>
            );
          })}
        </div>
      );
    } else {
      return "";
    }
  };
};

export const renderPropertyPage = (
  gridType: string,
  listing: boolean = false
) => {
  if (gridType == "Listings") {
    // eslint-disable-next-line react/display-name
    return (param: GridRowParams) => {
      if (param.row && param.row.id && param.row.external_property_id) {
        try {
          return (
            <a
              href={
                "/dashboard/rental-units/" +
                param.row.external_property_id +
                (listing ? "/" + param.row.id : "")
              }
              target="_blank"
              rel="noreferrer"
            >
              {listing ? param.row.id : param.row.external_property_id}
            </a>
          );
        } catch (error) {
          console.error("Failed to build the column");
          return "";
        }
      }
      // else {
      return "";
      // }
    };
  } else {
    // eslint-disable-next-line react/display-name
    return (param: GridCellParams) => {
      if (param.value) {
        try {
          var rental_unit_id = param.value.split("/").pop(-1).split("?")[0];
          return (
            <a
              href={"/dashboard/rental-units/" + rental_unit_id}
              target="_blank"
              rel="noreferrer"
            >
              {rental_unit_id}
            </a>
          );
        } catch (error) {
          console.error("Failed to build Rental Unit Record Column");
          return "";
        }
      } else {
        return "";
      }
    };
  }
};

export const renderEstimatedTaxBasis = () => {
  return (param: GridCellParams) => {
    if (param.value) {
      const str = param.value.replace(/_/g, " ");
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return "";
    }
  };
};

export const renderTrackingNumber = () => {
  // eslint-disable-next-line react/display-name
  return (param: GridCellParams) => {
    const trackingNumber = param.value;
    if (trackingNumber) {
      const url = `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`;
      return (
        <a href={url} target="_blank" rel="noreferrer">
          {trackingNumber}
        </a>
      );
    } else {
      return "";
    }
  };
};

export const renderEditableStatusField = (
  statusField: TableEditableField,
  columnHeader: string,
  callbackFunction: any
) => {
  // eslint-disable-next-line react/display-name
  return (params: GridCellParams) => {
    const getOptionsFromValue = (
      selectedOption: EditableFieldOption | undefined,
      statusField: TableEditableField
    ) => {
      let transitions = selectedOption ? selectedOption.transitions : [];
      return statusField.options.filter((option) =>
        transitions?.includes(option.id)
      );
    };
    const handleStatusSubmit = async (value: EditableFieldOption) => {
      if (params.row && params.row.id) {
        let result = await callbackFunction(
          params.row.id,
          statusField.field,
          value
        );
        if (result.type === "success") {
          result.options = getOptionsFromValue(value, statusField);
        }
        return result;
      }
    };
    let selectedOption;
    let isEditable;
    if (params.value) {
      selectedOption = statusField.options.find(
        (option) => option.id === params.value
      );
    } else {
      selectedOption = statusField.options.find(
        (option) => option.id === "new" || option.id === ""
      );
    }
    isEditable =
      statusField.field === "case_status" && params.value === "not_actionable"
        ? false
        : true;
    const options = getOptionsFromValue(selectedOption, statusField);
    return (
      <EditableDropDownRenderer
        cellText={selectedOption?.label || ""}
        options={options}
        value={selectedOption || null}
        handleSubmitCallBack={handleStatusSubmit}
        id={params.row.id}
        fieldName={columnHeader}
        isEditable={isEditable}
      />
    );
  };
};

export const renderEditablePermitNumberField = (
  unitPermitField: TableEditableField,
  columnHeader: string,
  callbackFunction: any
) => {
  // eslint-disable-next-line react/display-name
  return (params: GridCellParams) => {
    let selectedOption;
    if (params.value) {
      selectedOption = unitPermitField.options.find(
        (option) => option.id === params.value
      );
    }

    const handleStatusSubmit = async (value: EditableFieldOption) => {
      if (params.row && params.row.id) {
        let result = await callbackFunction(
          params.row.id,
          unitPermitField.field,
          value
        );
        return result;
      }
    };

    return (
      <EditableDropDownRenderer
        cellText={selectedOption?.label || ""}
        options={unitPermitField.options}
        value={selectedOption || null}
        handleSubmitCallBack={handleStatusSubmit}
        id={params.row.id}
        fieldName={columnHeader}
        isEditable={true}
      />
    );
  };
};

export const renderRevenueBreakdown = () => {
  // eslint-disable-next-line react/display-name
  return (param: GridCellParams) => {
    const externalPropertyID = param.id;
    if (externalPropertyID) {
      return (
        <a
          href={
            "/dashboard/estimated-revenue-by-quarter-by-rental-unit/" +
            externalPropertyID
          }
          target="_blank"
          rel="noreferrer"
        >
          {param.value}
        </a>
      );
    } else {
      return "";
    }
  };
};

export const renderComplaintType = () => {
  // eslint-disable-next-line react/display-name
  return (params: GridCellParams) => {
    const value = params.value;
    if (typeof value === "string") {
      return capitalizeFirstLetters(value);
    }
    return value;
  };
};

export const renderUploadcareCDN = () => {
  // eslint-disable-next-line react/display-name
  return (params: GridCellParams) => {
    if (params.row && params.row.uploadcare_cdn_url) {
      const urlLabel = params.value;
      const uploadcareCDNUrlValue = params.row.uploadcare_cdn_url;
      const urls = parseUploadcareCDNValue(uploadcareCDNUrlValue);

      if (urls.length === 0) {
        return null;
      } else if (urls.length === 1) {
        return (
          <a rel="noreferrer" href={urls[0]} target="_blank">
            {urlLabel}
          </a>
        );
      } else {
        return <UploadcareCDNRenderer urlLabel={urlLabel} urls={urls} />;
      }
    }
    return null;
  };
};

export const parseUploadcareCDNValue = (uploadcareCDNUrlValue: string) => {
  let urls: string[] = [];
  try {
    if (uploadcareCDNUrlValue.startsWith("[")) {
      urls = JSON.parse(uploadcareCDNUrlValue);
    } else {
      urls = [uploadcareCDNUrlValue];
    }
  } catch (error) {
    return urls;
  }
  return urls;
};

export const renderCallRecording = () => {
  // eslint-disable-next-line react/display-name
  return (params: GridCellParams) => {
    if (params.row && params.row.call_recording_url) {
      return <CallRecordingModal url={params.row.call_recording_url} />;
    } else {
      return null;
    }
  };
};

export const renderLetterCriteriaGridCell = (param: any) => {
  const templateCriteria = param.value;
  let firstCriteria = templateCriteria?.find(
    (criteria: any) => !criteria.criteria_met
  ); //show first unmet criteria to the user before hover
  if (!firstCriteria) {
    // If no criteria are unmet, we display the first criteria in the list
    firstCriteria = templateCriteria?.[0];
  }

  return (
    <LetterCriteriaGridCellRenderer
      templateCriteria={templateCriteria}
      firstCriteria={firstCriteria}
    />
  );
};

export const renderAirbnbHostID = () => {
  // eslint-disable-next-line react/display-name
  return (params: GridCellParams) => {
    if (params.row && params.row.airbnb_host_url) {
      const urlLabel = params.value;
      const airbnbHostURLValue = params.row.airbnb_host_url;
      return (
        <a rel="noreferrer" href={airbnbHostURLValue} target="_blank">
          {urlLabel}
        </a>
      );
    }
    return null;
  };
};

export const renderDeptLastAction = (
  editableFieldOptions: TableEditableField[] | undefined
) => {
  // eslint-disable-next-line react/display-name
  return (params: GridCellParams) => {
    if (editableFieldOptions) {
      const statusOptions = editableFieldOptions.find(
        (field) => field.field === "case_status"
      );
      if (statusOptions) {
        const option = statusOptions.options.find(
          (option) => option.id === params.value
        );
        if (option) {
          return option.label;
        }
        return null;
      }
      return null;
    }
    return null;
  };
};

export const renderOwnerName = (param: any) => {
  const ownerName = param.value;
  if (ownerName) {
    return ownerName;
  } else {
    return <span style={{ color: "#8b0000" }}>Owner Name Unavailable</span>;
  }
};

export const renderOwnerAddress = (param: any) => {
  const ownerAddress = param.value;
  if (ownerAddress) {
    return ownerAddress;
  } else {
    return <span style={{ color: "#8b0000" }}>Owner Address Unavailable</span>;
  }
};

export const renderRentalUnitRecord = (param: any) => {
  return (
    <a
      href={`/dashboard/rental-units/${param.value}`}
      target="_blank"
      rel="noreferrer"
    >
      {param.value}
    </a>
  );
};

export const renderParcelNumber = (param: any) => {
  const parcelNumber = param.value;
  if (parcelNumber) {
    return parcelNumber;
  } else {
    return <span style={{ color: "#8b0000" }}>Parcel Number Unavailable</span>;
  }
};

export const renderEditHistoryLink = () => {
  // eslint-disable-next-line react/display-name
  return (params: GridCellParams) => {
    if (params.id) {
      const queryParam = encodeURIComponent(params.id);
      const url = `/dashboard/registration_history?permit_registration_id=${queryParam}`;
      return (
        <a href={url} target="_blank" rel="noreferrer">
          Edit History
        </a>
      );
    }
    return null;
  };
};

export const renderEditHistoryPageLink = () => {
  // eslint-disable-next-line react/display-name
  return (params: GridCellParams) => {
    if (params.id) {
      const queryParam = encodeURIComponent(params.id);
      const url = `/dashboard/registration_history?permit_registration_id=${queryParam}`;
      return (
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      );
    }
    return null;
  };
};

export const renderRegistrationPendingStatus = (
  setSnackbarData: any,
  reloadGrid: any
) => {
  // eslint-disable-next-line react/display-name
  return (params: GridCellParams) => {
    return (
      <PendingRegistrationModalCell
        title="Manage pending registration"
        cellText={params.value}
        modalWidth="30%"
      >
        <PendingRegistrationModal
          row={params.row}
          setSnackbarData={setSnackbarData}
          reloadGrid={reloadGrid}
        />
      </PendingRegistrationModalCell>
    );
  };
};

export const renderUploadedDocuments = () => {
  // eslint-disable-next-line react/display-name
  return (param: GridCellParams) => {
    if (param.value) {
      let documentationLinks = param.value.split("<br/>").map((val: string) => {
        return extractInnerHTML(val);
      });

      return (
        <div>
          {documentationLinks.map((docLink: string, index: number) => {
            return (
              <div key={index} dangerouslySetInnerHTML={{ __html: docLink }} />
            );
          })}
        </div>
      );
    } else {
      return "";
    }
  };
};

export const renderRegistrationUpdateLink = () => {
  // eslint-disable-next-line react/display-name
  return (params: GridCellParams) => {
    if (params.id) {
      const queryParam = encodeURIComponent(params.id);
      const url = `/dashboard/questionnaires/registrations-admin-update/permit_id?value=${queryParam}`;
      return (
        <a href={url} target="_blank" rel="noreferrer">
          Update
        </a>
      );
    }
    return null;
  };
};

export const renderAuditManagementStatus = () => {
  // eslint-disable-next-line react/display-name
  return (params: GridCellParams) => {
    if (params.id) {
      const queryParam = encodeURIComponent(params.id);
      const url = `/dashboard/questionnaires/audits-admin-review/summary?audit_id=${queryParam}`;
      return (
        <Box>
          <Typography style={{ fontSize: "0.875rem" }}>
            {params.value?.toLowerCase()}
          </Typography>
          {params.value?.toLowerCase() === "submitted" && (
            <a href={url} target="_blank" rel="noreferrer">
              Start Review
            </a>
          )}
        </Box>
      );
    }
    return null;
  };
};

const renderEditableDropdown = (
  options: EditableFieldOption[],
  columnHeader: string,
  callbackFunction: any
) => {
  // eslint-disable-next-line react/display-name
  return (params: GridCellParams) => {
    const selectedOption = options.find((option) => option.id === params.value);

    if (!selectedOption) {
      return null;
    }

    const handleStatusSubmit = async (value: EditableFieldOption) => {
      if (params.row && params.row.id) {
        const result = await callbackFunction(
          params.row.id,
          params.field,
          value
        );
        if (result.type === "success") {
          result.options = options;
        }
        return result;
      }
    };

    return (
      <EditableDropDownRenderer
        cellText={selectedOption?.label || "N/A"}
        options={options}
        value={selectedOption || null}
        handleSubmitCallBack={handleStatusSubmit}
        id={params.row.id}
        fieldName={columnHeader}
        isEditable={true}
      />
    );
  };
};

export const renderViolationType = (
  columnHeader: string,
  callbackFunction: any
) => {
  return renderEditableDropdown(
    VIOLATION_OPTIONS,
    columnHeader,
    callbackFunction
  );
};

export const renderCivilViolationNoticeIssued = (
  columnHeader: string,
  callbackFunction: any
) => {
  return renderEditableDropdown(
    BOOLEAN_OPTIONS,
    columnHeader,
    callbackFunction
  );
};

export const renderCourtesyWarningNoticeIssued = (
  columnHeader: string,
  callbackFunction: any
) => {
  return renderEditableDropdown(
    BOOLEAN_OPTIONS,
    columnHeader,
    callbackFunction
  );
};
