import { formatDateTime } from "../../common/utils";

interface BaseOption {
  label: string;
  value: string;
}

interface Listing {
  id: string;
  title: string;
  url: string;
}

interface Screenshot {
  created_at: string;
  url: string;
}

interface ScreenshotOption extends BaseOption {}

interface ListingOption extends BaseOption {}

interface ListingWithScreenshot {
  rur_id: string;
  screenshot: Screenshot | null;
  listing: Listing;
}

interface ListingsAndScreenshotsObject {
  rur_id: string;
  listings: ListingWithScreenshots[];
  loaded_from_server?: boolean;
}

interface ListingWithScreenshots extends Listing {
  screenshots: Screenshot[] | null;
}

interface DropdownLoading {
  rur_id: string;
  loading: boolean;
  disabled: boolean;
}

export const getSelectedListingForRurID = (
  rurID: string,
  selectedListingsAndScreenshots: ListingWithScreenshot[]
): string => {
  const listingWithScreenshot = selectedListingsAndScreenshots.find(
    (item) => item.rur_id === rurID
  );
  return listingWithScreenshot ? listingWithScreenshot.listing?.id : "";
};

export const getSelectedScreenshotForRurID = (
  rurID: string,
  selectedListingsAndScreenshots: ListingWithScreenshot[]
): string => {
  const listingWithScreenshot = selectedListingsAndScreenshots.find(
    (item) => item.rur_id === rurID
  );
  return listingWithScreenshot?.screenshot?.created_at ?? "";
};

export const getListingOptionsForRurID = (
  rurID: string,
  gridListingsAndScreenshots: ListingsAndScreenshotsObject[]
): Array<ListingOption> => {
  const listingsAndScreenshotsObject = gridListingsAndScreenshots.find(
    (obj) => obj.rur_id === rurID
  );

  if (listingsAndScreenshotsObject) {
    return listingsAndScreenshotsObject.listings.map((listing) => ({
      value: listing.id,
      label: listing.title
        ? `${listing.id} : ${listing.title}`
        : `${listing.id}`,
    }));
  }

  return [];
};

export const getScreenshotOptionsForRurID = (
  rurID: string,
  selectedListingsAndScreenshots: ListingWithScreenshot[],
  gridListingsAndScreenshots: ListingsAndScreenshotsObject[]
): Array<ScreenshotOption> => {
  const selectedListingID = getSelectedListingForRurID(
    rurID,
    selectedListingsAndScreenshots
  );

  // Check if a selected listing is found
  if (selectedListingID) {
    const listingsAndScreenshotsObject = gridListingsAndScreenshots.find(
      (obj) => obj.rur_id === rurID
    );

    if (listingsAndScreenshotsObject) {
      const listingWithScreenshots = listingsAndScreenshotsObject.listings.find(
        (listing) => listing.id === selectedListingID
      );

      if (listingWithScreenshots && listingWithScreenshots.screenshots) {
        const screenshotOptions = listingWithScreenshots.screenshots.map(
          (screenshot) => ({
            value: screenshot.created_at,
            label: formatDateTime(screenshot.created_at),
          })
        );

        return screenshotOptions;
      } else {
        return [{ value: "", label: "" }];
      }
    }
  }

  return [];
};

export const getIsChangeScreenshotDropdownLoading = (
  rurID: string,
  isChangeScreenshotDropdownLoading: DropdownLoading[]
) => {
  const matchingObj = isChangeScreenshotDropdownLoading.find(
    (obj) => obj.rur_id === rurID
  );
  return matchingObj ? matchingObj.loading : false;
};

export const getIsChangeScreenshotDropdownDisabled = (
  rurID: string,
  isChangeScreenshotDropdownLoading: DropdownLoading[]
) => {
  const matchingObj = isChangeScreenshotDropdownLoading.find(
    (obj) => obj.rur_id === rurID
  );
  return matchingObj ? matchingObj.disabled : false;
};

export const processListingsAndScreenshotsResponse = (
  response: any
): ListingsAndScreenshotsObject => {
  const rur_id = response?.data?.bulk_letter_listings?.rental_unit_id;
  const hits = response?.data?.bulk_letter_listings?.hits;
  const processedListings: ListingWithScreenshots[] = hits
    .filter((listing: any) => {
      return listing.screenshot && listing.screenshot.length > 0;
    })
    .map((listing: any) => {
      const id = listing.id;
      const title = listing.title;
      const url = listing.url;
      const screenshots = listing.screenshot;

      const processedScreenshots: Screenshot[] | null = screenshots
        .slice(0, 50)
        .map((screenshot: any) => {
          //only return first 50 screenshots per listing.
          return {
            created_at: screenshot.created_date,
            url: screenshot.screenshot_url,
          };
        });

      return {
        id,
        title,
        url,
        screenshots: processedScreenshots,
      };
    });

  return {
    rur_id,
    listings: processedListings,
    loaded_from_server: true,
  };
};

export const initializeGridListingsAndScreenshots = (
  eligibilityResponse: any
): ListingsAndScreenshotsObject[] => {
  return eligibilityResponse?.recepient_info?.map((info: any) => {
    const listing: ListingWithScreenshots = {
      id: info.internal_listing_id,
      title: info.listing_title,
      url: info.listing_url,
      screenshots: [
        {
          created_at: info.listing_screenshot_date,
          url: info.listing_screenshot_url,
        },
      ],
    };

    return {
      rur_id: info.rental_unit_record,
      listings: [listing],
    };
  });
};

export const initializeChangeScreenshotDropdownLoading = (
  eligibilityResponse: any
) => {
  return eligibilityResponse?.recepient_info?.map((info: any) => {
    return {
      rur_id: info.rental_unit_record,
      loading: false,
      disabled: false,
    };
  });
};

export const getFirstScreenshotForListingID = (
  rurID: string,
  listingID: string,
  gridListingsAndScreenshots: ListingsAndScreenshotsObject[]
): Screenshot | null => {
  const listingsObject = gridListingsAndScreenshots.find(
    (obj) => obj.rur_id === rurID
  );

  if (listingsObject) {
    const listing = listingsObject.listings.find(
      (list) => list.id === listingID
    );

    if (listing && listing.screenshots && listing.screenshots.length > 0) {
      return listing.screenshots[0];
    }
  }

  return null;
};

export const getDefaultSelectedListingsAndScreenshots = (
  eligibilityResponse: any
): Array<ListingWithScreenshot> => {
  return (
    eligibilityResponse?.recepient_info?.map((item: any) => ({
      rur_id: item.id,
      screenshot: {
        created_at: item.listing_screenshot_date,
        url: item.listing_screenshot_url,
      },
      listing: {
        id: item.internal_listing_id,
        title: item.listing_title,
        url: item.listing_url,
      },
    })) || []
  );
};

export const getSelectedListingAndScreenshot = (
  rurIDs: Array<string>,
  selectedListingsAndScreenshots: ListingWithScreenshot[]
): ListingWithScreenshot[] | undefined => {
  const matchingSelectedListingAndScreenshot =
    selectedListingsAndScreenshots.filter(
      (listingWithScreenshot) =>
        rurIDs.indexOf(listingWithScreenshot.rur_id) > -1
    );
  return matchingSelectedListingAndScreenshot;
};

export const getListingByListingAndRurID = (
  listingID: string,
  rurID: string,
  gridListingsAndScreenshots: ListingsAndScreenshotsObject[]
): Listing | undefined => {
  const listingsObject = gridListingsAndScreenshots.find(
    (obj) => obj.rur_id === rurID
  );

  if (listingsObject) {
    const listing = listingsObject.listings.find(
      (list) => list.id === listingID
    );
    if (listing) {
      const { id, title, url } = listing;
      return { id, title, url };
    }
  }

  return undefined;
};

export const getIsLoadedFromServerByRurID = (
  rurID: string,
  gridListingsAndScreenshots: ListingsAndScreenshotsObject[]
): boolean => {
  const listingsObject = gridListingsAndScreenshots.find(
    (obj) => obj.rur_id === rurID
  );

  if (listingsObject && listingsObject.loaded_from_server) {
    return listingsObject.loaded_from_server;
  } else {
    return false;
  }
};

export const getScreenshotByCreatedDateAndRurID = (
  createdDate: string,
  rurID: string,
  gridListingsAndScreenshots: ListingsAndScreenshotsObject[]
): Screenshot | null => {
  const listingsObject = gridListingsAndScreenshots.find(
    (obj) => obj.rur_id === rurID
  );

  if (listingsObject) {
    const screenshot = listingsObject.listings
      .map((list) =>
        list.screenshots?.find((s) => s.created_at === createdDate)
      )
      .find((s) => s !== undefined);

    if (screenshot) {
      return screenshot;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
