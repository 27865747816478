//
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useRouteMatch
} from 'react-router-dom';

import { LoadingBar } from '../common/loading';
import AngularPassthru from '../common/angular-passthru';

//
// import AddressIdentification from './address_identification/address_identification';
import Qualification from './qualification/qualification';

//
function View() {
  let { path } = useRouteMatch();
  return (
    <Router>
      <LoadingBar />
      <Switch>
        <Route path={`${path}/:type(address_identification)/:task_id`} component={AngularPassthru} />
        <Route path={`${path}/:type(qualification)/:task_id`} component={Qualification} />
        <Route path="/">
          <Redirect to="/errors/404" />
        </Route>
      </Switch>
    </Router>
  );
}

export default View;
