import React from "react";
import { IMaskInput, IMask } from "react-imask";

export default React.forwardRef(function TextMaskCustom(props, ref) {
  let attr = Object.assign({}, props);
  delete attr.onChange;

  if (!attr.mask) {
    delete attr.mask;
  }

  return (
    <IMaskInput
      {...attr}
      unmask={true}
      definitions={{
        9: /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) =>
        props.onChange({ target: { name: props.name, value: value } })
      }
    />
  );
});

export const maskPhoneNumber = (value) => {
  const masked = IMask.createMask({
    mask: "(000) 000-0000",
    definitions: {
      0: /[0-9]/,
    },
  });
  masked.resolve(value);
  return masked.value;
};
