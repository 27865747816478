import React, { useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";

import {
  AppBar,
  Toolbar,
  IconButton,
  SwipeableDrawer,
  Breadcrumbs,
  Typography,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import qs from "qs";

import Sidemenu from "./common/sidemenu";
import { LoadingScreen } from "../common/loading";
import Features from "./legacy/features/features";
import Reports from "./reports/reports";
import Jurisdictions from "./legacy/jurisdictions/jurisdictions";
import RentalUnitsPage from "./rental-units/RentalUnitsPage";
import RegistrationsPage from "./registrations/RegistrationsPage";
import ListingsPage from "./listings/ListingsPage";
import LettersPage from "./letters/LettersPage";
import RentalUnitDetailsPage from "./rental-units/RentalUnitDetails/RentalUnitDetails";
import { Icon } from "./../components/Icon/Icon";
import DownloadExport from "./../components/DownloadExport/DownloadExport";
import SendBulkLettersPage from "./letters/SendBulkLettersPage";
import AuditDiscoveryPage from "./audit-discovery/AuditDiscoveryPage";
import InvoicePage from "./invoices/InvoicesPage";
import AuditManagementPage from "./audit-management/AuditManagementPage";
import ShortTermRentalHotlinePage from "./str-hotline/ShortTermRentalHotlinePage";
import ReportedRevenuePage from "./reported-revenue/ReportedRevenuePage";
import PaymentsPage from "./payments/PaymentsPage";
import EstimatedRevenueByQuarterPage from "./estimated-revenue-by-quarter/EstimatedRevenueByQuarterPage";
import EmailsGridPage from "./emails/EmailsPage";
import RegistrationHistoryPage from "./registration-history/RegistrationHistoryPage";
import DashboardQuestionnairesApp from "./questionnaires/dashboardQuestionnaires";
import { getBreadcrumbs } from "../common/questionnaires/util";

function Dashboard({ jurisdictionClient, jurisdictionData }) {
  const currentUrl = window.location.href;
  const isQJSONForm = currentUrl.includes("/questionnaires/");
  const location = useLocation();

  useEffect(() => {
    document.title = `Dashboard`;
  }, [location]);

  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const [breadcrumbs, setBreadcrumbs] = React.useState("Host Compliance");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setBreadcrumbs(getBreadcrumbs(location.pathname, searchParams));
  }, [location.pathname, location.search]);

  const toggleSidebar = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setSidebarOpened(open);
  };

  return (
    <div>
      <div className="hiddenPrint">
        {!isQJSONForm && (
          <AppBar
            position="static"
            style={{ boxShadow: "none", backgroundColor: "#fdfdfd" }}
          >
            <Toolbar>
              <IconButton
                className="hamburger-menu"
                aria-label="delete"
                onClick={toggleSidebar(true)}
              >
                <Icon type={"bars"} clickable={true} aria-label="menu" />
              </IconButton>
              <img
                src="https://cdn.hostcompliance.com/hostcompliance/Host-Compliance-icon-word.png"
                alt="Host Compliance"
                style={{ maxHeight: "26px", marginLeft: "10px" }}
              />
              <Breadcrumbs>
                <Typography>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ padding: "0 5px 1px 5px", height: "0.8em" }}
                  />
                  <span style={{ fontSize: "16px", fontWeight: 300 }}>
                    {breadcrumbs}
                  </span>
                </Typography>
              </Breadcrumbs>
            </Toolbar>
          </AppBar>
        )}
      </div>
      <Switch>
        <Route
          path="/dashboard/reports/:slug(complaints|address-identification|trend-monitoring)"
          component={Reports}
        />
        <Route exact path="/dashboard">
          <Redirect to="/dashboard/reports/address-identification" />
        </Route>

        <Route
          exact
          path="/dashboard/modals"
          render={({ location }) => {
            const queryParams = qs.parse(location.search, {
              ignoreQueryPrefix: true,
            });
            let to = {
              pathname: `/dashboard/questionnaires/${queryParams.questionnaireId}`,
            };
            if (queryParams.audit_id) {
              to.search = "?audit_id=" + queryParams.audit_id;
            }
            if (queryParams.permit_id) {
              to.search = "?permit_id=" + queryParams.permit_id;
            }
            return <Redirect to={to} />;
          }}
        />

        <Route path="/dashboard/features" component={Features} />
        <Route path="/dashboard/jurisdictions" component={Jurisdictions} />
        <Route
          path="/dashboard/questionnaires/:questionnaireId(audits-admin-review|managers-admin-review|registrations-admin-review|registrations-admin-update)/:questionId?"
          component={DashboardQuestionnairesApp}
          key={location.pathname}
        />

        <Route
          exact
          path="/dashboard/audit-discovery"
          component={AuditDiscoveryPage}
        />
        <Route
          path="/dashboard/estimated-revenue-by-quarter-by-rental-unit/:externalPropertyID"
          component={EstimatedRevenueByQuarterPage}
        />
        <Route
          exact
          path="/dashboard/audit-management"
          component={AuditManagementPage}
        />
        <Route
          exact
          path="/dashboard/reported-revenue"
          component={ReportedRevenuePage}
        />
        <Route exact path="/dashboard/emails" component={EmailsGridPage} />
        <Route exact path="/dashboard/letters" component={LettersPage} />
        <Route exact path="/dashboard/listings" component={ListingsPage} />
        <Route
          exact
          path="/dashboard/registrations"
          component={RegistrationsPage}
        />
        <Route
          exact
          path="/dashboard/registration_history"
          component={RegistrationHistoryPage}
        />
        <Route
          path="/dashboard/rental-units/:rentalUnitId/:listingId"
          component={RentalUnitDetailsPage}
        />
        <Route
          exact
          path="/dashboard/rental-units/sendLetters"
          component={SendBulkLettersPage}
        />
        <Route
          path="/dashboard/rental-units/:rentalUnitId"
          component={RentalUnitDetailsPage}
        />
        <Route
          path="/dashboard/rental-units"
          exact
          component={RentalUnitsPage}
        />
        <Route
          exact
          path="/dashboard/str-hotline"
          component={ShortTermRentalHotlinePage}
        />
        <Route exact path="/dashboard/payments" component={PaymentsPage} />
        <Route exact path="/dashboard/invoices" component={InvoicePage} />
        <Route path="/dashboard/download" component={DownloadExport} />
        <Route>
          <Redirect to="/errors/404" />
        </Route>
      </Switch>
      <SwipeableDrawer
        anchor="left"
        open={sidebarOpened}
        onClose={toggleSidebar(false)}
        onOpen={toggleSidebar(true)}
      >
        <Sidemenu
          closeSidebar={toggleSidebar(false)}
          jurisdictionData={jurisdictionData}
          jurisdictionClient={jurisdictionClient}
        />
      </SwipeableDrawer>
    </div>
  );
}

export default withAuthenticationRequired(Dashboard, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: LoadingScreen,
});
