import React from "react";
import {
  GridFilterItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { GridToolbarPreDefinedFiltersSelector } from "./GridToolbarPreDefinedFiltersSelector";
import { GridToolbarExport } from "./GridToolbarExport";
import {
  TableMetaFilters,
  TableView,
} from "../../dashboard/common/data-grid-cols";
import { GridToolbarClearAllButton } from "./GridToolbarClearAllButton";
import { GridToolbarCustomViewsSelector } from "./GridToolbarCustomViewsSelector";

export interface DataGridCustomToolbarProps {
  preDefinedFilter: string;
  filters: TableMetaFilters[];
  selectedFilters: GridFilterItem[];
  onPreDefinedFiltersChange: (value: string) => void;
  generateExport: () => void;
  showExport: string | null;
  clearFilter: () => void;
  onViewSelect: (value: string) => void;
  createView: (value: string) => any;
  deleteView: (value: string) => any;
  selectedView: string;
  views: TableView[];
  title: string;
  setViewsCallBack: () => void;
}

const DataGridCustomToolbar = (props: DataGridCustomToolbarProps) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton components={undefined} />
      {props.filters.length ? (
        <GridToolbarPreDefinedFiltersSelector
          onClick={props.onPreDefinedFiltersChange}
          value={props.preDefinedFilter}
          filters={props.filters}
        />
      ) : undefined}
      <GridToolbarCustomViewsSelector
        onClick={props.onViewSelect}
        value={props.selectedView}
        views={props.views}
        createView={props.createView}
        deleteView={props.deleteView}
        setViewsCallBack={props.setViewsCallBack}
      />
      <GridToolbarDensitySelector />
      {props.showExport && <GridToolbarExport onClick={props.generateExport} />}
      {props.selectedFilters.length ? (
        <GridToolbarClearAllButton onClick={props.clearFilter} />
      ) : undefined}
    </GridToolbarContainer>
  );
};

export default DataGridCustomToolbar;
