import { gql, useLazyQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import qs from "qs";
import { ParsedQs } from "qs";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const GET_SIGNED_URL = gql`
  query SIGNED_URL($url: URL!) {
    signed_url(url: $url)
  }
`;

/**
 * Download component to download the exports
 * @returns Exported file
 */
export const DownloadExport = () => {
  const location = useLocation<Location>();
  const { url }: ParsedQs = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [token, setToken] = useState<string | null>(null);
  const [authError, setAuthError] = useState<string | null>(null);

  const [getData, { data, loading, called, error: queryError }] = useLazyQuery(
    GET_SIGNED_URL,
    {
      context: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      errorPolicy: "all",
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    const fetchToken = async () => {
      try {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently();
          setToken(accessToken);
        }
      } catch (error) {
        setAuthError("Error fetching access token. Please try again.");
      }
    };

    fetchToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  // get the signed url
  useEffect(() => {
    if (url && !called && token) {
      getData({
        variables: {
          url: url,
        },
      });
    }
  }, [url, called, getData, token]);

  useEffect(() => {
    if (!loading && data && data.signed_url) {
      onLoad();
    }
  }, [loading, data]);

  const onLoad = useCallback(() => {
    if (data && data.signed_url) {
      window.location.assign(data.signed_url);
    }
  }, [data]);

  if (authError) {
    return (
      <Typography align="center" textAlign={"center"} color="error">
        {authError}
      </Typography>
    );
  }

  if (queryError) {
    return (
      <Typography align="center" textAlign={"center"} color="error">
        Error fetching the download URL. Please try again.
      </Typography>
    );
  }

  return (
    <>
      <Typography align="center" textAlign={"center"}>
        File download is in progress...
      </Typography>
    </>
  );
};

export default DownloadExport;
