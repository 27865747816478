import React, { useState } from "react";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAudio } from "@fortawesome/free-solid-svg-icons";

interface CallRecordingModalProps {
  url: string;
}

const CallRecordingModal = (props: CallRecordingModalProps) => {
  const [showCallRecordingModal, setShowCallRecordingModal] = useState(false);

  return (
    <>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => setShowCallRecordingModal(true)}
      >
        <FontAwesomeIcon
          icon={faFileAudio}
          size="2x"
          data-testid="audio-icon"
        />
      </div>
      <ModalComponent
        header="Call Recording"
        onClose={() => setShowCallRecordingModal(false)}
        open={showCallRecordingModal}
        width="500px"
      >
        <audio
          controls
          autoPlay
          style={{
            width: "100%",
            margin: "15px 0",
            boxSizing: "border-box",
            padding: "0 15px",
          }}
          data-testid="audio-element"
        >
          <source src={props.url} />
          Your browser does not support the audio element.
        </audio>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px",
          }}
        >
          <Button
            variant="contained"
            onClick={() => setShowCallRecordingModal(false)}
            data-testid="close-button"
          >
            Close
          </Button>
        </div>
      </ModalComponent>
    </>
  );
};

export default CallRecordingModal;
