
import React from 'react';
import { CircularProgress, LinearProgress, Grid, List, ListItem } from '@mui/material';

function LoadingScreen() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: '100%' }}
    >
      <CircularProgress />
    </Grid>
  );
}

function LoadingBar(attrs) {
  return (
    <LinearProgress style={{
      height: '2px',
      position: 'absolute',
      top: attrs.top || 0,
      left: 0,
      right: 0,
      zIndex: 100,
    }} />
  );
}

function LoadingList() {
  return (
    <List dense={true} style={{opacity:0.2}}>
      <ListItem>
        <LinearProgress color="secondary" style={{
          height: '0.7em',
          width: '20%',
        }}/>
      </ListItem>

      <ListItem>
        <LinearProgress color="secondary" style={{
          height: '0.7em',
          width: '20%',
        }}/>
      </ListItem>

      <ListItem>
        <LinearProgress color="secondary" style={{
          height: '0.7em',
          width: '12%',
        }}/>
      </ListItem>
    </List>
  );
}

export { LoadingScreen, LoadingBar, LoadingList }
