import React, { useState } from "react";
import { Box } from "@mui/material";
import GenericModalBody from "../../../components/ModalComponent/GenericModalBody";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";

interface PendingRegistrationModalProps {
  row: any;
  setSnackbarData: any;
  closeModal?: any;
  reloadGrid: () => any;
}
const updateRegistraionStatusQuery = loader(
  "./UpdateRegistrationStatus.graphql"
);

export const PendingRegistrationModal = (
  cellProps: PendingRegistrationModalProps
) => {
  const [isDenied, setIsDenied] = useState<boolean>(false);
  const [denialReason, setDenialReason] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [updateRegistraionStatusMutation] = useMutation(
    updateRegistraionStatusQuery
  );

  const closePendingRegistrationModal = () => {
    setIsDenied(false);
    setDenialReason("");
    cellProps.closeModal();
  };

  const updateRegistrationStatus = async (
    status: string,
    reasonForDenial?: string
  ) => {
    try {
      setLoading(true);
      await updateRegistraionStatusMutation({
        variables: {
          id: cellProps.row.registration_number,
          status: status.toLowerCase(),
          denial_reason: reasonForDenial ? reasonForDenial : null,
        },
      });
      setTimeout(() => {
        cellProps.setSnackbarData({
          open: true,
          message: "Submitted successfully",
          severity: "success",
          vertical: "top",
          horizontal: "center",
        });
        setLoading(false);
        cellProps.reloadGrid();
        closePendingRegistrationModal();
      }, 4000);
    } catch (error) {
      setLoading(false);
      cellProps.setSnackbarData({
        message: "Failed to submit the request, Please try again later",
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      closePendingRegistrationModal();
    }
  };

  const handleDeniedReason = async (event: any) => {
    setDenialReason(event.target.value);
  };

  return (
    <Box className="registrationModal">
      {isDenied ? (
        <GenericModalBody
          viewType="input"
          input={{
            name: "denial-reason",
            value: denialReason,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
              handleDeniedReason(event),
            label: "Please provide a denial reason:",
            type: "text",
          }}
          buttons={[
            {
              text: "Deny",
              onClick: () => updateRegistrationStatus("Denied", denialReason),
              variant: "contained",
              color: "primary",
              loading: loading,
            },
          ]}
        />
      ) : (
        <GenericModalBody
          text={"What would you like to do with the pending registration?"}
          viewType="text"
          buttons={[
            {
              text: "Approve",
              onClick: () => updateRegistrationStatus("Active"),
              variant: "outlined",
              color: "primary",
              loading: loading,
            },
            {
              text: "Deny",
              onClick: () => setIsDenied(true),
              variant: "contained",
              color: "primary",
            },
          ]}
        />
      )}
    </Box>
  );
};
