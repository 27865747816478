import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { formatDate } from "../../../common/utils";

interface InvalidateLettersFormProps {
  lettersData: any;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  removeLetters: string[];
  resetRemoveLetters: (loadFromLetterData: boolean) => void;
}

const InvalidateLetters = (props: InvalidateLettersFormProps) => {
  const [selectAllLetters, setSelectAllLetters] = useState(true);
  const [selectedLetters, setSelectedLetters] = useState([
    ...props.removeLetters,
  ]);
  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectAllLetters(event.target.checked);
    if (event.target.checked) {
      props.resetRemoveLetters(true);
    } else {
      props.resetRemoveLetters(false);
    }
  };

  useEffect(() => {
    setSelectAllLetters(
      props.removeLetters.length == props.lettersData?.results?.hits?.length
    );
    setSelectedLetters([...props.removeLetters]);
  }, [props.removeLetters]);

  return (
    <>
      <Typography sx={{ marginBottom: "20px" }}>
        Use the check boxes to remove any associated letters with this parcel.
        Letters selected will no longer count as legal notice.
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleSelectAll}
            id={"select-all-letters"}
            onClick={(event) => event.stopPropagation()}
            data-testid={"select-all-letters"}
            checked={selectAllLetters}
          />
        }
        label={"Select All"}
      />
      {
        <Box className="lettersDataContainer">
          {props.lettersData.results?.hits.map((item: any, index: number) => (
            <FormControlLabel
              key={index}
              className="lettersDataBox"
              control={
                <Checkbox
                  onChange={props.handleChange}
                  id={item.id}
                  onClick={(event) => event.stopPropagation()}
                  data-testid={item.id}
                  checked={selectedLetters.includes(item.id)}
                />
              }
              label={
                <Grid
                  className="letterInputLabel"
                  container
                  spacing={0}
                  columns={12}
                  alignItems="flex-end"
                  data-testid={"label-" + item.id}
                >
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {item.customer_display_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">Status:</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body2">
                      {item.letter_recipient_status}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">Created at:</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body2">
                      {formatDate(item.letter_date)}
                    </Typography>
                  </Grid>
                  {item.lob_to_name ? (
                    <Grid item xs={2}>
                      <Typography variant="body2">Sent to:</Typography>
                    </Grid>
                  ) : null}
                  {item.lob_to_name ? (
                    <Grid item xs={10}>
                      <Typography variant="body2">
                        {item.lob_to_name}
                      </Typography>
                    </Grid>
                  ) : null}
                  {item.to_full_address ? (
                    <Grid item xs={2}>
                      <Typography variant="body2">Address:</Typography>
                    </Grid>
                  ) : null}
                  {item.to_full_address ? (
                    <Grid item xs={10}>
                      <Typography variant="body2">
                        {item.to_full_address}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
              }
            />
          ))}
        </Box>
      }
    </>
  );
};

export default InvalidateLetters;
