import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const SwipeableImageStepper = ({
  images,
  height,
  containerHeight,
}: any) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <>
      <Box sx={{ height: containerHeight ? containerHeight : "80%" }}>
        <AutoPlaySwipeableViews
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={9000}
        >
          {images.map((step: any, index: number) => (
            <div key={index} style={{ height: height ? height : "60vh" }}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  component="img"
                  sx={{ width: "100%" }}
                  src={step.image}
                  alt={"Sample"}
                />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
      </Box>
      <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
        <KeyboardArrowLeftIcon />
      </Button>

      <Button
        size="small"
        style={{ float: "right" }}
        onClick={handleNext}
        disabled={activeStep === maxSteps - 1}
      >
        <KeyboardArrowRightIcon />
      </Button>
    </>
  );
};
