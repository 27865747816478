import React from "react";
import { gql } from "@apollo/client";
import { GridColDef } from "@mui/x-data-grid-pro";
import SearchView from "../../components/SearchView/SearchView";
import {
  ExtendedGridColDef,
  getTableMeta,
  getEditableFieldOptions,
} from "../common/data-grid-cols";

const ShortTermRentalHotlinePage = () => {
  const table = "calls_and_tips";
  const { columns, filters } = getTableMeta(table);
  const editableFieldOptions = getEditableFieldOptions(table);

  const shortTermRentalHotlineQuery = (
    columnsToFilter: ExtendedGridColDef[]
  ) => {
    const queryFields = columnsToFilter
      .filter(
        (column) =>
          !column.hide &&
          !column.dynamic &&
          column.field !== "uploadcare_cdn_url"
      )
      .map((column) => {
        if (column.field === "uploadcare_cdn") {
          // Always include uploadcare_cdn_url in the query when uploadcare_cdn is requested
          return `${column.field} uploadcare_cdn_url`;
        } else if (column.field === "call_recording") {
          // Always include call_recording_url in the query when call_recording is requested
          return `${column.field} call_recording_url`;
        } else if (column.field === "add_view_comments_modal") {
          return `add_view_comments_modal {
            count
            foreign_id
            foreign_type
            total
            unread
          }`;
        } else {
          return column.field;
        }
      })
      .join("\n");

    return gql(`
      query(
        $q: KibanaQL=""
        $phrase: String
        $size: Int
        $from: Int
        $sort: String
      ) {
        results: calls_and_tips(
          search: { query: $q, phrase: $phrase, size: $size, from: $from, sort: $sort }
        ) {
          size
          total
          hits {
            id: call_identifier
            ${queryFields}
          }
        }
      }
    `);
  };

  const renderColumn = (column: ExtendedGridColDef): GridColDef => {
    return { ...column };
  };

  return (
    <SearchView
      searchQuery={shortTermRentalHotlineQuery}
      columns={columns
        .filter((item) => item.field !== "uploadcare_cdn_url")
        .map((column: ExtendedGridColDef) => renderColumn(column))}
      filters={filters}
      title="STR Hotline"
      editableFieldOptions={editableFieldOptions}
      tableType={table}
    />
  );
};

export default ShortTermRentalHotlinePage;
