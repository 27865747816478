
const dot = require('dot-object');
import React, { useState } from 'react';
import { TextField, FormControl, FormHelperText } from '@mui/material';

function View({ question, context }) {

  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    output: context.pick('value') || ''
  });

  const validate = (formValues) => {
    let tmp = { ...formErrors };

    // browser validation... // TODO
    console.warn(formValues);

    // any additional validation...
    // nada

    setFormErrors(tmp);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let values = {...formValues};
    dot.str(name, value, values);
    setFormValues(values);
    validate({ [name]: value });
    context.assign({
      value: value
    });
  };

  // TECHDEBT: MUI doesn't support 'Character counter' yet, "6/2000" for accessibilty should be displayed

  return (
    <FormControl error fullWidth>
      <TextField
        id="text"
        name="output"
        multiline
        rows={4}
        label={question.properties.locale.name}
        placeholder={question.properties.locale.placeholder}
        variant="filled"
        value={formValues.output}
        onChange={handleInputChange}
        required={question.input.required}
        readOnly={question.input.readonly}
        autoFocus
        inputProps={{
          maxLength: question.input.maxlength ? question.input.maxlength : 80,
          inputref: 'ref-text'
        }}
        autoComplete="off"
        aria-describedby="text-helper-text"
      />
      <FormHelperText id="text-helper-text" style={{fontSize:'0.9rem'}}>{formErrors.text}</FormHelperText>
    </FormControl>
  );
}

export default View;
