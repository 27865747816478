
import React, { useEffect } from 'react';
import { Card, CardContent, Typography } from '@mui/material';

function View(props) {
  let params = props.match.params;

  useEffect(() => {
    document.title = `Error ${params.code}`;
  });

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2">
          { params.code === '-1' && <>Disconnected</> }
          { params.code === '401' && <>Unauthorized</> }
          { params.code === '404' && <>Not Found</> }
          { params.code === '405' && <>Method Not Allowed</> }
          { params.code === '408' && <>Request Timeout</> }
          { params.code === '500' && <>Internal Server</> }
          { params.code === '503' && <>Service Unavailable</> }
          { params.code === '504' && <>Gateway time-out</> }
          { params.code === '520' && <>Unknown</> }
          <small> (Error { params.code })</small>
        </Typography>

        <div className="body">
          { params.code === '401' && <p>Maybe you need to <a href="/">Sign In</a>?</p> }
          { params.code === '503' && <p>It is likely we are updating something. Please check back again soon.</p> }
          <p>If this problem persists, please contact <a href="mailto:support@hostcompliance.com">support@hostcompliance.com</a>.</p>
        </div>
      </CardContent>
    </Card>
  );
}

export default View;
