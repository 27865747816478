import React, { useContext } from "react";
import { Alert, Snackbar } from "@mui/material";
import { EsriContext, removeNotification } from "../context/EsriContext";
import { Notification as NotificationType } from "../interfaces";

const Notification: React.FC = () => {
  const { state, dispatch }: any = useContext(EsriContext);

  return state.notification.map(
    (notification: NotificationType, index: number) => (
      <Snackbar
        key={index}
        open={true}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => dispatch(removeNotification())}
      >
        <Alert
          severity={notification.type}
          sx={{ width: "100%" }}
          onClose={() => dispatch(removeNotification())}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    )
  );
};

export default Notification;
