// TODO: input
import React from "react";
import UploadAndPreview from "./uploadAndPreview";
import parser from "./parser";

function View({ question, context, showFormErrors }) {
  const locale = question.properties.locale;
  const input = question.input;

  const documents = Object.entries(question.input.documents)?.map(
    ([key, item]) => ({
      ...item,
      requestData: {
        ...parser.parse(question.input.data, context),
        extra: {
          key: key,
          label: locale[item.locale_key],
        },
      },
    })
  );

  return (
    <div className={`inputs inputs-uploads ${locale.dir}`}>
      {documents &&
        Object.keys(documents).length > 0 &&
        Object.values(documents).map((item) => (
          <div key={item.id} className="item">
            <UploadAndPreview
              id={`ref-uploads-${item.id}`}
              requestData={item.requestData}
              context={context}
              showFormErrors={showFormErrors}
              options={{
                label: locale[item.locale_key],
                description: locale[item.locale_key_more],
                accepted: locale[item.locale_key_accepted],
                readOnly: input.readonly,
                minFileSize: item.min_file_size ? item.min_file_size : 100,
                maxFileSize: item.max_file_size ? item.max_file_size : 25000000,
                minFiles: item.min_files ? item.min_files : 0,
                maxFiles: item.max_files ? item.max_files : 10,
                acceptedFiles: parser.parse(item.extensions, context),
                disableCamera: item.disable_camera,
                immovableAge: input.immovable_age,
              }}
            />
          </div>
        ))}
    </div>
  );
}

export default View;
