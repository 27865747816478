import React, { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import Mapping from "../components/Mapping";
import {
  EsriContext,
  _deleteEsriConfig,
  loadConfig,
  addEndpointFields,
  createNotification,
  updateToken,
} from "../context/EsriContext";
import { useMutation, useLazyQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Switch, Paper, Button, Typography, Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useAuth0 } from "@auth0/auth0-react";
import { loader } from "graphql.macro";
import { useFormContext } from "react-hook-form";
import {
  Mappings,
  EsriConfig,
  ExecutionStatus,
  DataEndpoint,
  EndpointFields,
} from "../interfaces";
import env from "../../../common/env";

import styles from "./MappingsPage.module.scss";

const CREATE_ESRI_CONFIG = loader("../mutations/createEsriConfig.graphql");
const UPDATE_ESRI_CONFIG = loader("../mutations/updateEsriConfig.graphql");
const DELETE_ESRI_CONFIG = loader("../mutations/deleteEsriconfig.graphql");
const FETCH_AUTH_TOKEN = loader("../queries/fetchAuthToken.graphql");

const STEP_DESCRIPTION =
  "Esri integration manager is ready to begin mapping appropriate data to Host Compliance. Please select an option below.";
const MAPPING_MODAL_BTN_TEXT = "Begin Data Mapping";
const CREATE_CONFIG_BTN_TEXT = "Create configuration";
const UPDATE_CONFIG_BTN_TEXT = "Update configuration";
const DELETE_CONFIG_BTN_TEXT = "Delete configuration";
const LAST_STATUS_TEXT = "Last data fetch status:";
const MODAL_TITLE = "Esri Fields Mapping";
const FIELDS_FETCH_FAILED =
  "Connection to endpoints wasn't established. Please check the provided endpoints url.";
const EMPTY_MAPPING =
  "Endpoint mapping is selected, at least one field must be picked.";

interface MappingsPageProps {
  moveToBeginning: () => void;
}

const MappingsPage: React.FC<MappingsPageProps> = ({ moveToBeginning }) => {
  const { user } = useAuth0();
  const [isOpen, setOpen] = useState(false);
  const { state: contextState, dispatch } = useContext(EsriContext);

  const [fetchAuthToken] = useLazyQuery(FETCH_AUTH_TOKEN, {
    onCompleted(data) {
      dispatch(updateToken(data.fetchAuthToken));
    },
    onError(error) {
      dispatch(
        createNotification({
          message: error.message,
          type: "error",
        })
      );
    },
  });

  useEffect(() => {
    const [username, password, token_endpoint] = getValues([
      "username",
      "password",
      "token_endpoint",
    ]);

    username &&
      password &&
      !contextState.authToken &&
      fetchAuthToken({
        variables: {
          username,
          password,
          endpoint: token_endpoint,
        },
      });
  }, []);

  useEffect(() => {
    const [username, password] = getValues(["username", "password"]);

    if (username && password && contextState.authToken) {
      fetchFields();
    }
    if (!(username && password)) {
      fetchFields();
    }
  }, [contextState.esriConfig.data_endpoints, contextState.authToken]);

  const { getValues, control, reset } = useFormContext();

  const [createConfig, { loading: createLoading }] = useMutation(
    CREATE_ESRI_CONFIG,
    {
      onCompleted(data) {
        dispatch(loadConfig(data.createEsriConfig.esriConfig));
        dispatch(
          createNotification({
            message: data.createEsriConfig.status.message,
            type: "success",
          })
        );
      },
      onError(error) {
        dispatch(createNotification({ message: error.message, type: "error" }));
      },
    }
  );

  const [updateConfig, { loading: updateLoading }] = useMutation(
    UPDATE_ESRI_CONFIG,
    {
      onCompleted(data) {
        dispatch(loadConfig(data.updateEsriConfig.esriConfig));
        dispatch(
          createNotification({
            message: data.updateEsriConfig.status.message,
            type: "success",
          })
        );
        reset(getValues());
      },
      onError(error) {
        dispatch(createNotification({ message: error.message, type: "error" }));
      },
    }
  );

  const [deleteConfig, { loading: deleteLoading }] = useMutation(
    DELETE_ESRI_CONFIG,
    {
      onCompleted(data) {
        dispatch(_deleteEsriConfig());
        moveToBeginning();
        dispatch(
          createNotification({
            message: data.deleteEsriConfig.message,
            type: "success",
          })
        );
      },
      onError(error) {
        dispatch(createNotification({ message: error.message, type: "error" }));
      },
    }
  );

  const renderLastExecutionStatus = () => {
    switch (contextState.esriConfig.last_execution_status) {
      case ExecutionStatus.SUCCESS: {
        const lastExecutionTime: string | null =
          contextState.esriConfig.last_execution_datetime;
        const formatedDate =
          lastExecutionTime && new Date(lastExecutionTime).toLocaleString();

        return (
          <Box>
            <Typography>
              {LAST_STATUS_TEXT}{" "}
              <FontAwesomeIcon style={{ color: "green" }} icon={faCircle} />{" "}
              {ExecutionStatus.SUCCESS}
            </Typography>
            <Typography>Last sync date/time: {formatedDate} UTC</Typography>
          </Box>
        );
      }
      case ExecutionStatus.FAILED: {
        const _lastExecutionTime: string | null =
          contextState.esriConfig.last_execution_datetime;
        const _formatedDate =
          _lastExecutionTime && new Date(_lastExecutionTime).toLocaleString();

        return (
          <Box>
            <Typography>
              {LAST_STATUS_TEXT}{" "}
              <FontAwesomeIcon style={{ color: "red" }} icon={faCircle} />{" "}
              {ExecutionStatus.FAILED}
            </Typography>
            <Typography>Last sync date/time: {_formatedDate} UTC</Typography>
          </Box>
        );
      }
      default:
        return;
    }
  };

  const handleRequest = async (
    url: string,
    payload: URLSearchParams,
    endpoint: DataEndpoint
  ) => {
    try {
      const resp = await fetch(url + "?" + payload);
      const data = await resp.json();

      if (data.error) {
        throw Error(data.error.message);
      }

      dispatch(addEndpointFields({ id: endpoint.id, fields: data.fields }));
    } catch {
      dispatch(
        createNotification({
          message: FIELDS_FETCH_FAILED,
          type: "error",
        })
      );
    }
  };

  const fetchFields = () => {
    contextState.esriConfig.data_endpoints.forEach((endpoint: DataEndpoint) => {
      if (
        !contextState.endpoints_fields.find(
          (_endpoint: EndpointFields) => _endpoint.id === endpoint.id
        )
      ) {
        const payload = new URLSearchParams({ f: "pjson" });
        if (contextState.authToken) {
          payload.append("token", contextState.authToken["token"]);
        }
        const url = endpoint.url.substring(0, endpoint.url.lastIndexOf("/"));

        handleRequest(url, payload, endpoint);
      }
    });
  };

  const deleteEsriConfig = () => {
    deleteConfig({
      variables: {
        id: contextState.esriConfig.id,
      },
    });
  };

  const validateConfig = () => {
    const errors: string[] = [];
    const mappings: Mappings = getValues("mappings");

    for (const [key, value] of Object.entries(mappings)) {
      if (value.id && value.field.length == 0) {
        dispatch(
          createNotification({
            message: EMPTY_MAPPING,
            type: "info",
          })
        );
        errors.push(key);
      }
    }

    return errors.length;
  };

  const collectUsedFields = (payload: EsriConfig) => {
    payload.data_endpoints.map((endpoint) => {
      endpoint.used_fields = [];
      if (endpoint.join_column) {
        endpoint.used_fields.push(endpoint.join_column);
      }
    });

    for (const value of Object.values(payload.mappings)) {
      const endpoint = payload.data_endpoints.find(
        (endpoint) => endpoint.id == value.id
      );

      if (endpoint) {
        if (Array.isArray(value.field)) {
          value.field.map(
            (field: string) =>
              endpoint.used_fields.indexOf(field) === -1 &&
              endpoint.used_fields.push(field)
          );
        } else {
          endpoint.used_fields.indexOf(value.field) === -1 &&
            endpoint.used_fields.push(value.field);
        }
      }
    }

    return payload;
  };

  const createOrUpdateConfig = () => {
    setOpen(false);

    if (validateConfig()) {
      return;
    }

    const { enabled, ...values } = getValues();
    let payload = {
      ...contextState.esriConfig,
      ...values,
    };

    payload["authentication"] = Boolean(payload.username && payload.password);

    if (enabled != null) {
      payload["enabled"] = enabled;
    }

    payload = collectUsedFields(payload);

    if (contextState.esriConfig?.__typename) {
      updateConfig({
        variables: {
          ...payload,
        },
      });
    } else {
      createConfig({
        variables: {
          ...payload,
          geoid: user && user[env.AUTH0_METADATA_KEY].geoid,
        },
      });
    }
  };

  return (
    <Box sx={{ height: 600 }}>
      <Paper elevation={3} sx={{ padding: "30px 30px" }}>
        <Typography variant="subtitle1">{STEP_DESCRIPTION}</Typography>
      </Paper>
      <Box
        sx={{ marginTop: 3, height: "450px" }}
        display="flex"
        flexDirection="column"
      >
        <Box className={styles.EnabledBox}>
          <Controller
            control={control}
            name="enabled"
            render={({ field: { value, onChange } }) => (
              <FormControlLabel
                control={
                  <Switch
                    value={value}
                    onChange={onChange}
                    checked={value}
                    disabled={contextState.esriConfig.enabled === null}
                  />
                }
                label="Enabled"
              />
            )}
          />
        </Box>
        <Box textAlign="center">
          <Button
            variant="contained"
            sx={{ backgroundColor: "#103255", width: "300px" }}
            onClick={() => setOpen(true)}
          >
            {MAPPING_MODAL_BTN_TEXT}
          </Button>
        </Box>
        <ModalComponent
          open={isOpen}
          onClose={() => setOpen(false)}
          header={MODAL_TITLE}
        >
          <Box display="flex" sx={{ marginTop: "20px", paddingX: "70px" }}>
            <Typography variant="h6">Host Compliance Field</Typography>

            <Typography
              variant="h6"
              textAlign="center"
              sx={{ width: "200px", marginLeft: "auto", marginRight: 5 }}
            >
              Source
            </Typography>

            <Typography
              variant="h6"
              textAlign="center"
              sx={{ width: "200px", marginRight: 3 }}
            >
              Esri Field Data
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{ marginLeft: "70px", marginTop: "40px" }}
              variant="h6"
            >
              Mandatory
            </Typography>
            <Mapping hcFieldName="property_id" userFriendlyName="Property ID" />
            <Mapping
              hcFieldName="street_address"
              userFriendlyName="Street Address"
            />
            <Mapping hcFieldName="unit_number" userFriendlyName="Unit Number" />
            <Mapping hcFieldName="country" userFriendlyName="Country" />
            <Mapping hcFieldName="state" userFriendlyName="State" />
            <Mapping hcFieldName="locality" userFriendlyName="City" />
            <Mapping hcFieldName="postal_code" userFriendlyName="Postal Code" />
            <Mapping hcFieldName="owner_name_1" userFriendlyName="Owner Name" />
            <Mapping
              hcFieldName="owner_name_2"
              userFriendlyName="Second Owner Name"
            />
            <Mapping
              hcFieldName="owner_address_line_1"
              userFriendlyName="Owner Street Address"
            />
            <Mapping
              hcFieldName="owner_address_line_2"
              userFriendlyName="Owner City, State, Zip"
            />
            <Mapping
              hcFieldName="owner_address_line_3"
              userFriendlyName="Owner Country"
            />
            <Mapping
              hcFieldName="provided_use_code"
              userFriendlyName="Use Code"
            />
            <Mapping
              hcFieldName="provided_use_name"
              userFriendlyName="Use Name"
            />
          </Box>
          <Box>
            <Typography
              sx={{ marginLeft: "70px", marginTop: "40px" }}
              variant="h6"
            >
              Optional
            </Typography>
            <Mapping hcFieldName="latitude" userFriendlyName="Latitude" />
            <Mapping hcFieldName="longitude" userFriendlyName="Longitude" />
            <Mapping
              hcFieldName="bedrooms"
              userFriendlyName="Number of Bedrooms"
            />
            <Mapping
              hcFieldName="bathrooms"
              userFriendlyName="Number of Bathrooms"
            />
            <Mapping hcFieldName="has_pool" userFriendlyName="Pool" />
            <Mapping hcFieldName="has_elevator" userFriendlyName="Elevator" />
            <Mapping hcFieldName="units" userFriendlyName="Units" />
            <Mapping hcFieldName="stories" userFriendlyName="Stories" />
            <Mapping hcFieldName="str_policy" userFriendlyName="STR Policy" />
            <Mapping
              hcFieldName="homestead_exemption"
              userFriendlyName="Homestead Exemption"
            />
            <Mapping hcFieldName="community" userFriendlyName="Community" />
            <Mapping
              hcFieldName="vacation_rental_district"
              userFriendlyName="Vacation Rental District"
            />
            <Mapping
              hcFieldName="code_officer"
              userFriendlyName="Code Officer"
            />
            <Mapping
              hcFieldName="code_enforcement_area_name"
              userFriendlyName="Code Enforcement Area Name"
            />
            <Mapping
              hcFieldName="route_post_directional"
              userFriendlyName="Route Post Directional (or Quadrant)"
              helpText="NW, NE, SW, SE"
            />
            <Mapping
              hcFieldName="route_post_type"
              userFriendlyName="Route Post Type (or Street Type)"
              helpText="Street, Avenue, Boulevard"
            />
            <Mapping
              hcFieldName="route_name"
              userFriendlyName="Route Name (or Street Name)"
              helpText="D, 4th, Bryant, 29th"
            />
            <Mapping
              hcFieldName="street_number"
              userFriendlyName="Street Number (or Address Number)"
              helpText="100, 1"
            />
            <Mapping
              hcFieldName="secondary_id"
              userFriendlyName="Secondary Identifier"
              helpText="100, 234"
            />
          </Box>
        </ModalComponent>
        <Box textAlign="center" mt={1}>
          <Button
            variant="contained"
            color="success"
            sx={{ width: "300px" }}
            onClick={() => createOrUpdateConfig()}
            disabled={createLoading || updateLoading || deleteLoading}
          >
            {contextState.esriConfig?.__typename
              ? UPDATE_CONFIG_BTN_TEXT
              : CREATE_CONFIG_BTN_TEXT}
          </Button>
        </Box>
        {Boolean(contextState.esriConfig?.__typename) && (
          <Typography textAlign="center" mt={2}>
            {renderLastExecutionStatus()}
          </Typography>
        )}
        <Box
          textAlign="center"
          mt={1}
          sx={{ marginTop: "auto", marginBottom: "10px" }}
        >
          <Button
            variant="contained"
            color="error"
            sx={{ width: "300px" }}
            onClick={() => deleteEsriConfig()}
            disabled={
              Boolean(!contextState.esriConfig?.__typename) ||
              createLoading ||
              updateLoading ||
              deleteLoading
            }
          >
            {DELETE_CONFIG_BTN_TEXT}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MappingsPage;
