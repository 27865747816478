//
import React, { useEffect } from 'react';

//
function View() {
  useEffect(() => {
    document.title = `Host Compliance`;
  });

  const pathname = window.location.href.replace(window.location.origin, '');

  return (
    <iframe
      title=" "
      src={'/angularjs.html#!' + pathname}
      style={{
        height:'100%',
        width:'100%',
        border: 0
      }}>
    </iframe>
  );
}

export default View;
