import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { TextField } from "@mui/material";
import {
  GridFilterInputDateProps,
  GridFilterInputDate as OriginalInputComponent,
} from "@mui/x-data-grid-pro";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import { getDateFormatForDatePicker } from "../dashboard/common/utils";

function GridFilterInputDateRange(props: GridFilterInputDateProps) {
  const { applyValue, item } = props;
  const dateFormatForDatePicker = getDateFormatForDatePicker(); //used for displaying purpose only
  const fromDateFormat = "YYYY-MM-DD[T][00:00:00.000]ZZ";
  const toDateFormat = "YYYY-MM-DD[T][23:59:59.999]ZZ";
  const currentFromDate = dayjs().format(fromDateFormat);
  const currentToDate = dayjs().format(toDateFormat);

  const [formattedFromDate, setFormattedFromDate] = useState<string>(
    item.value?.fromDate || currentFromDate
  );
  const [formattedToDate, setFormattedToDate] = useState<string>(
    item.value?.toDate || currentToDate
  );

  useEffect(() => {
    applyValue({
      ...item,
      value: { fromDate: formattedFromDate, toDate: formattedToDate },
    });
  }, []);

  const onFromDateChange = (newValue: Dayjs | null) => {
    if (!newValue || !newValue.isValid()) return;

    const fromDate = newValue.format(fromDateFormat);
    setFormattedFromDate(fromDate);

    // Reset toDate only if fromDate is later than toDate
    if (dayjs(fromDate).isAfter(dayjs(formattedToDate))) {
      const toDate = newValue.format(toDateFormat);
      setFormattedToDate(toDate);
      applyValue({
        ...item,
        value: { fromDate: fromDate, toDate: toDate },
      });
    } else {
      applyValue({
        ...item,
        value: { fromDate: fromDate, toDate: formattedToDate },
      });
    }
  };

  const onToDateChange = (newValue: Dayjs | null) => {
    if (!newValue || !newValue.isValid()) return;

    const toDate = newValue.format(toDateFormat);
    setFormattedToDate(toDate);
    applyValue({
      ...item,
      value: { fromDate: formattedFromDate, toDate: toDate },
    });
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="From"
          inputFormat={dateFormatForDatePicker}
          value={dayjs(formattedFromDate)}
          onChange={onFromDateChange}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ margin: 5 }}
              onKeyDown={(e) => e.preventDefault()}
            />
          )}
        />
        <DatePicker
          label="To"
          inputFormat={dateFormatForDatePicker}
          value={dayjs(formattedToDate)}
          onChange={onToDateChange}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ margin: 5 }}
              onKeyDown={(e) => e.preventDefault()}
            />
          )}
          minDate={dayjs(formattedFromDate)}
        />
      </LocalizationProvider>
    </div>
  );
}

GridFilterInputDateRange.propTypes = OriginalInputComponent.propTypes;

export default GridFilterInputDateRange;
