import { gql, useQuery } from "@apollo/client";
import {
  GridColDef,
  GridFilterItem,
  GridFilterOperator,
} from "@mui/x-data-grid-pro";
import { GridFilterInputMultipleSingleSelect } from "../../components/GridFilterInputMultipleSelect";
import columnsToNotLoad from "./columnsToNotLoad.json";
import filtersToNotLoad from "./filtersToNotLoad.json";
import GridFilterInputDateRange from "../../components/GridFilterInputDateRange";

interface TableMetaColumn {
  field: string;
  label: string;
  type: string;
  dynamic: boolean;
  visible: boolean;
}

export interface TableMetaFilter {
  source: string;
  options: string[];
}

export interface TableMetaFilters {
  rules: TableMetaFilter[];
  title: string;
}
export interface ExtendedGridColDef extends GridColDef {
  dynamic: boolean;
}
interface TableMetaInterface {
  columns: ExtendedGridColDef[];
  filters: TableMetaFilters[];
  comment_id_column?: string;
}

export interface TableView {
  key: string;
  value: any;
}
export interface UserSettingsInterface {
  views: TableView[];
}

export interface TableMetaResponseInterface {
  meta: {
    columns: TableMetaColumn[];
    filters: TableMetaFilters[];
    comment_id_column?: string;
  };
}

interface ColumnFilterValuesResponseInterface {
  valueSet: {
    valueSet: string[];
  };
}

export interface EditableFieldOption {
  label: string;
  id: string;
  transitions: string[];
}

export interface TableEditableField {
  field: string;
  options: EditableFieldOption[];
}

export interface TableFieldOptions {
  hits: TableEditableField[];
}

export interface TableEditableFieldData {
  statusOptions: TableFieldOptions;
}

const columnsQuery = gql`
  query ($table: TableName!) {
    meta(table: $table) {
      comment_id_column
      columns {
        field
        label
        visible
        dynamic
        type
      }
      filters {
        rules {
          options
          source
        }
        title
      }
    }
  }
`;

const editableFieldOptionsQuery = gql`
  query ($table: ForeignTableType!) {
    statusOptions: statusOptions(table: $table) {
      hits {
        field
        options {
          label: name
          id: code
          transitions
        }
      }
    }
  }
`;

const revenueEstimatesByQuarterColumnsQuery = gql`
  query ($table: TableName!, $externalPropertyId: [String]!) {
    meta(table: $table, only: { external_property_id: $externalPropertyId }) {
      comment_id_column
      columns {
        field
        label
        visible
        dynamic
        type
      }
      filters {
        rules {
          options
          source
        }
        title
      }
    }
  }
`;

export const userSettingsQuery = gql`
  query ($query: KibanaQL!) {
    userSettings(search: { query: $query }) {
      hits {
        key
        value
      }
    }
  }
`;

const columnFilterValuesQuery = gql`
  query ($table: TableName!, $column: String!) {
    valueSet(table: $table, column: $column) {
      valueSet {
        label
        value
      }
    }
  }
`;

const removeDuplicates = (values: string[]) => {
  return Array.from(new Set(values));
};

const getValueOptions = (table: string, field: string) => {
  const { data, error } = useQuery<ColumnFilterValuesResponseInterface>(
    columnFilterValuesQuery,
    {
      variables: {
        table: table,
        column: field,
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-and-network",
    }
  );

  if (error) {
    return ["No Options"];
  } else if (data) {
    if (
      data.valueSet &&
      data.valueSet.valueSet &&
      data.valueSet.valueSet.length > 0
    ) {
      let values = data.valueSet.valueSet;

      // Checking if the field is one of the specified fields in Miami-Dade County, then remove any duplicate valueSet values. Otherwise return whatever valueSet is received as it is.
      if (
        field === "violation_type" ||
        field === "civil_violation_notice_issued" ||
        field === "courtesy_warning_notice_issued"
      ) {
        values = removeDuplicates(values);
      }

      return values;
    } else {
      return ["No Options"];
    }
  } else {
    // used to indicate that the values are loading...
    return [];
  }
};

export const getEditableFieldOptions = (
  table: string
): TableEditableField[] => {
  const { data } = useQuery<TableEditableFieldData>(editableFieldOptionsQuery, {
    variables: {
      table: table,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-and-network",
  });
  return data?.statusOptions?.hits || [];
};

const isColumnFilterable = (
  table: string,
  column: TableMetaColumn
): boolean => {
  let filterable = true;

  Object.entries(filtersToNotLoad).forEach(([tableName, filterNames]) => {
    if (
      table === tableName &&
      filterNames.length > 0 &&
      filterNames.indexOf(column.field) > -1
    ) {
      filterable = false;
    }
  });

  return filterable;
};

export const getTableMeta = (table: string): TableMetaInterface => {
  const { data, error } = useQuery<TableMetaResponseInterface>(columnsQuery, {
    variables: { table: table },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-and-network",
  });

  if (error || !data) {
    return { columns: [], filters: [], comment_id_column: undefined };
  }

  const { columns: metaColumns, filters, comment_id_column } = data.meta;

  const columns: ExtendedGridColDef[] = metaColumns
    .filter((column: TableMetaColumn) => shouldLoadColumn(table, column.field))
    .map((column: TableMetaColumn) => ({
      field: column.field,
      headerName: column.label,
      hide: !column.visible,
      type: "singleSelect",
      width: column.label.length * 10,
      dynamic: column.dynamic,
      filterOperators: getColumnOperator(table, column.field),
      fieldType: column.type,
      valueOptions: (params: any) => getValueOptions(table, params.field),
      filterable: isColumnFilterable(table, column),
    }));

  localStorage.removeItem("isUserPreferenceAvailable");

  return { columns, filters, comment_id_column };
};

export const getTableMetaForRevenueEstimateByQuarter = (
  table: string,
  external_property_id: string
): TableMetaInterface => {
  const { data, error } = useQuery<TableMetaResponseInterface>(
    revenueEstimatesByQuarterColumnsQuery,
    {
      variables: { table: table, externalPropertyId: [external_property_id] },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-and-network",
    }
  );

  if (error || !data) {
    return { columns: [], filters: [], comment_id_column: undefined };
  }

  const { columns: metaColumns, filters, comment_id_column } = data.meta;

  const columns: ExtendedGridColDef[] = metaColumns
    .filter((column: TableMetaColumn) => shouldLoadColumn(table, column.field))
    .map((column: TableMetaColumn) => ({
      field: column.field,
      headerName: column.label,
      hide: !column.visible,
      type: "singleSelect",
      width: column.label.length * 10,
      dynamic: column.dynamic,
      filterOperators: getColumnOperator(table, column.field),
      fieldType: column.type,
      valueOptions: (params: any) => getValueOptions(table, params.field),
      filterable: isColumnFilterable(table, column),
    }));

  localStorage.removeItem("isUserPreferenceAvailable");

  return { columns, filters, comment_id_column };
};

const shouldLoadColumn = (table: string, field: string): boolean => {
  for (const [tableName, columnsToExclude] of Object.entries(
    columnsToNotLoad
  )) {
    if (tableName === table && columnsToExclude.includes(field)) {
      return false;
    }
  }
  return true;
};

const operator: Record<string, GridFilterOperator> = {
  isAnyOf: {
    label: "is any of",
    value: "isAnyOf",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length === 0) {
        return null;
      }
      const collator = new Intl.Collator(undefined, {
        sensitivity: "base",
        usage: "search",
      });
      return ({ value }): boolean =>
        value != null
          ? filterItem.value.some((filterValue: GridFilterItem["value"]) => {
              return (
                collator.compare(filterValue, value.toString() || "") === 0
              );
            })
          : false;
    },
    InputComponent: GridFilterInputMultipleSingleSelect,
  },
  isBetweenDates: {
    label: "is between dates",
    value: "isBetweenDates",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      const [startDate, endDate] = filterItem.value as [Date, Date];
      return ({ value }): boolean => {
        if (value == null) return false;
        const dateValue = new Date(value.toString());
        return dateValue >= startDate && dateValue <= endDate;
      };
    },
    InputComponent: GridFilterInputDateRange,
  },
};

const getColumnOperator = (table: string, field: string) => {
  const isBetweenDateColumns = [
    "letter_date",
    "created_before_date_actual",
    "last_documented_stay",
    "first_identified_date_actual",
    "next_available_date",
    "last_reposted_actual",
    "period_start",
    "period_end",
    "payment_datetime",
    "date_approved",
    "time_approved",
    "payout_date",
    "payout_time",
    "call_date_time",
    "created_at",
    "internal_date_last_found_actual",
    "screenshot_last_captured_actual",
    "last_review_date",
    "internal_date_first_found_actual",
    "removed_date_actual",
    "last_resposted_actual",
    "last_actively_managed",
    "created_date",
    "updated_date",
    "approved_or_denied_time",
    "expiry_date",
    "last_payment_time_approved",
    "updated_at",
    "lob_status_date",
    "last_updated",
    "status_change_date",
    "payment_date",
    "refund_date",
    "last_payment_time",
    "stripe_available_on",
  ];

  if (table === "invoices" && field === "payment_datetime") {
    return [operator.isAnyOf];
  }

  if (isBetweenDateColumns.includes(field)) {
    return [operator.isBetweenDates];
  }

  return [operator.isAnyOf];
};
