import {
  Button,
  ButtonUnstyled,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

import "./DialogComponent.scss";

const defaultConfirmDialogContent = `Click “continue editing” to retrieve and submit your changes. If you no longer wish to continue editing click “discard changes".`;
const defaultInfoText =
  "You have unsaved changes. If you exit, any text you’ve inputted will be lost.";

const defaultTitle = "Unsaved Changes";
const defaultActionButtonLabel = "Continue editing";
interface DialogProps {
  title?: string;
  content?: string;
  contentInfoText?: string;
  open: boolean;
  handleClose: () => void;
  onDiscard: any;
  actionButtonLabel?: string;
}

const DialogComponent = (props: DialogProps) => {
  const {
    title,
    content,
    contentInfoText,
    open,
    handleClose,
    onDiscard,
    actionButtonLabel,
  } = props;
  return (
    <Dialog open={open} disableEscapeKeyDown={true}>
      <DialogTitle className="dialogTitle">{title || defaultTitle}</DialogTitle>
      <DialogContent className="dialogContentMain">
        <div className="dialogContentInfo">
          {contentInfoText || defaultInfoText}
        </div>
        <div className="dialogContent">
          {content || defaultConfirmDialogContent}
        </div>
      </DialogContent>
      <DialogActions className="dialogActions">
        <Button
          className="continueButton"
          variant="contained"
          onClick={handleClose}
        >
          {actionButtonLabel || defaultActionButtonLabel}
        </Button>

        <ButtonUnstyled
          className="discardButton"
          onClick={() => {
            handleClose;
            onDiscard();
          }}
        >
          Discard Changes
        </ButtonUnstyled>
      </DialogActions>
    </Dialog>
  );
};

export default DialogComponent;
