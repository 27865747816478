import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation, gql } from "@apollo/client";
import PasswordChecklist from "react-password-checklist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faClose } from "@fortawesome/free-solid-svg-icons";
import "./ChangePasswordModal.scss";

interface ChangePasswordModalProps {
  open: boolean;
  closeModal: () => void;
  signout: () => void;
}

const SEND_CHANGE_PASSWORD_EMAIL = gql`
  mutation {
    sendChangePasswordEmail
  }
`;

const CHANGE_PASSWORD = gql`
  mutation ($password: String!, $otp: String!) {
    changePassword(password: $password, otp: $otp)
  }
`;

export const ChangePasswordModal = (props: ChangePasswordModalProps) => {
  const [loading, setLoading] = useState(false);
  const [mutateSendChangePasswordEmail] = useMutation(
    SEND_CHANGE_PASSWORD_EMAIL
  );
  const [mutateChangePassword] = useMutation(CHANGE_PASSWORD);
  const [showOTPField, setShowOTPField] = useState(false);
  const [password, setPassword] = useState("");
  const [confrimPassword, setConfirmPassword] = useState("");
  const [otp, setOTP] = useState("");
  const [resend, setResend] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [signOutCount, setSignOutCount] = useState<number | any>();
  const [enableSendButton, setEnableSendButton] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const requestChangePassword = async () => {
    setLoading(true);
    try {
      await mutateSendChangePasswordEmail({ variables: {} });
      setShowOTPField(true);
      setLoading(false);
      setSeconds(120);
      setResend(false);
      setErrorMessage("");
      setShowError(false);
    } catch (e) {
      setLoading(false);
      setErrorMessage(
        "Failed to send the code ,  Please try again after sometime."
      );
      setShowError(true);
    }
  };
  var timer: any;

  useEffect(() => {
    if (seconds > 0) {
      timer = setTimeout(() => setSeconds(seconds - 1), 1000);
    } else if (seconds === 0) {
      setResend(true);
    }
  }, [seconds]);

  useEffect(() => {
    if (signOutCount > 0) {
      setTimeout(() => setSignOutCount(signOutCount - 1), 1000);
    } else if (signOutCount === 0) {
      props.signout();
    }
  }, [signOutCount]);

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setLoading(true);
    try {
      let responseMessage = await mutateChangePassword({
        variables: {
          password: password,
          otp: otp,
        },
      });
      if (responseMessage?.data?.changePassword === "success") {
        setShowSuccessMessage(true);
        setSignOutCount(10);
      } else {
        setShowError(true);
        setErrorMessage(
          "Failed to change the password ,  Please try again after sometime."
        );
      }
    } catch (e) {
      let message;
      if (e instanceof Error) {
        message = e.message;
        if (message === "Invalid OTP") {
          setErrorMessage(
            "Invalid code. Please enter the correct verification code"
          );
        } else if (message.indexOf("PasswordHistoryError") > -1) {
          setErrorMessage(
            "Password has previously been used . Please go to previous step and submit a new password."
          );
        }
      } else {
        setErrorMessage(
          "Failed to change the password ,  Please try again after sometime."
        );
      }

      setShowError(true);
    }
    setLoading(false);
  };

  const handleInputChange = (event: any) => {
    event.preventDefault();
    switch (event.target.name) {
      case "password":
        setPassword(event.target.value);
        break;
      case "confirm_password":
        setConfirmPassword(event.target.value);
        break;
      case "otp":
        setOTP(event.target.value);
        break;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleDialogClose = (e: any, reason: any) => {
    if (reason !== "backdropClick") {
      handleClose();
    }
  };

  const handleClose = () => {
    setConfirmPassword("");
    setPassword("");
    setOTP("");
    setShowSuccessMessage(false);
    setShowOTPField(false);
    setResend(false);
    setShowError(false);
    setErrorMessage("");
    clearTimeout(timer);
    setLoading(false);
    props.closeModal();
  };

  const handleBack = () => {
    setShowOTPField(false);
    setShowError(false);
    setErrorMessage("");
    clearTimeout(timer);
    setLoading(false);
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleDialogClose}
      className={"change-password-modal modal"}
    >
      <Box className="change-password-header">
        {!showSuccessMessage && (
          <FontAwesomeIcon
            style={{ position: "absolute", top: "15px", right: "10px" }}
            className="closeButton"
            icon={faClose}
            onClick={handleClose}
          />
        )}
        {showOTPField && !showSuccessMessage && (
          <FontAwesomeIcon
            style={{
              position: "absolute",
              top: "15px",
              left: "5px",
              fontSize: "16px",
            }}
            className="closeButton"
            icon={faAngleLeft}
            onClick={() => handleBack()}
          />
        )}
        {!showSuccessMessage && (
          <Typography variant="h6" align="center" sx={{ color: "#000" }}>
            Change Password
          </Typography>
        )}
        {showSuccessMessage && (
          <Typography variant="h6" align="center">
            Changed Successfully!
          </Typography>
        )}
      </Box>
      <Box className={"change-password-container"}>
        {!showSuccessMessage && (
          <Box component="form" sx={{ mt: 1 }}>
            {showOTPField && (
              <>
                <Typography variant="body1">
                  Enter the 6-digit OTP that has been sent to your registered
                  email
                </Typography>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="otp"
                  label="OTP"
                  type="password"
                  id="otp"
                  onChange={handleInputChange}
                />
              </>
            )}
            {!showOTPField && (
              <>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="New Password"
                  type="password"
                  id="password"
                  value={password}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="confirm_password"
                  label="Confirm New Password"
                  type="password"
                  id="confirm_password"
                  value={confrimPassword}
                  onChange={handleInputChange}
                />
              </>
            )}

            <DialogActions sx={{ padding: 0 }}>
              {!showOTPField && (
                <LoadingButton
                  className={"SuccessButton"}
                  loading={loading}
                  variant="contained"
                  onClick={() => requestChangePassword()}
                  disabled={!enableSendButton}
                  sx={{ margin: "20px 0 !important", width: "100% !important" }}
                >
                  {loading ? "" : "Send Verification Code"}
                </LoadingButton>
              )}
              {showOTPField && (
                <>
                  {!resend && (
                    <span style={{ fontSize: "0.8em" }}>
                      {seconds}s remaining
                    </span>
                  )}
                  <LoadingButton
                    className={"SuccessButton"}
                    loading={loading}
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={!resend}
                    onClick={() => requestChangePassword()}
                    sx={{
                      margin: "20px 20px !important",
                      width: "30% !important",
                    }}
                  >
                    {loading ? "" : "Resend"}
                  </LoadingButton>
                  <LoadingButton
                    className={"SuccessButton"}
                    loading={loading}
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={!otp}
                    onClick={(event) => handleSubmit(event)}
                    sx={{
                      margin: "20px 0 20px 20px !important",
                      width: "30% !important",
                    }}
                  >
                    {loading ? "" : "Submit"}
                  </LoadingButton>
                </>
              )}
            </DialogActions>
            {showError && (
              <>
                <Typography
                  variant="body1"
                  style={{ color: "#ff0000", fontSize: "0.8em" }}
                >
                  {errorMessage}
                </Typography>
              </>
            )}
            {!showOTPField && password && (
              <PasswordChecklist
                rules={["minLength", "number", "capital", "match"]}
                minLength={8}
                value={password}
                valueAgain={confrimPassword}
                onChange={(isValid) => {
                  setEnableSendButton(isValid);
                }}
                style={{ fontSize: "0.8em" }}
              />
            )}
          </Box>
        )}
        {showSuccessMessage && (
          <Typography
            variant="body1"
            sx={{ color: "#003258", margin: "20px auto" }}
          >
            Logging out in {signOutCount}s <br></br>
            Please login with your new password
          </Typography>
        )}
      </Box>
    </Dialog>
  );
};
