import React, { useContext } from "react";
import { Controller } from "react-hook-form";
import { Typography, TextField, Box, Paper, Button } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { EsriContext } from "../context/EsriContext";
import styles from "./AuthPage.module.scss";
import { loader } from "graphql.macro";
import { createNotification, updateToken } from "../context/EsriContext";
import { useFormContext } from "react-hook-form";

const FETCH_AUTH_TOKEN = loader("../queries/fetchAuthToken.graphql");

const STEP_DESCRIPTION =
  "If authentication is required, please enter the required information below.";
const CHECK_CREDENTIALS_SUCCESS = "Successfuly connected to the account.";

const AuthPage: React.FC = () => {
  const { dispatch }: any = useContext(EsriContext);
  const { control, watch } = useFormContext();

  const [fetchAuthToken, { loading }] = useLazyQuery(FETCH_AUTH_TOKEN, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      dispatch(
        createNotification({
          message: CHECK_CREDENTIALS_SUCCESS,
          type: "success",
        })
      );
      dispatch(updateToken(data.fetchAuthToken));
    },
    onError(error) {
      dispatch(
        createNotification({
          message: error.message,
          type: "error",
        })
      );
    },
  });

  const checkProvidedCreds = () => {
    fetchAuthToken({
      variables: {
        username: watch("username"),
        password: watch("password"),
        endpoint: watch("token_endpoint"),
      },
    });
  };

  return (
    <Box sx={{ height: 600 }}>
      <Paper elevation={3} className={styles.Paper}>
        <Typography variant="subtitle1">{STEP_DESCRIPTION}</Typography>
      </Paper>
      <Box mt={5}>
        <>
          <Box>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  label="Token endpoint"
                  fullWidth
                />
              )}
              name="token_endpoint"
              control={control}
            />
          </Box>
          <Box className={styles.InputBox}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  label="Username"
                  sx={{ marginY: 1 }}
                />
              )}
              name="username"
              control={control}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  label="Password"
                  sx={{ marginY: 1 }}
                  type="password"
                />
              )}
              name="password"
              control={control}
            />
          </Box>
          <Box sx={{ marginTop: "20px" }} textAlign="center">
            <Button
              onClick={() => checkProvidedCreds()}
              disabled={
                !watch("username") ||
                !watch("password") ||
                !watch("token_endpoint") ||
                loading
              }
              variant="contained"
            >
              Check connection
            </Button>
          </Box>
        </>
      </Box>
    </Box>
  );
};

export default AuthPage;
