import React from "react";
import qs, { ParsedQs } from "qs";

interface KibanaIframeProps {
  url: string;
}

export default function KibanaIframe(props: KibanaIframeProps) {
  const queryParams: ParsedQs = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const decodeURL =
    queryParams.url != undefined
      ? decodeURIComponent(queryParams.url.toString())
      : "";
  const IframeURL = props.url ? props.url : decodeURL ? decodeURL : undefined;
  return (
    <iframe
      title=" "
      src={IframeURL}
      style={{
        height: "100%",
        width: "100%",
        border: 0,
      }}
    ></iframe>
  );
}
