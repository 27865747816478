
const dot = require('dot-object');
import React, { useState } from 'react';
import { Stack, Button, IconButton, FormControl, FormGroup, TextField, FormHelperText, Select, MenuItem, InputLabel } from '@mui/material';
import { useQuery, gql } from '@apollo/client';

const GET_PLATFORMS = gql`
  { platforms { hits { id name pattern placeholder } } }
`;

function View({ question, context }) {

  const defaultListing = { type: 'AIR', value: '' };
  const { data:platformsData } = useQuery(GET_PLATFORMS);

  //
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    output: context.pick('value') || [defaultListing]
  });

  const validate = (formValues) => {
    let tmp = { ...formErrors };

    console.warn(formValues, formErrors);

    // browser validation...
    // Object.keys(formValues).map(key=>tmp[key]=document.getElementById(key).validationMessage);

    // any additional validation...
    // nada

    setFormErrors(tmp);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let values = {...formValues};
    dot.str(name, value, values);
    setFormValues(values);
    validate({ [name]: value });
    context.assign({
      value: values.output
    });
  };

  //
  const getPlatformById = (type) => platformsData.platforms.hits.filter(o => o.id === type)[0];

  const addListing = () => {
    let output = [...formValues.output];
    output.push(defaultListing);
    setFormValues({ ...formValues, output: output });
  };

  const removeListing = (i) => {
    let output = [...formValues.output];
    output.splice(i, 1);
    setFormValues({ ...formValues, output: output });
  };

  return (
    <FormGroup>
      {platformsData && platformsData.platforms && Object.keys(platformsData.platforms.hits).length > 0 && formValues.output.map((l, i) => {
        return (
          <Stack direction={{ xs: 'column', sm: 'row' }} key={i}>
            <div style={{marginRight: '10px'}}>
              <IconButton variant="outlined" className="back" tabIndex={-1} onClick={()=>removeListing(i)}>
                <span style={{
                  width: '15px',
                  height: '15px',
                  fontSize: '15px',
                  fontWeight: 'bold'
                }}>X</span>
              </IconButton>
            </div>

            <FormControl fullWidth>
              <InputLabel id={`output.${i}-type-label`}>{question.properties.locale.type_placeholder}</InputLabel>
              <Select
                labelId={`output.${i}-type-label`}
                id={`output.${i}-type`}
                name={`output.${i}.type`}
                inputProps={{
                  inputref: `ref-${i}-type`
                }}
                value={formValues.output[i].type}
                onChange={handleInputChange}
                required
                readOnly={question.input.readonly}
                aria-describedby={`output.${i}-type-helper-text`}
              >
                {platformsData.platforms.hits.map(p=>{
                  return (
                    <MenuItem key={`output.${i}-type-option-${p.id}`} value={p.id}>{p.name}</MenuItem>
                  )
                })}
                <MenuItem value="OTHER">{question.properties.locale.other_placeholder}</MenuItem>
              </Select>
              <FormHelperText id={`output.${i}-type-helper-text`} style={{fontSize:'0.9rem'}}>{formErrors.text}</FormHelperText>
            </FormControl>

            <FormControl error fullWidth>
              <TextField
                id={`output.${i}-value`}
                name={`output.${i}.value`}
                label={formValues.output[i].type === 'OTHER' ? question.properties.locale.default_placeholder : `${getPlatformById(formValues.output[i].type).name} ${question.properties.locale.url_placeholder}`}
                placeholder={getPlatformById(formValues.output[i].type).placeholder}
                variant="filled"
                value={formValues.output[i].value}
                onChange={handleInputChange}
                required
                readOnly={question.input.readonly}
                autoFocus
                inputProps={{
                  maxLength: question.input.maxlength ? question.input.maxlength : 80,
                  inputref: `ref-${i}-value`,
                  pattern: getPlatformById(formValues.output[i].type).pattern
                }}
                autoComplete="off"
                aria-describedby={`output.${i}-value-helper-text`}
              />
              <FormHelperText id={`output.${i}-value-helper-text`} style={{fontSize:'0.9rem'}}>{formErrors.text}</FormHelperText>
            </FormControl>
          </Stack>
        )
      })}

      <nav dir={question.properties.locale.dir}>
        <Button variant="outlined" className="back" tabIndex={-1} onClick={addListing}>
          {question.properties.locale.add_listing}
        </Button>
      </nav>
    </FormGroup>
  );
}

export default View;
