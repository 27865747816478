import { FormLabel, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { GoogleMap, Marker } from "@react-google-maps/api";
import React, { ChangeEvent, useEffect, useState } from "react";
import { FormDataInterface } from "../Steps";

interface PinOnMapProps {
  setCoordinates: (position: { latitude: number; longitude: number }) => void;
  formData: FormDataInterface;
}

export const PinOnMap = ({ setCoordinates, formData }: PinOnMapProps) => {
  const [initialPosition] = useState<{ lat: number; lng: number }>({
    lat: formData.latitude,
    lng: formData.longitude,
  });

  const [position, setPosition] =
    useState<{ lat: number; lng: number }>(initialPosition);

  useEffect(() => {
    setCoordinates({ latitude: position.lat, longitude: position.lng });
  }, [position]);

  const [mapRef, setMapRef] = useState<any>();
  const handleOnLoad = (map: any) => {
    setMapRef(map);
  };

  const handleCenterChanged = () => {
    if (mapRef) {
      const newCenter = mapRef.getCenter();
      setPosition({ lat: newCenter.lat(), lng: newCenter.lng() });
    }
  };

  const handlePositionChange = (lat: number, lng: number) => {
    setPosition({ lat, lng });
    if (mapRef) {
      mapRef.setCenter({ lat: lat, lng: lng });
    }
  };

  return (
    <Box sx={{ margin: "10px 30px" }}>
      <FormLabel className="MainLabel">Set location on map</FormLabel>
      <GoogleMap
        center={initialPosition}
        zoom={17}
        mapContainerStyle={{ width: "100%", height: "300px", margin: "20px 0" }}
        onLoad={handleOnLoad}
        onCenterChanged={handleCenterChanged}
      >
        <Marker position={position} />
      </GoogleMap>
      <FormLabel className="MainLabel">Coordinates</FormLabel>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2, width: "100%" }}>
        <TextField
          id="latitude"
          label="Latitude"
          type="number"
          value={position.lat}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handlePositionChange(Number(event.target.value), position.lng)
          }
        />
        <TextField
          id="longitude"
          label="Longitude"
          type="number"
          sx={{ marginLeft: "10px" }}
          value={position.lng}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handlePositionChange(position.lat, Number(event.target.value))
          }
        />
      </Box>
    </Box>
  );
};
