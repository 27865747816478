import { gql } from "@apollo/client";

export const COMMENTS_QUERY = `
  query ($q: KibanaQL, $size: Int, $from: Int) {
    comments(search: { query: $q, size: $size, from: $from }) {
      size
      total
      hits {
        id
        body
        created_at
        author {
          id
          name
          username
          email
        }
        upload {
          files {
            id
            original_filename
            uploaded_at
            url
          }
        }
      }
    }
  }
`;

export const ADD_COMMENTS_MUTATION = gql`
  mutation ($item: NewComment!) {
    createComment(item: $item) {
      id
      body
      created_at
      author {
        id
        name
        username
        email
      }
      upload {
        id
        files {
          id
          original_filename
          uploaded_at
          url
        }
      }
    }
  }
`;

export const UPLOAD_FILE_MUTATION = gql`
  mutation ($upload_id: ID!, $item: NewFile!) {
    updateUploadNewFile(upload_id: $upload_id, item: $item) {
      id
      original_filename
      url
      previews {
        mimetype
        url
      }
      put_content_url
    }
  }
`;

export const REMOVE_FILE_MUTATION = gql`
  mutation ($upload_id: ID!, $file_id: ID!) {
    updateUploadRemoveFile(upload_id: $upload_id, file_id: $file_id) {
      id
      original_filename
      url
      previews {
        mimetype
        url
      }
      put_content_url
    }
  }
`;

export const CREATE_UPLOAD_MUTATION = gql`
  mutation createUploadMutation {
    createUpload(item: { foreign_type: COMMENT }) {
      id
    }
  }
`;
