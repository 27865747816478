import React from "react";

import { gql } from "@apollo/client";
import { GridColDef } from "@mui/x-data-grid-pro";

import SearchView from "../../components/SearchView/SearchView";
import {
  ExtendedGridColDef,
  getEditableFieldOptions,
  getTableMeta,
} from "../common/data-grid-cols";
import "./RentalUnitsPage.scss";

const RentalUnitsPage = () => {
  const { columns, filters, comment_id_column } = getTableMeta("properties");
  const table = "rental_unit";
  const editableFieldOptions = getEditableFieldOptions(table);

  const rentalUnitsQuery = (
    columnsToFilter: ExtendedGridColDef[],
    commentsIdColumn?: string
  ) => {
    const queryFields = columnsToFilter
      .filter((column) => !column.hide && !column.dynamic)
      .map((column) => {
        if (column.field === "add_view_comments_modal") {
          return `add_view_comments_modal {
            unread,
            count,
            total,
            foreign_id,
            foreign_type
          }`;
        } else {
          return column.field;
        }
      })
      .join("\n");

    return gql`
      query(
        $q: KibanaQL=""
        $size: Int
        $phrase: String
        $from: Int
        $sort: String
      ) {
        results: rental_unit_tables(
          search: { query: $q, phrase: $phrase, size: $size, from: $from, sort: $sort }
        ) {
          size
          total
          hits {
            id: external_property_id
            extra
            ${commentsIdColumn}
            ${queryFields}
          }
        }
      }
    `;
  };

  const renderColumn = (column: ExtendedGridColDef): GridColDef => {
    return { ...column };
  };

  return (
    <SearchView
      searchQuery={rentalUnitsQuery}
      columns={columns.map((column: ExtendedGridColDef) =>
        renderColumn(column)
      )}
      filters={filters}
      commentIdColumn={comment_id_column}
      title="Rental Units"
      editableFieldOptions={editableFieldOptions}
      tableType={table}
    />
  );
};

export default RentalUnitsPage;
