import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";

const INTRODUCTION_TEXT = "Get started with Esri";
const STEP_DESCRIPTION =
  "Integration of a jurisdiction's Esri data will build a seamless connection between assessor data and Host Compliance modules. This process ensures accurate data sharing between parties by mapping unique Esri data fields from a local assessor file to applicable fields within the Host Compliance application.";

const WelcomePage: React.FC = () => {
  return (
    <Box sx={{ height: 600 }}>
      <Paper elevation={3} sx={{ padding: "30px 30px" }}>
        <Typography variant="h5" sx={{ marginBottom: "30px" }}>
          {INTRODUCTION_TEXT}
        </Typography>
        <Typography variant="subtitle1">{STEP_DESCRIPTION}</Typography>
      </Paper>
    </Box>
  );
};

export default WelcomePage;
