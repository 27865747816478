import React, { useEffect, useState } from "react";
import ModalComponent from "../../../../components/ModalComponent/ModalComponent";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

interface RefundModalCellProps {
  rowParams: any;
  title: string;
  cellText: string;
  children: any;
  geoid: any;
}

export const RefundModalCell = (cellProps: RefundModalCellProps) => {
  const [width, setWidth] = useState<string>("55%");
  const [open, setOpen] = useState<boolean>(false);
  const rowParams = cellProps.rowParams;
  const [showRefundModal, setShowRefundModal] = useState<boolean>(false);
  const geoid = cellProps.geoid;

  useEffect(() => {
    if (geoid) {
      if (
        rowParams.row &&
        "tax" in rowParams.row &&
        rowParams.row.tax !== null &&
        rowParams.row.tax !== undefined &&
        rowParams.row.payment_datetime &&
        rowParams.row.payment_type &&
        rowParams.row.status &&
        rowParams.row.id &&
        geoid
      ) {
        let tax = rowParams.row.tax;
        if (typeof tax !== "string") {
          tax = parseFloat(tax).toFixed(2);
        } else {
          tax = parseFloat(tax.replace(/[$]/g, "").replace(/,/g, ""));
        }

        const status = rowParams.row.status;
        const paymentType = rowParams.row.payment_type;
        const paymentDatetime = rowParams.row.payment_datetime;
        const now = dayjs.utc(); // Current date and time in UTC
        const payedOn = dayjs.utc(paymentDatetime); // Parse the paymentDatetime as UTC

        const canBeRefunded =
          paymentDatetime &&
          (now.diff(payedOn, "day") < 90 || geoid == "16000US0644000");
        if (
          !canBeRefunded ||
          paymentType == "partial_refund" ||
          status == "returned" ||
          paymentType == "full_refund"
        ) {
          setShowRefundModal(false);
        } else {
          setShowRefundModal(true);
        }
      } else {
        setShowRefundModal(false);
      }
    }
  }, [geoid]);

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      {!showRefundModal && <p>N/A</p>}
      {showRefundModal && (
        <>
          <a
            onClick={() => setOpen(true)}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {cellProps.cellText}
          </a>
        </>
      )}
      <ModalComponent
        header={cellProps.title}
        open={open}
        onClose={() => {
          closeModal();
        }}
        width={width}
        sx={{
          margin: "auto",
        }}
      >
        {React.cloneElement(cellProps.children, {
          closeModal: closeModal,
          setModalWidth: setWidth,
        })}
      </ModalComponent>
    </>
  );
};
