const dot = require("dot-object");
import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { namedArray } from "./util";

function View({ question, context }) {
  const [formValidations, setFormValidations] = useState({});
  const [formValues, setFormValues] = useState({
    output: context.pick("value") || "",
    search: "",
  });
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    filter(""); // start with an empty filter
  }, [question]);

  const validate = (target, groupIndex) => {
    let temp = [...formValidations];

    if (
      (groups[groupIndex].max || groups[groupIndex].min) &&
      target.type === "checkbox"
    ) {
      const count = target.checked
        ? temp[groupIndex].count + 1
        : temp[groupIndex].count - 1;
      const isMinValid = groups[groupIndex].min
        ? count >= groups[groupIndex].min
        : temp[groupIndex].isMinValid;
      const isMaxValid = groups[groupIndex].max
        ? count <= groups[groupIndex].max
        : temp[groupIndex].isMaxValid;
      temp[groupIndex] = { ...temp[groupIndex], count, isMinValid, isMaxValid };
    } else if (target.type === "checkbox") {
      const isValid = target.checked;
      temp[groupIndex] = { ...temp[groupIndex], isValid };
    }
    setFormValidations(temp);
    return temp.every(
      (validation) =>
        validation.isValid || (validation.isMaxValid && validation.isMinValid)
    );
  };

  const filter = (query) => {
    const groups = question.input.groups.map((group) => {
      group.items = namedArray(group).filter((item) => {
        item.locale = question.properties.locale[item.locale_key];
        item.locale_more = question.properties.locale[item.locale_key_more];
        return JSON.stringify(item).indexOf(query) > -1;
      });
      return group;
    });

    let groupValidations = groups.map((group) => {
      if (group.min || group.max) {
        return {
          minMessage:
            question.properties.locale.validation_minimum + " " + group.min,
          maxMessage:
            question.properties.locale.validation_maximum + " " + group.max,
          count: 0,
          isMinValid: false,
          isMaxValid: true,
        };
      } else if (group.items[0].required) {
        return {
          message: question.properties.locale.validation_required,
          isValid: false,
        };
      }
    });

    if (groupValidations.length > 0) {
      context.assign({
        canSubmit: false,
      });
    }
    setFormValidations(groupValidations);
    setGroups(groups);
  };

  const handleInputChange = (e, groupIndex) => {
    const { name } = e.target;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (name === "search") filter(value);

    let values = { ...formValues };
    dot.str(name, value, values);

    if (question.input.filtered === true) {
      Object.keys(values.output).forEach((k) => {
        if (values.output[k] === false) {
          delete values.output[k];
        }
      });
    }

    setFormValues(values);
    let canSubmit = validate(e.target, groupIndex);
    context.assign({
      value: values.output,
      canSubmit: canSubmit,
    });
  };

  return (
    <div
      dir={question.properties.locale.dir}
      className="inputs inputs-check-list"
    >
      {question.input.searchable && (
        <FormControl error fullWidth>
          <TextField
            id="searchable"
            name="search"
            label={question.properties.locale.searchable}
            variant="filled"
            value={formValues.search}
            onChange={handleInputChange}
            autoFocus
            inputProps={{
              inputref: "ref-searchable",
            }}
            autoComplete="off"
            aria-describedby="searchable-helper-text"
          />
        </FormControl>
      )}

      {groups.map((group, index) => {
        return (
          <FormGroup key={index}>
            <h2>{question.properties.locale[group.locale_key]}</h2>
            {group.min && !formValidations[index].isMinValid && (
              <FormHelperText error className="error-message">
                {formValidations[index].minMessage}
              </FormHelperText>
            )}
            {group.max && !formValidations[index].isMaxValid && (
              <FormHelperText error className="error-message">
                {formValidations[index].maxMessage}
              </FormHelperText>
            )}
            {group.items.map((item) => {
              return (
                <FormControlLabel
                  key={item.id}
                  label={
                    <React.Fragment>
                      <h3>{question.properties.locale[item.locale_key]}</h3>
                      {item.locale_key_more && (
                        <p>
                          {question.properties.locale[item.locale_key_more]}
                        </p>
                      )}
                    </React.Fragment>
                  }
                  control={
                    <Checkbox
                      id={`ref-${item.id}`}
                      name={`output.${item.id}`}
                      required={question.input.required}
                      readOnly={question.input.readonly}
                      value={true}
                      inputProps={{
                        maxLength: question.input.maxlength
                          ? question.input.maxlength
                          : 80,
                        inputref: `ref-${item.id}`,
                      }}
                      onChange={(e) => handleInputChange(e, index)}
                      sx={{
                        color: "#0000008a",
                        "&.Mui-checked": {
                          color: "#ff5240",
                        },
                      }}
                    />
                  }
                />
              );
            })}
            {!formValidations[index].isValid && (
              <FormHelperText error className="error-message">
                {formValidations[index].message}
              </FormHelperText>
            )}
          </FormGroup>
        );
      })}
    </div>
  );
}

export default View;
