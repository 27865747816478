import { Badge, IconButton } from "@mui/material";
import React, { useState } from "react";
import CommentIcon from "@mui/icons-material/Comment";

import "./CommentsDrawer.scss";
import CommentsDrawer from "./CommentsDrawer";

const CommentCellRenderer = ({ params }: any) => {
  const commentsJson = params.value;
  const [commentsModalOpen, setCommentsModalOpen] = useState<boolean>(false);
  const [recentCommentsCount, setRecentCommentsCount] = useState<number>(
    commentsJson?.unread || 0
  );
  const [totalComment, setTotalComment] = useState<number>(commentsJson?.total);
  return (
    <>
      <div className="comments">
        <div>
          <IconButton onClick={() => setCommentsModalOpen(true)}>
            <Badge badgeContent={recentCommentsCount}>
              <CommentIcon color="action" />
            </Badge>
          </IconButton>{" "}
          {totalComment}
        </div>
      </div>

      <CommentsDrawer
        params={params}
        setTotalComment={setTotalComment}
        commentsModalOpen={commentsModalOpen}
        setCommentsModalOpen={setCommentsModalOpen}
        setRecentCommentsCount={setRecentCommentsCount}
        recentCommentsCount={recentCommentsCount}
        totalComment={totalComment}
      />
    </>
  );
};

export default CommentCellRenderer;
