import React, { useReducer, createContext, useMemo } from "react";
import { EsriContext as EsriContextType } from "../interfaces";

const ACTIONS = {
  DELETE_ENTIRE_CONFIGURATION: "DELETE_ENTIRE_CONFIGURATION",
  UPDATE_AUTHENTICATION: "UPDATE_AUTHENTICATION",
  UPDATE_ENDPOINTS: "UPDATE_ENDPOINTS",
  UPDATE_MAPPINGS: "UPDATE_MAPPINGS",
  LOAD_CONFIG: "LOAD_CONFIG",
  DELETE_ESRI_CONFIG: "DELETE_ESRI_CONFIG",
  ADD_ENDPOINT_FIELDS: "ADD_ENDPOINT_FIELDS",
  CREATE_NOTIFICATION: "CREATE_NOTIFICATION",
  REMOVE_NOTIFICATION: "REMOVE_NOTIFICATION",
  UPDATE_TOKEN: "UPDATE_TOKEN",
};

export const updateToken = (payload: any) => ({
  type: ACTIONS.UPDATE_TOKEN,
  payload,
});

export const removeNotification = () => ({
  type: ACTIONS.REMOVE_NOTIFICATION,
});

export const createNotification = (payload: any) => ({
  type: ACTIONS.CREATE_NOTIFICATION,
  payload,
});

export const updateAuthentication = (payload: any) => ({
  type: ACTIONS.UPDATE_AUTHENTICATION,
  payload,
});

export const updateEndpoints = (payload: any) => ({
  type: ACTIONS.UPDATE_ENDPOINTS,
  payload,
});

export const updateMappings = (payload: any) => ({
  type: ACTIONS.UPDATE_MAPPINGS,
  payload,
});

export const loadConfig = (payload: any) => ({
  type: ACTIONS.LOAD_CONFIG,
  payload,
});

export const _deleteEsriConfig = () => ({
  type: ACTIONS.DELETE_ESRI_CONFIG,
});

export const addEndpointFields = (payload: any) => ({
  type: ACTIONS.ADD_ENDPOINT_FIELDS,
  payload,
});

const initialState = {
  esriConfig: {
    id: null,
    username: "",
    password: "",
    authentication: false,
    token_endpoint: "",
    data_endpoints: [],
    mappings: {
      property_id: {
        id: "",
        field: [],
      },
      street_address: {
        id: "",
        field: [],
      },
      unit_number: {
        id: "",
        field: [],
      },
      locality: {
        id: "",
        field: [],
      },
      state: {
        id: "",
        field: [],
      },
      postal_code: {
        id: "",
        field: [],
      },
      country: {
        id: "",
        field: [],
      },
      has_pool: {
        id: "",
        field: [],
      },
      has_elevator: {
        id: "",
        field: [],
      },
      owner_name_1: {
        id: "",
        field: [],
      },
      owner_name_2: {
        id: "",
        field: [],
      },
      owner_address_line_1: {
        id: "",
        field: [],
      },
      owner_address_line_2: {
        id: "",
        field: [],
      },
      owner_address_line_3: {
        id: "",
        field: [],
      },
      bedrooms: {
        id: "",
        field: [],
      },
      bathrooms: {
        id: "",
        field: [],
      },
      units: {
        id: "",
        field: [],
      },
      stories: {
        id: "",
        field: [],
      },
      provided_use_code: {
        id: "",
        field: [],
      },
      provided_use_name: {
        id: "",
        field: [],
      },
      latitude: {
        id: "",
        field: [],
      },
      longitude: {
        id: "",
        field: [],
      },
      str_policy: {
        id: "",
        field: [],
      },
      homestead_exemption: {
        id: "",
        field: [],
      },
      community: {
        id: "",
        field: [],
      },
      vacation_rental_district: {
        id: "",
        field: [],
      },
      code_enforcement_area_name: {
        id: "",
        field: [],
      },
      code_officer: {
        id: "",
        field: [],
      },
      route_post_directional: {
        id: "",
        field: [],
      },
      route_post_type: {
        id: "",
        field: [],
      },
      route_name: {
        id: "",
        field: [],
      },
      street_number: {
        id: "",
        field: [],
      },
      secondary_id: {
        id: "",
        field: [],
      },
    },
    enabled: null,
    last_execution_status: null,
    last_execution_datetime: null,
  },
  authToken: null,
  configLoaded: false,
  endpoints_fields: [],
  notification: [],
};

export const EsriContext = createContext<{
  state: EsriContextType;
  dispatch: React.Dispatch<any>;
}>({ state: initialState, dispatch: () => null });

const reducer = (state: EsriContextType, action: any) => {
  switch (action.type) {
    case ACTIONS.DELETE_ENTIRE_CONFIGURATION:
      return {
        ...state,
        esriConfig: {
          ...initialState,
        },
      };
    case ACTIONS.UPDATE_AUTHENTICATION:
    case ACTIONS.UPDATE_ENDPOINTS:
    case ACTIONS.UPDATE_MAPPINGS:
      return {
        ...state,
        esriConfig: {
          ...state.esriConfig,
          ...action.payload,
        },
      };
    case ACTIONS.LOAD_CONFIG:
      return {
        ...state,
        esriConfig: {
          ...action.payload,
          data_endpoints: JSON.parse(action.payload.data_endpoints),
        },
        configLoaded: true,
      };
    case ACTIONS.DELETE_ESRI_CONFIG:
      return {
        ...initialState,
      };
    case ACTIONS.ADD_ENDPOINT_FIELDS:
      return {
        ...state,
        endpoints_fields: [...state.endpoints_fields, action.payload],
      };
    case ACTIONS.CREATE_NOTIFICATION:
      return {
        ...state,
        notification: [action.payload],
      };
    case ACTIONS.REMOVE_NOTIFICATION:
      return {
        ...state,
        notification: [],
      };
    case ACTIONS.UPDATE_TOKEN:
      return {
        ...state,
        authToken: action.payload,
      };
    default:
      return state;
  }
};

interface EsriProviderProps {
  children: React.ReactNode;
}

export const EsriProvider = ({ children }: EsriProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <EsriContext.Provider value={contextValue}>{children}</EsriContext.Provider>
  );
};
