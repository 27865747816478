import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Paper,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faExpand,
  faTableList,
} from "@fortawesome/free-solid-svg-icons";
import "./ListingInformation.scss";
import { Icon } from "../../../../components/Icon/Icon";
import ModalComponent from "../../../../components/ModalComponent/ModalComponent";
import MatchDetails from "../Modals/MatchDetails/MatchDetails";
import { Screenshots } from "../Modals/Screenshots/Screenshots";
import {
  ListingInterface,
  ListingTableInterface,
  RentalUnitContext,
} from "../RentalUnitContext";
import { isEmpty, parseSubsite } from "../../../common/utils";

export interface ListingsDataProps {
  complianceStatus: boolean | null;
  isIdentified: boolean | null;
  onSelectedListingChange: (value: number) => void;
  defaultListing: string | undefined;
  hasScreenshots: boolean;
}

const ListingInformation = (props: ListingsDataProps) => {
  const { listings, listingTables } = useContext(RentalUnitContext);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [screenshotModalOpen, setScreenshotModalOpen] =
    useState<boolean>(false);

  const handleChange = (value: number) => {
    setSelectedTab(value ? Number(value) : 0);
    props.onSelectedListingChange(Number(value));
  };
  const [listingMatchData, setListingMatchData] = useState<ListingInterface>();
  const [hideMatchData, setHideMatchData] = useState<Boolean>();

  useEffect(() => {
    if (props.defaultListing) {
      handleSelectedListingChange(props.defaultListing);
    }
  }, [props.defaultListing]);

  const getListingStatusIndicator = (status: boolean) => {
    return (
      <FontAwesomeIcon
        className="icon"
        icon={faCircle}
        color={status ? "rgb(0, 173, 130)" : "grey"}
      />
    );
  };

  const [openMatchDetailsModal, setOpenMatchDetailsModal] = useState(false);

  const StatusIndicatorTooltip = styled(
    ({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    )
  )(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      marginBottom: "5px !important",
    },
  }));

  const handleSelectedListingChange = (value: string) => {
    const index = listingTables?.hits.findIndex(
      (item: ListingTableInterface) => item.internal_listing_id === value
    );
    // TECHDEBT
    setSelectedTab(index ? index : 0);
    props.onSelectedListingChange(index ? index : 0);
  };

  useEffect(() => {
    if (listingTables && listings) {
      const selectedListing = listingTables?.hits[selectedTab];
      const newListingMatchData: ListingInterface = listings?.hits.filter(
        (item: ListingInterface) =>
          item.id === selectedListing.internal_listing_id
      )[0];
      setListingMatchData(newListingMatchData);
      // Checking whether to hide Matches or not , Weightage to checks given in ascending order to handle edge cases .
      let hideMatches = props.isIdentified !== true; // Check 1 : Hiding Matches if is_identified is not true .
      if (
        newListingMatchData &&
        newListingMatchData.analysis && // Check 2 : Hiding Matches if there is no match data in selected listing.
        isEmpty(newListingMatchData.analysis.comments) &&
        isEmpty(newListingMatchData.analysis.created_at) &&
        isEmpty(newListingMatchData.analysis.evidence) &&
        (isEmpty(newListingMatchData.analysis.matches) ||
          (newListingMatchData.analysis.matches &&
            (isEmpty(newListingMatchData.analysis.matches.owner_name) ||
              !newListingMatchData.analysis.matches.owner_name) &&
            isEmpty(newListingMatchData.analysis.matches.city) &&
            isEmpty(newListingMatchData.analysis.matches.postal_code))) &&
        isEmpty(newListingMatchData.analysis.worker_id)
      ) {
        hideMatches = true;
      }
      setHideMatchData(hideMatches);
    }
  }, [listings, selectedTab]);

  return listingTables && listings ? (
    <Box className="listingInformationContainer">
      <TabContext value={String(selectedTab)}>
        <Box className="tabList">
          <TabList
            variant={"scrollable"}
            onChange={(event, value) => handleChange(value)}
          >
            {listingTables?.hits.map((listing, index) => (
              <Tab
                className="tabLabel"
                key={index}
                value={String(index)}
                label={
                  <Typography variant="body1" className="bold" key={index}>
                    <Icon
                      className="subsiteIcon"
                      type={parseSubsite(listing.internal_subsite_name)}
                    />
                    <span className="labelText">
                      {listing.internal_listing_id.toUpperCase()}
                    </span>
                    <StatusIndicatorTooltip
                      title={
                        (listing.is_advertised ? "Active" : "Inactive") +
                        " Listing"
                      }
                      placement="top"
                      arrow
                    >
                      <span>
                        {getListingStatusIndicator(listing.is_advertised)}
                      </span>
                    </StatusIndicatorTooltip>
                  </Typography>
                }
              />
            ))}
          </TabList>
        </Box>
        {listingTables?.hits.map((listing, index) => (
          <TabPanel key={index} value={String(index)}>
            <TableContainer
              component={Paper}
              sx={{
                width: "100%",
              }}
            >
              <Table className="table">
                <colgroup>
                  <col style={{ width: "40%" }} />
                  <col style={{ width: "60%" }} />
                </colgroup>
                <TableBody>
                  <TableRow className="pageBreak">
                    <TableCell variant={"body"}>
                      <Typography variant="body1" className="bold">
                        URL
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1">
                        <a href={listing.listing_url}>{listing.listing_url}</a>
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow className="pageBreak">
                    <TableCell>
                      <Typography variant="body1" className="bold">
                        Listing status
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1">
                        {listing.is_advertised ? "Active" : "Inactive"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow className="pageBreak">
                    <TableCell>
                      <Typography variant="body1" className="bold">
                        Listing ID
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1">
                        {listing.internal_listing_id}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow className="pageBreak">
                    <TableCell>
                      <Typography variant="body1" className="bold">
                        Listing title
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1">
                        {listing.listing_title}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow className="pageBreak">
                    <TableCell>
                      <Typography variant="body1" className="bold">
                        Platform name
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1">
                        {listing.internal_subsite_name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow className="pageBreak">
                    <TableCell>
                      <Typography variant="body1" className="bold">
                        Bedrooms
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1">
                        {listing.listing_bedrooms}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow className="pageBreak">
                    <TableCell>
                      <Typography variant="body1" className="bold">
                        Cleaning fee in USD
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1">
                        {listing.listing_cleaning_fee_in_usd && (
                          <span>
                            {"$" + listing.listing_cleaning_fee_in_usd}
                          </span>
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow className="pageBreak">
                    <TableCell>
                      <Typography variant="body1" className="bold">
                        Daily rate in USD
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1">
                        {listing.listing_daily_rate_in_usd && (
                          <span>{"$" + listing.listing_daily_rate_in_usd}</span>
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow className="pageBreak">
                    <TableCell>
                      <Typography variant="body1" className="bold">
                        Host provided name
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1">
                        {listing.listing_host_provided_name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow className="pageBreak">
                    <TableCell>
                      <Typography variant="body1" className="bold">
                        Max people per bedroom
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1">
                        {listing.listing_max_number_of_people_per_bedroom}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow className="pageBreak">
                    <TableCell>
                      <Typography variant="body1" className="bold">
                        Max sleeping capacity
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1">
                        {listing.listing_max_sleeping_capacity}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow className="pageBreak">
                    <TableCell>
                      <Typography variant="body1" className="bold">
                        Min nights
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1">
                        {listing.listing_min_nights}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow className="pageBreak">
                    <TableCell>
                      <Typography variant="body1" className="bold">
                        Property type
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1">
                        {listing.listing_property_type}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow className="pageBreak">
                    <TableCell>
                      <Typography variant="body1" className="bold">
                        Review count
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1">
                        {listing.listing_review_count}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow className="pageBreak">
                    <TableCell>
                      <Typography variant="body1" className="bold">
                        Listing room type
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1">
                        {listing.listing_room_type}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow className="pageBreak">
                    <TableCell>
                      <Typography variant="body1" className="bold">
                        Bathrooms
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1">
                        {listing.listing_total_bathrooms}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow className="pageBreak">
                    <TableCell>
                      <Typography variant="body1" className="bold">
                        View screenshot history
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {props.hasScreenshots ? (
                        <IconButton
                          onClick={() =>
                            setScreenshotModalOpen(!screenshotModalOpen)
                          }
                        >
                          <FontAwesomeIcon icon={faExpand} />
                        </IconButton>
                      ) : (
                        <Typography variant="body1">
                          We do not have screenshots for this listing.
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow className="pageBreak">
                    <TableCell>
                      <Typography variant="body1" className="bold">
                        View match details
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1">
                        {!hideMatchData ? (
                          <IconButton
                            onClick={() => setOpenMatchDetailsModal(true)}
                          >
                            <FontAwesomeIcon icon={faExpand} />
                          </IconButton>
                        ) : (
                          "Property has not yet been identified."
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <ModalComponent
              header="Screenshot History"
              open={screenshotModalOpen}
              onClose={() => setScreenshotModalOpen(false)}
              width="90%"
            >
              <Screenshots
                changeListing={(value) => handleSelectedListingChange(value)}
              />
            </ModalComponent>
          </TabPanel>
        ))}
      </TabContext>
      <ModalComponent
        header={"Match Details"}
        open={openMatchDetailsModal}
        onClose={() => setOpenMatchDetailsModal(false)}
        width="80%"
      >
        {listingMatchData && <MatchDetails matchData={listingMatchData} />}
      </ModalComponent>
    </Box>
  ) : (
    <Box
      sx={{
        height: "100%",
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography variant="h1" align="center">
        <FontAwesomeIcon icon={faTableList} color="grey" />
      </Typography>
      <Typography variant="h6" color="grey" align="center">
        No listing data available
      </Typography>
    </Box>
  );
};

export default ListingInformation;
