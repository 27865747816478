import React from "react";
import { gql } from "@apollo/client";
import { GridColDef } from "@mui/x-data-grid-pro";
import SearchView from "../../components/SearchView/SearchView";
import { ExtendedGridColDef, getTableMeta } from "../common/data-grid-cols";

const ReportedRevenuePage = () => {
  const { columns, filters } = getTableMeta("reported_revenue");

  const reportedRevenueQuery = (columnsToFilter: ExtendedGridColDef[]) => {
    const queryFields = columnsToFilter
      .filter((column) => !column.hide && !column.dynamic)
      .map((column) => column.field)
      .join("\n");

    return gql`
      query(
        $q: KibanaQL=""
        $phrase: String
        $size: Int
        $from: Int
        $sort: String
      ) {
        results: reported_revenue(
          search: { query: $q, phrase: $phrase, size: $size, from: $from, sort: $sort }
        ) {
          size
          total
          hits {
            id
            ${queryFields}
          }
        }
      }
    `;
  };

  const renderColumn = (column: ExtendedGridColDef): GridColDef => {
    return { ...column };
  };

  return (
    <SearchView
      searchQuery={reportedRevenueQuery}
      columns={columns.map((column: ExtendedGridColDef) =>
        renderColumn(column)
      )}
      filters={filters}
      title="Reported Revenue"
    />
  );
};

export default ReportedRevenuePage;
