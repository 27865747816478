//
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useRouteMatch
} from 'react-router-dom';

import { LoadingBar } from '../common/loading';

//
import AddressIDMap from './address-id-map/address-id-map';

//
function View() {
  let { path } = useRouteMatch();
  return (
    <Router>
      <LoadingBar />
      <Switch>
        <Route path={`${path}/address-id-map`} component={AddressIDMap} />
        <Route path="/">
          <Redirect to="/errors/404" />
        </Route>
      </Switch>
    </Router>
  );
}

export default View;
