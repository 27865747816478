//
import React from "react";
import { RouteComponentProps } from "react-router";

import env from "../../common/env";

interface ReportProps
  extends RouteComponentProps<{
    slug: any;
  }> {}

//
export const View = (props: ReportProps) => {
  let url = `${env.KIBANA_URL}/app/kibana#/dashboard/`;
  let title = "Dashboard";

  switch (props.match.params.slug) {
    case "address-identification":
      url += env.KIBANA_ADDRESS_IDENTIFICATION;
      title = "Address Identification Dashboard";
      break;
    case "complaints":
      url += env.KIBANA_CALL_CENTER_DASHBOARD;
      title = "Complaints Dashboard";
      break;
    case "trend-monitoring":
      url += env.KIBANA_TREND_MONITORING_DASHBOARD;
      title = "Trend Monitoring Dashboard";
      break;
    default:
      console.error("ERROR: No report with slug");
  }
  url += "?embed=true&_g=()";

  document.title = title;

  return (
    <iframe
      title=" "
      src={"/loadKibana?url=" + encodeURIComponent(url)}
      style={{
        height: "100%",
        width: "100%",
        border: 0,
      }}
    ></iframe>
  );
};

export default View;
