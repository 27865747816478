import React from "react";
import { Alert, Snackbar as _MUISnackbar, SnackbarOrigin } from "@mui/material";

export type AlertColor = "success" | "info" | "warning" | "error";

interface SnackbarProps {
  open: boolean;
  autoHideDuration?: number | null;
  onClose?: () => void;
  message: string;
  severity: AlertColor;
  anchorOrigin: SnackbarOrigin;
}

const Snackbar: React.FC<SnackbarProps> = ({
  open,
  autoHideDuration,
  onClose,
  message,
  severity,
  anchorOrigin,
}) => {
  return (
    <_MUISnackbar
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      anchorOrigin={anchorOrigin}
    >
      <Alert severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </_MUISnackbar>
  );
};

export default Snackbar;
