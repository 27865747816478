import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Link, Tooltip } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { parseSubsite } from "../../dashboard/common/utils";
import { Icon } from "../Icon/Icon";

interface ListingsInterface {
  type: string;
  url: string;
}

const LISTING_SITES = [
  "airbnb",
  "booking",
  "homeaway",
  "vrbo",
  "vacasa",
  "flipkey",
  "vacationrentals",
  "tripadvisor",
];

export const ListingsCell = (listingsCellProps: GridCellParams | any) => {
  const [listings, setListings] = useState<ListingsInterface[]>();
  const [hovered, setHovered] = useState(false);
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    if (
      listingsCellProps?.params?.value &&
      listingsCellProps?.params?.value.length > 0
    ) {
      let newListings: ListingsInterface[] =
        listingsCellProps?.params?.value.map((listing: any) => {
          let listingSite = "others";
          let listingUrl = "";

          if (typeof listing === "string") {
            listingUrl = listing;
            LISTING_SITES.forEach((site) => {
              if (listing.indexOf(site) > -1) {
                listingSite = site;
              }
            });
          } else if (listing.website && listing.webpage) {
            listingUrl = listing.webpage;
            listingSite = listing.website.toLowerCase();
          }

          return { type: listingSite, url: listingUrl };
        });
      setListings(newListings);
    }
  }, [listingsCellProps?.params]);

  useEffect(() => {
    let itemWidth = window?.document?.getElementById(
      `listing-${listingsCellProps.params.id}`
    )?.offsetWidth;
    const localData: string | null = localStorage.getItem(
      location.pathname.replace(/\/$/, "")
    );
    const initialStoredColumns: GridColDef[] = localData
      ? JSON.parse(localData)["columnsDefinition"]
      : [];
    const localStoredColumn: GridColDef | undefined = initialStoredColumns.find(
      (obj) => obj["field"] === "listings"
    );
    if (localStoredColumn?.width) {
      if (itemWidth && itemWidth >= localStoredColumn?.width) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    }
  });

  return (
    <Box
      className={`listings-icons-container ${hovered ? "hovered" : ""}`}
      id={`listing-${listingsCellProps.params.id}`}
      onMouseLeave={() => setHovered(false)}
    >
      <Box className={`listings-icons-box ${hovered ? "hovered" : ""}`}>
        {listings?.map((listing, index) => {
          return (
            <Tooltip
              key={index}
              title={listing.url}
              placement="top"
              style={{ pointerEvents: "visible" }}
              arrow
            >
              <Link
                className="listing-icon-link"
                href={listing.url}
                target="_blank"
              >
                <Icon
                  className="listing-icon"
                  type={parseSubsite(listing.type)}
                />
              </Link>
            </Tooltip>
          );
        })}
      </Box>
      {!hovered && showArrow && (
        <Box
          className="listings-show-more"
          onMouseLeave={() => setHovered(false)}
        >
          <FontAwesomeIcon
            icon={faCaretDown}
            onMouseOver={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          />
        </Box>
      )}
    </Box>
  );
};
