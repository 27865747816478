import React from "react";

export interface JurisdictionInterface {
  jurisDictionId: string;
  jurisdictionName: string;
  jurisdictioSlug: string;
  showSendLetter: boolean;
}

export const JurisdictionContext = React.createContext<JurisdictionInterface>({
  jurisDictionId: "",
  jurisdictionName: "",
  jurisdictioSlug: "",
  showSendLetter: false,
});
