import React, { useState } from "react";
import "./EditOwnerInformationModal.scss";
import { DialogContent, Grid } from "@mui/material";
import ModalComponent from "../../../../../components/ModalComponent/ModalComponent";
import EditOwnerInformationForm from "../../../../letters/ReportIssue/ReportOwnerInformation/EditOwnerInformationForm";
import { RentalUnitWithOwnerInfoInterface } from "../../../../letters/ReportIssue/ReportIssue";
import DialogComponent from "../../../../../components/DialogComponent/DialogComponent";

interface EditOwnerInformationProps {
  setSnackbarData: (data: any) => any;
  open: boolean;
  closeModal: () => void;
  listingId: string;
  rentalUnitID: string | null;
  rentalUnit: RentalUnitWithOwnerInfoInterface | null;
  reloadGrid: () => any | null;
}

const EditOwnerInformationModal = (props: EditOwnerInformationProps) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [isDataUpdated, setIsDataUpdated] = useState<boolean>(false);
  const confirmDialogContent = `Click “continue editing” to retrieve and submit your changes to the property's owner information. If you no longer wish to continue editing, click “discard changes."`;
  const infoText =
    "You have unsaved changes. If you exit, any changes you’ve inputted will be lost.";

  const updateFormData = (isUpdated: boolean) => {
    setIsDataUpdated(isUpdated);
  };

  const handleClose = () => {
    if (isDataUpdated) {
      setShowConfirmDialog(true);
      return;
    }
    props.closeModal();
  };

  return (
    <ModalComponent
      open={props.open}
      onClose={handleClose}
      className={"modal"}
      header="Edit Owner Information"
      width={"55%"}
    >
      <Grid>
        <DialogContent>
          <EditOwnerInformationForm
            goBack={props.closeModal}
            setSnackbarData={props.setSnackbarData}
            closeModal={props.closeModal}
            rentalUnitID={props.rentalUnitID}
            rentalUnit={props.rentalUnit}
            loadingRentalUnitData
            reloadGrid={props.reloadGrid}
            updateFormData={updateFormData}
          />
        </DialogContent>
      </Grid>
      <DialogComponent
        open={showConfirmDialog}
        handleClose={() => setShowConfirmDialog(false)}
        title={"Unsaved changes to address information"}
        contentInfoText={infoText}
        content={confirmDialogContent}
        onDiscard={() => {
          setShowConfirmDialog(false);
          setIsDataUpdated(false);
          props.closeModal();
        }}
      ></DialogComponent>
    </ModalComponent>
  );
};

export default EditOwnerInformationModal;
