import "./GridToolbarCustomViewsSelector.scss";

import React, { useState } from "react";
import { GridMenu, useGridRootProps } from "@mui/x-data-grid-pro";
import { unstable_useId as useId, useForkRef } from "@mui/material/utils";
import {
  Box,
  Button,
  IconButton,
  List,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableView } from "../../dashboard/common/data-grid-cols";
import { LoadingButton } from "@mui/lab";
import {
  Save as SaveIcon,
  ClearSharp,
  ViewComfy,
  Delete as DeleteIcon,
} from "@mui/icons-material";

interface GridToolbarCustomViewsSelectorProps {
  onClick: (value: string) => void;
  value: string;
  views: TableView[];
  createView: (value: string) => any;
  deleteView: (value: string) => any;
  setViewsCallBack: () => void;
}
export const GridToolbarCustomViewsSelector = React.forwardRef<
  HTMLButtonElement,
  GridToolbarCustomViewsSelectorProps
>(function GridToolbarCustomViewsSelector(props, ref) {
  const { createView, deleteView, onClick, views, setViewsCallBack } = props;
  const rootProps = useGridRootProps();
  const [open, setOpen] = useState<boolean>(false);
  const [showCreateField, setShowCreateField] = useState<boolean>(false);
  const [newViewName, setNewViewName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<TableView>();
  const [ActiveMenuItem, setActiveMenuItem] = useState<string>("");
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const viewButtonId = useId();
  const viewMenuId = useId();
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const handleRef = useForkRef(ref, buttonRef);
  const parsedViews = views?.map((option: TableView) => {
    return {
      key: option.key,
      value: JSON.parse(option.value),
    };
  });
  const sortedViews = parsedViews.sort((a, b) => {
    const dateA = new Date(a.value.date);
    const dateB = new Date(b.value.date);
    return dateA.getTime() - dateB.getTime();
  });
  const viewOptions = sortedViews?.map((option: TableView, index: number) => {
    return (
      <Box key={index}>
        <MenuItem
          onMouseEnter={(e) => {
            e.preventDefault();
            setActiveMenuItem(option.key);
          }}
          onMouseLeave={(e) => {
            e.preventDefault();
            setActiveMenuItem("");
          }}
          key={index}
          onClick={() => handleOnViewSelect(option)}
        >
          <Box className="menuItemContainer">
            <ViewComfy fontSize="small" className="viewItemIcom" />
            <Typography variant="body1">{option.value?.name}</Typography>
            {ActiveMenuItem === option.key && (
              <IconButton
                aria-label="delete"
                size="small"
                onClick={(e) => {
                  handleDeleteConfirmation(e, option);
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        </MenuItem>
      </Box>
    );
  });

  const handleDeleteConfirmation = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: TableView
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDelete(true);
    setDeleteItem(value);
  };

  const handleViewDeletion = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault;
    e.stopPropagation;
    setDeleteLoading(true);
    if (deleteItem?.key) {
      let response = await deleteView(deleteItem.key);
      if (response?.type !== "error") {
        await setViewsCallBack();
        resetState(true);
      }
    }
    setDeleteLoading(false);
  };

  const resetState = (stayOpen?: boolean) => {
    setNewViewName("");
    setShowCreateField(false);
    if (!stayOpen) {
      setOpen(false);
    }
    setLoading(false);
    setShowDelete(false);
    setActiveMenuItem("");
  };

  const handleOnViewSelect = (option: TableView) => {
    onClick(option.key);
    resetState();
  };

  const saveView = async () => {
    setLoading(true);
    let response = await createView(newViewName);
    if (response?.type !== "error") {
      await setViewsCallBack();
      resetState(true);
    }
    setLoading(false);
  };

  const handleViewNameChange = (value: string) => {
    setNewViewName(value);
    setLoading(false);
  };

  return (
    <>
      <rootProps.components.BaseButton
        id={viewButtonId}
        ref={handleRef}
        startIcon={<ViewComfy fontSize="medium" />}
        onClick={() => {
          setOpen(true);
        }}
      >
        <span style={{ fontSize: "0.8125rem" }}>{"Views"}</span>
      </rootProps.components.BaseButton>
      <GridMenu
        open={open}
        target={buttonRef.current}
        onClickAway={() => {
          resetState();
        }}
        className={
          views.length || showCreateField ? "viewsGrid" : "noViewsGrid"
        }
        position="bottom-start"
      >
        {showDelete ? (
          <Box className="deleteView">
            <Typography
              sx={{ padding: "5px", fontWeight: "bold" }}
              variant="body1"
            >
              Are you sure you want to delete this view?
            </Typography>
            <MenuItem selected disableRipple>
              <Box className="menuItemContainer">
                <ViewComfy fontSize="small" className="viewItemIcom" />
                <Typography variant="body1" sx={{ fontWeight: "normal" }}>
                  {deleteItem?.value?.name}
                </Typography>
              </Box>
            </MenuItem>
            <Stack
              sx={{ padding: "50px 40px 25px" }}
              spacing={2}
              direction="row"
            >
              <Button variant="contained" onClick={() => resetState(true)}>
                Keep View
              </Button>
              {deleteLoading ? (
                <LoadingButton
                  startIcon={<DeleteIcon />}
                  variant="outlined"
                  loading
                >
                  Delete View
                </LoadingButton>
              ) : (
                <Button
                  variant="outlined"
                  onClick={(e) => handleViewDeletion(e)}
                >
                  Delete View
                </Button>
              )}
            </Stack>
          </Box>
        ) : (
          <>
            <List className="viewsList" id={viewMenuId}>
              {viewOptions}
            </List>
            {!showCreateField ? (
              <Button
                key="add_current_view"
                className="viewControl"
                onClick={(event) => {
                  event.preventDefault();
                  setShowCreateField(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} className="addItemIcon" />
                Add Current View
              </Button>
            ) : (
              <>
                <TextField
                  key={"edit_key"}
                  id="menu-item-input"
                  placeholder="Name (max 100 characters)"
                  variant="standard"
                  onChange={(event) => {
                    handleViewNameChange(event.target.value);
                  }} // send key here
                  value={newViewName}
                  sx={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    display: "flex",
                  }}
                  inputProps={{ maxLength: 100 }}
                />
                <Box
                  key="save_or_cancel"
                  component="div"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    className="viewControl"
                    onClick={(event) => {
                      event.preventDefault();
                      saveView();
                    }}
                    disabled={
                      !newViewName || !newViewName.replace(/\s/g, "").length
                    }
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </LoadingButton>
                  <Button
                    className="viewControl"
                    onClick={(event) => {
                      event.preventDefault();
                      setShowCreateField(false);
                      setLoading(false);
                      setNewViewName("");
                    }}
                    startIcon={<ClearSharp />}
                  >
                    Cancel
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
      </GridMenu>
    </>
  );
});
