import React, { useState } from "react";
import { GridMenu, useGridRootProps } from "@mui/x-data-grid-pro";
import { unstable_useId as useId, useForkRef } from "@mui/material/utils";
import { Badge, ButtonProps, MenuItem, MenuList } from "@mui/material";
import { TableMetaFilters } from "../../dashboard/common/data-grid-cols";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//@ts-ignore
interface GridToolbarPreDefinedFiltersSelectorProps extends ButtonProps {
  filters: TableMetaFilters[];
  onClick: (value: string) => void;
  value: string;
}
export const GridToolbarPreDefinedFiltersSelector = React.forwardRef<
  HTMLButtonElement,
  GridToolbarPreDefinedFiltersSelectorProps
>(function GridToolbarPreDefinedFiltersSelector(props, ref) {
  const { onClick, value, filters } = props;
  const rootProps = useGridRootProps();
  const [open, setOpen] = useState<boolean>(false);
  const filterButtonId = useId();
  const filterMenuId = useId();
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const handleRef = useForkRef(ref, buttonRef);

  const filterOptions = filters.map(
    (option: TableMetaFilters, index: number) => (
      <MenuItem
        key={index}
        onClick={() => handleOnFilterSelect(option)}
        selected={option.title === value}
      >
        {option.title}
      </MenuItem>
    )
  );

  const handleOnFilterSelect = (option: TableMetaFilters) => {
    onClick(option.title);
    setOpen(false);
  };

  return (
    <>
      <rootProps.components.BaseButton
        id={filterButtonId}
        ref={handleRef}
        startIcon={
          <Badge badgeContent={value ? 1 : 0} color="primary">
            <FontAwesomeIcon icon={faFilter} />
          </Badge>
        }
        onClick={() => setOpen(true)}
      >
        <span style={{fontSize: "0.8125rem"}}>Pre-defined filters</span>
      </rootProps.components.BaseButton>
      <GridMenu
        open={open}
        target={buttonRef.current}
        onClickAway={() => setOpen(false)}
        position="bottom-start"
      >
        <MenuList id={filterMenuId}>{filterOptions}</MenuList>
      </GridMenu>
    </>
  );
});
