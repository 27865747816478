import React from "react";
import { useFieldArray, Controller } from "react-hook-form";
import { Paper, TextField, Button, Typography, Box } from "@mui/material";
import styles from "./EndpointsPage.module.scss";
import { useFormContext } from "react-hook-form";

const STEP_DESCRPTION =
  "Host Compliance is capable of handling multiple endpoints during configuration. Please enter the required endpoints for your jurisdiction.";

const EndpointsPage: React.FC = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "data_endpoints",
  });

  return (
    <Box className={styles.EndpointsPage}>
      <Paper elevation={3} className={styles.Paper}>
        <Typography variant="subtitle1">{STEP_DESCRPTION}</Typography>
      </Paper>
      <Box className={styles.EndpointsBox}>
        {fields.map((field, index) => (
          <Box key={field.id} className={styles.EndpointBox}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="standard"
                  label={`Endpoint ${index + 1}`}
                />
              )}
              name={`data_endpoints.${index}.url`}
              control={control}
            />
            <Box className={styles.JoinColumnBox}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ display: fields.length > 1 ? "block" : "none" }}
                    variant="standard"
                    label="Join column"
                  />
                )}
                name={`data_endpoints.${index}.join_column`}
                control={control}
              />
              <Button
                variant="contained"
                size="small"
                color="error"
                onClick={() => remove(index)}
              >
                Delete
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
      <Box className={styles.EndpointAddBtn}>
        <Button
          variant="contained"
          onClick={() =>
            append({
              url: "",
              used_fields: [],
              join_column: "",
              id: fields.length + 1,
            })
          }
        >
          Add endpoint
        </Button>
      </Box>
    </Box>
  );
};

export default EndpointsPage;
