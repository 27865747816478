import { useAuth0 } from "@auth0/auth0-react";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import env from "../../../../common/env";
import ModalComponent from "../../../../components/ModalComponent/ModalComponent";
import CommentsList from "../../../common/renderer/CommentsList";
import { COMMENTS_QUERY } from "../../../common/renderer/CommentsQuery";
import { fetchService, formatDate } from "../../../common/utils";

import "./ActivityCommentsRenderer.scss";

const ActivityCommentsRenderer = ({ params }: any) => {
  const [openCommentsModal, setOpenCommentsModal] = useState<boolean>(false);
  const [comments, setComments] = useState<any[]>([]);
  const [isCommentsFetched, setIsCommentsFetched] = useState<boolean>(false);

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const isCommentActivity = params.row.activity.toLowerCase() === "comment";

  const fetchComments = async () => {
    setIsCommentsFetched(false);
    const headers = {
      "Content-Type": "application/json",
      Authorization: isAuthenticated ? await getAccessTokenSilently() : "",
    };
    const body = JSON.stringify({
      query: COMMENTS_QUERY,
      variables: {
        q: `foreign_type:${params.row.view.foreignType} foreign_id:${params.row.view.foreignId}`,
        from: 0,
      },
    });

    fetchService(env.API_URL, "POST", headers, body)
      .then((res) => res.json())
      .then(
        (result) => {
          const comments = result.data?.comments?.hits;
          const selectedTimestamp = params.row.publishedAt;

          const filtertedComments = comments.filter(
            (comment: { created_at: any }) =>
              formatDate(comment.created_at) === selectedTimestamp
          );
          setComments(filtertedComments);
          setIsCommentsFetched(true);
        },
        (error) => console.error(error)
      );
    setOpenCommentsModal(true);
  };
  return (
    <>
      <div>
        {isCommentActivity ? (
          <IconButton onClick={fetchComments}>
            <FontAwesomeIcon icon={faExpand} />
          </IconButton>
        ) : undefined}
      </div>

      <div>
        <ModalComponent
          header={params.row.details !== "1 Comments" ? "Comments" : "Comment"}
          open={openCommentsModal}
          onClose={() => {
            setOpenCommentsModal(false);
            setComments([]);
          }}
          width="40%"
        >
          <CommentsList
            comments={comments}
            isCommentsFetched={isCommentsFetched}
          ></CommentsList>
        </ModalComponent>
      </div>
    </>
  );
};

export default ActivityCommentsRenderer;
