import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import ModalComponent from "../../../../components/ModalComponent/ModalComponent";
import { LettersHistory } from "../Modals/LettersHistory/LettersHistory";

const ActivityLettersRenderer = ({ params }: any) => {
  const [openLettersHistoryModal, setOpenLettersHistoryModal] =
    useState<boolean>(false);
  const isLettersActivity = params.row.activity
    .toLowerCase()
    .includes("letter");

  return (
    <>
      <div>
        {isLettersActivity ? (
          <IconButton onClick={() => setOpenLettersHistoryModal(true)}>
            <FontAwesomeIcon icon={faExpand} />
          </IconButton>
        ) : null}
      </div>
      <div>
        <ModalComponent
          header={"Letters History"}
          open={openLettersHistoryModal}
          onClose={() => setOpenLettersHistoryModal(false)}
          width={"1022px"}
        >
          <LettersHistory pickedLetterId={params.row.letterData.letterId} />
        </ModalComponent>
      </div>
    </>
  );
};

export default ActivityLettersRenderer;
