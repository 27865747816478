import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Snackbar,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { fetchService } from "../utils";
import { FileRejection, useDropzone } from "react-dropzone";
import { useAuth0 } from "@auth0/auth0-react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ClearIcon from "@mui/icons-material/Clear";

import env from "../../../common/env";
import { useMutation } from "@apollo/client";

import "./CommentsDrawer.scss";
import {
  ADD_COMMENTS_MUTATION,
  COMMENTS_QUERY,
  CREATE_UPLOAD_MUTATION,
  REMOVE_FILE_MUTATION,
  UPLOAD_FILE_MUTATION,
} from "./CommentsQuery";
import CommentsList from "./CommentsList";
import DialogComponent from "../../../components/DialogComponent/DialogComponent";
import { LoadingButton } from "@mui/lab";
import { SnackbarData } from "../../../components/SearchView/SearchView";

const RENTAL_UNITS = "rental-units";
const LISTINGS = "listings";
const REGISTRATIONS = "registrations";

export enum CommentableType {
  PERMIT = "PERMIT",
  RENTAL_UNIT = "RENTAL_UNIT",
  CALL = "CALL",
  LETTER = "LETTER",
  LISTING = "LISTING",
}

interface NewComment {
  foreign_type: CommentableType;
  foreign_id: string;
  body: string;
  upload_id?: string;
}

const confirmDialogContent = `Click “continue editing” to retrieve and submit your comment. If you no longer wish to continue, click “discard changes."`;
const infoText =
  "You have unsaved changes. If you exit, any comments you’ve inputted will be lost.";

/**
 * Renderer for comments cell in data grid
 */
export const CommentsDrawer = ({
  params,
  commentsModalOpen,
  setTotalComment,
  setCommentsModalOpen,
  setRecentCommentsCount,
  recentCommentsCount,
  totalComment,
}: any) => {
  const commentsJson = params.value;
  const foreignId = commentsJson?.foreign_id;
  const foreignType = commentsJson?.foreign_type;

  const [files, setFiles] = useState<File[]>([]);
  const [comments, setComments] = useState<any[]>([]);
  const [isCommentsFetched, setIsCommentsFetched] = useState<boolean>(false);
  const [isCommentPosted, setIsCommentPosted] = useState<boolean>(true);
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(true);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [uploadId, setUploadId] = useState<string>("");
  const [snackbarData, setSnackbarData] = useState<SnackbarData>({
    message: "",
    type: "warning",
  });
  const [dragActive, setDragActive] = useState<boolean>(false);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const textInput = useRef<any>(null);
  const uploadIdRef = useRef();

  const dashboardPath = window.location.pathname.split("/");
  const pathName = dashboardPath[dashboardPath.length - 1];

  /**
   * Mutations
   */
  const [mutateNewComment] = useMutation(ADD_COMMENTS_MUTATION);
  const [mutateNewUpload] = useMutation(CREATE_UPLOAD_MUTATION);
  const [mutateNewFile] = useMutation(UPLOAD_FILE_MUTATION);
  const [mutateRemoveFile] = useMutation(REMOVE_FILE_MUTATION);

  /**
   * Load comments on load
   */
  useEffect(() => {
    if (!commentsModalOpen) {
      return;
    }
    openCommentsDrawer();
  }, [commentsModalOpen]);

  /**
   * Handle drop on extended drop zone
   * @param event
   */
  const onDrop = (event: any) => {
    setDragActive(false);
    event.preventDefault();
    if (event.dataTransfer.files.length > 0) {
      handleDrop(Object.values(event.dataTransfer.files));
    }
  };

  /**
   * Toggle comments Drawer
   * @param open
   * @returns void
   */
  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (textInput.current?.value || files.length > 0) {
      setShowConfirmDialog(true);
    } else {
      setCommentsModalOpen(open);
      setRecentCommentsCount(!open ? 0 : recentCommentsCount);
    }
  };

  const getForeignId = (pathName: string) => {
    switch (pathName) {
      case RENTAL_UNITS:
      case LISTINGS:
        return params.row.external_property_id;

      case REGISTRATIONS:
        return params.row.registration_number;
    }
  };

  const getForeignType = (pathName: string) => {
    switch (pathName) {
      case RENTAL_UNITS:
        return CommentableType.RENTAL_UNIT;
      case LISTINGS:
        return CommentableType.LISTING;
      case REGISTRATIONS:
        return CommentableType.PERMIT;
    }
  };

  /**
   * Handles newly added comment, post it to server and update the list of comments and data grid
   * @returns void
   */
  const handleNewComment = () => {
    const newComment = textInput.current?.value;

    if (!newComment.replace(/\s/g, "").length) {
      setSnackbarData({
        message: "Comments cannot be blank/empty.",
        type: "warning",
      });
      return;
    }

    setIsCommentPosted(false);

    // prepare payload
    let items: NewComment = {
      foreign_type: foreignType || getForeignType(pathName),
      foreign_id: foreignId || getForeignId(pathName),
      body: newComment,
    };

    if (uploadId) {
      items.upload_id = uploadId;
    }

    // post the comment to server
    mutateNewComment({
      variables: {
        item: items,
      },
    })
      .then((response) => {
        if (response && response.data && response.data.createComment) {
          const comment = response.data.createComment;
          if (comments) {
            comments.unshift(comment);
            setComments([...comments]);
          } else {
            setComments([comment]);
          }
          // comment posted successfully, increment the count and clear input values
          setTotalComment(totalComment ? totalComment + 1 : 1);
          textInput.current.value = "";
          setFiles([]);
          setUploadId("");
          setIsCommentPosted(true);
        }
      })
      .catch((error) => console.error(error));
  };

  const validateFiles = (uploadedFiles: any[]) => {
    return uploadedFiles.length === 0 || uploadedFiles.length > 10
      ? "Upload failed: Maximum file limit is 10."
      : uploadedFiles.some((file) => file.size > 20000000)
      ? "Upload failed: 20 MB file size limit exceeded"
      : null;
  };

  /**
   * Upload file
   * @param file
   */
  const handleDrop = async (acceptedFiles: any) => {
    const errorMessage = validateFiles(acceptedFiles);
    if (errorMessage) {
      setSnackbarData({
        message: errorMessage,
        type: "error",
      });
      return;
    }
    setIsFileUploaded(false);

    const uploadedFiles = [];
    let id = uploadId;

    if (uploadId === "") {
      // get the upload Id for attachments
      await mutateNewUpload().then((response: any) => {
        setUploadId(response.data.createUpload?.id);
        id = response.data.createUpload?.id;
      });
    }

    for await (const file of acceptedFiles) {
      uploadedFiles.push(await uploadFile(id, file));
    }

    const updatedFiles: any[] = [...files, ...uploadedFiles];
    setFiles(updatedFiles.flat());
    setIsFileUploaded(true);
  };

  /**
   * Upload file against upload Id
   * @param uploadId
   * @param file
   */
  const uploadFile = async (uploadId: string, file: File) => {
    let uploadedFile = {};
    setIsFileUploaded(false);
    await mutateNewFile({
      variables: {
        upload_id: uploadId,
        item: {
          name: file.name,
          size: file.size,
          type: file.type,
        },
      },
    })
      .then(async (response) => {
        // put contents of the file to the server
        await fetch(response.data.updateUploadNewFile.put_content_url, {
          method: "PUT",
          headers: {
            "Content-Type": file.type,
          },
          body: file,
        }).then(() => {
          uploadedFile = response.data.updateUploadNewFile;
        });
      })
      .catch((error) => console.error(error));

    return uploadedFile;
  };

  const { isDragActive, getInputProps, getRootProps } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
    maxFiles: 10,
    maxSize: 20000000,
    onDropRejected: (fileRejections: FileRejection[]) => {
      console.log("reject", fileRejections);

      const fileError = fileRejections[0].errors[0];
      let message = "Upload failed.";

      switch (fileError.code) {
        case "file-too-large":
          message = "Upload failed: 20 MB file size limit exceeded.";
          break;

        case "too-many-files":
          message = "Upload failed: Maximum file limit is 10.";
          break;

        default:
          message = fileError.message;
          break;
      }

      setSnackbarData({
        message: message,
        type: "error",
      });
    },
  });

  /**
   * Removes files from the attached files state variable
   * @param index Index of the file
   * @param name name of the file
   */
  const removeFile = (fileId: number) => {
    mutateRemoveFile({
      variables: {
        upload_id: uploadId,
        file_id: fileId,
      },
    })
      .then(() => {
        setFiles(
          files.filter((file: any) => {
            return file.id !== fileId;
          })
        );
      })
      .catch((error) => console.error(error));
  };

  /**
   * Opens comments drawer and fetches the comments from server
   */
  const openCommentsDrawer = async () => {
    setFiles([]);

    // get the upload Id for attachments
    await mutateNewUpload().then((response: any) => {
      setUploadId(response.data.createUpload?.id);
    });

    const id = foreignId || getForeignId(pathName);
    const type = foreignType || getForeignType(pathName);

    if (!id) {
      setIsCommentsFetched(true);
      return;
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: isAuthenticated ? await getAccessTokenSilently() : "",
    };
    const body = JSON.stringify({
      query: COMMENTS_QUERY,
      variables: {
        q: `foreign_type:${type} foreign_id:${id}`,
        from: 0,
      },
    });

    await fetchService(env.API_URL, "POST", headers, body)
      .then((res) => res.json())
      .then(
        (result) => {
          setComments(result.data?.comments?.hits);
          setIsCommentsFetched(true);
        },
        (error) => console.error(error)
      );
  };

  return (
    <div>
      <Drawer
        anchor="right"
        open={commentsModalOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: { width: "36%", minWidth: "20%" },
        }}
      >
        <Box
          sx={{
            padding: 2,
            visibility: dragActive ? "visible" : "hidden",
            opacity: dragActive ? 1.5 : 0,
          }}
          onDrop={onDrop}
          onDragLeave={() => setDragActive(false)}
          className="extendedDropZone"
          role="presentation"
        >
          <FileUploadIcon />
          Drop files to attach them to comment
        </Box>

        <Box
          sx={{ padding: 2, overflow: "hidden" }}
          role="presentation"
          ref={uploadIdRef}
          id="extendedDropZone"
          onDragOver={() => setDragActive(true)}
          onDragEnter={() => setDragActive(true)}
        >
          <FontAwesomeIcon
            className="closeButton"
            icon={faClose}
            onClick={toggleDrawer(false)}
          />

          <div className="commentsDrawerHeader d-in-flex">Comments</div>
          <div className="pt-8">
            <TextareaAutosize
              className="commentsInput"
              placeholder="Write your comment or note and click “Add Comment” to save it. You can click to attach files below or drag and drop."
              ref={textInput}
            ></TextareaAutosize>
            <div className="fileList">
              {files.map((file: any, index: number) => {
                return (
                  <div key={index} className="uploadFiles">
                    <Button
                      className="uploadFileList"
                      variant="outlined"
                      endIcon={
                        <IconButton
                          className="removeFileIcon"
                          onClick={() => removeFile(file.id)}
                        >
                          <ClearIcon className="removeFile" fontSize="small" />
                        </IconButton>
                      }
                    >
                      {file.original_filename}
                    </Button>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="d-flex pt-8 row">
            <div
              {...getRootProps({
                className: isDragActive ? "dragFile " : "dropzone",
              })}
            >
              <input {...getInputProps()} />
              <div className="pd-4 dragComponent ">
                <IconButton className="attachIcon">
                  <AttachFileIcon fontSize="small"></AttachFileIcon>
                </IconButton>
                {" Drag and drop files, or click to select"}
              </div>
            </div>
            <div className="progress">
              {!isFileUploaded && <CircularProgress thickness={2} size={20} />}
            </div>
            <Tooltip
              title={
                !isFileUploaded
                  ? "File is uploading, please wait"
                  : !isCommentsFetched
                  ? "Comments are loading, please wait "
                  : ""
              }
              followCursor
            >
              <div className="commentButton">
                <LoadingButton
                  loading={!isCommentPosted}
                  sx={{ float: "right" }}
                  variant="contained"
                  className="submitButton"
                  onClick={handleNewComment}
                  disabled={
                    !isFileUploaded || !isCommentsFetched ? true : false
                  }
                >
                  Add Comment
                </LoadingButton>
              </div>
            </Tooltip>
          </div>
        </Box>

        <div className="commentBox">
          <CommentsList
            isCommentsFetched={isCommentsFetched}
            comments={comments}
          ></CommentsList>
        </div>

        <DialogComponent
          open={showConfirmDialog}
          handleClose={() => setShowConfirmDialog(false)}
          title={"Unsaved comment"}
          contentInfoText={infoText}
          content={confirmDialogContent}
          onDiscard={() => {
            setCommentsModalOpen(false);
            setShowConfirmDialog(false);
          }}
        ></DialogComponent>
        <Snackbar
          open={!!snackbarData.message}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setSnackbarData({ message: "", type: "warning" })}
        >
          <Alert
            severity={snackbarData.type}
            sx={{ width: "100%" }}
            onClose={() => setSnackbarData({ message: "", type: "warning" })}
          >
            {snackbarData.message}
          </Alert>
        </Snackbar>
      </Drawer>
    </div>
  );
};

export default CommentsDrawer;
