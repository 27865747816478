import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  FormGroup,
  FormLabel,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ListingInterface,
  RentalUnitContext,
} from "../../../../RentalUnitContext";
import { MatchDetailCheckboxGroup } from "./MatchDetailCheckboxGroup/MatchDetailCheckboxGroup";
import { FormDataInterface } from "../Steps";
import "./VerifyMatchDetails.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

interface VerifyMatchDetailsProps {
  formData: FormDataInterface;
  initialForm: FormDataInterface;
  setVerifyMatchDetailsValues: (formData: FormDataInterface) => void;
}

export const VerifyMatchDetails = ({
  formData,
  setVerifyMatchDetailsValues,
}: VerifyMatchDetailsProps) => {
  const { listings, listingTables } = useContext(RentalUnitContext);
  const [pickedListings] = useState<ListingInterface[]>(
    listings?.hits.filter((item) =>
      formData.internal_listing_ids.includes(item.id)
    ) || []
  );
  const [isSelectAllChecked, setIsSelectAllChecked] = useState<boolean>(true);

  useEffect(() => {
    updateSelectAllCheckbox();
  }, [formData]);

  function getCommentByListingID(id: string) {
    const commentObj = formData.comments.find(
      (commentObj) => commentObj.internal_listing_id === id
    );
    if (commentObj) {
      return commentObj.comment === null ? "" : commentObj.comment;
    } else {
      return "";
    }
  }

  const parseIdToIndex = (id: string) => {
    return (
      listingTables?.hits.findIndex(
        (item) => item.internal_listing_id === id
      ) || 0
    );
  };

  const handleCheckAllEvidences = (
    event: ChangeEvent<HTMLInputElement>,
    listingID: string
  ) => {
    const checked = event.target.checked;
    const evidence = formData.evidences?.find(
      (evidence) => evidence.listing_id === listingID
    );

    if (evidence) {
      let updatedKeepArray: number[] = [];
      let updatedDiscardArray: number[] = [];

      if (checked) {
        updatedKeepArray = [...evidence.discard, ...evidence.keep];
      } else {
        updatedDiscardArray = [...evidence.keep, ...evidence.discard];
      }

      // Update the formData with the modified keep and discard arrays
      const updatedEvidences = formData.evidences?.map((evidence) => {
        if (evidence.listing_id === listingID) {
          return {
            ...evidence,
            keep: updatedKeepArray,
            discard: updatedDiscardArray,
          };
        }
        return evidence;
      });

      setVerifyMatchDetailsValues({ ...formData, evidences: updatedEvidences });
    }
  };

  const handleCommentChange = (value: {
    internal_listing_id: string;
    comment: string;
  }) => {
    setVerifyMatchDetailsValues({
      ...formData,
      comments: [
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...formData.comments?.filter(
          (item) => item.internal_listing_id !== value.internal_listing_id
        ),
        value,
      ],
    });
  };

  const getEvidencesCheckedByListingID = (listingID: string) => {
    const evidence = formData.evidences?.find(
      (evidence) => evidence.listing_id === listingID
    );
    return evidence ? evidence.keep : [];
  };

  const handleEvidenceChecked = (
    checked: boolean,
    listingID: string,
    evidenceIndex: number
  ) => {
    const evidence = formData.evidences?.find(
      (evidence) => evidence.listing_id === listingID
    );
    if (evidence) {
      const keepArray = evidence.keep;
      const discardArray = evidence.discard;

      if (checked) {
        // Add evidenceIndex to keep array and remove from discard array
        if (!keepArray.includes(evidenceIndex)) {
          keepArray.push(evidenceIndex);
        }
        discardArray.splice(discardArray.indexOf(evidenceIndex), 1);
      } else {
        // Remove evidenceIndex from keep array and add to discard array
        keepArray.splice(keepArray.indexOf(evidenceIndex), 1);
        if (!discardArray.includes(evidenceIndex)) {
          discardArray.push(evidenceIndex);
        }
      }

      const updatedEvidences = formData.evidences?.map((evidence) => {
        if (evidence.listing_id === listingID) {
          return {
            ...evidence,
            keep: keepArray,
            discard: discardArray,
          };
        }
        return evidence;
      });
      setVerifyMatchDetailsValues({ ...formData, evidences: updatedEvidences });
    }
  };

  const isListingChecked = (listingID: string) => {
    const evidence = formData.evidences?.find(
      (evidence) => evidence.listing_id === listingID
    );

    //listings with at least one checked evidence or no evidence at all will be in "checked" state
    if (evidence) {
      return (
        evidence.keep.length > 0 ||
        (evidence.keep.length === 0 && evidence.discard.length === 0)
      );
    }
  };

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIsSelectAllChecked(checked);

    const updatedEvidences = formData.evidences?.map((evidence) => {
      let updatedKeepArray: number[] = [];
      let updatedDiscardArray: number[] = [];

      if (checked) {
        updatedKeepArray = [...evidence.discard, ...evidence.keep];
      } else {
        updatedDiscardArray = [...evidence.keep, ...evidence.discard];
      }

      return {
        ...evidence,
        keep: updatedKeepArray,
        discard: updatedDiscardArray,
      };
    });

    setVerifyMatchDetailsValues({ ...formData, evidences: updatedEvidences });
  };

  const updateSelectAllCheckbox = () => {
    const isAllChecked = pickedListings.every((listing) => {
      const evidence = formData.evidences?.find(
        (evidence) => evidence.listing_id === listing.id
      );
      return evidence && evidence.discard.length === 0;
    });

    setIsSelectAllChecked(isAllChecked);
  };

  return (
    <Box sx={{ margin: "0px 30px" }}>
      <FormGroup>
        <FormLabel className="MainLabel">
          Please check the validity of match details for this rental unit
        </FormLabel>
        {pickedListings ? (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSelectAllChecked}
                  onChange={handleSelectAll}
                />
              }
              label="Select all"
            />
            {pickedListings.map((listing, listingIndex) => (
              <Accordion
                key={listingIndex}
                defaultExpanded={listingIndex === 0}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="custom-accordion-left">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isListingChecked(listing.id)}
                          disabled={listing.analysis?.evidence.length === 0}
                          id={listing.id}
                          onChange={(event) =>
                            handleCheckAllEvidences(event, listing.id)
                          }
                          onClick={(event: any) => event.stopPropagation()}
                        />
                      }
                      label={
                        <>
                          <Typography variant="body1">{listing.id}</Typography>
                          <Typography variant="body2">
                            {
                              listingTables?.hits[parseIdToIndex(listing.id)]
                                .listing_title
                            }
                          </Typography>
                        </>
                      }
                    />
                  </div>
                  <div className="custom-accordion-right">
                    {getCommentByListingID(listing.id) === "" && (
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        className={"errorWarning"}
                        size="lg"
                      />
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <MatchDetailCheckboxGroup
                    matchDetailCheckboxes={
                      listing.analysis
                        ? listing.analysis?.evidence.map((item) => {
                            return {
                              evidence: { ...item },
                            };
                          })
                        : []
                    }
                    evidencesChecked={getEvidencesCheckedByListingID(
                      listing.id
                    )}
                    onEvidenceChecked={handleEvidenceChecked}
                    listingId={listing.id}
                    commentProp={getCommentByListingID(listing.id)}
                    key={listingIndex}
                    onCommentChange={handleCommentChange}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        ) : null}
      </FormGroup>
    </Box>
  );
};
