import React from "react";
import LetterCriteriaTooltip from "../../rental-units/Letter/LetterCriteriaTooltip";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

interface LetterCriteriaGridCellProps {
  templateCriteria: any;
  firstCriteria: any;
}

const CustomTooltip = styled(
  ({ className, ...props }: TooltipProps & { className?: string }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f5",
    maxWidth: "400px",
    border: "1px solid black",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#f5f5f5",
    fontSize: "2em",
    "&::before": {
      border: "1px solid black",
    },
  },
});

const LetterCriteriaGridCellRenderer = ({
  templateCriteria,
  firstCriteria,
}: LetterCriteriaGridCellProps) => {
  return (
    <CustomTooltip
      title={<LetterCriteriaTooltip templateCriteria={templateCriteria} />}
      arrow
    >
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "400px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {firstCriteria.criteria_met ? (
          <DoneIcon
            fontSize="small"
            color="success"
            style={{ marginRight: "4px" }}
          />
        ) : (
          <CloseIcon
            fontSize="small"
            color="error"
            style={{ marginRight: "4px" }}
          />
        )}
        <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {firstCriteria?.criteria_description}
        </p>
      </div>
    </CustomTooltip>
  );
};

export default LetterCriteriaGridCellRenderer;
