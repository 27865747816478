import { AlertColor } from "@mui/material";

export enum ExecutionStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  STATUS_UNKNOWN = "NOT EXECUTED YET",
}

export enum StepperSteps {
  WELCOME = 0,
  AUTH = 1,
  ENDPOINTS = 2,
  MAPPINGS = 3,
}

export interface DataEndpoint {
  id: number;
  url: string;
  used_fields: string[];
  join_column: string;
}

export interface AuthToken {
  __typename: String;
  expires: string;
  token: string;
}

export interface EsriEndpointField {
  name: string;
}

export interface EndpointDetail {
  id: number;
  fields: EsriEndpointField[];
}

export interface Mappings {
  property_id: Mapping;
  street_address: Mapping;
  unit_number: Mapping;
  locality: Mapping;
  state: Mapping;
  postal_code: Mapping;
  country: Mapping;
  has_pool: Mapping;
  has_elevator: Mapping;
  owner_name_1: Mapping;
  owner_name_2: Mapping;
  owner_address_line_1: Mapping;
  owner_address_line_2: Mapping;
  owner_address_line_3: Mapping;
  bedrooms: Mapping;
  bathrooms: Mapping;
  units: Mapping;
  stories: Mapping;
  provided_use_code: Mapping;
  provided_use_name: Mapping;
  latitude: Mapping;
  longitude: Mapping;
  str_policy: Mapping;
  homestead_exemption: Mapping;
  community: Mapping;
  vacation_rental_district: Mapping;
  code_enforcement_area_name: Mapping;
  code_officer: Mapping;
  route_post_directional: Mapping;
  route_post_type: Mapping;
  route_name: Mapping;
  street_number: Mapping;
  secondary_id: Mapping;
  __typename?: string;
}

export interface Mapping {
  id: string;
  field: string | string[];
}

export interface EsriConfig {
  __typename?: string;
  id?: string | null;
  username: string;
  password: string;
  authentication: boolean;
  token_endpoint: string;
  data_endpoints: DataEndpoint[];
  mappings: Mappings;
  enabled: boolean | null;
  last_execution_status: ExecutionStatus | null;
  last_execution_datetime: string | null;
}

export interface EndpointFields {
  id: number;
  fields: EndpointField[];
}

export interface EndpointField {
  name: string;
}

export interface Notification {
  message: string;
  type: AlertColor;
}
export interface EsriContext {
  __typename?: string;
  authToken: AuthToken | null;
  esriConfig: EsriConfig;
  endpoints_fields: EndpointFields[];
  configLoaded: boolean;
  notification: Notification[];
}
