import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { useQuery, useMutation, gql } from "@apollo/client";
import { LoadingList } from "../../../common/loading";
import "./jurisdictions.scss";
import SearchViewToolbar from "../../../components/SearchView/SearchViewToolbar";

const GET_INITIAL = gql`
  {
    jurisdictions(search: { size: 1000 }) {
      total
      hits {
        id
        name
        slug
      }
    }
  }
`;

const SWITCH_JURISDICTION = gql`
  mutation ($id: ID!) {
    switchJurisdiction(id: $id)
  }
`;

//
function View() {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [jurisdictions, setJurisdictions] = useState("");
  const [jurisdictionID, setJurisdictionID] = useState("");
  useEffect(() => {
    document.title = "Jurisdictions | Host Compliance";
  }, []);
  const storedID = localStorage.getItem("jurisdiction");
  useEffect(() => {
    if (storedID) {
      setJurisdictionID(storedID);
    }
  }, [storedID]);
  const { data, loading, error } = useQuery(GET_INITIAL);

  useEffect(() => {
    if (data && data.jurisdictions && data.jurisdictions.hits) {
      setJurisdictions([...data.jurisdictions.hits]);
    }
  }, [data]);

  const [mutateSwitchJurisdiction] = useMutation(SWITCH_JURISDICTION);

  const switchJurisdiction = (id) => {
    mutateSwitchJurisdiction({ variables: { id: id } });
    window.location.href = "/";
  };

  const handleSearch = (event) => {
    const newValue = event.target.value;
    setSearchInputValue(newValue);
    let hits = [...data.jurisdictions.hits];
    if (newValue.length >= 3) {
      let matches = hits.filter((jurisdiction) => {
        return (
          jurisdiction.id.indexOf(newValue) > -1 ||
          jurisdiction.name.toLowerCase().indexOf(newValue.toLowerCase()) > -1
        );
      });
      setJurisdictions(matches);
    } else if (newValue.length === 0) {
      setJurisdictions(hits);
    }
  };

  return (
    <Card>
      <CardContent>
        <Grid className="jurisdictions" container spacing={3}>
          <Grid item xs={12} md={2}>
            <Typography variant="h5">Jurisdictions</Typography>
            <Typography variant="subtitle1">
              Manage &amp; switch customers
            </Typography>
          </Grid>

          <Grid item xs={12} md={8}>
            {data && data.jurisdictions && (
              <SearchViewToolbar
                searchValue={searchInputValue}
                handleSearch={handleSearch}
                title={"jurisdictions"}
              />
            )}
            {loading && LoadingList()}

            {error && <h1>Error! {error.message}</h1>}

            {jurisdictions && (
              <List dense={true}>
                {jurisdictions.map((item) => {
                  return (
                    <ListItem key={item.id}>
                      <ListItemText primary={item.name} secondary={item.id} />
                      <ListItemSecondaryAction className="current-jurisdiction">
                        {jurisdictionID === item.id && (
                          <small>CURRENT JURISDICTION</small>
                        )}
                        {jurisdictionID !== item.id && (
                          <IconButton
                            edge="end"
                            aria-label="Switch"
                            onClick={() => switchJurisdiction(item.id)}
                            size="small"
                            className="change-button"
                          >
                            <FontAwesomeIcon icon={faExchangeAlt} />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default View;
