import React from "react";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import { Box, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { LoadingButton } from "@mui/lab";
import "./SendLetterConfirmationModal.scss";
import { List, Alert, Snackbar } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

interface SendLetterConfirmationModalProps {
  onClose: () => void;
  onFinish: () => void;
  onGoBack: () => void;
  showSendLetterConfirmationRequired: boolean;
  openSendLetterConfirmationModal: boolean;
  selectedTemplate: any;
  sendLetterLoading: boolean;
  sendLetterAndShowConfirmation: () => void;
  isErrorVisibleOnSend: boolean;
  setIsErrorVisibleOnSend: (arg0: boolean) => void;
  errorMessageOnSend: string;
  recipientDetails: any;
  onWayString: string;
  letterPreparedString: string;
  supportString: string;
}

const SendLetterConfirmationModal = (
  props: SendLetterConfirmationModalProps
) => {
  return (
    <>
      <ModalComponent
        header={
          props.showSendLetterConfirmationRequired
            ? "Send Letter: Confirmation Required"
            : "Confirmation"
        }
        open={props.openSendLetterConfirmationModal}
        className={"confirmation modal summaryModal"}
        onClose={props.onClose}
      >
        <Box
          sx={{
            p: 4,
          }}
          style={{ padding: "20px" }}
        >
          {props.showSendLetterConfirmationRequired && (
            <>
              <div className="confirmationMainDiv">
                <div className="ready">Ready to send?</div>
                <div className="confirmationText">
                  You are about to send the {props.selectedTemplate.name} to:
                </div>
                <div className="confirmationAddress">
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <PersonIcon
                          sx={{
                            color: "#000000",
                            top: "12px",
                            position: "absolute",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ margin: "0px" }}
                        primary={
                          <div className="ownerInfoDetail">
                            {props.recipientDetails}
                          </div>
                        }
                      />
                    </ListItem>
                  </List>
                </div>
              </div>
              <div className="buttonContainer">
                <LoadingButton
                  sx={{ float: "right" }}
                  variant="outlined"
                  className="submitButton"
                  onClick={props.onGoBack}
                >
                  Go Back
                </LoadingButton>
                <LoadingButton
                  sx={{ float: "right" }}
                  loading={props.sendLetterLoading}
                  variant="contained"
                  className="submitButton"
                  onClick={props.sendLetterAndShowConfirmation}
                >
                  Confirm and Send Letter
                </LoadingButton>
              </div>
            </>
          )}

          {!props.showSendLetterConfirmationRequired && (
            <>
              {props.isErrorVisibleOnSend && (
                <div>
                  <Snackbar
                    sx={{ position: "static" }}
                    open={props.isErrorVisibleOnSend}
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                  >
                    <Alert severity="error" sx={{ width: "100%" }}>
                      {props.errorMessageOnSend}
                    </Alert>
                  </Snackbar>
                </div>
              )}

              {!props.isErrorVisibleOnSend && (
                <>
                  <div className="confirmationMainDiv">
                    <div style={{ display: "flex" }}>
                      <div className="doneIcon">
                        <DoneIcon fontSize="large"> </DoneIcon>
                      </div>
                      <div className="onWay">{props.onWayString}</div>
                    </div>
                    <div
                      className="letterPrepared"
                      style={{ paddingTop: "20px" }}
                    >
                      {props.letterPreparedString}
                    </div>
                    <div
                      className="supportSection"
                      style={{ paddingTop: "20px" }}
                    >
                      {props.supportString}
                    </div>
                  </div>
                </>
              )}
              <div className="buttonContainer">
                <LoadingButton
                  sx={{ float: "right" }}
                  variant="contained"
                  className="submitButton"
                  onClick={props.onFinish}
                >
                  Finish
                </LoadingButton>
              </div>
            </>
          )}
        </Box>
      </ModalComponent>
    </>
  );
};

export default SendLetterConfirmationModal;
