import React, { useState, useEffect, useContext } from "react";
import AuthPage from "./AuthStep";
import MappingsPage from "./MappingsStep";
import WelcomePage from "./WelcomeStep";
import EndpointsPage from "./EndpointsStep";
import Button from "@mui/material/Button";
import { Container, Typography } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";
import { useForm, FormProvider } from "react-hook-form";
import Snackbar from "./components/Snackbar";
import { EsriContext, createNotification } from "./context/EsriContext";
import { DataEndpoint, Mappings, StepperSteps } from "./interfaces";

const STEPS = [
  "Introduction",
  "Authentication",
  "Data sources",
  "Mappings selection",
];

const EMPTY_ENDPOINTS = "At least one endpoint configuration must be provided.";
const PARTLY_CONFIGURATION = "Not enought information provided.";
const JOIN_COLUMN_NOT_NEEDED =
  "Join column isn't needed for a single endpoint.";
const EMPTY_URL = "Endpoint URL not provided.";
const JOIN_COLUMN_REQUIRED =
  "For multiple endpoints a join column is required.";
const WRONG_URL = "The endpoint URL muste end with '/query'.";

const EsriStepper: React.FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const { state, dispatch } = useContext(EsriContext);
  const methods = useForm<any>({
    defaultValues: {
      username: "",
      password: "",
      token_endpoint: "",
      data_endpoints: [],
      mappings: {
        property_id: {
          id: "",
          field: [],
        },
        street_address: {
          id: "",
          field: [],
        },
        unit_number: {
          id: "",
          field: [],
        },
        locality: {
          id: "",
          field: [],
        },
        state: {
          id: "",
          field: [],
        },
        postal_code: {
          id: "",
          field: [],
        },
        country: {
          id: "",
          field: [],
        },
        has_pool: {
          id: "",
          field: [],
        },
        has_elevator: {
          id: "",
          field: [],
        },
        owner_name_1: {
          id: "",
          field: [],
        },
        owner_name_2: {
          id: "",
          field: [],
        },
        owner_address_line_1: {
          id: "",
          field: [],
        },
        owner_address_line_2: {
          id: "",
          field: [],
        },
        owner_address_line_3: {
          id: "",
          field: [],
        },
        bedrooms: {
          id: "",
          field: [],
        },
        bathrooms: {
          id: "",
          field: [],
        },
        units: {
          id: "",
          field: [],
        },
        stories: {
          id: "",
          field: [],
        },
        provided_use_code: {
          id: "",
          field: [],
        },
        provided_use_name: {
          id: "",
          field: [],
        },
        latitude: {
          id: "",
          field: [],
        },
        longitude: {
          id: "",
          field: [],
        },
        str_policy: {
          id: "",
          field: [],
        },
        homestead_exemption: {
          id: "",
          field: [],
        },
        community: {
          id: "",
          field: [],
        },
        vacation_rental_district: {
          id: "",
          field: [],
        },
        code_enforcement_area_name: {
          id: "",
          field: [],
        },
        code_officer: {
          id: "",
          field: [],
        },
        route_post_directional: {
          id: "",
          field: [],
        },
        route_post_type: {
          id: "",
          field: [],
        },
        route_name: {
          id: "",
          field: [],
        },
        street_number: {
          id: "",
          field: [],
        },
        secondary_id: {
          id: "",
          field: [],
        },
      },
      enabled: false,
    },
  });
  const { getValues, setValue, watch } = methods;

  const [
    username,
    password,
    token_endpoint,
    data_endpoints,
    mappings,
    enabled,
  ] = watch([
    "username",
    "password",
    "token_endpoint",
    "data_endpoints",
    "mappings",
    "enabled",
  ]);

  const mappingContext: Mappings = { ...state.esriConfig.mappings };
  delete mappingContext["__typename"];

  const esriConfigContext = {
    username: state.esriConfig.username,
    password: state.esriConfig.password,
    token_endpoint: state.esriConfig.token_endpoint,
    data_endpoints: state.esriConfig.data_endpoints,
    mappings: Object.entries(mappingContext).sort(),
    enabled: state.esriConfig.enabled,
  };
  const esriConfigForm = {
    username,
    password,
    token_endpoint,
    data_endpoints,
    mappings: Object.entries(mappings).sort(),
    enabled,
  };

  const isDirty = !(
    JSON.stringify(esriConfigContext) === JSON.stringify(esriConfigForm)
  );

  useEffect(() => {
    setValue("username", state.esriConfig.username);
    setValue("password", state.esriConfig.password);
    setValue("token_endpoint", state.esriConfig.token_endpoint);

    setValue("data_endpoints", state.esriConfig.data_endpoints);

    setValue(
      "mappings.property_id.id",
      state.esriConfig.mappings.property_id.id
    );
    setValue(
      "mappings.property_id.field",
      state.esriConfig.mappings.property_id.field
    );
    setValue(
      "mappings.street_address.id",
      state.esriConfig.mappings.street_address.id
    );
    setValue(
      "mappings.street_address.field",
      state.esriConfig.mappings.street_address.field
    );
    setValue(
      "mappings.unit_number.id",
      state.esriConfig.mappings.unit_number.id
    );
    setValue(
      "mappings.unit_number.field",
      state.esriConfig.mappings.unit_number.field
    );
    setValue("mappings.locality.id", state.esriConfig.mappings.locality.id);
    setValue(
      "mappings.locality.field",
      state.esriConfig.mappings.locality.field
    );
    setValue("mappings.state.id", state.esriConfig.mappings.state.id);
    setValue("mappings.state.field", state.esriConfig.mappings.state.field);
    setValue(
      "mappings.postal_code.id",
      state.esriConfig.mappings.postal_code.id
    );
    setValue(
      "mappings.postal_code.field",
      state.esriConfig.mappings.postal_code.field
    );
    setValue("mappings.country.id", state.esriConfig.mappings.country.id);
    setValue("mappings.country.field", state.esriConfig.mappings.country.field);
    setValue("mappings.has_pool.id", state.esriConfig.mappings.has_pool.id);
    setValue(
      "mappings.has_pool.field",
      state.esriConfig.mappings.has_pool.field
    );
    setValue(
      "mappings.has_elevator.id",
      state.esriConfig.mappings.has_elevator.id
    );
    setValue(
      "mappings.has_elevator.field",
      state.esriConfig.mappings.has_elevator.field
    );
    setValue(
      "mappings.owner_name_1.id",
      state.esriConfig.mappings.owner_name_1.id
    );
    setValue(
      "mappings.owner_name_1.field",
      state.esriConfig.mappings.owner_name_1.field
    );
    setValue(
      "mappings.owner_name_2.id",
      state.esriConfig.mappings.owner_name_2.id
    );
    setValue(
      "mappings.owner_name_2.field",
      state.esriConfig.mappings.owner_name_2.field
    );
    setValue(
      "mappings.owner_address_line_1.id",
      state.esriConfig.mappings.owner_address_line_1.id
    );
    setValue(
      "mappings.owner_address_line_1.field",
      state.esriConfig.mappings.owner_address_line_1.field
    );
    setValue(
      "mappings.owner_address_line_2.id",
      state.esriConfig.mappings.owner_address_line_2.id
    );
    setValue(
      "mappings.owner_address_line_2.field",
      state.esriConfig.mappings.owner_address_line_2.field
    );
    setValue(
      "mappings.owner_address_line_3.id",
      state.esriConfig.mappings.owner_address_line_3.id
    );
    setValue(
      "mappings.owner_address_line_3.field",
      state.esriConfig.mappings.owner_address_line_3.field
    );
    setValue("mappings.bedrooms.id", state.esriConfig.mappings.bedrooms.id);
    setValue(
      "mappings.bedrooms.field",
      state.esriConfig.mappings.bedrooms.field
    );
    setValue("mappings.bathrooms.id", state.esriConfig.mappings.bathrooms.id);
    setValue(
      "mappings.bathrooms.field",
      state.esriConfig.mappings.bathrooms.field
    );
    setValue("mappings.units.id", state.esriConfig.mappings.units.id);
    setValue("mappings.units.field", state.esriConfig.mappings.units.field);
    setValue("mappings.stories.id", state.esriConfig.mappings.stories.id);
    setValue("mappings.stories.field", state.esriConfig.mappings.stories.field);
    setValue(
      "mappings.provided_use_code.id",
      state.esriConfig.mappings.provided_use_code.id
    );
    setValue(
      "mappings.provided_use_code.field",
      state.esriConfig.mappings.provided_use_code.field
    );
    setValue(
      "mappings.provided_use_name.id",
      state.esriConfig.mappings.provided_use_name.id
    );
    setValue(
      "mappings.provided_use_name.field",
      state.esriConfig.mappings.provided_use_name.field
    );
    setValue("mappings.latitude.id", state.esriConfig.mappings.latitude.id);
    setValue(
      "mappings.latitude.field",
      state.esriConfig.mappings.latitude.field
    );
    setValue("mappings.longitude.id", state.esriConfig.mappings.longitude.id);
    setValue(
      "mappings.longitude.field",
      state.esriConfig.mappings.longitude.field
    );
    setValue("mappings.str_policy.id", state.esriConfig.mappings.str_policy.id);
    setValue(
      "mappings.str_policy.field",
      state.esriConfig.mappings.str_policy.field
    );
    setValue(
      "mappings.homestead_exemption.id",
      state.esriConfig.mappings.homestead_exemption.id
    );
    setValue(
      "mappings.homestead_exemption.field",
      state.esriConfig.mappings.homestead_exemption.field
    );
    setValue("mappings.community.id", state.esriConfig.mappings.community.id);
    setValue(
      "mappings.community.field",
      state.esriConfig.mappings.community.field
    );
    setValue(
      "mappings.vacation_rental_district.id",
      state.esriConfig.mappings.vacation_rental_district.id
    );
    setValue(
      "mappings.vacation_rental_district.field",
      state.esriConfig.mappings.vacation_rental_district.field
    );
    setValue(
      "mappings.code_enforcement_area_name.id",
      state.esriConfig.mappings.code_enforcement_area_name.id
    );
    setValue(
      "mappings.code_enforcement_area_name.field",
      state.esriConfig.mappings.code_enforcement_area_name.field
    );
    setValue(
      "mappings.code_officer.id",
      state.esriConfig.mappings.code_officer.id
    );
    setValue(
      "mappings.code_officer.field",
      state.esriConfig.mappings.code_officer.field
    );
    setValue(
      "mappings.route_post_directional.id",
      state.esriConfig.mappings.route_post_directional?.id
        ? state.esriConfig.mappings.route_post_directional?.id
        : ""
    );
    setValue(
      "mappings.route_post_directional.field",
      state.esriConfig.mappings.route_post_directional?.field
        ? state.esriConfig.mappings.route_post_directional?.field
        : []
    );
    setValue(
      "mappings.route_post_type.id",
      state.esriConfig.mappings.route_post_type?.id
        ? state.esriConfig.mappings.route_post_type?.id
        : ""
    );
    setValue(
      "mappings.route_post_type.field",
      state.esriConfig.mappings.route_post_type?.field
        ? state.esriConfig.mappings.route_post_type?.field
        : []
    );
    setValue(
      "mappings.route_name.id",
      state.esriConfig.mappings.route_name?.id
        ? state.esriConfig.mappings.route_name?.id
        : ""
    );
    setValue(
      "mappings.route_name.field",
      state.esriConfig.mappings.route_name?.field
        ? state.esriConfig.mappings.route_name?.field
        : []
    );
    setValue(
      "mappings.street_number.id",
      state.esriConfig.mappings.street_number?.id
        ? state.esriConfig.mappings.street_number?.id
        : ""
    );
    setValue(
      "mappings.street_number.field",
      state.esriConfig.mappings.street_number?.field
        ? state.esriConfig.mappings.street_number?.field
        : []
    );
    setValue(
      "mappings.secondary_id.id",
      state.esriConfig.mappings.secondary_id?.id
        ? state.esriConfig.mappings.secondary_id?.id
        : ""
    );
    setValue(
      "mappings.secondary_id.field",
      state.esriConfig.mappings.secondary_id?.field
        ? state.esriConfig.mappings.secondary_id?.field
        : []
    );

    setValue("enabled", state.esriConfig.enabled);
  }, [state.configLoaded]);

  const handleNext = () => {
    switch (activeStep) {
      case StepperSteps.AUTH: {
        const [token_endpoint, username, password] = getValues([
          "token_endpoint",
          "username",
          "password",
        ]);
        if (
          !(
            (username && password && token_endpoint) ||
            (!username && !password && !token_endpoint)
          )
        ) {
          dispatch(
            createNotification({
              message: PARTLY_CONFIGURATION,
              type: "info",
            })
          );
          return;
        }
        return setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      case StepperSteps.ENDPOINTS: {
        const endpoints: DataEndpoint[] = getValues("data_endpoints");

        if (endpoints.length == 0) {
          dispatch(
            createNotification({
              message: EMPTY_ENDPOINTS,
              type: "info",
            })
          );
          return;
        } else if (endpoints.length == 1) {
          const endpoint = endpoints[0];

          if (endpoint.join_column) {
            dispatch(
              createNotification({
                message: JOIN_COLUMN_NOT_NEEDED,
                type: "info",
              })
            );
            return;
          }
          if (!endpoint.url) {
            dispatch(
              createNotification({
                message: EMPTY_URL,
                type: "info",
              })
            );
            return;
          }
          if (!endpoint.url.endsWith("/query")) {
            dispatch(
              createNotification({
                message: WRONG_URL,
                type: "info",
              })
            );
            return;
          }
        } else {
          const errors: DataEndpoint[] = [];
          endpoints.forEach((endpoint) => {
            if (!endpoint.join_column) {
              dispatch(
                createNotification({
                  message: JOIN_COLUMN_REQUIRED,
                  type: "info",
                })
              );
              return errors.push(endpoint);
            }
            if (!endpoint.url) {
              dispatch(
                createNotification({
                  message: EMPTY_URL,
                  type: "info",
                })
              );
              return errors.push(endpoint);
            }
          });

          if (errors.length) return;
        }
        return setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      default:
        return setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const moveToBeginning = () => {
    setActiveStep(0);
  };

  const renderStepComponent = (step: number) => {
    switch (step) {
      case 0:
        return <WelcomePage />;
      case 1:
        return <AuthPage />;
      case 2:
        return <EndpointsPage />;
      case 3:
        return <MappingsPage moveToBeginning={moveToBeginning} />;
      default:
        return;
    }
  };

  return (
    <Container maxWidth="sm" sx={{ height: "100%" }}>
      <Snackbar
        open={isDirty && state.esriConfig.id !== null}
        message="You have unsaved changes."
        severity="info"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
      <Typography variant="h5" textAlign="center" sx={{ marginBottom: 5 }}>
        {STEPS[activeStep]}
      </Typography>
      <FormProvider {...methods}>
        {renderStepComponent(activeStep)}
      </FormProvider>
      <MobileStepper
        steps={STEPS.length}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            sx={{
              visibility:
                activeStep === STEPS.length - 1 ? "hidden" : "visible",
            }}
            onClick={handleNext}
          >
            Next
          </Button>
        }
        backButton={
          <Button
            size="small"
            sx={{ visibility: activeStep === 0 ? "hidden" : "visible" }}
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            Back
          </Button>
        }
      />
    </Container>
  );
};

export default EsriStepper;
