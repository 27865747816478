import React from "react";
import {
  Button,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface ModalButton {
  text: string;
  onClick?: () => void;
  variant: string;
  color: string;
  disabled?: boolean;
  loading?: boolean;
}

interface ModalRadioButton {
  value: string | boolean;
  label: string;
}

interface ModalRadio {
  name: string;
  value: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  buttons: ModalRadioButton[];
  label: string;
}

interface ModalInput {
  name: string;
  value: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  label: string;
  placeholder?: string;
  helperText?: string;
}

interface GenericModalBodyProps {
  children?: React.ReactNode;
  buttons: ModalButton[];
  text?: string;
  viewType?: string;
  radio?: ModalRadio;
  input?: ModalInput;
}

const GenericModalBody = (props: GenericModalBodyProps) => {
  return (
    <>
      {props.viewType === "text" && props.text && (
        <Typography
          variant="body1"
          style={{ marginTop: "10px", minHeight: "50px" }}
        >
          {props.text}
        </Typography>
      )}
      {props.viewType === "radio" && (
        <>
          <FormLabel component="legend">{props.radio?.label}</FormLabel>
          <RadioGroup
            aria-label="report-issue"
            name="report-issue"
            value={props.radio?.value}
            onChange={props.radio?.onChange}
            style={{ display: "flex", flexDirection: "row" }}
          >
            {props.radio?.buttons.map(
              (raidoButton: any, radioButtonID: number) => {
                return (
                  <FormControlLabel
                    value={raidoButton.value}
                    control={<Radio />}
                    label={raidoButton.label}
                    key={radioButtonID}
                  />
                );
              }
            )}
          </RadioGroup>
        </>
      )}
      {props.viewType === "input" && (
        <>
          <FormLabel component="legend" htmlFor={props.input?.name}>
            {props.input?.label}
          </FormLabel>
          <TextField
            id={props.input?.name}
            name={props.input?.name}
            value={props.input?.value || ""}
            onChange={props.input?.onChange}
            type={props.input?.type}
            placeholder={props.input?.placeholder}
            fullWidth
            style={{ marginTop: "10px" }}
            helperText={props.input?.helperText}
            FormHelperTextProps={{
              style: {
                marginLeft: 0,
                marginTop: "10px",
                fontSize: "1rem",
              },
            }}
          />
        </>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        {props.buttons.map((button: any, buttonID: number) =>
          button.loading ? (
            <LoadingButton
              key={buttonID}
              variant={button.variant}
              color={button.color}
              style={{ marginLeft: "10px" }}
              disabled={button.disabled}
              loading={button.loading}
            >
              {button.text}
            </LoadingButton>
          ) : (
            <Button
              key={buttonID}
              variant={button.variant}
              color={button.color}
              style={{ marginLeft: "10px" }}
              onClick={button.onClick}
              disabled={button.disabled}
            >
              {button.text}
            </Button>
          )
        )}
      </div>
    </>
  );
};

export default GenericModalBody;
