import React, { useState } from "react";
import { Box, Link } from "@mui/material";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import "./PendingRegistrationModalCell.scss";

interface PendingRegistrationModalCellProps {
  title: string;
  cellText: string;
  children: any;
  modalWidth: string;
}

export const PendingRegistrationModalCell = (
  cellProps: PendingRegistrationModalCellProps
) => {
  const [open, setOpen] = useState<boolean>(false);

  const closeModal = () => {
    setOpen(false);
  };

  const cellText = cellProps.cellText || "";

  return (
    <>
      {cellText.toLowerCase() === "pending" ? (
        <>
          <Link
            onClick={() => setOpen(true)}
            className={"link"}
            sx={{ cursor: "pointer" }}
          >
            {cellText + " Approve or Deny"}
          </Link>
          <ModalComponent
            header={cellProps.title}
            open={open}
            onClose={closeModal}
            width={cellProps.modalWidth}
            sx={{
              margin: "auto",
            }}
          >
            {React.cloneElement(cellProps.children, {
              closeModal: closeModal,
            })}
          </ModalComponent>
        </>
      ) : (
        <Box className={"nonEditableGridCell"}>{cellText}</Box>
      )}
    </>
  );
};
