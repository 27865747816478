import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { EditAddress } from "./EditAddress/EditAddress";
import { PinOnMap } from "./PinOnMap/PinOnMap";
import { VerifyMatchDetails } from "./VerifyMatchDetails/VerifyMatchDetails";
import "./Steps.scss";
import { loader } from "graphql.macro";
import { ApolloError, useMutation } from "@apollo/client";
import { ReviewChanges } from "./ReviewChanges/ReviewChanges";
import { VerifyLetters } from "./VerifyLetters/VerifyLetters";
import { Letter, RentalUnitContext } from "../../../RentalUnitContext";
import CloseIcon from "@mui/icons-material/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

const updateAIMutation = loader("../EditAddressInformation.graphql");

interface StepsProps {
  onFormDataChanged: (data: FormDataInterface) => void;
  onSubmit: () => void;
  onClose: () => void;
  initialForm: FormDataInterface;
}

export interface Evidence {
  listing_id: string;
  keep: number[];
  discard: number[];
}

export interface FormDataInterface extends Object {
  id: string;
  internal_listing_ids: string[];
  formatted: string;
  latitude: number;
  longitude: number;
  unit_number: string;
  parcel_number: string;
  comments: {
    comment: string | null;
    internal_listing_id: string;
  }[];
  remove_letters: string[];
  evidences: Evidence[] | undefined;
}

export const Steps = (props: StepsProps) => {
  const [step, setStep] = useState<number>(0);
  const { listings } = useContext(RentalUnitContext);
  const [lettersFullData, setLettersFullData] = useState<Letter[]>([]);
  const [letters] = useState<(Letter | null)[]>([
    ...new Set(
      listings?.hits
        .map((item) => item.letters)
        .flat(1)
        .filter((item) => item !== undefined) || []
    ),
  ]);
  const [steps] = useState<string[]>(
    letters.length !== 0
      ? [
          "Edit address",
          "Set location",
          "Verify match details",
          "Verify letters",
          "Review changes",
        ]
      : [
          "Edit address",
          "Set location",
          "Verify match details",
          "Review changes",
        ]
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [isButtonDisable, setIsButtonDisable] = useState<boolean>(false);

  const [updateRentalUnit] = useMutation(updateAIMutation, {
    refetchQueries: ["getRentalUnitDetails"],
    awaitRefetchQueries: true,
    onCompleted: props.onSubmit,
    onError: (error: ApolloError) => {
      if (error.message) {
        setErrorMessage(error.message);
        setHasError(true);
      }
      setLoading(false);
    },
  });

  const [formData, setFormData] = useState<FormDataInterface>(
    props.initialForm
  );

  const checkChanges = () => {
    return JSON.stringify(formData) !== JSON.stringify(props.initialForm);
  };

  const handleSubmit = () => {
    resetError();
    if (!checkChanges()) {
      props.onClose();
      return;
    }
    setLoading(true);
    updateRentalUnit({
      variables:
        formData.parcel_number === "Unknown"
          ? { ...formData, parcel_number: "" }
          : formData,
    });
  };

  const resetError = () => {
    setHasError(false);
    setErrorMessage("");
  };

  useEffect(() => {
    props.onFormDataChanged(formData);
    if (step === 0 && formData.formatted.length === 0) {
      setIsButtonDisable(true);
    } else if (step === 2) {
      const allCommentsNonEmpty = formData.comments.every((commentObj) => {
        const comment = commentObj.comment;
        return comment !== null && comment.trim() !== "";
      });

      if (!allCommentsNonEmpty) {
        setIsButtonDisable(true);
      } else {
        setIsButtonDisable(false);
      }
    } else {
      setIsButtonDisable(false);
    }
  }, [formData]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Stepper className="stepCounter" activeStep={step} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box height={500} sx={{ overflow: "scroll" }}>
        {hasError && (
          <Alert
            severity="error"
            onClose={() => setHasError(false)}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  resetError();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorMessage}
          </Alert>
        )}
        {step === 0 && (
          <EditAddress
            initialForm={props.initialForm}
            formValues={formData}
            setEditAddressValues={(value) =>
              setFormData({ ...formData, ...value })
            }
          />
        )}
        {step === 1 && (
          <PinOnMap
            setCoordinates={(value) => setFormData({ ...formData, ...value })}
            formData={formData}
          />
        )}
        {step === 2 && (
          <VerifyMatchDetails
            formData={formData}
            initialForm={props.initialForm}
            setVerifyMatchDetailsValues={(value) =>
              setFormData({ ...formData, ...value })
            }
          />
        )}

        {steps.length === 5 && step === 3 && (
          <VerifyLetters
            setVerifyLettersValues={(values) =>
              setFormData({ ...formData, ...values })
            }
            formData={formData}
            setRemoveLettersFullData={(values) => setLettersFullData(values)}
          />
        )}
        {step === steps.length - 1 && (
          <ReviewChanges
            formData={formData}
            initialForm={props.initialForm}
            jumpToStep={(step: number) => {
              resetError();
              setStep(step);
            }}
            steps={steps}
            lettersData={lettersFullData}
          />
        )}
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "row", pt: 2, margin: "20px" }}
      >
        <Box sx={{ alignSelf: "center", padding: "0px 10px" }}>
          {isButtonDisable && (
            <Typography variant="body2" className={"errorMessage"}>
              <FontAwesomeIcon
                icon={faCircleExclamation}
                className={"errorMessage"}
                size="lg"
              />
              Please fill the required fields in the above section to proceed
              further.
            </Typography>
          )}
        </Box>
        <Box sx={{ flex: "1 1 auto" }} />
        {step >= 1 && (
          <Button
            className="navButton"
            variant="outlined"
            onClick={() => {
              resetError(), setStep(step - 1);
            }}
            sx={{ textTransform: "capitalize" }}
          >
            Previous
          </Button>
        )}
        {step < steps.length - 1 && (
          <Button
            className="navButton"
            variant="contained"
            onClick={() => {
              resetError(), setStep(step + 1);
            }}
            sx={{ textTransform: "capitalize" }}
            disabled={isButtonDisable}
          >
            Next
          </Button>
        )}
        {step === steps.length - 1 && (
          <Button
            onClick={handleSubmit}
            className="navButton"
            variant="contained"
            disabled={loading}
            sx={{ textTransform: "capitalize" }}
          >
            {checkChanges() ? (loading ? "Submitting" : "Submit") : "Close"}
            {loading ? (
              <CircularProgress
                size={20}
                sx={{ marginLeft: 1 }}
                color="secondary"
              />
            ) : null}
          </Button>
        )}
      </Box>
    </Box>
  );
};
