import {
  Avatar,
  Backdrop,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { formatDateTime } from "../utils";

const LimitedBackdrop = withStyles({
  root: {
    position: "absolute",
    zIndex: 1,
    backgroundColor: "#fff",
  },
})(Backdrop);

const CommentsList = ({ comments, isCommentsFetched }: any) => {
  /**
   * Get initials of username
   * @param name
   * @returns
   */
  const getInitials = (name: string) => {
    if (name.includes("@")) {
      return `${name.charAt(0).toUpperCase()}`;
    }
    const fullName = name.split(" ");
    if (fullName && fullName.length > 0) {
      return `${fullName.shift()?.charAt(0).toUpperCase()}${fullName
        .pop()
        ?.charAt(0)
        .toUpperCase()}`;
    }
  };

  /**
   * Creates Letter avatar of user's initials
   * @param name
   * @returns
   */
  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: "rgba(16, 50, 85, 0.5)",
      },
      children: name ? getInitials(name) : undefined,
    };
  };

  const capitalizeInitials = (fullName: string): string => {
    // If name is same as email Id then return email Id
    if (fullName.includes("@")) {
      return fullName;
    }

    return fullName
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <>
      <LimitedBackdrop open={!isCommentsFetched}>
        <CircularProgress sx={{ color: "#003258" }} />
      </LimitedBackdrop>

      {isCommentsFetched ? (
        <div className="commentsList">
          {comments && comments.length > 0 ? (
            <List>
              {comments?.map((comment: any, index: any) => {
                const author = comment.author?.name || comment.author?.email;
                const body = comment.body.replaceAll("<br />", "\n");
                return (
                  <div key={index}>
                    <ListItem className="commentsListItem">
                      <ListItemAvatar className="commentUserAvatar">
                        <Avatar {...stringAvatar(author)} />
                      </ListItemAvatar>
                      <ListItemText className="commentsList">
                        <div className="commentsAuthor">
                          {capitalizeInitials(author)}
                        </div>
                        <div className="commentsBody">{body}</div>
                        {comment.upload?.files?.map(
                          (file: any, index: number) => {
                            return (
                              <div key={index} className="commentsFile">
                                <a
                                  href={file.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <IconButton
                                    sx={{
                                      padding: 0,
                                      "&.MuiButtonBase-root:hover": {
                                        bgcolor: "transparent",
                                      },
                                    }}
                                  >
                                    <AttachFileIcon fontSize="small"></AttachFileIcon>
                                  </IconButton>
                                </a>{" "}
                                <a
                                  href={file.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {file.original_filename}
                                </a>
                              </div>
                            );
                          }
                        )}
                        <div className="commentsTime">
                          {formatDateTime(comment.created_at)}
                        </div>
                      </ListItemText>
                    </ListItem>
                    <Divider light />
                  </div>
                );
              })}
            </List>
          ) : (
            "There are no comments to display"
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default CommentsList;
