import React, { useEffect, useState } from "react";
import { withApollo } from "@apollo/client/react/hoc";
import { useQuery, gql } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";

import env from "../../common/env";
import { featureFlags } from "../../common/feature-flags";
import { LoadingBar } from "../../common/loading";
import { QuestionnairesProvider } from "../../common/questionnaires/provider";
import { useLocation, useHistory } from "react-router-dom";
import { queryStringToObject } from "../common/utils";

const GET_QJSON = gql`
  query ($mode: Mode, $questionnaireId: ID!, $jurisdictionId: ID) {
    questionnaire(
      id: $questionnaireId
      jurisdiction_id: $jurisdictionId
      mode: $mode
    ) {
      properties
      hooks
      questions
      version
    }
  }
`;

function View({ match }) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  let userFeatures = featureFlags.get();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryString = queryStringToObject(searchParams);
  const history = useHistory();

  const { data, loading } = useQuery(GET_QJSON, {
    variables: Object.assign(match.params, {
      jurisdictionId: localStorage.getItem("jurisdictionSlug"),
      mode: userFeatures.questionnnaires_test === true ? "TEST" : "LIVE",
    }),
  });

  const [context, setContext] = useState({});

  useEffect(() => {
    if (!data) return;
    async function getToken() {
      let params = location.state
        ? { ...location.state }
        : { ...match.params, ...queryString };
      let token, decodedToken;
      if (isAuthenticated) {
        token = await getAccessTokenSilently();
        decodedToken = jwt_decode(token);
      } else {
        token = null;
        decodedToken = {};
      }

      setContext({
        checksum: data.questionnaire.properties.checksum,
        config: {
          API_URL: env.API_URL,
          GOOGLE_MAPS_API_KEY: env.GOOGLE_MAPS_API_KEY,
        },
        params: params,
        session: {
          token_type: "Bearer",
          access_token: token,
          access_token_decoded: {
            // TECHDEBT: remove, QJSON to move to scope
            payload: {
              scope: decodedToken.scope,
            },
          },
          metadata: decodedToken["https://api.hostcompliance.com/metadata"],
          scope: decodedToken.scope,
        },
        admin: decodedToken.iss === "https://hostcompliance.auth0.com/", // TECHDEBT: remove, QJSON to move to scope
        value: searchParams.get("value"),
        data: {
          jurisdiction: {
            id: localStorage.getItem("jurisdiction"),
          },
        },
      });
    }
    getToken();
  }, [data, loading]);

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="questionnaires">
      {loading && <LoadingBar />}
      {!loading && context.config && (
        <div>
          <QuestionnairesProvider
            qjson={data.questionnaire}
            questionId={match.params.questionId}
            context={context}
            prefix={`com.hostcompliance.questionnaire${
              match.params.jurisdictionId
                ? "." + match.params.jurisdictionId
                : ""
            }.${match.params.questionnaireId}`}
            goBack={goBack}
          ></QuestionnairesProvider>
        </div>
      )}
    </div>
  );
}

export default withApollo(View);
