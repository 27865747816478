
const dot = require('dot-object');
import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import { namedArray } from './util';
import parser from './parser';

function View({ question, context }) {
  const options = namedArray(question.input)
    .map(o=>{
      o.active = parser.parse(o.active, context);
      return o;
    })
    .filter(o=>o.active!==false)
  ;

  const flex = options.length % 2 ? 4 : 5;

  const [formValues, setFormValues] = useState({
    output: context.pick('value') || ''
  });

  const handleRadioChange = (o) => {
    question.properties.action = o.action;

    let values = {...formValues};
    dot.str('output', o.value, values);
    setFormValues(values);
    context.assign({
      value: values.output
    });
  };

  return (
    <div dir={question.properties.locale.dir} className="inputs inputs-radio">

      <input
        id="radio"
        name="radio"
        value={formValues.radio}
        type="hidden"
      />

      <Grid container spacing={0}>
        {
          options.map(o=>{
            return (
              <Grid item xs={12} md={flex} key={o.id}>
                <Button type="submit" disabled={question.input.readonly} onClick={()=>handleRadioChange(o)}>
                  {parser.parse(question.properties.locale[o.locale_key], context)}
                  {
                    o.locale_key_more && (
                      <small><br />{parser.parse(question.properties.locale[o.locale_key_more], context)}</small>
                    )
                  }
                </Button>
              </Grid>
            );
          })
        }
      </Grid>
    </div>
  );
}

export default View;
