import React from "react";

import { gql } from "@apollo/client";
import { GridColDef } from "@mui/x-data-grid-pro";

import SearchView from "../../components/SearchView/SearchView";
import {
  getTableMeta,
  getEditableFieldOptions,
} from "../common/data-grid-cols";

import "./RegistrationsPage.scss";

const RegistrationsPage = () => {
  const { columns, filters, comment_id_column } = getTableMeta("registrations");
  const editableFieldOptions = getEditableFieldOptions("permit_registration");

  const registrationsQuery = (
    columnsToFilter: GridColDef[],
    commentsIdColumn?: string
  ) => {
    const queryFields = columnsToFilter
      .filter((column) => !column.hide)
      .map((column) => {
        if (column.field === "add_view_comments_modal") {
          return `add_view_comments_modal {
            unread
            count
            total
            foreign_id
            foreign_type
          }`;
        } else {
          return column.field;
        }
      })
      .join("\n");

    return gql`
      query($q: KibanaQL="", $phrase: String, $size: Int, $from: Int, $sort: String) {
        results: permit_tables(
          search: { query: $q, phrase: $phrase, size: $size, from: $from, sort: $sort }
        ) {
          size
          total
          hits {
            id
            ${commentsIdColumn}
            ${queryFields}
          }
        }
      }
    `;
  };

  return (
    <SearchView
      searchQuery={registrationsQuery}
      columns={columns}
      filters={filters}
      commentIdColumn={comment_id_column}
      title="Registrations"
      editableFieldOptions={editableFieldOptions}
      tableType={"permit_registration"}
    />
  );
};

export default RegistrationsPage;
