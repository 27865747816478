import React, { useState, ChangeEvent } from "react";
import { FormControl, Box } from "@mui/material";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import GenericModalBody from "../../../../components/ModalComponent/GenericModalBody";

interface RefundProps {
  row: any;
  setSnackbarData?: any;
  closeModal?: any;
  reloadGrid: () => any;
}

const refundOrReturnPayment = loader("../../RefundOrReturnPayment.graphql");

export const RefundModalBody = (props: RefundProps) => {
  const SELECT_REFUND_OPTIONS = [
    {
      value: "full_refund",
      label: "Mark the payment as 100% returned",
    },
    {
      value: "partial_refund",
      label: "Refund part of this payment",
    },
  ];

  const [step, setStep] = useState<string>("initial");
  const rowParams = props.row;
  const tax = rowParams.tax;
  const id = rowParams.id;
  const [refundType, setRefundType] = useState<string>("full_refund");
  const [refundExplanation, setRefundExplanation] = useState<string>("");
  const [refundAmount, setRefundAmount] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [refundOrReturnPaymentMutation] = useMutation(refundOrReturnPayment);

  const handleRefundTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRefundType(event.target.value);
  };

  const handleRefundExplanationChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setRefundExplanation(event.target.value);
  };

  const handleRefundAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    if (!isNaN(value)) {
      setRefundAmount(value);
    } else {
      setRefundAmount(undefined);
    }
  };

  const submitRefund = async () => {
    setStep("submitting");
    setLoading(true);

    try {
      await refundOrReturnPaymentMutation({
        variables: {
          id: id,
          amount: refundAmount || null,
          explanation: refundExplanation,
          type: refundType,
        },
      });
      props.setSnackbarData({
        open: true,
        message: "Submitted successfully",
        severity: "success",
        vertical: "top",
        horizontal: "center",
      });
      setLoading(false);
      props.reloadGrid();
      props.closeModal();
    } catch (error) {
      setLoading(false);
      props.setSnackbarData({
        message: "Failed to submit the request, Please try again later",
        type: "error",
        vertical: "top",
        horizontal: "center",
      });
      props.closeModal();
    }
  };

  const handleRefundTypeSelection = () => {
    switch (refundType) {
      case "full_refund":
        setStep("refund_explanation");
        break;
      case "partial_refund":
        setStep("partial_refund_input");
        break;
    }
  };

  return (
    <Box className="reportIssueBox">
      <FormControl component="fieldset" fullWidth>
        {step === "initial" && (
          <>
            {tax <= 0 && (
              <GenericModalBody
                viewType="text"
                text="You cannot mark a payment as returned or refunded because the taxable amount is $0"
                buttons={[
                  {
                    text: "Close",
                    onClick: props.closeModal,
                    variant: "contained",
                    color: "primary",
                  },
                ]}
              />
            )}
            {tax > 0 && (
              <GenericModalBody
                viewType="radio"
                radio={{
                  name: "refund-modal",
                  value: refundType,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                    handleRefundTypeChange(event),
                  buttons: SELECT_REFUND_OPTIONS,
                  label:
                    "Do you want to mark this payment as returned or record a refund?",
                }}
                buttons={[
                  {
                    text: "Next",
                    onClick: () => handleRefundTypeSelection(),
                    variant: "contained",
                    color: "primary",
                  },
                ]}
              />
            )}
          </>
        )}
        {step === "refund_explanation" && (
          <>
            <GenericModalBody
              viewType="input"
              input={{
                name: "refund-modal-refund-explanation",
                value: refundExplanation,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                  handleRefundExplanationChange(event),
                label: "Please provide an explanation for the refund",
                type: "text",
              }}
              buttons={[
                {
                  text: "Back",
                  onClick: () =>
                    refundType === "full_refund"
                      ? setStep("initial")
                      : setStep("partial_refund_input"),
                  variant: "outlined",
                  color: "primary",
                },
                {
                  text: "Next",
                  onClick: () => setStep("user_confirmation"),
                  variant: "contained",
                  color: "primary",
                  disabled: !refundExplanation.length,
                },
              ]}
            />
          </>
        )}
        {step === "partial_refund_input" && (
          <>
            <GenericModalBody
              viewType="input"
              input={{
                name: "refund-modal-partial-refund-amount",
                value: refundAmount,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                  handleRefundAmountChange(event),
                label: "What is the amount you would like to refund?",
                type: "number",
                helperText: `Please enter a value between $0 and $${tax}`,
              }}
              buttons={[
                {
                  text: "Back",
                  onClick: () => setStep("initial"),
                  variant: "outlined",
                  color: "primary",
                },
                {
                  text: "Next",
                  onClick: () => setStep("refund_explanation"),
                  variant: "contained",
                  color: "primary",
                  disabled:
                    !refundAmount || refundAmount > tax || refundAmount <= 0,
                },
              ]}
            />
          </>
        )}
        {step === "user_confirmation" && (
          <>
            <GenericModalBody
              viewType="text"
              text="We have everything we need. Are you ready to submit these changes?"
              buttons={[
                {
                  text: "Back",
                  onClick: () => setStep("refund_explanation"),
                  variant: "outlined",
                  color: "primary",
                },
                {
                  text: "Submit",
                  onClick: submitRefund,
                  variant: "contained",
                  color: "primary",
                },
              ]}
            />
          </>
        )}
        {step === "submitting" && (
          <>
            <GenericModalBody
              viewType="text"
              text="We’re applying your changes now. Because we have to recalculate some things, this may take a few seconds."
              buttons={[
                {
                  text: "Submit",
                  variant: "contained",
                  color: "primary",
                  loading: loading,
                },
              ]}
            />
          </>
        )}
      </FormControl>
    </Box>
  );
};

export default RefundModalBody;
