import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { FormDataInterface } from "../Steps";
import { Letter, RentalUnitContext } from "../../../../RentalUnitContext";
import { loader } from "graphql.macro";
import { useLazyQuery } from "@apollo/client";
import "./VerifyLetters.scss";
import { LoadingScreen } from "../../../../../../../common/loading";
import { formatDate } from "../../../../../../common/utils";

interface VerifyLettersProps {
  setVerifyLettersValues: (values: FormDataInterface) => void;
  formData: FormDataInterface;
  setRemoveLettersFullData: (lettersData: Letter[]) => void;
}

const query = loader("./VerifyLetters.graphql");

export const VerifyLetters = ({
  formData,
  setVerifyLettersValues,
  setRemoveLettersFullData,
}: VerifyLettersProps) => {
  const { listings } = useContext(RentalUnitContext);
  const [getLetters, { data: letterData, loading }] = useLazyQuery<{
    letters: { hits: Letter[] };
  }>(query, {
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [letters] = useState<(Letter | null)[]>([
    ...new Set(
      listings?.hits
        .filter((item) => formData.internal_listing_ids.includes(item.id))
        .map((item) => item.letters)
        .flat(1)
        .filter((item) => item !== undefined) || []
    ),
  ]);
  useEffect(() => {
    if (!letterData && !loading) {
      getLetters({
        variables: {
          q: `id: "${letters.map((item: any) => item?.id).join('" OR id: "')}"`,
          size: letters.length,
        },
      });
    }
  }, []);

  const [removeLetters, setRemoveLetters] = useState<string[]>([]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const id = event.target.id;
    if (id === "select-all") {
      //select all checkbox is clicked
      setSelectAllChecked(event.target.checked);
      if (event.target.checked) {
        const allLetterIds = letters.map((item: any) => item.id);
        setRemoveLetters(allLetterIds);
      } else {
        setRemoveLetters([]);
      }
    } else {
      //letter checkbox is clicked
      if (!event.target.checked) {
        setSelectAllChecked(false);
      }
      removeLetters.includes(id)
        ? setRemoveLetters(removeLetters.filter((item) => item !== id))
        : setRemoveLetters([...removeLetters, id]);
    }
  };

  useEffect(() => {
    setRemoveLettersFullData(
      letterData?.letters.hits.filter((item) =>
        removeLetters.includes(item.id)
      ) || []
    );
    setRemoveLetters(letterData?.letters.hits.map((letter) => letter.id) || []);
  }, [letterData]);

  useEffect(() => {
    if (removeLetters.length === letterData?.letters.hits.length) {
      setSelectAllChecked(true);
    }
    setVerifyLettersValues({ ...formData, remove_letters: removeLetters });
    setRemoveLettersFullData(
      letterData?.letters.hits.filter((item) =>
        removeLetters.includes(item.id)
      ) || []
    );
  }, [removeLetters]);

  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);

  return !loading ? (
    <Box sx={{ margin: "10px 30px" }}>
      <Typography sx={{ marginBottom: "20px" }}>
        Use the check boxes to remove any associated letters with this parcel.
        Letters selected will no longer count as legal notice.
      </Typography>
      {letterData && letterData.letters.hits.length > 0 && (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectAllChecked}
                onChange={handleChange}
                id="select-all"
                inputProps={{ "aria-label": "select all letters" }}
              />
            }
            label="Select all"
          />
        </FormGroup>
      )}
      {letterData && letterData.letters.hits.length > 0 ? (
        letterData.letters.hits.map((item, index) => (
          <FormControlLabel
            key={index}
            className="letterDataBox"
            sx={{ width: "100%" }}
            control={
              <Checkbox
                checked={removeLetters.includes(item.id)}
                onChange={handleChange}
                id={item.id}
                onClick={(event) => event.stopPropagation()}
              />
            }
            label={
              <Grid
                container
                spacing={0}
                columns={12}
                alignItems="flex-end"
                className="grid-container"
              >
                <Grid item xs={12} className="grid-item-xs-12">
                  <Typography variant="h6">
                    {item.customer_display_name}
                  </Typography>
                </Grid>
                <Grid item xs={1} className="grid-item-xs-1">
                  <Typography variant="body2">Status:</Typography>
                </Grid>
                <Grid item xs={5} className="grid-item-xs-5">
                  <Typography variant="body2">
                    {item.letter_recipient_status}
                  </Typography>
                </Grid>
                <Grid item xs={1} className="grid-item-xs-1">
                  <Typography variant="body2">Created at:</Typography>
                </Grid>
                <Grid item xs={5} className="grid-item-xs-5">
                  <Typography variant="body2">
                    {formatDate(item.created_at)}
                  </Typography>
                </Grid>
                {item.recipient ? (
                  <Grid item xs={1} className="grid-item-xs-1">
                    <Typography variant="body2">Sent to:</Typography>
                  </Grid>
                ) : null}
                {item.recipient ? (
                  <Grid item xs={5} className="grid-item-xs-5">
                    <Typography variant="body2">
                      {item.recipient.name}
                    </Typography>
                  </Grid>
                ) : null}
                {item.recipient ? (
                  <Grid item xs={1} className="grid-item-xs-1">
                    <Typography variant="body2">Address:</Typography>
                  </Grid>
                ) : null}
                {item.recipient ? (
                  <Grid item xs={5} className="grid-item-xs-5">
                    <Typography variant="body2">
                      {item.recipient.address}
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            }
          />
        ))
      ) : (
        <Typography variant="body1">
          No letters sent to that property
        </Typography>
      )}
    </Box>
  ) : (
    <LoadingScreen />
  );
};
