import { faChevronRight, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import { formatDate } from "../../../../../../common/utils";
import { Letter } from "../../../../RentalUnitContext";
import "./ReviewedValue.scss";
import "./ReviewedLetters.scss";

interface ReviewedValueInterface {
  formValue: Letter[];
  onClick: () => void;
}

export const ReviewedLetters = ({
  formValue,
  onClick,
}: ReviewedValueInterface) => {
  const [showEditIcon, setShowEditIcon] = useState<boolean>(false);

  return (
    <div className="flex-row flex-row-letters">
      <div className="flex-element">
        <Typography variant="body2" className="font-light">
          Removing associated letters
        </Typography>
      </div>
      <div className="flex-chevron chevron-letters">
        <FontAwesomeIcon icon={faChevronRight} style={{ height: "0.8em" }} />
      </div>
      <div
        className={classNames(
          "flex-element",
          "flex-element-letters",
          { infoGreyValue: showEditIcon },
          "hoverClassValue"
        )}
        onMouseEnter={() => setShowEditIcon(true)}
        onMouseLeave={() => setShowEditIcon(false)}
        onClick={() => onClick()}
      >
        <div className="flex-letters-group">
          {formValue.map((item) => (
            <div
              className="informationTileValue flex-letter-element"
              key={item.id}
            >
              <Typography variant="body1" className="text-emphasis">
                {item.customer_display_name}
              </Typography>
              <Typography variant="body2" className="description">
                {item.letter_recipient_status} {formatDate(item.created_at)}
              </Typography>
            </div>
          ))}
        </div>
        <div className="iconContainer">
          {showEditIcon && (
            <FontAwesomeIcon icon={faPencil} className="iconWrapperValue" />
          )}
        </div>
      </div>
    </div>
  );
};
