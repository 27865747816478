import { LoadingButton } from "@mui/lab";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  LinearProgress,
  Alert,
  Snackbar,
  CircularProgress,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import { SwipeableImageStepper } from "../../common/AutoSwipeable/SwipeableImageStepper";
import StarIcon from "@mui/icons-material/Star";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import { TemplateCriteria } from "../RentalUnitDetails/RentalUnitDetails";
import { loader } from "graphql.macro";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ApolloError, useQuery } from "@apollo/client";
import DoneIcon from "@mui/icons-material/Done";

import "./LetterSummary.scss";
import env from "../../../common/env";
import { formatDateTime } from "../../common/utils";

const templatePreviewQuery = loader("./LetterTemplatePreview.graphql");
const sendLetterQuery = loader("./SendLetter.graphql");

export interface LetterSummaryInterface {
  showLetterSummaryModal: boolean;
  storedPreviewLoading: boolean;
  storedPreview: any;
  rentalUnitData: any;
  selectedListingTable: number;
  selectedTemplate: any;
  templateThumbnail: string[];
  templateCriteria: TemplateCriteria[];
  handleModalClose: () => void;
  templateHasScreenshot: boolean;
}

const listingScreenshotsMap: Map<any, any> = new Map();

const LetterSummary = (props: any) => {
  // State variables...
  const [previewUrl, setPreviewUrl] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isRefetching, setRefetching] = useState<boolean>(false);
  const [isContentCollapsed, setContentCollapsed] = useState<boolean>(true);
  const [showSendLetterConfirmation, setShowSendLetterConfirmation] =
    useState<boolean>(false);
  const [errorMessageOnSend, setErrorMessageOnSend] = useState("");
  const [isErrorVisibleOnSend, setIsErrorVisibleOnSend] = useState(false);
  const [sendLetterClicked, setSendLetterClicked] = useState<boolean>(false);
  const [isMapPopulated, setMapPopulated] = useState<boolean>(false);

  const [selectedListing, setSelectedListing] = useState<any>({
    id: props.rentalUnitData?.listing_tables?.hits[props.selectedListingTable]
      ?.internal_listing_id,
    title:
      props.rentalUnitData?.listing_tables?.hits[props.selectedListingTable]
        ?.listing_title,
    url: props.rentalUnitData?.listing_tables?.hits[props.selectedListingTable]
      ?.listing_url,
  });
  const [selectedScreenshot, setSelectedScreenshot] = useState<any>("");

  const [previewQueryVariables, setPreviewQueryVariables] = useState({
    template_id: props.selectedTemplate.id,
    rental_unit_id: props.rentalUnitId,
    ...(props.templateHasScreenshot
      ? {
          listing: selectedListing,
          screenshot: selectedScreenshot ? selectedScreenshot : null,
        }
      : {}),
  });

  const [sendLetterQueryVariables, setSendLetterQueryVariables] = useState({
    template_id: props.selectedTemplate.id,
    rental_unit_ids: [
      {
        id: props.rentalUnitId,
        ...(props.templateHasScreenshot
          ? {
              listing: selectedListing,
              screenshot: selectedScreenshot ? selectedScreenshot : null,
            }
          : {}),
      },
    ],
    test: env.ENVIRONMENT === "production" ? false : true,
  });

  // GraphQL queries
  const { refetch } = useQuery(templatePreviewQuery, {
    variables: previewQueryVariables,
    skip: true,
    onError: (error: ApolloError) => {
      populateErrorMessage(error.message);
      setPreviewUrl(null);
    },
  });

  const {
    data: sendLetterStatus,
    loading: sendLetterLoading,
    refetch: refetchSendLetter,
  } = useQuery(sendLetterQuery, {
    variables: sendLetterQueryVariables,
    skip: !sendLetterClicked,
    onError: (error: ApolloError) => {
      populateErrorMessageOnSend(error.message);
    },
  });

  useEffect(() => {
    populateListingScreenshotsMap();
  }, []);

  // Fetch letter preview data from the server
  useEffect(() => {
    // Fetch stored preview if available, else fetch from the server
    if (props.storedPreview) {
      setPDFUrl(props.storedPreview.letter_storedpreview);
    } else {
      setPreviewUrl(null);
    }
  }, [props.storedPreview]);

  useEffect(() => {
    // Call refetch here with updated state variables
    setPreviewQueryVariables({
      template_id: props.selectedTemplate.id,
      rental_unit_id: props.rentalUnitId,
      ...(props.templateHasScreenshot
        ? {
            listing: selectedListing,
            screenshot: selectedScreenshot ? selectedScreenshot : null,
          }
        : {}),
    });
    setSendLetterQueryVariables({
      template_id: props.selectedTemplate.id,
      rental_unit_ids: [
        {
          id: props.rentalUnitId,
          ...(props.templateHasScreenshot
            ? {
                listing: selectedListing,
                screenshot: selectedScreenshot ? selectedScreenshot : null,
              }
            : {}),
        },
      ],
      test: env.ENVIRONMENT === "production" ? false : true,
    });
  }, [selectedListing, selectedScreenshot]);

  useEffect(() => {
    if (isMapPopulated) {
      // Set default listing and screenshot in dropdown if provided, else set the first available
      let defaultListing, defaultScreenshot;

      if (props.defaultSelectedListing && props.defaultSelectedScreenshot) {
        const result = getListingAndScreenshotByDate(
          props.defaultSelectedListing,
          props.defaultSelectedScreenshot
        );

        if (result) {
          ({ listing: defaultListing, screenshot: defaultScreenshot } = result);
        }
      }

      // If no default values provided for listing and screenshot, set the first listing and first screenshot from the map
      if (!defaultListing) {
        defaultListing = listingScreenshotsMap.keys().next().value;
        defaultScreenshot = Array.from(
          listingScreenshotsMap.get(defaultListing)
        )[0];
      }

      setSelectedListing(defaultListing);
      setSelectedScreenshot(defaultScreenshot);
    }
  }, [
    isMapPopulated,
    props.defaultSelectedListing,
    props.defaultSelectedScreenshot,
    listingScreenshotsMap,
  ]);

  const isEqual = (objA: any, objB: any) => {
    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
      return false;
    }

    for (const key of keysA) {
      if (objA[key] !== objB[key]) {
        return false;
      }
    }

    return true;
  };

  const populateListingScreenshotsMap = () => {
    listingScreenshotsMap.clear();
    setMapPopulated(false);
    const { rentalUnitData } = props;
    const listings = rentalUnitData?.listings?.hits || [];
    const listingTables = rentalUnitData?.listing_tables?.hits || [];

    listingScreenshotsMap.set(selectedListing, selectedScreenshot);

    for (const listing of listingTables) {
      const screenshots = listings
        .filter((list: { id: any }) => list.id === listing.internal_listing_id)
        .flatMap(
          (filteredListing: { screenshots: any[] }) =>
            filteredListing.screenshots
        )
        .map((screenShot: { created_at: any; file: { url: any } }) => ({
          created_at: screenShot.created_at,
          url: screenShot.file.url,
        }));

      const listingInfo = {
        id: listing.internal_listing_id,
        title: listing.listing_title,
        url: listing.listing_url,
      };

      if (screenshots && screenshots.length > 0) {
        if (isEqual(selectedListing, listingInfo)) {
          listingScreenshotsMap.set(selectedListing, screenshots);
        } else {
          listingScreenshotsMap.set(listingInfo, screenshots);
        }
      }
    }

    setMapPopulated(listingScreenshotsMap.size > 0);
  };

  const getListingAndScreenshotByDate = (
    listingId: string,
    createdDate: string
  ) => {
    let foundListing = null;
    let foundScreenshot = null;

    for (const [listingInfo, screenshots] of listingScreenshotsMap.entries()) {
      if (listingInfo.id === listingId) {
        foundListing = listingInfo;
        foundScreenshot = screenshots.find(
          (screenshot: any) => screenshot.created_at === createdDate
        );
        break;
      }
    }

    return foundListing && foundScreenshot
      ? { listing: foundListing, screenshot: foundScreenshot }
      : null;
  };

  /**
   * Fetches the letter preview data from the server.
   * and sets the PDF URL using the response data.
   */
  const fetchData = () => {
    if (errorMessage !== "" || previewUrl === null) {
      setErrorMessage("");
      setRefetching(true);

      refetch(previewQueryVariables)
        .then((response: any) => {
          setTimeout(() => {
            setPDFUrl(response.data.letter_preview);
            setRefetching(false);
          }, 10000);
        })
        .catch((error: ApolloError) => {
          populateErrorMessage(error.message);
          setRefetching(false);
        });
    }
  };

  // Set PDF URL for the preview
  const setPDFUrl = (letterPreviewResponse: any) => {
    if (letterPreviewResponse?.preview) {
      setPreviewUrl(letterPreviewResponse?.preview);
    }
  };

  /**
   * Populates the error message and displays the error message.
   * @param error The error message to be displayed.
   */
  const populateErrorMessage = (error: string) => {
    setErrorMessage(error);
    setIsErrorVisible(true);
    setPreviewUrl(null);
  };

  /**
   * Populates the error message on send and displays the error message.
   * @param error The error message to be displayed.
   */
  const populateErrorMessageOnSend = (error: string) => {
    setErrorMessageOnSend(error);
    setIsErrorVisibleOnSend(true);
  };

  /**
   * Opens a new tab and loads the preview URL in the new tab.
   */
  const loadPreview = () => {
    const newTab = window.open();
    if (newTab) {
      newTab.location.href = previewUrl;
    }
  };

  // Proceed to send the letter and show confirmation
  const sendLetterAndShowConfirmation = () => {
    if (errorMessageOnSend !== "") {
      setErrorMessageOnSend("");
      refetchSendLetter(sendLetterQueryVariables).catch((error: ApolloError) =>
        populateErrorMessageOnSend(error.message)
      );
    } else {
      setSendLetterClicked(true);
    }
  };

  const handleListingChange = (event: any) => {
    const selectedOption = event.target.value;
    setSelectedListing(selectedOption);
    setSelectedScreenshot(
      Array.from(listingScreenshotsMap.get(selectedOption))[0]
    );
    setPreviewUrl(null);
  };

  const handleScreenshotChange = (event: any) => {
    setSelectedScreenshot(event.target.value);
    setPreviewUrl(null);
  };

  /**
   * Retrieves the formatted rental unit address.
   * If the address contains multiple lines (separated by commas), it splits the address and renders each line separately.
   * @returns {JSX.Element} The formatted rental unit address.
   */
  const getRentalUnitAddress = () => {
    const formatted_address =
      props.rentalUnitData?.rentalUnit?.situs_address?.formatted;
    if (formatted_address && formatted_address.split(",").length > 1) {
      return (
        <>
          <div>
            {formatted_address.substring(0, formatted_address.indexOf(","))}
          </div>
          <div>
            {formatted_address.substring(formatted_address.indexOf(",") + 1)}
          </div>
        </>
      );
    }
    return <div>formatted_address</div>;
  };

  /**
   * Retrieves the formatted owner address.
   * @returns {JSX.Element} The formatted owner address.
   */
  const getOwnerAddress = () => {
    const owner = props.rentalUnitData?.rentalUnit?.owner;
    return (
      <>
        <div>{owner?.name}</div>
        {owner?.name_secondary && <div>{owner?.name_secondary}</div>}
        <div>
          {owner?.mailing_address?.line_1}
          <br />
          {`${
            owner?.mailing_address?.city
              ? owner?.mailing_address?.city + ", "
              : ""
          }${
            owner?.mailing_address?.state
              ? owner?.mailing_address?.state + " "
              : ""
          }${
            owner?.mailing_address?.postal_code
              ? owner?.mailing_address?.postal_code + " "
              : ""
          }${
            owner?.mailing_address?.country_code
              ? ", " + owner?.mailing_address?.country_code
              : ""
          }`}
        </div>
      </>
    );
  };

  // Get formatted description for large criteria
  const getLargeCriteriaDescription = (description: string) => {
    if (isContentCollapsed) {
      return description.substring(0, 110);
    }
    return description;
  };

  // Proceed to send the letter
  const proceedToSend = () => {
    setShowSendLetterConfirmation(true);
  };

  return (
    <>
      <div>
        <ModalComponent
          header={
            showSendLetterConfirmation
              ? "Send Letter: Confirmation Required"
              : "Send Letter"
          }
          open={props.showLetterSummaryModal}
          className={
            showSendLetterConfirmation
              ? "confirmation modal summaryModal"
              : "summaryModal"
          }
          onClose={() => {
            setPreviewUrl(null);
            setShowSendLetterConfirmation(false);
            props.handleModalClose();
          }}
        >
          <Box
            sx={{
              p: 4,
            }}
            style={{ padding: "20px", height: "90%" }}
          >
            {!sendLetterStatus?.letters_send?.id &&
              !showSendLetterConfirmation && (
                <>
                  <div style={{ display: "flex", height: "100%" }}>
                    <div style={{ width: "50%", flexDirection: "column" }}>
                      <div
                        style={{
                          maxHeight: "54%",
                          height: "352px",
                          borderBottom: "1px solid #D8D8D8",
                          overflow: "auto",
                        }}
                      >
                        <List>
                          <ListItem>
                            <ListItemIcon>
                              <PersonIcon
                                sx={{
                                  color: "#000000",
                                  top: "12px",
                                  position: "absolute",
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <div className="ownerInfoLabel">
                                  Recipient Details:
                                </div>
                              }
                              secondary={
                                <div className="ownerInfoDetail">
                                  {getOwnerAddress()}
                                </div>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <HomeIcon
                                sx={{
                                  color: "#000000",
                                  top: "12px",
                                  position: "absolute",
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <div className="ownerInfoLabel">
                                  Rental Unit:
                                </div>
                              }
                              secondary={
                                <div className="ownerInfoDetail">
                                  {getRentalUnitAddress()}
                                </div>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <HomeIcon
                                sx={{
                                  color: "#000000",
                                  top: "12px",
                                  position: "absolute",
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <div className="ownerInfoLabel">
                                  Parcel Number:
                                </div>
                              }
                              secondary={
                                <div className="ownerInfoDetail">
                                  {
                                    props.rentalUnitData?.rentalUnit
                                      ?.parcel_number
                                  }
                                </div>
                              }
                            />
                          </ListItem>
                        </List>
                      </div>

                      <div
                        style={{
                          maxHeight: "48%",
                          overflow: "auto",
                          height: "500px",
                        }}
                      >
                        <List>
                          <ListItem>
                            <ListItemIcon>
                              <LocalPostOfficeIcon
                                sx={{
                                  color: "#000000",
                                  top: "14px",
                                  position: "absolute",
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                props.selectedTemplate.name + " Criteria"
                              }
                              secondary={
                                "Why this property is eligible to receive the " +
                                props.selectedTemplate.name +
                                ":"
                              }
                            />
                          </ListItem>
                          {props.templateCriteria.map(
                            (criteria: any, index: number) => {
                              return (
                                <ListItem
                                  key={index}
                                  sx={{
                                    paddingLeft: "15%",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <ListItemIcon>
                                    <StarIcon />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={
                                      <div className="criteriaName">
                                        {criteria.criteria_description.length <
                                        110 ? (
                                          <>{criteria.criteria_description}</>
                                        ) : (
                                          <>
                                            {getLargeCriteriaDescription(
                                              criteria.criteria_description
                                            )}{" "}
                                            <div>
                                              <Button
                                                className="showMore"
                                                variant="text"
                                                onClick={() =>
                                                  setContentCollapsed(
                                                    !isContentCollapsed
                                                  )
                                                }
                                              >
                                                {isContentCollapsed
                                                  ? "Show more.."
                                                  : "Show less"}
                                              </Button>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    }
                                  />
                                </ListItem>
                              );
                            }
                          )}
                        </List>
                      </div>
                    </div>
                    <div
                      style={{ width: "50%", borderLeft: "1px solid #D8D8D8" }}
                    >
                      <div style={{ margin: "2% 6% 2% 8%" }}>
                        <div className="templatePreviewHead">
                          Template Selected: {props.selectedTemplate.name}
                        </div>
                        {props.templateHasScreenshot && (
                          <div className="screenshotText">
                            Screenshot to be attached:{" "}
                            <Select
                              className="dropdownWithEllipsis"
                              value={selectedListing}
                              onChange={handleListingChange}
                            >
                              {Array.from(listingScreenshotsMap.keys()).map(
                                (listing) => (
                                  <MenuItem key={listing.id} value={listing}>
                                    {listing.id} {listing.title && ": "}
                                    {listing.title}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                            {listingScreenshotsMap.get(selectedListing) && (
                              <Select
                                className="dropdownWithEllipsis"
                                value={selectedScreenshot}
                                onChange={handleScreenshotChange}
                                disabled={!selectedListing}
                              >
                                {listingScreenshotsMap
                                  .get(selectedListing)
                                  ?.map((screenShot: any) => (
                                    <MenuItem
                                      key={screenShot.created_at}
                                      value={screenShot}
                                    >
                                      {formatDateTime(screenShot.created_at)}
                                    </MenuItem>
                                  ))}
                              </Select>
                            )}
                          </div>
                        )}

                        <div
                          style={{
                            border: "1px solid #000000",
                          }}
                        >
                          <SwipeableImageStepper
                            images={props.templateThumbnail}
                          />
                          <div className="preview">
                            {previewUrl === null &&
                              !props.storedPreviewLoading &&
                              !isRefetching && (
                                <Button
                                  className="genPreview"
                                  variant="text"
                                  onClick={fetchData}
                                >
                                  Generate Preview
                                </Button>
                              )}
                            {props.storedPreviewLoading && (
                              <div style={{ display: "flex" }}>
                                <CircularProgress
                                  sx={{ color: "#003258", top: "4px" }}
                                  size={20}
                                />
                              </div>
                            )}
                            {isRefetching && (
                              <>
                                <div style={{ display: "flex" }}>
                                  <span className="generatePreview">
                                    Generating Preview...
                                  </span>
                                  <LinearProgress
                                    sx={{
                                      width: "30%",
                                      top: "10px",
                                      left: "6px",
                                    }}
                                  ></LinearProgress>
                                </div>
                                <span className="subText">
                                  We&apos;re currently in the process of
                                  generating a preview. Please hold on for a
                                  moment...
                                </span>
                              </>
                            )}
                            {!isRefetching &&
                              previewUrl !== null &&
                              !props.storedPreviewLoading && (
                                <div>
                                  <Button
                                    className="openPreview"
                                    variant="text"
                                    onClick={loadPreview}
                                    startIcon={
                                      <OpenInNewIcon fontSize="small" />
                                    }
                                  >
                                    Preview
                                  </Button>
                                </div>
                              )}
                            <Snackbar
                              style={{ top: "66px", right: "50px" }}
                              open={isErrorVisible}
                              autoHideDuration={10000}
                              onClose={() => setIsErrorVisible(false)}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <Alert
                                severity="error"
                                sx={{ width: "100%" }}
                                onClose={() => setIsErrorVisible(false)}
                              >
                                {errorMessage}
                              </Alert>
                            </Snackbar>
                          </div>
                        </div>
                      </div>
                      <div style={{ marginBottom: "50px", marginRight: "4%" }}>
                        <LoadingButton
                          sx={{ float: "right" }}
                          variant="contained"
                          className="submitButton"
                          onClick={proceedToSend}
                        >
                          Send Letter
                        </LoadingButton>
                      </div>
                    </div>
                  </div>
                </>
              )}
            {!sendLetterStatus?.letters_send?.id && showSendLetterConfirmation && (
              <>
                <div className="confirmationMainDiv">
                  <div className="ready">Ready to send?</div>
                  <div className="confirmationText">
                    You are about to send the {props.selectedTemplate.name} to:
                  </div>
                  <div className="confirmationAddress">
                    <List>
                      <ListItem>
                        <ListItemIcon>
                          <PersonIcon
                            sx={{
                              color: "#000000",
                              top: "12px",
                              position: "absolute",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ margin: "0px" }}
                          primary={
                            <div className="ownerInfoDetail">
                              {getOwnerAddress()}
                            </div>
                          }
                        />
                      </ListItem>
                    </List>
                  </div>
                </div>
                <div className="buttonContainer">
                  <LoadingButton
                    sx={{ float: "right" }}
                    variant="outlined"
                    className="submitButton"
                    onClick={() => {
                      setShowSendLetterConfirmation(false);
                      setIsErrorVisibleOnSend(false);
                    }}
                  >
                    Go Back
                  </LoadingButton>

                  <LoadingButton
                    sx={{ float: "right" }}
                    loading={sendLetterLoading}
                    variant="contained"
                    className="submitButton"
                    onClick={sendLetterAndShowConfirmation}
                  >
                    Confirm and Send Letter
                  </LoadingButton>
                </div>
                {isErrorVisibleOnSend && (
                  <Snackbar
                    style={{ top: "66px", right: "50px" }}
                    open={isErrorVisibleOnSend}
                    autoHideDuration={10000}
                    onClose={() => setIsErrorVisibleOnSend(false)}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <Alert
                      severity="error"
                      sx={{ width: "100%" }}
                      onClose={() => setIsErrorVisibleOnSend(false)}
                    >
                      {errorMessageOnSend}
                    </Alert>
                  </Snackbar>
                )}
              </>
            )}
            {sendLetterStatus?.letters_send?.id && (
              <>
                <div className="confirmationMainDiv">
                  <div style={{ display: "flex" }}>
                    <div className="doneIcon">
                      <DoneIcon fontSize="large"> </DoneIcon>
                    </div>
                    <div className="onWay">Your letter is on the way!</div>
                  </div>
                  <div
                    className="letterPrepared"
                    style={{ paddingTop: "20px" }}
                  >
                    Your letter is being prepared. You can check on the status
                    in the activity timeline within a business day.
                  </div>
                  <div
                    className="supportSection"
                    style={{ paddingTop: "20px" }}
                  >
                    If this was done by mistake, please contact the Granicus
                    support team by emailing support@granicus.com or going to
                    support.granicus.com.
                  </div>
                </div>
                <div className="buttonContainer">
                  <LoadingButton
                    sx={{ float: "right" }}
                    variant="contained"
                    className="submitButton"
                    onClick={() => {
                      setShowSendLetterConfirmation(false);
                      props.handleModalClose();
                    }}
                  >
                    Finish
                  </LoadingButton>
                </div>
              </>
            )}
          </Box>
        </ModalComponent>
      </div>
    </>
  );
};
export default LetterSummary;
