import React from "react";
import "./ModalComponent.scss";
import Modal, { ModalProps } from "@mui/material/Modal";
import {
  Box,
  InternalStandardProps as StandardProps,
  Typography,
} from "@mui/material";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface ModalComponentProps
  extends StandardProps<ModalProps, "children"> {
  header: string;
  onClose: () => void;
  children?: React.ReactNode;
  width?: string;
  height?: string;
}

export default function ModalComponent(props: ModalComponentProps) {
  return (
    <Modal {...props}>
      <Box
        className="modalBox"
        onKeyDown={props.onKeyDown}
        style={{
          width: props.width ? props.width : "60%",
        }}
      >
        <Typography variant="h6" className="bold title clearfix">
          {props.header}
          <FontAwesomeIcon
            className="closeButton"
            icon={faClose}
            onClick={props.onClose}
          />
        </Typography>
        <div className="modalContentOuter">
          <div className="modalContentInner">{props.children}</div>
        </div>
      </Box>
    </Modal>
  );
}
