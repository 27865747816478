import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";

const autocompleteService = { current: null };
const placesService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
}

interface SearchAddressProps {
  initialValue: string;
  setPlace: (value: string, coordinates?: Coordinates) => void;
}

interface PlaceDetailsRequest {
  placeId: string;
}

interface PlaceResult {
  geometry?: PlaceGeometry;
}

interface PlaceGeometry {
  location?: LatLng;
}

export interface Coordinates {
  lat: number;
  lng: number;
}

interface LatLng {
  lat(): number;
  lng(): number;
}

type PlaceDetailsCallback = (result?: PlaceResult) => void;

export default function SearchAddress({
  initialValue,
  setPlace,
}: SearchAddressProps) {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState(initialValue);
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        400
      ),
    []
  );

  const fetchPlaceDetails = React.useMemo(
    () => (request: PlaceDetailsRequest, callback: PlaceDetailsCallback) => {
      (placesService.current as any).getDetails(request, callback);
    },
    []
  );

  const setPlaceAndCoordinates = (value: string, placeId: string) => {
    if (placeId.trim() !== "") {
      if (!placesService.current) {
        placesService.current = new (
          window as any
        ).google.maps.places.PlacesService(document.createElement("div"));
      }

      if (placesService.current) {
        const placeDetailsRequest: PlaceDetailsRequest = {
          placeId: placeId,
        };

        fetchPlaceDetails(placeDetailsRequest, (placeResult?: PlaceResult) => {
          const lat = placeResult?.geometry?.location?.lat();
          const lng = placeResult?.geometry?.location?.lng();
          if (lat && lng) {
            const coordinates: Coordinates = { lat: lat, lng: lng };
            setPlace(value, coordinates);
          } else {
            setPlace(value);
          }
        });
      } else {
        setPlace(value);
      }
    } else {
      setPlace(value);
    }
  };

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  React.useEffect(() => {
    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (results) {
        setValue(results[0]);
      }
    });
  }, []);

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{ width: "100%" }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        setPlaceAndCoordinates(
          newValue?.description ? newValue.description : "",
          newValue?.place_id ? newValue.place_id : ""
        );
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Add a location" fullWidth />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
