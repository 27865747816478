import React, { ForwardedRef } from "react";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import "./PrintTemplate.scss";


interface PrintTemplateProps {
    imgUrl: string;
    date: string;
}

export const PrintTemplate = React.forwardRef(({imgUrl, date}: PrintTemplateProps, ref: ForwardedRef<null>) => {
    return (
        <div className="printTemplateBox" ref={ref}>
            <Typography className="screenshotInformation" variant="h5">{dayjs(date).format("MMMM DD, YYYY - HH:mmA")}</Typography>
            <Typography className="screenshotInformation" variant="h6"><span className="bold">Screenshot printed on:</span> {dayjs().format("MM/DD/YYYY HH:mmA")}</Typography>
            <img src={imgUrl} alt="" />
        </div>
    )
})
PrintTemplate.displayName = "Print Template";