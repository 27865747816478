import React, { useState, useContext } from "react";
import { Alert, Box, Grid, Snackbar, Typography } from "@mui/material";
import {
  faUserTag,
  faHome,
  faTag,
  faSquareCheck,
  faPencil,
  faAddressBook,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./AddressInformation.scss";
import { GoogleMap, Marker } from "@react-google-maps/api";
import classNames from "classnames";
import EditOwnerInformationModal from "../Modals/EditOwnerInformation/EditOwnerInformationModal";
import { RentalUnitContext } from "../RentalUnitContext";
import { EditAddressInformationModal } from "../Modals/EditAddressInformation/EditAddressInformationModal";
import { SnackbarData } from "../../../../components/SearchView/SearchView";

const AddressInformation = () => {
  const [showEditOIIcon, setShowEditOIIcon] = useState<boolean>(false);
  const [openEditOIModal, setOpenEditOIModal] = useState<boolean>(false);
  const [showEditAIIcon, setShowEditAIIcon] = useState<boolean>(false);
  const [openEditAIModal, setOpenEditAIModal] = useState<boolean>(false);
  const [snackbarData, setSnackbarData] = useState<SnackbarData>({
    message: "",
    type: "success",
  });

  const handleCloseModal = (callback: (param: boolean) => void) => {
    callback(false);
  };

  const { rentalUnit, listingTables, selectedListingTable, rentalUnitId } =
    useContext(RentalUnitContext);

  const formatted_address = rentalUnit?.situs_address?.formatted;
  const has_address =
    rentalUnit?.owner?.mailing_address != null &&
    (rentalUnit?.owner?.mailing_address.line_1 ||
      rentalUnit?.owner?.mailing_address?.city ||
      rentalUnit?.owner?.mailing_address?.state ||
      rentalUnit?.owner?.mailing_address?.postal_code ||
      rentalUnit?.owner?.mailing_address?.country_code);
  const no_owner = !(
    rentalUnit?.owner?.name_secondary || rentalUnit?.owner?.name
  );
  return rentalUnit &&
    rentalUnit.situs_address &&
    rentalUnit.situs_address.position &&
    listingTables ? (
    <Box className="addressInformationWrapper pageBreak">
      <Grid
        container
        direction={"column"}
        columns={6}
        spacing={0}
        className="informationContainer pageBreak block"
      >
        <EditOwnerInformationModal
          rentalUnit={rentalUnit}
          listingId={
            listingTables?.hits[selectedListingTable].internal_listing_id || ""
          }
          open={openEditOIModal}
          closeModal={() => handleCloseModal(setOpenEditOIModal)}
          rentalUnitID={rentalUnitId}
          setSnackbarData={setSnackbarData}
          reloadGrid={() => {}}
        />
        <EditAddressInformationModal
          open={openEditAIModal}
          closeModal={() => handleCloseModal(setOpenEditAIModal)}
        />
        <Grid container direction={"row"}>
          <Grid item xs={1} className="iconWrapper hiddenPrint">
            <FontAwesomeIcon icon={faSquareCheck} />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h6" className="bold">
              Compliance Reason
            </Typography>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <Typography>{rentalUnit?.compliance_explanation}</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction={"row"}
          className={rentalUnit?.parcel_number ? "hoverClass" : ""}
        >
          <Grid item xs={1} className="iconWrapper hiddenPrint">
            <FontAwesomeIcon icon={faUserTag} />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h6" className="bold">
              Owner Information
            </Typography>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid
            item
            xs={4}
            container
            direction="row"
            className={classNames(
              "ownerInfo",
              { infoGrey: showEditOIIcon },
              { hoverClass: Boolean(rentalUnit.parcel_number) }
            )}
            onMouseEnter={() =>
              setShowEditOIIcon(Boolean(rentalUnit?.parcel_number))
            }
            onMouseLeave={() => setShowEditOIIcon(false)}
            onClick={() => {
              if (rentalUnit?.parcel_number) setOpenEditOIModal(true);
            }}
          >
            <Grid item xs={5} className="informationTile">
              {no_owner ? (
                <Typography className="gray">Owner name unavailable</Typography>
              ) : (
                <>
                  <Typography variant="body1" className="ellipsis">
                    {rentalUnit?.owner?.name}
                  </Typography>
                  <span className="iconWrapper gray"></span>
                  {rentalUnit?.owner?.name_secondary && (
                    <Typography className="ellipsis">
                      {rentalUnit?.owner?.name_secondary}
                    </Typography>
                  )}
                </>
              )}

              {has_address ? (
                <Typography className="ellipsis">
                  {rentalUnit?.owner?.mailing_address?.line_1} <br />
                  {rentalUnit?.owner?.mailing_address?.city &&
                    `${rentalUnit.owner.mailing_address.city}, `}
                  {rentalUnit?.owner?.mailing_address?.state &&
                    `${rentalUnit.owner.mailing_address.state} `}
                  {rentalUnit?.owner?.mailing_address?.postal_code}
                  {rentalUnit?.owner?.mailing_address?.country_code &&
                    `, ${rentalUnit.owner.mailing_address.country_code}`}
                </Typography>
              ) : (
                <Typography className="gray">
                  Owner address unavailable
                </Typography>
              )}
            </Grid>
            <Grid item xs={1} className="informationTile">
              {showEditOIIcon && <FontAwesomeIcon icon={faPencil} />}
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        <Grid container direction={"row"} className="identifiedAddress">
          <Grid item xs={1} className="iconWrapper hiddenPrint">
            <FontAwesomeIcon icon={faHome} />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h6" className="bold">
              {rentalUnit?.is_identified ? "Identified" : "Approximate"} Address
            </Typography>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid
            item
            xs={4}
            container
            direction="row"
            className={classNames("ownerInfo", {
              infoGrey: showEditAIIcon,
              hoverClass: true,
            })}
            onMouseEnter={() => setShowEditAIIcon(true)}
            onMouseLeave={() => setShowEditAIIcon(false)}
            onClick={() => setOpenEditAIModal(true)}
          >
            <Grid item xs={5} className="informationTile">
              {formatted_address ? (
                <>
                  {formatted_address.split(",").length > 1 ? (
                    <>
                      <Typography>
                        {formatted_address.substring(
                          0,
                          formatted_address.indexOf(",")
                        )}
                      </Typography>
                      <Typography>
                        {formatted_address.substring(
                          formatted_address.indexOf(",") + 1
                        )}
                      </Typography>
                    </>
                  ) : (
                    <Typography>{formatted_address}</Typography>
                  )}
                </>
              ) : (
                <Typography className={"gray"}>
                  Property not identified
                </Typography>
              )}
            </Grid>
            <Grid item xs={1} className="informationTile">
              {showEditAIIcon && <FontAwesomeIcon icon={faPencil} />}
            </Grid>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid container>
          <Grid item xs={1} className="iconWrapper hiddenPrint">
            <FontAwesomeIcon icon={faHome} />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h6" className="bold">
              Parcel Number
            </Typography>
            {rentalUnit?.parcel_number ? (
              <Typography>{rentalUnit?.parcel_number}</Typography>
            ) : (
              <Typography className={"gray"}>Not yet identified</Typography>
            )}
          </Grid>
        </Grid>
        <Grid container className="pageBreak block">
          <Grid item xs={1} className="iconWrapper hiddenPrint pageBreak">
            <FontAwesomeIcon icon={faTag} />
          </Grid>
          <Grid item xs={5} className="pageBreak">
            <Typography variant="h6" className="bold">
              Registration Number
            </Typography>
            {rentalUnit?.permit_number ? (
              <Typography className="pageBreak">
                {rentalUnit?.permit_number}
              </Typography>
            ) : (
              <Typography className={"gray pageBreak"}>
                Not registered
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container className="rentalUnitMap hiddenPrint">
          <Grid item xs={6}>
            <GoogleMap
              center={{
                lat: rentalUnit?.situs_address.position.latitude,
                lng: rentalUnit?.situs_address.position.longitude,
              }}
              zoom={17}
              mapContainerStyle={{ width: "100%", height: "400px" }}
            >
              <Marker
                position={{
                  lat: rentalUnit?.situs_address.position.latitude,
                  lng: rentalUnit?.situs_address.position.longitude,
                }}
                title={rentalUnit?.situs_address.formatted}
              />
            </GoogleMap>
          </Grid>

          <Typography>
            {rentalUnit?.situs_address.position.latitude}
            {", "}
            {rentalUnit?.situs_address.position.longitude}
            {" (Lat, Long)"}
          </Typography>
        </Grid>
      </Grid>
      <Snackbar
        open={!!snackbarData.message}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: snackbarData.vertical || "top",
          horizontal: snackbarData.horizontal || "right",
        }}
        onClose={() => setSnackbarData({ message: "", type: "success" })}
      >
        <Alert
          severity={snackbarData.type}
          sx={{ width: "100%" }}
          onClose={() => setSnackbarData({ message: "", type: "success" })}
        >
          {snackbarData.message}
        </Alert>
      </Snackbar>
    </Box>
  ) : (
    <Box
      sx={{
        height: "100%",
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography variant="h1" align="center">
        <FontAwesomeIcon icon={faAddressBook} color="grey" />
      </Typography>
      <Typography variant="h6" color="grey" align="center">
        No address information data available
      </Typography>
    </Box>
  );
};

export default AddressInformation;
