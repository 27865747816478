import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from "@mui/x-data-grid-pro";
import StarIcon from "@mui/icons-material/Star";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import "./SendBulkLettersPage.scss";
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Paper,
  Snackbar,
  Tab,
  Tabs,
} from "@mui/material";
import SendLetterConfirmationModal from "../common/Modals/SendLetterConfirmationModal";
import { loader } from "graphql.macro";
import { ApolloError, useQuery } from "@apollo/client";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GridDropdownComponent from "../../components/GridDropdownComponent/GridDropdownComponent";
import env from "../../common/env";
import {
  getDefaultSelectedListingsAndScreenshots,
  getFirstScreenshotForListingID,
  getIsChangeScreenshotDropdownDisabled,
  getIsChangeScreenshotDropdownLoading,
  getIsLoadedFromServerByRurID,
  getListingByListingAndRurID,
  getListingOptionsForRurID,
  getScreenshotByCreatedDateAndRurID,
  getScreenshotOptionsForRurID,
  getSelectedListingAndScreenshot,
  getSelectedListingForRurID,
  getSelectedScreenshotForRurID,
  initializeChangeScreenshotDropdownLoading,
  initializeGridListingsAndScreenshots,
  processListingsAndScreenshotsResponse,
} from "./commons/Utils";
import ThumbnailContainer from "./ThumbnailContainer";
import {
  renderLetterCriteriaGridCell,
  renderOwnerAddress,
  renderOwnerName,
  renderParcelNumber,
  renderRentalUnitRecord,
} from "../common/data-grid-cells";
import {
  DropdownLoading,
  Listing,
  ListingWithScreenshot,
  ListingsAndScreenshotsObject,
  PDFUrl,
  PreviewQueryVariables,
  Screenshot,
} from "./commons/Models";

const templatePreviewQuery = loader(
  "../rental-units/Letter/LetterTemplatePreview.graphql"
);

const bulkSendLettersQuery = loader(
  "../rental-units/Letter/SendLetter.graphql"
);

const bulkLettersListingsQuery = loader("./BulkLettersListings.graphql");

const SendBulkLettersPage = () => {
  document.title = "Send Letters";
  const history = useHistory();
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState(0);
  const [eligibilityResponse, setEligibilityResponse] = useState<any>(
    location.state
  );
  const [openSendLetterConfirmationModal, setOpenSendLetterConfirmationModal] =
    useState<boolean>(false);
  const [sendLetterLoading, setSendLetterLoading] = useState<boolean>(false);
  const [isErrorVisibleOnSend, setIsErrorVisibleOnSend] =
    useState<boolean>(false);
  const [errorMessageOnSend, setErrorMessageOnSend] = useState<string>("");
  const [errorMessageOnGeneratePreview, setErrorMessageOnGeneratePreview] =
    useState<string>("");
  const [errorMessageOnFetchListings, setErrorMessageOnFetchListings] =
    useState<string>("");
  const [isQueryingGeneratePreview, setIsQueryingGeneratePreview] =
    useState<boolean>(false);
  const [queryingGeneratePreviewForID, setQueryingGeneratePreviewForID] =
    useState<string>(""); //keeps track of rur id for which preview is being generated
  const [
    showSendLetterConfirmationRequired,
    setShowSendLetterConfirmationRequired,
  ] = useState<boolean>(true); //shows the confirmation required content on modal else shows confirmed content
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [recipientDetailsString, setRecipientDetailsString] =
    useState<string>("");
  const [pdfUrls, setPdfUrls] = useState<Array<PDFUrl>>([]);
  const [wasSendLettersSuccessful, setWasSendLettersSuccessful] =
    useState<boolean>(false);
  const [gridListingsAndScreenshots, setGridListingsAndScreenshots] = useState<
    Array<ListingsAndScreenshotsObject>
  >(initializeGridListingsAndScreenshots(eligibilityResponse));
  const [
    isChangeScreenshotDropdownLoading,
    setIsChangeScreenshotDropdownLoading,
  ] = useState<Array<DropdownLoading>>(
    initializeChangeScreenshotDropdownLoading(eligibilityResponse)
  );
  const [selectedListingsAndScreenshots, setSelectedListingsAndScreenshots] =
    useState<Array<ListingWithScreenshot>>(
      getDefaultSelectedListingsAndScreenshots(eligibilityResponse)
    );

  const onWayString = "Your letters are on the way!";
  const letterPreparedString =
    "Your letters are being prepared. You can check on the status in the activity timeline within a business day.";
  const supportString =
    "If this was done by mistake, please contact the Granicus support team by emailing support@granicus.com or going to support.granicus.com.";

  const setChangeScreenshotDropdownLoadingStatus = (
    rurID: string,
    loadingStatus: boolean,
    disabledStatus: boolean
  ) => {
    setIsChangeScreenshotDropdownLoading((prevState) => {
      const index = prevState.findIndex((item) => item.rur_id === rurID);
      if (index !== -1) {
        return [
          ...prevState.slice(0, index),
          {
            ...prevState[index],
            loading: loadingStatus,
            disabled: disabledStatus,
          },
          ...prevState.slice(index + 1),
        ];
      } else {
        return prevState;
      }
    });
  };

  //function to set listing/screenshot by rurID
  const setByRurID = (
    rurID: string,
    key: "listing" | "screenshot",
    value: Listing | Screenshot | null
  ) => {
    setSelectedListingsAndScreenshots((prevListings) => {
      const index = prevListings.findIndex((item) => item.rur_id === rurID);

      if (index !== -1) {
        return [
          ...prevListings.slice(0, index),
          {
            ...prevListings[index],
            [key]: value,
          },
          ...prevListings.slice(index + 1),
        ];
      } else {
        return prevListings;
      }
    });
  };

  const setListingByRurID = (rurID: string, newListing: Listing): void => {
    setByRurID(rurID, "listing", newListing);
  };

  const setScreenshotByRurID = (
    rurID: string,
    newScreenshot: Screenshot | null
  ): void => {
    setByRurID(rurID, "screenshot", newScreenshot);
  };

  const { refetch: bulkSendLettersQueryRefetch } = useQuery(
    bulkSendLettersQuery,
    {
      skip: true,
      onError: (error: ApolloError) => {
        showErrorOnSendLetter(error);
      },
    }
  );

  const { refetch: bulkLettersListingsQueryRefetch } = useQuery(
    bulkLettersListingsQuery,
    {
      skip: true,
      onError: (error: ApolloError) => {
        setErrorMessageOnFetchListings(error.message);
      },
    }
  );

  const doesIdHavePDFUrl = (id: string) => {
    return pdfUrls.some((pdfUrl) => pdfUrl.id === id && pdfUrl.url);
  };

  const deletePDFUrlByID = (id: string) => {
    setPdfUrls((prevPdfUrls) =>
      prevPdfUrls.filter((pdfUrl) => pdfUrl.id !== id)
    );
  };

  const getPdfUrlById = (id: string) => {
    const pdfUrl = pdfUrls.find((pdfUrl) => pdfUrl.id === id);
    return pdfUrl ? pdfUrl.url : null;
  };

  const loadPreview = (id: string) => {
    const previewUrl = getPdfUrlById(id);
    if (previewUrl) {
      const newTab = window.open();
      if (newTab) {
        newTab.location.href = previewUrl;
      }
    }
  };

  const renderPreviewCell = (params: any) => {
    const id = params.id;

    if (queryingGeneratePreviewForID === id) {
      return (
        <LinearProgress
          sx={{
            width: "100%",
          }}
        ></LinearProgress>
      );
    } else if (doesIdHavePDFUrl(id)) {
      return (
        <Button
          className="openPreview"
          variant="text"
          onClick={() => {
            loadPreview(id);
          }}
          startIcon={<OpenInNewIcon fontSize="small" />}
        >
          Preview
        </Button>
      );
    } else {
      return (
        <Button
          className="genPreview"
          variant="text"
          onClick={() => fetchPreview(id)}
          disabled={isQueryingGeneratePreview}
        >
          Generate
        </Button>
      );
    }
  };

  const fetchListingsAndScreenshotsData = (rurID: string) => {
    if (
      getIsLoadedFromServerByRurID(rurID, gridListingsAndScreenshots) === false
    ) {
      //fetch listings and screenshots from server only if not loaded for that rur earlier.
      const selectedListingID = getSelectedListingForRurID(
        rurID,
        selectedListingsAndScreenshots
      );
      fetchListings(rurID, selectedListingID);
    }
  };

  const addListingsAndScreenshotsObjectToGridDropdown = (
    rurID: string,
    listingsAndScreenshotsObject: ListingsAndScreenshotsObject
  ) => {
    setGridListingsAndScreenshots((prevListingsAndScreenshots) => {
      const existingIndex = prevListingsAndScreenshots.findIndex(
        (item) => item.rur_id === rurID
      );

      if (existingIndex !== -1) {
        // If object with the same rurID exists, overwrite it
        return [
          ...prevListingsAndScreenshots.slice(0, existingIndex),
          listingsAndScreenshotsObject,
          ...prevListingsAndScreenshots.slice(existingIndex + 1),
        ];
      } else {
        // If object with the same rurID does not exist, add it to the array
        return [...prevListingsAndScreenshots, listingsAndScreenshotsObject];
      }
    });
  };

  const changeSelectedListingForRurID = (
    selectedListingID: string,
    rurID: string
  ) => {
    //delete generated preview when user makes a new selection
    deletePDFUrlByID(rurID);

    const newlySelectedListing = getListingByListingAndRurID(
      selectedListingID,
      rurID,
      gridListingsAndScreenshots
    );
    if (newlySelectedListing) {
      setListingByRurID(rurID, newlySelectedListing);
    }

    const selectedScreenshot = getFirstScreenshotForListingID(
      rurID,
      selectedListingID,
      gridListingsAndScreenshots
    );
    if (selectedScreenshot) {
      setScreenshotByRurID(rurID, selectedScreenshot);
    } else {
      setScreenshotByRurID(rurID, null);
    }
  };

  const changeSelectedScreenshotForRurID = (
    selectedScreenshot: string,
    rurID: string
  ) => {
    // Delete generated preview when the user makes a new selection
    deletePDFUrlByID(rurID);

    const newlySelectedScreenshot = getScreenshotByCreatedDateAndRurID(
      selectedScreenshot,
      rurID,
      gridListingsAndScreenshots
    );
    if (newlySelectedScreenshot) {
      setScreenshotByRurID(rurID, newlySelectedScreenshot);
    }
  };

  const renderScreenshotsCell = (params: any) => {
    const rurID = params.id;

    return (
      <>
        <GridDropdownComponent
          value={getSelectedListingForRurID(
            rurID,
            selectedListingsAndScreenshots
          )}
          options={getListingOptionsForRurID(rurID, gridListingsAndScreenshots)}
          onChange={(selectedListingID) =>
            changeSelectedListingForRurID(selectedListingID, rurID)
          }
          isLoading={getIsChangeScreenshotDropdownLoading(
            rurID,
            isChangeScreenshotDropdownLoading
          )}
          disabled={getIsChangeScreenshotDropdownDisabled(
            rurID,
            isChangeScreenshotDropdownLoading
          )}
          onDropdownOpen={() => fetchListingsAndScreenshotsData(rurID)}
        />

        <GridDropdownComponent
          value={getSelectedScreenshotForRurID(
            rurID,
            selectedListingsAndScreenshots
          )}
          options={getScreenshotOptionsForRurID(
            rurID,
            selectedListingsAndScreenshots,
            gridListingsAndScreenshots
          )}
          onChange={(selectedScreenshot) =>
            changeSelectedScreenshotForRurID(selectedScreenshot, rurID)
          }
          isLoading={getIsChangeScreenshotDropdownLoading(
            rurID,
            isChangeScreenshotDropdownLoading
          )}
          disabled={getIsChangeScreenshotDropdownDisabled(
            rurID,
            isChangeScreenshotDropdownLoading
          )}
          onDropdownOpen={() => fetchListingsAndScreenshotsData(rurID)}
        />

        {getIsChangeScreenshotDropdownLoading(
          rurID,
          isChangeScreenshotDropdownLoading
        ) && <CircularProgress size={20} />}
      </>
    );
  };

  const eligibleRecipientsColumns = [
    {
      headerName: "Parcel Number",
      field: "parcel_number",
      flex: 2,
      align: "left",
      headerAlign: "left",
    },
    {
      headerName: "Owner Name",
      field: "owner",
      flex: 4,
      align: "left",
      headerAlign: "left",
    },
    {
      headerName: "Owner Address",
      field: "owner_address",
      flex: 7,
      align: "left",
      headerAlign: "left",
    },
    {
      headerName: "Property Address",
      field: "property_address",
      flex: 7,
      align: "left",
      headerAlign: "left",
    },
    {
      headerName: "Rental Unit Record",
      field: "id",
      flex: 3,
      align: "left",
      headerAlign: "left",
      renderCell: renderRentalUnitRecord,
    },
    {
      headerName: "Preview",
      field: "preview",
      flex: 3,
      align: "left",
      headerAlign: "left",
      renderCell: renderPreviewCell,
    },
    ...(eligibilityResponse?.has_screenshot
      ? [
          {
            headerName: "Screenshot",
            field: "screenshot",
            flex: 14,
            align: "left",
            headerAlign: "left",
            renderCell: renderScreenshotsCell,
          },
        ]
      : []),
  ];

  const ineligibleRecipientsColumns = [
    {
      headerName: "Parcel Number",
      field: "parcel_number",
      flex: 1,
      align: "left",
      headerAlign: "left",
      renderCell: renderParcelNumber,
    },
    {
      headerName: "Owner Name",
      field: "owner",
      flex: 1,
      align: "left",
      headerAlign: "left",
      renderCell: renderOwnerName,
    },
    {
      headerName: "Owner Address",
      field: "owner_address",
      flex: 1,
      align: "left",
      headerAlign: "left",
      renderCell: renderOwnerAddress,
    },
    {
      headerName: "Property Address",
      field: "property_address",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      headerName: "Rental Unit Record",
      field: "id",
      flex: 1,
      align: "left",
      headerAlign: "left",
      renderCell: renderRentalUnitRecord,
    },
    {
      headerName: "Ineligibility Reason(s)",
      field: "criteria_details",
      flex: 2,
      align: "left",
      headerAlign: "left",
      renderCell: renderLetterCriteriaGridCell,
    },
  ];

  const sendLetterClickHandler = () => {
    setRecipientDetailsString(getRecipientDetailsString());
    setOpenSendLetterConfirmationModal(true);
  };

  const closeSendLetterConfirmationModal = () => {
    setOpenSendLetterConfirmationModal(false);
    setSendLetterLoading(false);
    setShowSendLetterConfirmationRequired(true);
  };

  const sendLetterAndShowConfirmation = () => {
    setSendLetterLoading(true);
    let selectedListingAndScreenshotRows = getSelectedListingAndScreenshot(
      selectedRows,
      selectedListingsAndScreenshots
    );
    // changing rur_id key to id
    let rowsToSend = selectedListingAndScreenshotRows?.map(
      (listingWithScreenshot) => {
        let row = {
          listing: listingWithScreenshot.listing,
          id: listingWithScreenshot.rur_id,
          screenshot: listingWithScreenshot.screenshot,
        };
        return row;
      }
    );
    bulkSendLettersQueryRefetch({
      template_id: eligibilityResponse?.selectedTemplate?.letter_template_id,
      rental_unit_ids: rowsToSend,
      test: env.ENVIRONMENT === "production" ? false : true, //should be true only for prod
    })
      .then(() => {
        setSendLetterLoading(false);
        setShowSendLetterConfirmationRequired(false);
        setWasSendLettersSuccessful(true);
      })
      .catch((error) => {
        setSendLetterLoading(false);
        setShowSendLetterConfirmationRequired(false);
        showErrorOnSendLetter(error);
      });
  };

  const fetchListings = (rurID: string, listingID: string) => {
    setChangeScreenshotDropdownLoadingStatus(rurID, true, true);

    bulkLettersListingsQueryRefetch({
      rental_unit_id: rurID,
      internal_listing_id: listingID,
    })
      .then((response) => {
        const processedListingAndScreenshotResponse =
          processListingsAndScreenshotsResponse(response);
        addListingsAndScreenshotsObjectToGridDropdown(
          rurID,
          processedListingAndScreenshotResponse
        );
      })
      .catch((error) => {
        setErrorMessageOnFetchListings(error);
      })
      .finally(() => {
        setChangeScreenshotDropdownLoadingStatus(rurID, false, false);
      });
  };

  const getRecipientDetailsString = () => {
    const recipientCount = selectedRows.length;

    if (recipientCount > 1) {
      return `${recipientCount} Eligible Recipients`;
    } else {
      return `${recipientCount} Eligible Recipient`;
    }
  };

  const showErrorOnSendLetter = (error: any) => {
    setErrorMessageOnSend(error.message);
    setIsErrorVisibleOnSend(true);
  };

  useEffect(() => {
    // Access and handle state data from location.state
    setEligibilityResponse(location.state);

    // Update state or perform other actions as needed
  }, [location.state]);

  const handleSelectionChange = (newSelection: any) => {
    setSelectedRows(newSelection);
  };

  const setPDFUrl = (letterPreviewResponse: any, id: string) => {
    if (!letterPreviewResponse?.preview) {
      return;
    }

    const preview = letterPreviewResponse.preview;
    const pdfUrl = { id: id, url: preview };
    setPdfUrls((prevPdfUrls) => [...prevPdfUrls, pdfUrl]);
  };

  const { refetch } = useQuery(templatePreviewQuery, {
    skip: true,
    onError: (error: ApolloError) => {
      setErrorMessageOnGeneratePreview(error.message);
    },
  });

  const fetchPreview = (id: string) => {
    setErrorMessageOnGeneratePreview("");
    setIsQueryingGeneratePreview(true);
    setQueryingGeneratePreviewForID(id);

    const previewQueryVariables: PreviewQueryVariables = {
      template_id: eligibilityResponse?.selectedTemplate?.letter_template_id,
      rental_unit_id: id,
    };

    if (eligibilityResponse?.has_screenshot) {
      const selectedListingAndScreenshot = getSelectedListingAndScreenshot(
        [id],
        selectedListingsAndScreenshots
      );
      if (selectedListingAndScreenshot) {
        previewQueryVariables.listing = selectedListingAndScreenshot[0].listing;
        previewQueryVariables.screenshot = selectedListingAndScreenshot[0]
          .screenshot
          ? selectedListingAndScreenshot[0].screenshot
          : null;
      }
    }

    refetch(previewQueryVariables)
      .then((response: any) => {
        setPDFUrl(response.data.letter_preview, id);
      })
      .catch((error: ApolloError) =>
        setErrorMessageOnGeneratePreview(error.message)
      )
      .finally(() => {
        setIsQueryingGeneratePreview(false);
        setQueryingGeneratePreviewForID("");
      });
  };

  const onSendLetterFinish = () => {
    //redirect user to letters page if letters were sent, else stay on the current page
    if (wasSendLettersSuccessful) {
      navigateToRentalUnitsPage();
    }
  };

  const navigateToRentalUnitsPage = () => {
    history.push("/dashboard/rental-units");
  };

  return (
    <>
      <div className="goBackSection">
        <Button
          variant="text"
          className="backButton"
          startIcon={<ArrowBackIcon />}
          onClick={navigateToRentalUnitsPage}
        >
          Back
        </Button>
      </div>
      <div className="templateTitle">
        {eligibilityResponse?.selectedTemplate?.customer_display_name}
        {eligibilityResponse?.total_rurids &&
          eligibilityResponse?.recepient_info?.length > 0 && (
            <LoadingButton
              sx={{
                right: "30px",
                position: "absolute",
              }}
              variant="contained"
              className="submitButton"
              onClick={sendLetterClickHandler}
              disabled={selectedRows.length < 1}
            >
              Continue to Send
            </LoadingButton>
          )}
      </div>
      {eligibilityResponse?.total_rurids && (
        <>
          <div className="eligibilityMsg">
            <div className="starIcon">
              <StarIcon />{" "}
            </div>{" "}
            Of the {eligibilityResponse?.total_rurids} properties selected,{" "}
            {eligibilityResponse?.eligible_rurids} matched the mailing criteria
            for this letter
          </div>
          {eligibilityResponse?.recepient_info?.length > 0 && (
            <div className="instructionSection">
              <p>Select letters from the list below to send</p>
            </div>
          )}
        </>
      )}
      <Tabs
        value={selectedTab}
        onChange={(event, newValue) => {
          setSelectedTab(newValue);
        }}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Eligible" />
        {eligibilityResponse?.non_eligible_details?.length > 0 && (
          <Tab label="Ineligible" />
        )}
      </Tabs>

      <Paper
        sx={{
          width: "calc(100% - 64px)",
          overflow: "hidden",
          margin: "0 32px",
          height: "calc(75% - 10px)",
        }}
      >
        <Snackbar
          style={{ top: "66px", right: "50px" }}
          open={
            errorMessageOnGeneratePreview !== "" ||
            errorMessageOnFetchListings !== ""
          }
          autoHideDuration={10000}
          onClose={() => {
            setErrorMessageOnGeneratePreview("");
            setErrorMessageOnFetchListings("");
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert
            severity="error"
            sx={{ width: "100%" }}
            onClose={() => {
              setErrorMessageOnGeneratePreview("");
              setErrorMessageOnFetchListings("");
            }}
          >
            {errorMessageOnGeneratePreview}
            {errorMessageOnFetchListings}
          </Alert>
        </Snackbar>
        <Grid container spacing={0} className="reviewRecipientsContainer">
          {eligibilityResponse?.recepient_info?.length > 0 && (
            <ThumbnailContainer eligibilityResponse={eligibilityResponse} />
          )}
          {selectedTab === 0 && (
            <>
              {eligibilityResponse?.recepient_info?.length > 0 ? (
                <Grid item xs={10} style={{ height: "100%" }}>
                  <DataGridPro
                    // @ts-ignore
                    columns={eligibleRecipientsColumns}
                    checkboxSelection
                    disableSelectionOnClick
                    rows={eligibilityResponse?.recepient_info}
                    style={{ height: "100%" }}
                    onSelectionModelChange={handleSelectionChange}
                    disableColumnReorder
                    disableColumnResize
                    disableColumnPinning
                    disableColumnSelector
                    disableColumnFilter
                  />
                </Grid>
              ) : (
                <Grid item xs={12} style={{ height: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Alert severity="info" sx={{ width: "fit-content" }}>
                      There are no eligible properties in the selected list.
                      Please navigate to the &apos;Ineligible&apos; tab to view
                      the reasons for ineligibility.
                    </Alert>
                  </div>
                </Grid>
              )}
            </>
          )}

          {eligibilityResponse?.non_eligible_details?.length > 0 && (
            <>
              {selectedTab === 1 && (
                <Grid
                  item
                  xs={eligibilityResponse?.recepient_info?.length > 0 ? 10 : 12}
                  style={{ height: "100%" }}
                >
                  <DataGridPro
                    // @ts-ignore
                    columns={ineligibleRecipientsColumns}
                    className="dark-row-hover"
                    disableSelectionOnClick
                    rows={
                      eligibilityResponse?.non_eligible_details
                        ? eligibilityResponse?.non_eligible_details
                        : []
                    }
                    style={{ height: "100%" }}
                    disableColumnReorder
                    disableColumnPinning
                    disableColumnSelector
                    disableColumnFilter
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Paper>
      {openSendLetterConfirmationModal && (
        <SendLetterConfirmationModal
          onClose={closeSendLetterConfirmationModal}
          onGoBack={closeSendLetterConfirmationModal}
          showSendLetterConfirmationRequired={
            showSendLetterConfirmationRequired
          }
          openSendLetterConfirmationModal={openSendLetterConfirmationModal}
          selectedTemplate={{
            name: eligibilityResponse?.selectedTemplate?.customer_display_name,
          }}
          sendLetterLoading={sendLetterLoading}
          sendLetterAndShowConfirmation={sendLetterAndShowConfirmation}
          isErrorVisibleOnSend={isErrorVisibleOnSend}
          setIsErrorVisibleOnSend={setIsErrorVisibleOnSend}
          errorMessageOnSend={errorMessageOnSend}
          recipientDetails={recipientDetailsString}
          onWayString={onWayString}
          letterPreparedString={letterPreparedString}
          supportString={supportString}
          onFinish={onSendLetterFinish}
        />
      )}
    </>
  );
};

export default SendBulkLettersPage;
