import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { SwipeableImageStepper } from "../common/AutoSwipeable/SwipeableImageStepper";
import StarIcon from "@mui/icons-material/Star";

const CRITERIA_TEXT_LIMIT = 70;

const ThumbnailContainer = ({
  eligibilityResponse,
}: {
  eligibilityResponse: any;
}) => {
  const [isContentCollapsed, setContentCollapsed] = useState<boolean>(true);
  const getLargeCriteriaDescription = (description: string) => {
    if (isContentCollapsed) {
      return description.substring(0, CRITERIA_TEXT_LIMIT);
    }
    return description;
  };

  return (
    <Grid item xs={2} className="sampleThumbnailContainer">
      <Grid item xs={12} className="sampleTemplateText">
        <Typography>Sample Template</Typography>
      </Grid>
      {eligibilityResponse?.selectedTemplate?.thumbnails && (
        <Box className="thumbnailBox">
          <SwipeableImageStepper
            images={eligibilityResponse?.selectedTemplate?.thumbnails.map(
              (thumbnail: any) => {
                return { image: thumbnail.medium };
              }
            )}
            containerHeight={"unset"}
            height={"unset"}
          />
        </Box>
      )}
      <Box className="recipientsCriteriaList">
        {eligibilityResponse?.selectedTemplate?.criteria.map(
          (criteria: any, index: number) => {
            return (
              <ListItem key={index} className="recipientsCriteriaListItem">
                <ListItemIcon className="recipientsCriteriaListItemIcon">
                  <StarIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <div className="criteriaName">
                      {criteria.criteria_description.length <
                      CRITERIA_TEXT_LIMIT ? (
                        <>{criteria.criteria_description}</>
                      ) : (
                        <>
                          {getLargeCriteriaDescription(
                            criteria.criteria_description
                          )}{" "}
                          <div>
                            <Button
                              className="showMore"
                              variant="text"
                              onClick={() =>
                                setContentCollapsed(!isContentCollapsed)
                              }
                            >
                              {isContentCollapsed ? "Show more.." : "Show less"}
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  }
                />
              </ListItem>
            );
          }
        )}
      </Box>
    </Grid>
  );
};

export default ThumbnailContainer;
