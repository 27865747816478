import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import React from "react";

const LetterCriteriaTooltip = ({ templateCriteria }: any) => {
  return (
    <List>
      {templateCriteria?.map((criteria: any, index: number) => {
        return (
          <ListItem key={index}>
            <ListItemIcon>
              {criteria.criteria_met ? (
                <DoneIcon fontSize="small" color="success" />
              ) : (
                <CloseIcon fontSize="small" color="error" />
              )}
            </ListItemIcon>
            <ListItemText
              primary={
                <div
                  className="criteriaName"
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {criteria.criteria_description}
                </div>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default LetterCriteriaTooltip;
