import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Assuming you have FontAwesome set up
import { EditableFieldOption } from "../data-grid-cols";
import { faPen, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Grid,
  TextField,
} from "@mui/material";
import "./EditableDropDownRenderer.scss";

interface ChildProps {
  cellText: string;
  options: EditableFieldOption[];
  value: EditableFieldOption | null;
  handleSubmitCallBack: any;
  id: string;
  fieldName: string;
  isEditable: boolean;
}

const EditableDropDownRenderer: React.FC<ChildProps> = ({
  cellText,
  options,
  value,
  handleSubmitCallBack,
  id,
  fieldName,
  isEditable,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelectedValue] = useState<EditableFieldOption | null>(
    value
  );
  const [lastStatus, setLastStatus] = useState<EditableFieldOption | null>(
    value
  );
  const [statusOptions, setStatusOptions] =
    useState<EditableFieldOption[]>(options);
  const [position, setPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });
  const [currentFieldText, setCurrentFieldText] = useState<string>(cellText);
  const elementID = `${fieldName}-${id}`;
  let field = fieldName?.replace(/_/g, " ");
  const dropdownLabel =
    "Choose a " + field.charAt(0).toUpperCase() + field.slice(1);
  const editableGridCell =
    currentFieldText === null || currentFieldText === ""
      ? "emptyGridCell"
      : "editableGridCell";

  const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPosition({
      top:
        event.currentTarget.parentElement?.offsetTop ||
        event.currentTarget.offsetTop,
      left:
        event.currentTarget.parentElement?.offsetLeft ||
        event.currentTarget.offsetLeft,
    });
    setEditMode(true);
    handleMouseLeave();
  };

  const handleOnChange = (newValue: EditableFieldOption | null) => {
    if (newValue) {
      setSelectedValue(newValue);
      setLoading(false);
    }
  };

  const handleClose = () => {
    setEditMode(false);
    setSelectedValue(lastStatus);
  };

  const handleSubmit = async () => {
    setEditMode(false);
    setCurrentFieldText(selected?.label || "");
    setLoading(true);
    let result = await handleSubmitCallBack(selected);
    if (result.type === "error") {
      setCurrentFieldText(lastStatus?.label || "");
      setSelectedValue(lastStatus);
    } else if (result.type === "success") {
      setCurrentFieldText(selected?.label || "");
      setLastStatus(selected);
      if (result.options) {
        setStatusOptions(result.options); // updating options based upon new status
      }
    }
    setLoading(false);
  };

  const handleMouseEnter = () => {
    if (!editMode) {
      const pencilIcon = document.getElementById(elementID);
      if (pencilIcon) {
        pencilIcon.style.visibility = "visible";
      }
    }
  };

  const handleMouseLeave = () => {
    if (!editMode) {
      const pencilIcon = document.getElementById(elementID);
      if (pencilIcon) {
        pencilIcon.style.visibility = "hidden";
      }
    }
  };

  const NonEditableField = () => {
    return <Box className={"nonEditableGridCell"}>{currentFieldText}</Box>;
  };

  return (
    <>
      {editMode && (
        <ClickAwayListener onClickAway={handleClose}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            className="editableDropDownContainer"
            style={{ top: position.top, left: position.left }}
          >
            <Grid item xs={12}>
              <Autocomplete
                value={selected}
                onChange={(event, newValue) => handleOnChange(newValue)}
                options={statusOptions}
                getOptionLabel={(option: EditableFieldOption) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label={dropdownLabel} />
                )}
                renderOption={(props, option) => (
                  <li {...props}>{option.label}</li>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item xs={4}>
                  <Button
                    className="viewControl"
                    onClick={handleClose}
                    startIcon={
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="editableDropDownIconButtons"
                      />
                    }
                    data-testid="cancel-button"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    className="viewControl"
                    onClick={handleSubmit}
                    startIcon={
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="editableDropDownIconButtons"
                      />
                    }
                    disabled={lastStatus?.id === selected?.id}
                    data-testid="submit-button"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ClickAwayListener>
      )}
      {loading ? (
        <Button
          disabled
          sx={{
            textTransform: "none",
            textDecoration: "underline",
          }}
        >
          {currentFieldText}
          <CircularProgress style={{ width: "10px", height: "10px" }} />
        </Button>
      ) : isEditable ? (
        <Box
          className={editableGridCell}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Button
            onClick={handleEditClick}
            sx={{
              textTransform: "none",
              textDecoration: "underline",
            }}
          >
            {currentFieldText}
            <FontAwesomeIcon
              icon={faPen}
              id={elementID}
              style={{
                visibility: "hidden",
                position: "relative",
                left: "5px",
                zIndex: 999,
              }}
              className="editableDropDownIconButtons"
            />
          </Button>
        </Box>
      ) : (
        <NonEditableField />
      )}
    </>
  );
};

export default EditableDropDownRenderer;
