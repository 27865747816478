import React, { useEffect, useState } from "react";
import parser from "./parser";
import {
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  List,
  ListItem,
  Box,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/material";

function View({ question, context }) {
  const [searchable, setSearchable] = useState("");
  const [selectedNumber, setSelectedNumber] = useState(null);
  const input = parser.parse(question.input, context);
  const locale = question.properties.locale;
  const [options, setOptions] = useState();
  const tempOptions = context.pick("options") || question.input.options;

  useEffect(() => {
    setOptions(
      tempOptions?.filter(
        ({ number }) =>
          number &&
          typeof number === "string" &&
          number.toUpperCase().includes(searchable.trim().toUpperCase())
      )
    );
    context.assign({
      canSubmit: false,
    });
  }, [searchable]);

  const handleSearchableChange = (event) => {
    setSearchable(event.target.value);
  };

  const handleRadioChange = (event) => {
    setSelectedNumber(event.target.value);
    context.assign({
      value: event.target.value,
      canSubmit: true,
    });
  };

  return (
    <Box dir={locale.dir} className="inputs-radio-list">
      {input.searchable && (
        <Box className="search-input">
          <TextField
            fullWidth
            label={locale.searchable}
            type="text"
            name="text"
            id="ref-search"
            value={searchable}
            onChange={handleSearchableChange}
            autoComplete="off"
            InputProps={{
              style: { fontSize: "larger" },
            }}
          />
        </Box>
      )}

      <FormControl error>
        <RadioGroup
          name="radio"
          value={selectedNumber}
          onChange={handleRadioChange}
          required={input.required}
        >
          <List>
            {options &&
              options.map((item, index) => (
                <ListItem key={index} className="md-2-line">
                  {question.properties.answer_key ? (
                    <FormControlLabel
                      value={item[input.value_key]}
                      control={<Radio disabled={input.readonly} />}
                      label={
                        <div className="md-list-item-text">
                          <Typography
                            variant="h6"
                            className="registration-number"
                          >
                            {parser.interpolate(locale.label, item)}
                          </Typography>
                          {/* Using dangerouslySetInnerHTML because locale.label_more contains html tags. */}
                          {locale.label_more && (
                            <Typography
                              className="address-text"
                              dangerouslySetInnerHTML={{
                                __html: parser.interpolate(
                                  locale.label_more,
                                  item
                                ),
                              }}
                            ></Typography>
                          )}
                        </div>
                      }
                      id={`ref-${item[input.value_key]}`}
                    />
                  ) : (
                    <div className="md-list-item-text">
                      <Typography variant="h4" className="registration-number">
                        {parser.interpolate(locale.label, item)}
                      </Typography>
                      {/* Using dangerouslySetInnerHTML because locale.label_more contains html tags. */}
                      {locale.label_more && (
                        <Typography
                          className="address-text"
                          dangerouslySetInnerHTML={{
                            __html: parser.interpolate(locale.label_more, item),
                          }}
                        ></Typography>
                      )}
                    </div>
                  )}
                </ListItem>
              ))}
          </List>
        </RadioGroup>
        {options && options.length > 0 && input.required && !selectedNumber && (
          <FormHelperText error>Required</FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}

export default View;
