import React from "react";

import { MenuItem, Select } from "@mui/material";
import "./GridDropdownComponent.scss";

interface GridDropdownProps {
  value: string;
  options: { label: string; value: string; [key: string]: any }[];
  onChange: (value: string) => void;
  isLoading: boolean;
  onDropdownOpen: () => void;
  disabled: boolean;
}

const GridDropdownComponent = (props: GridDropdownProps) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Select
        value={props.value}
        onChange={(event) => {
          props.onChange(event.target.value);
        }}
        onOpen={props.onDropdownOpen}
        className="dropdownWithEllipsis"
        disabled={props.disabled}
      >
        {props.options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default GridDropdownComponent;
