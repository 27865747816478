const dot = require("dot-object");
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import {
  Stack,
  IconButton,
  FormControl,
  FormHelperText,
  TextField,
  InputAdornment,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import hooks from "./hooks";
import parser from "./parser";

function View({ question, context }) {
  const [results, setResults] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    output: context.pick("value") || [],
  });

  question.input = parser.parse(question.input, context);
  if (!question.input.fields) {
    question.input.fields = [{ key: "amount" }];
  }

  useEffect(() => {
    if (results) return;

    hooks
      .gql(
        {
          query: `
        query($jurisdiction_id:ID! $foreign_type:FilingType! $foreign_id:ID) {
          platforms { hits { id name } }

          filingPeriods(jurisdiction_id:$jurisdiction_id foreign_type:$foreign_type foreign_id:$foreign_id) {
            filing_frequency
            period { start_at end_at }
            periods { start_at end_at }
          }
        }
      `,
          variables: parser.parse(question.input.subject, context),
        },
        context
      )
      .then((res) => {
        setResults(res);

        let fields = {};
        question.input.fields.forEach((field) => {
          if (field.default) {
            fields[field.key] = field.default;
          }
        });
        let defaultValue = [];
        res.data?.filingPeriods?.periods.forEach((period) => {
          question.input.platforms.forEach((platform_id) => {
            defaultValue.push(
              Object.assign(
                {
                  period: {
                    start_at: period.start_at,
                    end_at: period.end_at,
                  },
                  platform_id: platform_id === "null" ? null : platform_id,
                  foreign_id: parser.getContextValue(
                    question.input.subject.foreign_id,
                    context
                  ),
                },
                fields
              )
            );
          });
        });

        if (formValues.output.length === 0) {
          let values = { ...formValues };
          values.output = defaultValue;
          setFormValues(values);
        }
      });
  }, [results]);

  const getPlatformById = (id) => {
    const res = results.data.platforms.hits.filter((o) => o.id === id);
    return res.length > 0 ? res[0] : false;
  };

  const removeInterval = (i) => {
    let values = { ...formValues };
    values.output.splice(i, 1);
    setFormValues(values);
  };

  const validate = (formValues) => {
    let tmp = { ...formErrors };

    // any additional validation... // TODO
    console.warn(formValues);

    setFormErrors(tmp);
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    if (!isNaN(value)) {
      let values = { ...formValues };
      dot.str(name, value !== "" ? parseInt(value) : value, values);
      setFormValues(values);
      validate({ [name]: value });
      context.assign({
        value: values.output,
      });
    }
  };

  return (
    <div>
      {results &&
        formValues.output.map((filing, i) => {
          return (
            <div key={`filing-${i}`}>
              <h2>
                {filing.platform_id &&
                  [null, "OTHER"].indexOf(filing.platform_id) === -1 && (
                    <span>{question.properties.locale.other_placeholder} </span>
                  )}
                {filing.platform_id &&
                  [null, "OTHER"].indexOf(filing.platform_id) > -1 && (
                    <span>{getPlatformById(filing.platform_id).name} </span>
                  )}
                {dayjs(filing.period.start_at).format("MMMM YYYY")}
                {results.data.filingPeriods.filing_frequency ===
                  "QUARTERLY" && (
                  <span>
                    {" "}
                    - {dayjs(filing.period.end_at).format("MMMM YYYY")}
                  </span>
                )}
              </h2>

              <Stack direction={{ xs: "column", sm: "row" }}>
                {question.input.fields.map((field, fieldId) => {
                  return (
                    <FormControl error fullWidth key={fieldId}>
                      <TextField
                        name={`output.${i}.${field.key}`}
                        label={question.properties.locale[field.key]}
                        variant="filled"
                        value={formValues.output[i][field.key]}
                        onChange={handleInputChange}
                        required={field.required}
                        readOnly={field.readonly}
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          style: { fontSize: "20px" },
                        }}
                        autoComplete="off"
                        aria-describedby={`filing-${i}-${field}-helper-text`}
                        InputProps={
                          field.key === "amount" ||
                          field.key === "avg_nightly_rate"
                            ? {
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    style={{ marginRight: "2px" }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "#000000cf",
                                      }}
                                    >
                                      $
                                    </span>
                                  </InputAdornment>
                                ),
                              }
                            : {}
                        } // Masking the field with $
                      />
                      <FormHelperText
                        id={`filing-${i}-${field}-helper-text`}
                        style={{ fontSize: "0.9rem" }}
                      >
                        {formErrors.text}
                      </FormHelperText>
                    </FormControl>
                  );
                })}

                {context.get().admin === true && ( // TODO: use scopes not `admin`
                  <IconButton onClick={() => removeInterval(i)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </IconButton>
                )}
              </Stack>
            </div>
          );
        })}
    </div>
  );
}

export default View;
