import { Box, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { RentalUnitContext } from "../../../../../RentalUnitContext";
import { MatchDetailCheckbox } from "../MatchDetailCheckbox/MatchDetailCheckbox";

export interface MatchDetailsCheckbox {
  evidence: {
    evidence_index: number;
    comments: string;
    photo_url: string;
    screenshot_url: string;
    evidence_url: string;
  };
}
interface MatchDetailCheckboxGroupProps {
  matchDetailCheckboxes: MatchDetailsCheckbox[];
  evidencesChecked: number[];
  onEvidenceChecked: (
    checked: boolean,
    listingID: string,
    evidenceIndex: number
  ) => void;
  listingId: string;
  commentProp: string | null;
  onCommentChange: (value: {
    internal_listing_id: string;
    comment: string;
  }) => void;
}

export const MatchDetailCheckboxGroup = ({
  matchDetailCheckboxes,
  listingId,
  commentProp,
  onCommentChange,
  evidencesChecked,
  onEvidenceChecked,
}: MatchDetailCheckboxGroupProps) => {
  const { listingTables } = useContext(RentalUnitContext);
  const [comment, setComment] = useState<string>(commentProp || "");
  const [commentError, setCommentError] = useState<string>("");
  const parseIdToIndex = (id: string) => {
    return (
      listingTables?.hits.findIndex(
        (item) => item.internal_listing_id === id
      ) || 0
    );
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newComment = event.target.value;
    setComment(newComment);
    if (newComment.trim() === "") {
      setCommentError("Verify comment is required.");
    } else {
      setCommentError("");
    }
  };

  useEffect(() => {
    if (comment === null || comment.trim() === "") {
      setCommentError("Verify comment is required.");
    } else {
      setCommentError("");
    }

    onCommentChange({
      comment: comment,
      internal_listing_id: listingId,
    });
  }, [comment]);

  const handleEvidenceCheckboxChange = (
    checked: boolean,
    evidenceIndex: number
  ) => {
    onEvidenceChecked(checked, listingId, evidenceIndex);
  };

  return listingTables ? (
    <Box>
      <Typography variant="body1">
        {listingTables.hits[parseIdToIndex(listingId)].listing_title}
      </Typography>
      <TextField
        autoFocus
        id="comments"
        label="Verify comment"
        sx={{ width: "100%", margin: "20px 0" }}
        value={comment}
        onChange={handleChange}
        required
        error={commentError !== ""}
        helperText={commentError}
      />
      <div>
        {matchDetailCheckboxes.length !== 0 ? (
          matchDetailCheckboxes.map((item, index) => (
            <MatchDetailCheckbox
              {...item}
              checked={evidencesChecked.includes(item.evidence.evidence_index)}
              key={index}
              onCheckboxChange={(checked) =>
                handleEvidenceCheckboxChange(
                  checked,
                  item.evidence.evidence_index
                )
              }
            />
          ))
        ) : (
          <Typography variant="body1">No evidence for this listing</Typography>
        )}
      </div>
    </Box>
  ) : null;
};
