import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  ButtonUnstyled,
  CircularProgress,
  Typography,
} from "@mui/material";
import classNames from "classnames";
import { loader } from "graphql.macro";
import { formatDateWithOptionalTime } from "../../../../common/utils";
import { Letter, RentalUnitContext } from "../../RentalUnitContext";
import { Document, Page, pdfjs } from "react-pdf";
import saveAs from "file-saver";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "./LettersHistory.scss";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { LoadingScreen } from "../../../../../common/loading";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface LettersHistoryProps {
  pickedLetterId: number;
}

const query = loader("./Letters.graphql");

export const LettersHistory = (props: LettersHistoryProps) => {
  const { listings } = useContext(RentalUnitContext);

  const [letterListingData] = useState<(Letter | null)[]>([
    ...new Set(
      listings?.hits
        .map((item) => item.letters)
        .flat(1)
        .filter((item) => item !== undefined) || []
    ),
  ]);
  const [isFirstLetterFound, setIsFirstLetterFound] = useState<boolean>(false);
  const [isButtonQueried, setIsButtonQueried] = useState<boolean>(false);
  const [letterIds] = useState<string[]>(
    letterListingData.map((item: any) => item.id)
  );
  const [currentFirstLetterIndex, setCurrentFirstLetterIndex] =
    useState<number>(0);
  const [letters, setLetters] = useState<Letter[]>([]);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedLetter, setSelectedLetter] = useState<number>(0);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const [getLetters, { data: letterData, loading }] = useLazyQuery<{
    letters: { hits: Letter[] };
  }>(query, {
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (letters.length) setLetters([]);
  }, []);

  useEffect(() => {
    const ids = letterIds.slice(
      currentFirstLetterIndex,
      currentFirstLetterIndex + 9
    );
    const query = `id: "${ids.join('" OR id: "')}"`;
    getLetters({
      variables: {
        q: ids ? query : "",
      },
    });
  }, [currentFirstLetterIndex]);

  useEffect(() => {
    if (letterData) setLetters([...letters, ...letterData.letters.hits]);
  }, [letterData]);

  useEffect(() => {
    setSelectedLetter(
      letters.findIndex((item) => Number(item.id) === props.pickedLetterId)
    );
  }, [isFirstLetterFound]);

  useEffect(() => {
    if (currentFirstLetterIndex === 0) {
      setSelectedLetter(
        letters.findIndex((item) => Number(item.id) === props.pickedLetterId)
      );
    }
  }, [letters]);

  useEffect(() => {
    const button = document.querySelector(".selectedLetter");
    if (!isButtonQueried && button) {
      button?.scrollIntoView({ behavior: "smooth" });
      setIsButtonQueried(true);
    }
  });

  useEffect(() => {
    if (
      letters.length &&
      letters.find((item) => Number(item.id) === props.pickedLetterId) ===
        undefined
    ) {
      handleShowMore();
      setIsFirstLetterFound(false);
    } else {
      setIsFirstLetterFound(true);
    }
  }, [letters]);

  const handleShowMore = () => {
    if (currentFirstLetterIndex + 9 < letterIds.length)
      setCurrentFirstLetterIndex(currentFirstLetterIndex + 10);
  };

  const handleDownload = () => {
    if (letters && letters[selectedLetter].pdf_url)
      saveAs(
        letters[selectedLetter].pdf_url,
        `${
          letters[selectedLetter].customer_display_name
        } - ${formatDateWithOptionalTime(letters[selectedLetter].created_at)}`
      );
  };

  const handleChangeSelectedLetter = (index: number) => {
    setSelectedLetter(index);
    setPageNumber(1);
  };

  return letters.length ? (
    <Box className="letterModalBox">
      <div className="innerDiv">
        <aside className="aside">
          <div className="asideContainer">
            <div className="buttonsContainer">
              {letters.map((item, index) => (
                <Button
                  key={item.id}
                  title={item.created_at}
                  className={classNames("buttonElement", {
                    selectedLetter: selectedLetter === index,
                  })}
                  variant={selectedLetter === index ? "contained" : "outlined"}
                  onClick={() => {
                    if (selectedLetter !== index)
                      handleChangeSelectedLetter(index);
                  }}
                >
                  <div className="buttonInfo">
                    <Typography className="buttonHeadline">
                      {item.customer_display_name}
                    </Typography>
                    <Typography className="buttonDateTime">
                      {formatDateWithOptionalTime(item.created_at)}
                    </Typography>
                  </div>
                  <Typography className="buttonStatus">
                    {item.letter_recipient_status}
                  </Typography>
                </Button>
              ))}
              {letterIds.length > currentFirstLetterIndex + 9 ? (
                !loading ? (
                  <Typography
                    className="buttonDateTime buttonShowMore"
                    onClick={handleShowMore}
                  >
                    Show more
                  </Typography>
                ) : (
                  <div className="showMoreLoading">
                    <Typography color="secondary">Loading</Typography>
                    <CircularProgress
                      size={20}
                      sx={{ marginLeft: 1 }}
                      color="secondary"
                    />
                  </div>
                )
              ) : null}
            </div>
          </div>
        </aside>
        <section className="main">
          {letters.length &&
          letters[selectedLetter] &&
          letters[selectedLetter].created_at ? (
            <>
              <div
                key={letters[selectedLetter].id}
                className="letterInformation"
              >
                <div className="letterDate">
                  <Typography variant="button" className="date">
                    {formatDateWithOptionalTime(
                      letters[selectedLetter].created_at
                    )}
                  </Typography>
                </div>
                <div className="buttonGroup">
                  <ButtonUnstyled
                    className="topButton"
                    onClick={handleDownload}
                  >
                    <FontAwesomeIcon className="printIcon" icon={faDownload} />
                    Download
                  </ButtonUnstyled>
                </div>
              </div>
              <div className="letterWrapper">
                <Document
                  file={letters[selectedLetter].pdf_url}
                  onLoadSuccess={onDocumentLoadSuccess}
                  className="letter"
                >
                  <Page pageNumber={pageNumber}>
                    <div className="buttonGroup">
                      <Button
                        size="small"
                        onClick={() => setPageNumber(pageNumber - 1)}
                        disabled={pageNumber === 1}
                      >
                        <KeyboardArrowLeftIcon />
                      </Button>

                      <Button
                        size="small"
                        style={{ float: "right" }}
                        onClick={() => setPageNumber(pageNumber + 1)}
                        disabled={pageNumber === numPages}
                      >
                        <KeyboardArrowRightIcon />
                      </Button>
                    </div>
                  </Page>
                </Document>
              </div>
            </>
          ) : (
            <div className="loadingContainer">
              <LoadingScreen />
            </div>
          )}
        </section>
      </div>
    </Box>
  ) : (
    <div className="spinnerContainer">
      <LoadingScreen />
    </div>
  );
};
